import {TWorkspace} from '../../state/timeline/state';

import {
  WorkspaceBackgroundIndex,
  WorkspaceBackgroundShade,
  workspaceBackgroundShades,
} from './timeline.constants';

export const getWorkspaceOption = (
  options: TWorkspace['options'] | undefined,
  optionId: WorkspaceBackgroundIndex,
): TWorkspace['options'][number] | undefined => {
  if (!options) return;
  return options.find((o) => o.optionId === optionId);
};

export const getWorkspaceOptionValue = (
  options: TWorkspace['options'] | undefined,
  optionId: WorkspaceBackgroundIndex,
): TWorkspace['options'][number]['value'] | undefined => {
  if (!options) return;
  const option = getWorkspaceOption(options, optionId);
  if (!!option === null || option === undefined) return undefined;
  return option.value;
};

export const getIndexFromShade = (
  shade: WorkspaceBackgroundShade,
): WorkspaceBackgroundIndex | undefined => {
  const index = workspaceBackgroundShades.findIndex(
    (element: WorkspaceBackgroundShade) => element === shade,
  );
  if (index === -1) return;
  return index;
};

export const getShadeFromIndex = (
  index: WorkspaceBackgroundIndex,
): WorkspaceBackgroundShade | undefined => {
  const shade = workspaceBackgroundShades[index];
  if (!shade) return;
  return shade;
};
