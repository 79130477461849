import React, {ReactElement, ReactNode, useCallback, useMemo, useState} from 'react';

import {useDraggable} from '@dnd-kit/core';
import {CommentOutlined, DragIndicator, FolderOpen, LabelOutlined} from '@material-ui/icons/';
import {
  DataGridPro,
  GridCellEditCommitParams,
  GridColDef,
  GridEditRowsModel,
  GridSelectionModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import {unstable_batchedUpdates} from 'react-dom';
import {useDispatch} from 'react-redux';
import {graphql, useFragment} from 'react-relay';

import {evidenceList_folderEvidenceFull$key} from '../../../graphql/__generated__/evidenceList_folderEvidenceFull.graphql';

import useUpdateOccuredOnDate from '../../../hooks/mutations/update-evidence-occurred-on-date.hook';

import {
  customDateTimeString,
  hasIntersection,
  humanFileSize,
  isNotNullish,
  isValidDate,
  muiDateTimeString,
} from '../../../utils/utils';

import {setApiFeedback} from '../../../state/common/actions';

import {EvidenceRow} from './evidence-list.controller';

type DraggableProps = {
  id: string;
  type: string;
  children: ReactNode;
};

export function Draggable(props: DraggableProps): ReactElement {
  const {id, type, children} = props;
  const {attributes, listeners, setNodeRef} = useDraggable({
    id: `draggable-${id}`,
    data: {
      type: type,
    },
  });

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
}

type Props = {
  currentFolderId: string;
  currentTagFilterIds: number[];
  folder: evidenceList_folderEvidenceFull$key;
  setSelectedEvidenceIds(ids: string[]): void;
};

const EvidenceListView = ({
  currentFolderId,
  currentTagFilterIds,
  folder,
  setSelectedEvidenceIds,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const [updateOccuredOnDate] = useUpdateOccuredOnDate();
  const [editRowsModel, setEditRowsModel] = useState<GridEditRowsModel>({});
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();

  const data = useFragment(
    graphql`
      fragment evidenceList_folderEvidenceFull on Folder {
        evidences {
          edges {
            node {
              id
              fileName: name
              storedOn: created
              eventDate
              size
              folderCount
              tagCount
              commentCount
              tags: tagsWithIds {
                id
                text
              }
            }
          }
        }
      }
    `,
    folder,
  );

  const handleEditRowsModelChange = useCallback((newModel: GridEditRowsModel) => {
    const updatedModel = {...newModel};
    Object.keys(updatedModel).forEach((id) => {
      if (updatedModel[id].eventDate) {
        const isValid = isValidDate(updatedModel[id].eventDate.value as string);
        updatedModel[id].eventDate = {...updatedModel[id].eventDate, error: !isValid};
      }
    });
    setEditRowsModel(updatedModel);
  }, []);

  const handleCellEditCommit = useCallback(({id, field, value}: GridCellEditCommitParams) => {
    if (field === 'eventDate' && isNotNullish(value)) {
      const eventDate = new Date(value as Date);
      // why only one id and multiple filenames?
      // ids can come from selectionModel but multiple row editing is not possible.
      // we'd have to add a date editing component to evidence detail
      const evidenceIds = [id.toString()];
      const evidenceFilenames = rows
        ?.filter((fe) => isNotNullish(fe.id) && evidenceIds.includes(fe.id))
        .map(({fileName}) => fileName)
        .join(', ');
      const callback = () => {
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Occurred On date: ${customDateTimeString(
              eventDate,
            )} changed for Evidence: ${evidenceFilenames}`,
          }),
        );
      };
      updateOccuredOnDate(eventDate, evidenceIds, callback);
    }
  }, []);

  const rows = useMemo(() => {
    if (!data?.evidences?.edges) {
      return [];
    }
    const evidenceNodes = data?.evidences?.edges?.map((e) => ({
      ...e?.node,
      tags: e?.node.tags?.map((t) => t.id),
    }));

    let taggedFolderRows = [] as EvidenceRow[];
    if (currentTagFilterIds?.length > 0) {
      taggedFolderRows = evidenceNodes?.filter((r) => {
        return hasIntersection(currentTagFilterIds, r.tags?.map((t) => parseInt(t, 10)) ?? []);
      });
    } else {
      taggedFolderRows = evidenceNodes;
    }

    const filteredFolderRows = taggedFolderRows
      ?.sort((a, b) => {
        if (typeof a.fileName === 'undefined' || typeof b.fileName === 'undefined') {
          return 0;
        }
        return a.fileName.localeCompare(b.fileName);
      })
      .map(({id, fileName, storedOn, eventDate, size, folderCount, tagCount, commentCount}) => ({
        id,
        fileName,
        storedOn: storedOn ? customDateTimeString(storedOn) : 'Unknown',
        eventDate: eventDate ? muiDateTimeString(eventDate) : 'Unknown',
        size,
        folderCount,
        tagCount,
        commentCount,
      }));
    return filteredFolderRows;
  }, [data, currentTagFilterIds]);

  const columns: GridColDef[] = [
    {
      field: 'draggable',
      headerName: ' ',
      width: 20,
      minWidth: 32,
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => '',
      renderCell: (params) => {
        const {id} = params.row;

        return (
          <Draggable id={id} key={id} type={'folder'}>
            <DragIndicator />
          </Draggable>
        );
      },
    },
    {
      field: 'fileName',
      headerName: 'File name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'storedOn',
      headerName: 'Stored On',
      type: 'dateTime',
      minWidth: 160,
    },
    {
      field: 'eventDate',
      headerName: 'Occurred On',
      minWidth: 160,
      editable: true,
      type: 'dateTime',
      valueFormatter: (params: GridValueFormatterParams) => {
        return customDateTimeString(params.value as string);
      },
    },
    {
      field: 'size',
      headerName: 'Size',
      minWidth: 105,
      align: 'right',
      renderCell: (params) => {
        return humanFileSize(params.row.size);
      },
    },
    {
      field: 'folderCount',
      renderHeader: () => <FolderOpen fontSize={'small'} />,
      align: 'right',
      width: 65,
      disableColumnMenu: true,
    },
    {
      field: 'tagCount',
      renderHeader: () => <LabelOutlined fontSize={'small'} />,
      align: 'right',
      width: 65,
      disableColumnMenu: true,
    },
    {
      field: 'commentCount',
      renderHeader: () => <CommentOutlined fontSize={'small'} />,
      align: 'right',
      width: 65,
      disableColumnMenu: true,
    },
  ];

  const handleSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    unstable_batchedUpdates(() => {
      setSelectionModel(newSelectionModel);
      setSelectedEvidenceIds(newSelectionModel as string[]);
    });
  };

  return (
    rows && (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
        <DataGridPro
          autoHeight
          checkboxSelection
          checkboxSelectionVisibleOnly
          columns={columns}
          density={'compact'}
          editRowsModel={editRowsModel}
          key={`grid-${currentFolderId}`}
          onCellEditCommit={handleCellEditCommit}
          onEditRowsModelChange={handleEditRowsModelChange}
          onSelectionModelChange={handleSelectionModelChange}
          pageSize={25}
          pagination
          rows={rows}
          rowsPerPageOptions={[25, 50, 100]}
          selectionModel={selectionModel}
        />
      </div>
    )
  );
};

export default EvidenceListView;
