import React, {ReactElement, useEffect} from 'react';

import {useLazyLoadQuery} from 'react-relay';

import {tagsQuery} from '../../../graphql/__generated__/tagsQuery.graphql';

import {mimeLookup} from '../../../services/evidences/data/evidence/content-type';
import MimeType from '../../../services/evidences/data/evidence/mime-type';
import {ITag} from '../../../services/evidences/data/tag/tag';
import {TEvidence, TTag} from '../../../services/evidences/data/types';
import tagsQueryGQL from './tags.query';
import TagsAdminView from './tags.view';

type Props = {
  allTags: TTag[] | undefined;
  allEvidence: TEvidence[] | undefined;
  setTags(Array: ITag[]): void;
  setEvidence(Array: TEvidence[]): void;
};

function TagsAdminController({allTags, allEvidence, setTags, setEvidence}: Props): ReactElement {
  const data = useLazyLoadQuery<tagsQuery>(tagsQueryGQL, {});
  const account = data.currentSession?.account ?? undefined;
  if (account === undefined) {
    throw new Error('no account');
  }

  const activeTags = account?.activeTagsWithIds ?? undefined;
  const activeTagsWithNumIds = activeTags.map((tag) => ({
    ...tag,
    id: parseInt(tag.id),
  }));

  const allFilesFolder = account?.allFilesFolder ?? undefined;
  const evidenceForState = allFilesFolder.evidences?.edges?.flat().map((e) => ({
    ...e?.node,
    contentType: e?.node.mimeType
      ? mimeLookup(e?.node.mimeType as MimeType)
      : mimeLookup('text/journalentry'),
    folderIds: e?.node.folders.map((f) => f.id),
    tagIds: e?.node.tags.map((t) => parseInt(t.id)),
  }));

  useEffect(() => {
    if (activeTagsWithNumIds !== undefined) {
      setTags(activeTagsWithNumIds as ITag[]);
    }
    if (evidenceForState !== undefined) {
      setEvidence(evidenceForState as unknown as TEvidence[]);
    }
  }, []);

  return <TagsAdminView allEvidence={allEvidence} allTags={allTags} />;
}

export default TagsAdminController;
