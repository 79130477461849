/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateUserInput = {
    email: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateUserControllerMutationVariables = {
    input: CreateUserInput;
};
export type CreateUserControllerMutationResponse = {
    readonly createUser: {
        readonly user: {
            readonly email: string;
        };
    } | null;
};
export type CreateUserControllerMutation = {
    readonly response: CreateUserControllerMutationResponse;
    readonly variables: CreateUserControllerMutationVariables;
};



/*
mutation CreateUserControllerMutation(
  $input: CreateUserInput!
) {
  createUser(input: $input) {
    user {
      email
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUserControllerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUserPayload",
        "kind": "LinkedField",
        "name": "createUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateUserControllerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUserPayload",
        "kind": "LinkedField",
        "name": "createUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0985423482c9759004544a51384b666e",
    "id": null,
    "metadata": {},
    "name": "CreateUserControllerMutation",
    "operationKind": "mutation",
    "text": "mutation CreateUserControllerMutation(\n  $input: CreateUserInput!\n) {\n  createUser(input: $input) {\n    user {\n      email\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6e1dd0334dfc1de37b160e7f81f36ab0';
export default node;
