import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {setWorkspaceTagColor} from '../../../state/timeline/actions';
import {getSelectedWorkspaceId} from '../../../state/timeline/selectors';

import TagNodeColorPickerController from './tag-node-color-picker.controller';

const mapStateToProps = (state: TRootState) => ({
  selectedWorkspaceId: getSelectedWorkspaceId(state),
});

const mapDispatchToProps = {setWorkspaceTagColor};

const TagNodeColorPicker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TagNodeColorPickerController);

export default TagNodeColorPicker;
