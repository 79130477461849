import {connect} from 'react-redux';

import {setWorkspaceModified, setWorkspaceName} from '../../../../state/timeline/actions';

import RenameWorkspaceModalController from './rename-workspace-modal.controller';

const mapDispatchToProps = {setWorkspaceName, setWorkspaceModified};

const RenameWorkspaceModal = connect(undefined, mapDispatchToProps)(RenameWorkspaceModalController);

export default RenameWorkspaceModal;
