import React, {ReactElement} from 'react';

import Grid from '@material-ui/core/Grid';

import ContentCard from '../../../components/surfaces/content-card';
import InputsAccordion from '../../../components/surfaces/inputs-accordion';

type Props = {
  username: string;
  email: string;
};

const DetailsView = ({username, email}: Props): ReactElement => {
  return (
    <Grid alignItems={'center'} container direction={'column'} spacing={4}>
      <Grid item xs={12}>
        <ContentCard
          header={'Personal info'}
          subheader={'View and manage descriptive information about you'}
        >
          <InputsAccordion disabled panelId={1} settingName={'Username'} settingValue={username} />
          <InputsAccordion disabled panelId={2} settingName={'Email'} settingValue={email} />
        </ContentCard>
      </Grid>
    </Grid>
  );
};

export default DetailsView;
