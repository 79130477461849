import {createAction} from 'typesafe-actions';

import {ApiFeedbackRedux} from '../../components/feedback/api-feedback';

import type {UserAccount} from '../common/state';

export const setIsAuthenticated = createAction('@common/setIsAuthenticated')<boolean>();

export const setApiFeedback = createAction('@common/setApiFeedback')<ApiFeedbackRedux>();

export const setAccessedAccount = createAction('@common/setAccessedAccount')<string | null>();

export const setAuthenticatedUser = createAction(
  '@common/setAuthenticatedUser',
)<UserAccount | null>();

export const setImpersonatedUser = createAction(
  '@common/setImpersonatedUser',
)<UserAccount | null>();
