/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserRole = "ADMIN" | "%future added value";
export type headerNav_userRoles = {
    readonly id: string;
    readonly roles: ReadonlyArray<UserRole> | null;
    readonly " $refType": "headerNav_userRoles";
};
export type headerNav_userRoles$data = headerNav_userRoles;
export type headerNav_userRoles$key = {
    readonly " $data"?: headerNav_userRoles$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"headerNav_userRoles">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "headerNav_userRoles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '2f9653b62cc0c80dc8f4a150af1e4495';
export default node;
