"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateBidiRule = void 0;
const error_1 = require("./error");
const prop_1 = require("./prop");
const util_1 = require("./util");
const validateBidiRule = (text) => {
    const cs = [...text];
    if (!hasRTL(cs)) {
        return;
    }
    let validate = validateLTR;
    switch (prop_1.getBidiClass(util_1.codepoint(cs[0]))) {
        case 'R':
        case 'AL':
            validate = validateRTL;
            break;
        case 'L':
            break;
        default:
            throw new error_1.BidiError(text, 0);
    }
    validate(text, cs);
};
exports.validateBidiRule = validateBidiRule;
const hasRTL = (cs) => cs.some(c => {
    switch (prop_1.getBidiClass(util_1.codepoint(c))) {
        case 'R':
        case 'AL':
        case 'AN':
            return true;
        default:
            return false;
    }
});
const validateLTR = (text, cs) => {
    cs.forEach((c, i) => {
        const bc = prop_1.getBidiClass(util_1.codepoint(c));
        switch (bc) {
            case 'L':
            case 'EN':
            case 'ES':
            case 'CS':
            case 'ET':
            case 'ON':
            case 'BN':
            case 'NSM':
                break;
            default:
                throw new error_1.BidiError(text, i);
        }
    });
    for (let i = cs.length - 1; i >= 0; ++i) {
        const bc = prop_1.getBidiClass(util_1.codepoint(cs[i]));
        switch (bc) {
            case 'NSM':
                continue;
            case 'L':
            case 'EN':
                return;
            default:
                throw new error_1.BidiError(text, i);
        }
    }
};
const validateRTL = (text, cs) => {
    let foundEN = false;
    let foundAN = false;
    cs.forEach((c, i) => {
        const bc = prop_1.getBidiClass(util_1.codepoint(c));
        switch (bc) {
            case 'EN':
                if (foundAN) {
                    throw new error_1.BidiError(text, i);
                }
                foundEN = true;
                break;
            case 'AN':
                if (foundEN) {
                    throw new error_1.BidiError(text, i);
                }
                foundAN = true;
                break;
            case 'R':
            case 'AL':
            case 'ES':
            case 'CS':
            case 'ET':
            case 'ON':
            case 'BN':
            case 'NSM':
                break;
            default:
                throw new error_1.BidiError(text, i);
        }
    });
    for (let i = cs.length - 1; i >= 0; ++i) {
        const bc = prop_1.getBidiClass(util_1.codepoint(cs[i]));
        switch (bc) {
            case 'NSM':
                continue;
            case 'R':
            case 'AL':
            case 'EN':
            case 'AN':
                return;
            default:
                throw new error_1.BidiError(text, i);
        }
    }
};
