import React, {ReactElement} from 'react';

import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Route, Switch, useLocation} from 'react-router-dom';

import {ACCOUNT_RECOVERY, HOME, LOGIN, RESET_PASSWORD} from '../../config/routes';

import AccountRecovery from './account-recovery';
import LoginForm from './login-form';
import ResetPassword from './reset-password';

export interface LocationState {
  from: {
    pathname: string;
  };
}

const useStyles = makeStyles((theme) => ({
  loginRoot: {
    marginTop: theme.spacing(6),
  },
}));

const LoginPage = (): ReactElement => {
  const classes = useStyles();

  const location = useLocation<LocationState>();
  const {from} = location.state || {from: {pathname: HOME}};

  return (
    <Grid
      alignItems={'center'}
      className={classes.loginRoot}
      container
      direction={'row'}
      justifyContent={'center'}
      spacing={1}
    >
      <Grid item lg={4} md={5} sm={6} xs={11}>
        <Switch>
          <Route exact path={ACCOUNT_RECOVERY}>
            <AccountRecovery />
          </Route>
          <Route path={RESET_PASSWORD}>
            <ResetPassword />
          </Route>
          <Route path={LOGIN}>
            <LoginForm referrer={from} />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
