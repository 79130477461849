import {RelayNetworkLayer, urlMiddleware} from 'react-relay-network-modern';
import type {RelayRequestAny} from 'react-relay-network-modern';
import {Environment, RecordSource, Store} from 'relay-runtime';

import {getAccessTokens, refreshTokens, setAccessTokens} from '../utils/tokens';

const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9090'
    : 'https://factimize-api-master.azurewebsites.net';

//https://api.factimize.com

// const baseURL =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:9090'
//     : 'https://factimize-api-master.azurewebsites.net';

const serverURL = `${baseURL}/graphql`;

const network = new RelayNetworkLayer([
  urlMiddleware({
    url: () => Promise.resolve(serverURL),
  }),
  (next) => async (req) => {
    const sendReq = async (req: RelayRequestAny) => {
      tokens = getAccessTokens();
      if (tokens !== undefined) {
        for (const [name, value] of Object.entries(tokens)) {
          req.fetchOpts.headers[name] = value;
        }
      }
      const resp = await next(req);
      if (resp.headers !== undefined) {
        // Relay's types are wrong here.
        // `resp.headers` is actually a Headers object, not a plain one.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setAccessTokens(resp.headers as any);
      }
      return resp;
    };

    let tokens;
    try {
      return await sendReq(req);
    } catch (e) {
      if (e.res && e.res.status === 401) {
        if (tokens !== undefined) {
          const idRefreshToken = tokens['facz-id-refresh-token'];
          await refreshTokens(baseURL, idRefreshToken);
          return await sendReq(req);
        }
      }
      throw e;
    }
  },
]);

const source = new RecordSource();
const store = new Store(source);
const environment = new Environment({network, store});

export default environment;
