import {either} from 'fp-ts/Either';
import * as t from 'io-ts';

export type Timestamp = number;

export const DateFromString = new t.Type<Date, string, unknown>(
  'DateFromString',
  (v): v is Date => v instanceof Date,
  (u, c) =>
    either.chain(t.string.validate(u, c), (s) => {
      const d = new Date(s);
      return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
    }),
  (a) => a.toISOString(),
);

export const fromEnum = <T extends string, TEnumValue extends string | number>(
  enumName: string,
  theEnum: {[key in T]: TEnumValue},
): t.Type<TEnumValue> => {
  const isEnumValue = (input: unknown): input is TEnumValue =>
    Object.values(theEnum).includes(input);

  return new t.Type<TEnumValue>(
    enumName,
    isEnumValue,
    (input, context) => (isEnumValue(input) ? t.success(input) : t.failure(input, context)),
    t.identity,
  );
};
