import React from 'react';

import {Button, ButtonGroup, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import {TWorkspaceSortKey} from '../../../state/timeline/state';

const useStyles = makeStyles((theme) => ({activeButton: {color: theme.palette.primary.main}}));

type Props = {
  viewSort: TWorkspaceSortKey;
  setViewSort(viewSort: TWorkspaceSortKey): void;
};

const ViewSortButtonGroup: React.FC<Props> = ({viewSort, setViewSort}) => {
  const classes = useStyles(),
    sortStore = viewSort === 'storedOn';

  return (
    <ButtonGroup color={'inherit'}>
      <Tooltip title={'Sort by Stored Date'}>
        <Button
          aria-pressed={sortStore}
          className={clsx({[classes.activeButton]: sortStore})}
          color={'inherit'}
          onClick={() => setViewSort('storedOn')}
          variant={sortStore ? 'contained' : 'outlined'}
        >
          stored date
        </Button>
      </Tooltip>
      <Tooltip title={'Sort by Occurrence Date'}>
        <Button
          aria-pressed={!sortStore}
          className={clsx({[classes.activeButton]: !sortStore})}
          color={'inherit'}
          onClick={() => setViewSort('eventDate')}
          variant={!sortStore ? 'contained' : 'outlined'}
        >
          occurrence date
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};

export default ViewSortButtonGroup;
