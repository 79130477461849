import {IsString, MaxLength, MinLength} from 'class-validator';

import Match from './custom-decorators/match';

export default class PasswordResetData {
  @IsString()
  @MinLength(6)
  @MaxLength(64)
  public password = '';

  @IsString()
  @Match('password')
  public passwordConfirm = '';
}
