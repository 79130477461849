import React from 'react';
import type {ReactElement, ReactNode} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {MuiThemeProvider} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Provider as StoreProvider} from 'react-redux';

import theme from '../../config/theme';

import {store} from '../../state/store';

import {container} from '../../services/common/container';
import {ContainerProvider} from './container-provider';
import RelayProvider from './relay-provider';

type Props = {
  children: ReactNode;
};

const ProviderWrapper = ({children}: Props): ReactElement => {
  return (
    <ContainerProvider container={container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StoreProvider store={store}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <RelayProvider>{children}</RelayProvider>
          </MuiThemeProvider>
        </StoreProvider>
      </MuiPickersUtilsProvider>
    </ContainerProvider>
  );
};

export default ProviderWrapper;
