import {connect} from 'react-redux';

import {getAuthenticatedUser} from '../../state/common/selectors';
import {setSelectedEvidenceIds} from '../../state/evidence/actions';
import {getCurrentFolderId, getSelectedEvidenceIds} from '../../state/evidence/selectors';
import {TRootState} from '../../state/store';

import EvidenceDetailController from './evidence-detail.controller';

const mapStateToProps = (state: TRootState) => ({
  currentFolderId: getCurrentFolderId(state),
  authenticatedUser: getAuthenticatedUser(state),
  selectedEvidenceIds: getSelectedEvidenceIds(state),
});

const mapDispatchToProps = {
  setSelectedEvidenceIds,
};

const EvidenceDetail = connect(mapStateToProps, mapDispatchToProps)(EvidenceDetailController);

export default EvidenceDetail;
