/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserRole = "ADMIN" | "%future added value";
export type routerViewQueryVariables = {};
export type routerViewQueryResponse = {
    readonly currentSession: {
        readonly user?: {
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly email: string;
            readonly roles: ReadonlyArray<UserRole> | null;
            readonly " $fragmentRefs": FragmentRefs<"headerNav_userRoles">;
        } | undefined;
        readonly " $fragmentRefs": FragmentRefs<"headerNav_prevUser">;
    } | null;
};
export type routerViewQuery = {
    readonly response: routerViewQueryResponse;
    readonly variables: routerViewQueryVariables;
};



/*
query routerViewQuery {
  currentSession {
    __typename
    ... on Session {
      ...headerNav_prevUser
      user {
        firstName
        lastName
        email
        roles
        ...headerNav_userRoles
        id
      }
    }
  }
}

fragment headerNav_prevUser on Session {
  user {
    email
    id
  }
  prevUser {
    id
  }
}

fragment headerNav_userRoles on User {
  id
  roles
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routerViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "headerNav_userRoles"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "headerNav_prevUser"
              }
            ],
            "type": "Session",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routerViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "prevUser",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e76baf496a8689953679c0916912ec5",
    "id": null,
    "metadata": {},
    "name": "routerViewQuery",
    "operationKind": "query",
    "text": "query routerViewQuery {\n  currentSession {\n    __typename\n    ... on Session {\n      ...headerNav_prevUser\n      user {\n        firstName\n        lastName\n        email\n        roles\n        ...headerNav_userRoles\n        id\n      }\n    }\n  }\n}\n\nfragment headerNav_prevUser on Session {\n  user {\n    email\n    id\n  }\n  prevUser {\n    id\n  }\n}\n\nfragment headerNav_userRoles on User {\n  id\n  roles\n}\n"
  }
};
})();
(node as any).hash = 'dc8346a40597c5084f742e894821d1eb';
export default node;
