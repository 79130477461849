import {ContainerModule} from 'inversify';

import TimelineService, {ITimelineService} from '../timeline-service';
import timelineKeys from './keys';

const timelineModule = new ContainerModule((bind) => {
  bind<ITimelineService>(timelineKeys.ITimelineService).to(TimelineService).inSingletonScope();
  bind<TimelineService>(timelineKeys.TimelineService).to(TimelineService).inSingletonScope();
});

export default timelineModule;
