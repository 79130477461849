import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  RemoveEvidenceFromFolderInput,
  removeEvidenceFromFolderMutation,
} from '../../graphql/__generated__/removeEvidenceFromFolderMutation.graphql';

const mutation = graphql`
  mutation removeEvidenceFromFolderMutation($input: RemoveEvidenceFromFolderInput!) {
    removeEvidenceFromFolder(input: $input) {
      userFolder {
        evidenceCount
        ...evidenceNav_tagsNav
        ...evidenceMain_tagFilter
        ...evidenceList_folderEvidenceFull
      }
      evidenceNodes {
        node {
          folderCount
          ...evidenceDetail_folderGroup
        }
      }
    }
  }
`;

function useRemoveEvidenceFromFolder(): [
  (userFolderId: string, evidenceIds: string[], callback: () => void) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<removeEvidenceFromFolderMutation>(mutation);
  return [
    useCallback(
      (userFolderId, evidenceIds, callback) => {
        const input: RemoveEvidenceFromFolderInput = {
          userFolderId,
          evidenceIds,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted() {
            callback();
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useRemoveEvidenceFromFolder;
