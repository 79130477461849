import React, {Fragment, ReactElement} from 'react';

import {Box, Grid, Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useLazyLoadQuery} from 'react-relay';
import {graphql} from 'relay-runtime';

import {evidenceDetailQuery} from '../../graphql/__generated__/evidenceDetailQuery.graphql';
import {evidenceDetailTaggedEvidenceQuery} from '../../graphql/__generated__/evidenceDetailTaggedEvidenceQuery.graphql';
import {evidenceDetail_actions$key} from '../../graphql/__generated__/evidenceDetail_actions.graphql';
import {evidenceDetail_activeTags$key} from '../../graphql/__generated__/evidenceDetail_activeTags.graphql';
import {evidenceDetail_addTag$key} from '../../graphql/__generated__/evidenceDetail_addTag.graphql';
import {evidenceDetail_comments$key} from '../../graphql/__generated__/evidenceDetail_comments.graphql';
import {evidenceDetail_folderGroup$key} from '../../graphql/__generated__/evidenceDetail_folderGroup.graphql';
import {evidenceDetail_grid$key} from '../../graphql/__generated__/evidenceDetail_grid.graphql';
import {evidenceDetail_info$key} from '../../graphql/__generated__/evidenceDetail_info.graphql';
import {evidenceDetail_metaData$key} from '../../graphql/__generated__/evidenceDetail_metaData.graphql';
import {evidenceDetail_multiple$key} from '../../graphql/__generated__/evidenceDetail_multiple.graphql';
import {evidenceDetail_tagGroup$key} from '../../graphql/__generated__/evidenceDetail_tagGroup.graphql';

import {ConditionalWrapper} from '../layout/conditional-wrapper';
import EvidenceDetailActions from './evidence-detail.actions';
import {Referrer} from './evidence-detail.controller';
import EvidenceGrid from './evidence-detail.grid';
import EvidenceInfo from './evidence-detail.info';
import EvidenceMetaData from './evidence-detail.meta-data';
import EvidenceMultiple from './evidence-detail.multiple';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: 'none',
  },
  details: {
    flexShrink: 0,
    height: '100%',
    width: '100%',
    position: 'relative',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  detailsContainer: {
    overflow: 'auto',
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
  },
}));

type Props = {
  account: evidenceDetail_activeTags$key;
  referrer: Referrer['referrer'];
  orientation?: 'horizontal' | 'vertical';
  selectedEvidenceIds: string[];
  taggedEvidence: string[] | undefined;
  currentFolderId: string;
  setSelectedEvidenceIds(ids: string[]): void;
};

export type EvidenceAllKeys = evidenceDetail_actions$key &
  evidenceDetail_info$key &
  evidenceDetail_multiple$key &
  evidenceDetail_folderGroup$key &
  evidenceDetail_tagGroup$key &
  evidenceDetail_addTag$key;

const EvidenceDetailView = ({
  account,
  referrer,
  orientation,
  selectedEvidenceIds,
  taggedEvidence,
  currentFolderId,
  setSelectedEvidenceIds,
}: Props): ReactElement => {
  const classes = useStyles();

  const direction = orientation === 'vertical' ? 'column' : 'row';

  const data = useLazyLoadQuery<evidenceDetailQuery>(
    graphql`
      query evidenceDetailQuery($ids: [ID!]!, $skip: Boolean!) {
        evidence: nodes(ids: $ids) @skip(if: $skip) {
          ...evidenceDetail_actions
          ...evidenceDetail_addTag
          ...evidenceDetail_comments
          ...evidenceDetail_folderGroup
          ...evidenceDetail_info
          ...evidenceDetail_metaData
          ...evidenceDetail_multiple
          ...evidenceDetail_tagGroup
        }
      }
    `,
    {
      ids: selectedEvidenceIds, // we need to pass something because of the query $ids type declaration
      skip: selectedEvidenceIds === undefined || selectedEvidenceIds.length === 0, // skip when there are no evidenceIds
    },
  );

  const gridData = useLazyLoadQuery<evidenceDetailTaggedEvidenceQuery>(
    graphql`
      query evidenceDetailTaggedEvidenceQuery($ids: [ID!]!, $skip: Boolean!) {
        evidence: nodes(ids: $ids) @skip(if: $skip) {
          ...evidenceDetail_grid
        }
      }
    `,
    {
      ids: taggedEvidence || [], // we need to pass something because of the query $ids type declaration
      skip: !taggedEvidence || taggedEvidence.length === 0, // skip when there are no evidenceIds
    },
  );

  if (!selectedEvidenceIds || selectedEvidenceIds.length === 0) {
    return (
      <Grid container direction={direction} spacing={4}>
        <Grid item xs>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <Typography variant={'h6'}>
                    Please select evidence rows to see individual or shared details.
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>{' '}
      </Grid>
    );
  }

  return (
    <Grid container direction={direction} spacing={4}>
      {referrer === 'workspace-tag-detail' && (
        <Fragment>
          <Grid item xs>
            <EvidenceGrid
              evidence={gridData.evidence as evidenceDetail_grid$key}
              setSelectedEvidenceIds={setSelectedEvidenceIds}
            />
          </Grid>
          <Grid item xs>
            <EvidenceDetailActions
              currentFolderId={currentFolderId}
              evidence={data.evidence as evidenceDetail_actions$key}
              referrer={referrer}
            />
            <Table>
              <TableBody>
                <EvidenceMetaData
                  account={account}
                  currentFolderId={currentFolderId}
                  evidence={data.evidence as evidenceDetail_metaData$key}
                  referrer={referrer}
                />
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs>
            <Table>
              <TableBody>
                <EvidenceInfo
                  account={account}
                  currentFolderId={currentFolderId}
                  evidence={
                    data.evidence as evidenceDetail_info$key &
                      evidenceDetail_comments$key &
                      evidenceDetail_folderGroup$key &
                      evidenceDetail_tagGroup$key &
                      evidenceDetail_addTag$key
                  }
                  referrer={referrer}
                />
              </TableBody>
            </Table>
          </Grid>
        </Fragment>
      )}
      {referrer !== 'workspace-tag-detail' && (
        <ConditionalWrapper
          condition={referrer === 'evidence-list'}
          key={referrer}
          wrapper={(children) => (
            <Box className={classes.details}>
              <Box className={classes.detailsContainer}>{children}</Box>
            </Box>
          )}
        >
          <Fragment>
            {selectedEvidenceIds && selectedEvidenceIds?.length > 1 && (
              <Grid item xs>
                <EvidenceMultiple
                  account={account}
                  currentFolderId={currentFolderId}
                  evidence={
                    data.evidence as evidenceDetail_multiple$key &
                      evidenceDetail_actions$key &
                      evidenceDetail_folderGroup$key &
                      evidenceDetail_tagGroup$key &
                      evidenceDetail_addTag$key &
                      evidenceDetail_comments$key
                  }
                  referrer={referrer}
                />
              </Grid>
            )}
            {selectedEvidenceIds && selectedEvidenceIds?.length === 1 && (
              <Fragment>
                <Grid item xs>
                  <EvidenceDetailActions
                    currentFolderId={currentFolderId}
                    evidence={data.evidence as evidenceDetail_actions$key}
                    referrer={referrer}
                  />
                  <Table>
                    <TableBody>
                      <EvidenceMetaData
                        account={account}
                        currentFolderId={currentFolderId}
                        evidence={data.evidence as evidenceDetail_metaData$key}
                        referrer={referrer}
                      />
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs>
                  <Table>
                    <TableBody>
                      <EvidenceInfo
                        account={account}
                        currentFolderId={currentFolderId}
                        evidence={
                          data.evidence as evidenceDetail_info$key &
                            evidenceDetail_comments$key &
                            evidenceDetail_folderGroup$key &
                            evidenceDetail_tagGroup$key &
                            evidenceDetail_addTag$key
                        }
                        referrer={referrer}
                      />
                    </TableBody>
                  </Table>
                </Grid>
              </Fragment>
            )}
          </Fragment>
        </ConditionalWrapper>
      )}
    </Grid>
  );
};

export default EvidenceDetailView;
