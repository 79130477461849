import React, {Suspense, useCallback, useState} from 'react';
import type {ReactElement} from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import LoadingScreen from '../../../components/feedback/loading-screen';
import ContentCard from '../../../components/surfaces/content-card';

import TagExport from './tools.tag-export';

const ToolsView = (): ReactElement => {
  const [downloading, setDownloading] = useState(false);

  const onDone = useCallback(() => {
    setDownloading(false);
  }, []);

  return (
    <Grid alignItems={'center'} container direction={'column'} spacing={4}>
      <Grid item xs={12}>
        <ContentCard
          header={'Export tags'}
          subheader={'Click the button below to retrieve a list of tags'}
        >
          <Grid alignItems={'center'} container direction={'column'}>
            <Grid item>
              {downloading ? (
                <Suspense fallback={<LoadingScreen />}>
                  <TagExport onDone={onDone} />
                </Suspense>
              ) : (
                <Button
                  color={'primary'}
                  onClick={() => {
                    setDownloading(true);
                  }}
                  variant={'contained'}
                >
                  Download
                </Button>
              )}
            </Grid>
          </Grid>
        </ContentCard>
      </Grid>
    </Grid>
  );
};

export default ToolsView;
