import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {
  setEvidences,
  setFolders,
  setTags,
  setTypes,
  setWorkspaces,
} from '../../../state/timeline/actions';
import {getSelectedWorkspace, getSelectedWorkspaceId} from '../../../state/timeline/selectors';

import TimelineBaseControllerComponent from './timeline-base.controller';

const mapStateToProps = (state: TRootState) => ({
  selectedWorkspace: getSelectedWorkspace(state),
  selectedWorkspaceId: getSelectedWorkspaceId(state),
});

const mapDispatchToProps = {
  setWorkspaces,
  setFolders,
  setTags,
  setTypes,
  setEvidences,
};

const TimelineBaseController = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimelineBaseControllerComponent);

export default TimelineBaseController;
