/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userDetails_userDetails = {
    readonly id: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly email: string;
    readonly gender: number | null;
    readonly timezone: string | null;
    readonly active: boolean | null;
    readonly activeDate: Date | null;
    readonly promoContact: boolean | null;
    readonly " $refType": "userDetails_userDetails";
};
export type userDetails_userDetails$data = userDetails_userDetails;
export type userDetails_userDetails$key = {
    readonly " $data"?: userDetails_userDetails$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"userDetails_userDetails">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userDetails_userDetails",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "promoContact",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'b18f254b0fb5bafca3289a9c133314bf';
export default node;
