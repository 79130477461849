import {connect} from 'react-redux';

import {removeWorkspace} from '../../../../state/timeline/actions';

import DeleteWorkspaceModalController from './delete-workspace-modal.controller';

const mapDispatchToProps = {deleteWorkspace: removeWorkspace};

const DeleteWorkspaceModal = connect(undefined, mapDispatchToProps)(DeleteWorkspaceModalController);

export default DeleteWorkspaceModal;
