import {Base64} from 'js-base64';

import {createClientAuth} from './crypto';
import type {ClientAuth, H, HMAC, Hi, RSAEncrypt, RandomBytes} from './crypto';

const randomBytes: RandomBytes = (n) => Promise.resolve(crypto.getRandomValues(new Uint8Array(n)));

export const h: H = async (data) => {
  const buffer = await crypto.subtle.digest('SHA-256', data);
  return new Uint8Array(buffer);
};

export const hmac: HMAC = async (key, data) => {
  const k = await crypto.subtle.importKey(
    'raw',
    key,
    {
      name: 'HMAC',
      hash: 'SHA-256',
    },
    false,
    ['sign'],
  );
  const buffer = await crypto.subtle.sign('HMAC', k, data);
  return new Uint8Array(buffer);
};

export const hi: Hi = async (data, salt, iterations) => {
  const key = await crypto.subtle.importKey('raw', data, 'PBKDF2', false, [
    'deriveBits',
    'deriveKey',
  ]);
  const derived = await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      hash: 'SHA-256',
      salt,
      iterations,
    },
    key,
    {
      name: 'HMAC',
      hash: 'SHA-256',
      length: 256,
    },
    true,
    ['sign', 'verify'],
  );
  const buffer = await crypto.subtle.exportKey('raw', derived);
  return new Uint8Array(buffer);
};

const rsaEncrypt: RSAEncrypt = async (key, data) => {
  const pub = await crypto.subtle.importKey(
    'spki',
    readPemPublicKey(key),
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    false,
    ['encrypt'],
  );
  return new Uint8Array(await crypto.subtle.encrypt({name: 'RSA-OAEP'}, pub, data));
};

const readPemPublicKey = (s: string): Uint8Array => {
  const lines = s.split('\n');
  let i = 0;
  for (; i < lines.length; ++i) {
    if (lines[i].includes('BEGIN PUBLIC KEY')) {
      ++i;
      break;
    }
  }
  if (i === lines.length) {
    throw new Error('No public key block found');
  }
  let b64Data = '';
  for (; i < lines.length; ++i) {
    if (lines[i].includes('END PUBLIC KEY')) {
      break;
    }
    b64Data += lines[i].trim();
  }
  if (i === lines.length) {
    throw new Error('Unexpected end of data');
  }
  if (!Base64.isValid(b64Data)) {
    throw new Error('Invalid base64 data');
  }
  return Base64.toUint8Array(b64Data);
};

export const clientAuth: ClientAuth = createClientAuth({
  randomBytes,
  h,
  hmac,
  hi,
  rsaEncrypt,
});
