import React from 'react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'reflect-metadata';

import Footer from './components/navigation/footer.nav';
import ProviderWrapper from './components/providers/provider-wrapper';
import Router from './components/router';

const App: React.FC = () => {
  return (
    <ProviderWrapper>
      <Router />
      <Footer />
    </ProviderWrapper>
  );
};

export default App;
