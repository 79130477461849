/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceBase_dragOverlay = {
    readonly evidences: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly fileName: string;
            };
        } | null> | null;
    };
    readonly " $refType": "evidenceBase_dragOverlay";
};
export type evidenceBase_dragOverlay$data = evidenceBase_dragOverlay;
export type evidenceBase_dragOverlay$key = {
    readonly " $data"?: evidenceBase_dragOverlay$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceBase_dragOverlay">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "evidenceBase_dragOverlay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EvidenceConnection",
      "kind": "LinkedField",
      "name": "evidences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EvidenceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": "fileName",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Folder",
  "abstractKey": "__isFolder"
};
(node as any).hash = 'f9cd49bfc02c7841269ad006d0e17e96';
export default node;
