import React, {Fragment, ReactElement} from 'react';

import {Button, IconButton, Tooltip} from '@material-ui/core';
import {CloudDownload, SaveAlt} from '@material-ui/icons';
import {connect, useSelector} from 'react-redux';
import {fetchQuery, graphql} from 'react-relay';

import {downloadEvidenceQueryResponse} from '../../graphql/__generated__/downloadEvidenceQuery.graphql';
import environment from '../../graphql/environment';

import {TRootState} from '../../state/store';
import {getSelectedWorkspace, getVisibleFilteredEvidences} from '../../state/timeline/selectors';
import {TTimelineEvidence, TWorkspace} from '../../state/timeline/state';

import LoadingScreen from '../feedback/loading-screen';
import useDownloadFiles, {FileDownload} from './use-download-files.hook';

export type DownloadOrigin = {
  origin: 'evidence' | 'timeline';
};

type Props = DownloadOrigin & {
  selectedWorkspace: TWorkspace | undefined;
  visibleFilteredEvidences: TTimelineEvidence[];
};

const DownloadEvidenceComponent = ({
  origin,
  selectedWorkspace,
  visibleFilteredEvidences,
}: Props): ReactElement => {
  const [downloadFiles, isDownloading, setIsDownloading, numItemsProcessed] = useDownloadFiles();

  // all the useSelectors to avoid accessing visibleFilteredEvidence in redux state but effect is same since I have visibleEvidenceIds itself
  const authenticatedUser = useSelector((state: TRootState) => state.common.authenticatedUser);
  const selectedEvidenceIds = useSelector(
    (state: TRootState) => state.evidences.selectedEvidenceIds,
  );

  const visibleFilteredEvidenceIds = visibleFilteredEvidences.map((e) => e.id);

  const evidenceIds = origin === 'timeline' ? visibleFilteredEvidenceIds : selectedEvidenceIds;

  const downloadEvidenceQuery = graphql`
    query downloadEvidenceQuery($ids: [ID!]!) {
      evidence: nodes(ids: $ids) {
        ... on Evidence {
          id
          size
          eventDate
          fileName: name
          eventDate
          type: __typename
          ... on File {
            mimeType
            url
          }
          ... on JournalEntry {
            subject
            body
          }
          folders {
            id
            name
          }
        }
      }
    }
  `;

  const handleDownload = async () => {
    setIsDownloading(true);
    fetchQuery(environment, downloadEvidenceQuery, {ids: evidenceIds}).subscribe({
      // start: () => {...},
      // complete: () => {...},
      // error: (error) => {...},
      next: (data) => {
        const fetchedData = data as downloadEvidenceQueryResponse;
        const fetchedEvidence = fetchedData.evidence.map(
          (e) =>
            ({
              ...e,
              folders: e?.folders?.map((f) => f),
            } || []),
        ) as FileDownload[];

        if (fetchedData && authenticatedUser) {
          downloadFiles(fetchedEvidence, authenticatedUser, origin, selectedWorkspace);
        }
      },
    });
  };

  return (
    <Fragment>
      {isDownloading && <LoadingScreen label={(numItemsProcessed / evidenceIds?.length) * 100} />}
      {origin === 'timeline' && (
        <Tooltip title={`Download ${evidenceIds?.length} visible file(s)`}>
          <IconButton color={'inherit'} disabled={isDownloading} onClick={handleDownload}>
            <SaveAlt />
          </IconButton>
        </Tooltip>
      )}
      {origin === 'evidence' && (
        <Tooltip title={`Download ${evidenceIds?.length} file(s)`}>
          <Button
            color={'primary'}
            endIcon={<CloudDownload />}
            fullWidth
            onClick={handleDownload}
            variant={'contained'}
          >
            Download
          </Button>
        </Tooltip>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: TRootState) => ({
  selectedWorkspace: getSelectedWorkspace(state),
  visibleFilteredEvidences: getVisibleFilteredEvidences(state),
});

const DownloadEvidence = connect(mapStateToProps)(DownloadEvidenceComponent);

export default DownloadEvidence;
