import React, {ReactElement} from 'react';

import {Box, CircularProgress, CircularProgressProps, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    zIndex: 99999,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  global: {
    position: 'fixed',
    inset: 0,
    width: '100vw',
    height: '100vh',
  },
  local: {
    position: 'absolute',
    inset: 0,
  },
  inline: {
    zIndex: 99999,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

type Props = {
  local?: boolean;
  inline?: boolean;
  label?: number;
};

const LoadingScreen = ({local = false, inline = false, label}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.root]: !inline,
        [classes.inline]: inline,
        [classes.local]: local,
        [classes.global]: !local && !inline,
      })}
    >
      {!label && <CircularProgress />}
      {(label || label == 0) && <CircularProgressWithLabel value={label} />}
    </div>
  );
};

export default LoadingScreen;

function CircularProgressWithLabel({value}: CircularProgressProps) {
  const percent = Math.round(value || 0);
  return (
    <Box display={'inline-flex'} position={'relative'}>
      <CircularProgress size={70} value={percent} variant={'determinate'} />
      <Box
        alignItems={'center'}
        bottom={0}
        display={'flex'}
        justifyContent={'center'}
        left={0}
        position={'absolute'}
        right={0}
        top={0}
      >
        <Typography color={'primary'} component={'h5'} variant={'h5'}>{`${percent}%`}</Typography>
      </Box>
    </Box>
  );
}
