/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceNav_tagsNav = {
    readonly id: string;
    readonly tags: ReadonlyArray<{
        readonly id: string;
        readonly tagId: number;
        readonly text: string;
        readonly evidenceCount: number;
    }>;
    readonly " $refType": "evidenceNav_tagsNav";
};
export type evidenceNav_tagsNav$data = evidenceNav_tagsNav;
export type evidenceNav_tagsNav$key = {
    readonly " $data"?: evidenceNav_tagsNav$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceNav_tagsNav">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "evidenceNav_tagsNav",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FolderTag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tagId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "evidenceCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Folder",
  "abstractKey": "__isFolder"
};
})();
(node as any).hash = '39eb5c14d0e19a7447ef8b9fdd94b12f';
export default node;
