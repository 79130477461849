import {connect} from 'react-redux';

import {TRootState} from '../../../../state/store';
import {setSelectedWorkspaceId, unsetSelectedWorkspaceId} from '../../../../state/timeline/actions';
import {getSelectedWorkspaceId, getWorkspaces} from '../../../../state/timeline/selectors';

import WorkspaceSelectionModalController from './workspace-selection-modal.controller';

const mapStateToProps = (state: TRootState) => ({
  selectedWorkspaceId: getSelectedWorkspaceId(state),
  workspaces: getWorkspaces(state),
});

const mapDispatchToProps = {setSelectedWorkspaceId, unsetSelectedWorkspaceId};

const WorkspaceSelectionModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkspaceSelectionModalController);

export default WorkspaceSelectionModal;
