import {connect} from 'react-redux';

import {setSelectedEvidenceIds} from '../../../state/evidence/actions';
import {TRootState} from '../../../state/store';
import {setOpenedWorkspaceTag} from '../../../state/timeline/actions';
import {getOpenedWorkspaceTag, getWorkspaceTagEvidenceIds} from '../../../state/timeline/selectors';

import WorkspaceTagDetailController from './workspace-tag-detail.controller';

const mapStateToProps = (state: TRootState) => ({
  workspaceTagEvidenceIds: getWorkspaceTagEvidenceIds(state),
  openedWorkspaceTag: getOpenedWorkspaceTag(state),
});

const mapDispatchToProps = {setOpenedWorkspaceTag, setSelectedEvidenceIds};

const WorkspaceTagDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkspaceTagDetailController);

export default WorkspaceTagDetail;
