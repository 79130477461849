import React, {Suspense} from 'react';
import type {ReactElement, ReactNode} from 'react';

import {RelayEnvironmentProvider} from 'relay-hooks';

import environment from '../../graphql/environment';

import LoadingScreen from '../feedback/loading-screen';

type Props = {
  children: ReactNode;
};

const RelayProvider = ({children}: Props): ReactElement => {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
    </RelayEnvironmentProvider>
  );
};

export default RelayProvider;
