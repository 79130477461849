import React, {Fragment, useState} from 'react';

import {grey} from '@material-ui/core/colors';
import {Theme, makeStyles} from '@material-ui/core/styles';

import LoadingScreen from '../../../components/feedback/loading-screen';
import {PatentAndCopyright} from '../../../components/navigation/footer.nav';

import {colorValueToCss} from '../../../services/timeline/utils/utils';

import {TWorkspace, TWorkspaceTag} from '../../../state/timeline/state';

import EvidenceNodeDetail from '../evidence-node-detail';
import TagEvidenceConnectionLines from '../tag-evidence-connection-lines';
import TagNodeColorPicker from '../tag-node-color-picker';
import TimelineCanvasCalendar from '../timeline-canvas-calendar';
import TimelineTagNodes from '../timeline-tag-nodes';
import {
  BACKGROUND_COLOR_OPTION_ID,
  DEFAULT_BG_COLOR_INDEX,
  WorkspaceBackgroundShade,
} from '../timeline.constants';
import {getShadeFromIndex, getWorkspaceOptionValue} from '../timeline.helpers';
import WorkspaceTagDetail from '../workspace-tag-detail';

type StyleProps = {
  wsBgColor: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    '--workspace-background': ({wsBgColor}) => wsBgColor,
    '--workspace-contrast-text-color': ({wsBgColor}) =>
      theme.palette.getContrastText(colorValueToCss(wsBgColor)),
    'backgroundColor': 'var(--workspace-background)',
    'position': 'relative',
    'width': '100%',
    'flexGrow': 1,
    'overflow': 'hidden',
    'display': 'flex',
    'alignItems': 'center',
  },
  svg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    pointerEvents: 'none',
  },
  patent: {
    color: 'var(--workspace-contrast-text-color)',
    position: 'absolute',
    bottom: 0,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
  },
}));

type Props = {
  isLoading: boolean;
  selectedWorkspace: TWorkspace | undefined;
};

const TimelineCanvasView: React.FC<Props> = ({isLoading, selectedWorkspace}) => {
  const wsBgColorIndex =
    getWorkspaceOptionValue(selectedWorkspace?.options, BACKGROUND_COLOR_OPTION_ID) ??
    DEFAULT_BG_COLOR_INDEX;
  const wsBgColorShade = getShadeFromIndex(wsBgColorIndex);
  const wsBgColor = grey[wsBgColorShade as WorkspaceBackgroundShade];
  const classes = useStyles({wsBgColor});

  const [colorPickerTag, setColorPickerTag] = useState<TWorkspaceTag | undefined>();
  const [moveCount, setMoveCount] = useState(0);

  const incMoveCount = () => setMoveCount(moveCount + 1);

  return (
    <div className={classes.root} id={'timeline-canvas'}>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <TimelineCanvasCalendar />
          <TimelineTagNodes incMoveCount={incMoveCount} setColorPickerTag={setColorPickerTag} />
          {selectedWorkspace?.showLines && <TagEvidenceConnectionLines />}
        </Fragment>
      )}
      <WorkspaceTagDetail />
      <EvidenceNodeDetail />
      <TagNodeColorPicker
        handleClose={() => setColorPickerTag(undefined)}
        selectedTag={colorPickerTag}
      />
      <div className={classes.patent}>
        <PatentAndCopyright />
      </div>
    </div>
  );
};

export default TimelineCanvasView;
