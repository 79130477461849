import React, {Suspense} from 'react';
import type {CSSProperties, ReactElement, ReactNode} from 'react';

import Grid from '@material-ui/core/Grid';
import Paper, {PaperProps} from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';

import theme from '../../../config/theme';

import ContentHeading, {ContentHeadingProps} from '../../../components/display/content-heading';
import LoadingScreen from '../../../components/feedback/loading-screen';

import {uniqueIdGenerator} from '../../../utils/utils';

const useStyles = makeStyles({
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(4),
  },
  paper: {
    width: '100%',
  },
  colHeaders: {
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  hidden: {
    display: 'none',
  },
  myFilesControlsRow2: {
    gap: '0 2rem',
  },
});

export type TablePadding = 'none' | 'normal' | 'checkbox';

type TableCustomizationProps = {
  children?: ReactNode;
  toolbarAlt?: ReactNode;
};

type ColumnHeaderProps = {
  /** id: key of table row data, used to make consistent columns from row data */
  id: string;
  /** padding: 'none' | 'normal' | 'checkbox', padding on the header cell (defaults to 'none') */
  padding?: TablePadding;
  /** label: ReactNode, what to display in the header cell */
  label: ReactNode;
  /** alignRight: boolean, aligns column header. Standard is right for numbers (to make math easier) */
  alignRight: boolean;
  /** (optional) hide: boolean, removes column from table view */
  hide?: boolean;
  /** (optional) style: inline html styling, applies css to the column header cell */
  style?: CSSProperties;
};

export type TableProps = {
  /** columnHeaders: ColumnHeaderProps[], array used to populate column headers */
  columnHeaders: ColumnHeaderProps[];
  /** headingText: string, the text populating the table header */
  headingText: string;
  /** (optional) headingType: number between [1-6] inclusive. Determines type of header tag
   *  for the table (ie. {1} for <h1>). Defaults to 1 (h1) if not provided
   */
  /** headingSort: ReactNode, elements to sort rows */
  headingSort?: ReactNode;
  /** headingButtons: ReactNode, elements to populate the toolbar when no rows are selected */
  headingButtons?: ReactNode;
  headingType?: 1 | 2 | 3 | 4 | 5 | 6;
  /** (optional) headerProps: Omit<ContentHeadingProps, 'headingText' | 'headingType'>, applies
   *  props to the ContentHeading component (except 'headingText' and 'headingType')
   */
  headerProps?: Omit<ContentHeadingProps, 'headingText' | 'headingType'>;
  /** (optional) paperElevation: number, applies elevation to the Paper component that contains the table.
   *  Value of {0} removes elevation/shadow/border
   */
  paperElevation?: number;
  /** (optional) paperProps: Omit<PaperProps, 'elevation'>, applies props (except 'elevation') to the Paper component */
  paperProps?: Omit<PaperProps, 'elevation'>;
  /** (optional) tableContainerClass: string, applies class to the table component */
  tableContainerClassName?: string;
  /** (optional) toolbarClass: string, applies class to the top bar of table */
  toolbarClassName?: string;
  /** (optional) stickyHeader: boolean, determines if header sticks to top of table on scroll */
  stickyHeader?: boolean;
};

type Props = TableProps & TableCustomizationProps;

const TableTemplate = ({
  children,
  toolbarAlt,
  columnHeaders,
  headingText,
  headingButtons,
  headingSort,
  headingType,
  headerProps,
  paperElevation,
  paperProps,
  tableContainerClassName,
  toolbarClassName,
  stickyHeader,
}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <Paper elevation={paperElevation} {...paperProps}>
      {/* ====================================================================
              TOOLBAR
          ==================================================================== */}
      <Toolbar className={`${classes.toolbar} ${toolbarClassName ?? ''}`}>
        <Grid container direction={'row'} justifyContent={'space-between'}>
          <Grid item xs={4}>
            <ContentHeading
              headingText={headingText}
              headingType={headingType ? headingType : 1}
              id={`${headingType ? headingType : 1}-${headingText}`}
              {...headerProps}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid alignItems={'center'} container justifyContent={'flex-end'} spacing={1}>
              <Grid item>{headingButtons}</Grid>
              <Grid item>
                <Grid
                  alignItems={'center'}
                  className={classes.myFilesControlsRow2}
                  container
                  justifyContent={'flex-end'}
                  wrap={'wrap'}
                >
                  <Grid item>{toolbarAlt}</Grid>
                  <Grid item>{headingSort}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <TableContainer className={tableContainerClassName}>
        <Suspense fallback={<LoadingScreen />}>
          <Table aria-labelledby={headingText} size={'small'} stickyHeader={stickyHeader}>
            {/* ====================================================================
                  COLUMN HEADERS
              ==================================================================== */}
            <TableHead>
              <TableRow>
                {columnHeaders.map((headCell) => (
                  <TableCell
                    align={headCell.alignRight ? 'right' : 'left'}
                    className={`${classes.colHeaders} ${headCell.hide ? classes.hidden : ''}`}
                    key={`${headCell.id as string}-${uniqueIdGenerator()}`}
                    padding={headCell.padding ?? 'normal'}
                    style={headCell.style}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* ====================================================================
                  ROW DEFINITION
              ==================================================================== */}
            <TableBody>{children}</TableBody>
          </Table>
        </Suspense>
      </TableContainer>
    </Paper>
  );
};

export default TableTemplate;
