import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  CreateJournalEntryInput,
  createJournalEntryMutation,
} from '../../graphql/__generated__/createJournalEntryMutation.graphql';

const mutation = graphql`
  mutation createJournalEntryMutation($input: CreateJournalEntryInput!) {
    createJournalEntry(input: $input) {
      unsortedFolder {
        evidenceCount
        ...evidenceList_folderEvidenceFull
      }
      allFilesFolder {
        evidenceCount
      }
      journalEntry {
        id
      }
    }
  }
`;

function useCreateJournalEntry(): [
  (eventDate: Date, subject: string, body: string, callback: () => void) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<createJournalEntryMutation>(mutation);
  return [
    useCallback(
      (eventDate, subject, body, callback) => {
        const input: CreateJournalEntryInput = {
          eventDate,
          subject,
          body,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted(response) {
            if (response.createJournalEntry?.journalEntry !== undefined) {
              callback();
            }
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useCreateJournalEntry;
