import React, {ReactElement, ReactNode} from 'react';

interface Props {
  children?: ReactNode;
  index: number;
  value: number;
  ariaLabelledby?: string;
  id?: string;
}

const TabPanel = ({children, index, value, ariaLabelledby, id, ...other}: Props): ReactElement => {
  return (
    <div
      aria-labelledby={ariaLabelledby ?? `tab-${index}`}
      hidden={value !== index}
      id={id ?? `tabpanel-${index}`}
      role={'tabpanel'}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

export default TabPanel;
