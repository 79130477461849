import {ContainerModule} from 'inversify';

import EntityService, {IEvidenceService} from '../evidence-service';
import EvidenceRepository, {IEvidenceRepository} from '../repositories/evidence-repository';
import entityKeys from './keys';

const entityModule = new ContainerModule((bind) => {
  bind<IEvidenceService>(entityKeys.IEvidenceService).to(EntityService);
  bind<EntityService>(entityKeys.EntityService).to(EntityService);

  bind<IEvidenceRepository>(entityKeys.IEvidenceRepository).to(EvidenceRepository);
});

export default entityModule;
