/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateEvidenceEventDateInput = {
    evidenceIds: Array<string>;
    eventDate: Date;
    clientMutationId?: string | null | undefined;
};
export type updateEvidenceOccurredOnDateMutationVariables = {
    input: UpdateEvidenceEventDateInput;
};
export type updateEvidenceOccurredOnDateMutationResponse = {
    readonly updateEvidenceEventDate: {
        readonly evidenceNodes: ReadonlyArray<{
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_metaData">;
            };
        }>;
    } | null;
};
export type updateEvidenceOccurredOnDateMutation = {
    readonly response: updateEvidenceOccurredOnDateMutationResponse;
    readonly variables: updateEvidenceOccurredOnDateMutationVariables;
};



/*
mutation updateEvidenceOccurredOnDateMutation(
  $input: UpdateEvidenceEventDateInput!
) {
  updateEvidenceEventDate(input: $input) {
    evidenceNodes {
      node {
        __typename
        ...evidenceDetail_metaData
        id
      }
    }
  }
}

fragment evidenceDetail_metaData on Evidence {
  __isEvidence: __typename
  id
  storedOn: created
  fileName: name
  eventDate
  size
  ... on File {
    mimeType
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateEvidenceOccurredOnDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEvidenceEventDatePayload",
        "kind": "LinkedField",
        "name": "updateEvidenceEventDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceDetail_metaData"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateEvidenceOccurredOnDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEvidenceEventDatePayload",
        "kind": "LinkedField",
        "name": "updateEvidenceEventDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEvidence"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "storedOn",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": "fileName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eventDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mimeType",
                        "storageKey": null
                      }
                    ],
                    "type": "File",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4413185daa89dfb96bf534f341ab3782",
    "id": null,
    "metadata": {},
    "name": "updateEvidenceOccurredOnDateMutation",
    "operationKind": "mutation",
    "text": "mutation updateEvidenceOccurredOnDateMutation(\n  $input: UpdateEvidenceEventDateInput!\n) {\n  updateEvidenceEventDate(input: $input) {\n    evidenceNodes {\n      node {\n        __typename\n        ...evidenceDetail_metaData\n        id\n      }\n    }\n  }\n}\n\nfragment evidenceDetail_metaData on Evidence {\n  __isEvidence: __typename\n  id\n  storedOn: created\n  fileName: name\n  eventDate\n  size\n  ... on File {\n    mimeType\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ae2a27c8b19c6690561364aca96d0b24';
export default node;
