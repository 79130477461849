import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  DeleteEvidenceInput,
  deleteEvidenceMutation,
} from '../../graphql/__generated__/deleteEvidenceMutation.graphql';

const mutation = graphql`
  mutation deleteEvidenceMutation($input: DeleteEvidenceInput!) {
    deleteEvidence(input: $input) {
      folder {
        evidenceCount
        ...evidenceList_folderEvidenceFull
      }
      allFilesFolder {
        evidenceCount
      }
      evidenceNodes {
        node {
          id @deleteRecord
        }
      }
    }
  }
`;

function useDeleteEvidence(): [
  (folderId: string, evidenceIds: string[], callback: () => void) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<deleteEvidenceMutation>(mutation);
  return [
    useCallback(
      (folderId, evidenceIds, callback) => {
        const input: DeleteEvidenceInput = {
          folderId,
          evidenceIds,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted() {
            callback();
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useDeleteEvidence;
