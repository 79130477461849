// TODO: @material-ui/core@5.0.0 - this can be moved back to the regular createMuiTheme. See:
// https://github.com/mui-org/material-ui/pull/21974
import {unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core';

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: 'Arial, sans-serif, Verdana, Geneva, Helvetica',
          fontSmooth: 'always',
          fontSize: '12px',
          marginTop: '0px',
        },
        textarea: {
          extend: 'body',
        },
        input: {
          extend: 'body',
        },
        p: {
          fontFamily: 'Arial, sans-serif, Verdana, Geneva, Helvetica',
        },
        a: {
          textDecoration: 'none',
        },
        legend: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#63838a',
        },
        pre: {
          whiteSpace: 'pre-wrap' /* css-3 */,
          /* Mozilla, since 1999 -->  Opera 4-6 --> Opera 7 --> Internet Explorer 5.5+ */
          fallbacks: [
            {whiteSpace: [['-moz-pre-wrap'], '!important']},
            {whiteSpace: 'pre-wrap'},
            {whiteSpace: '-o-pre-wrap'},
            {whiteSpace: 'break-word'},
          ],
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#2e3f42',
      light: '#586a6d',
      dark: '#06191b',
    },
    secondary: {
      main: '#aa6f55',
      light: '#de9e82',
      dark: '#78432c',
    },
    type: 'light',
  },
  typography: {
    body1: {
      color: '#202124',
    },
    body2: {
      color: '#202124',
    },
    h1: {
      fontSize: '2.75rem',
      fontWeight: 300,
      color: '#3c4043',
    },
    h2: {
      fontSize: '2rem',
      color: '#3c4043',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 300,
      color: '#202124',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 800,
      color: '#3c4043',
    },
  },
});

export type SiteTheme = typeof theme;

export default theme;
