import React, {useEffect, useState} from 'react';
import type {ReactElement} from 'react';

import {graphql, useLazyLoadQuery} from 'react-relay';

import {timelineBaseQuery} from '../../../graphql/__generated__/timelineBaseQuery.graphql';

import {
  TCaseFolder,
  TCaseTag,
  TEvidenceMimeType,
  TTimelineEvidence,
  TWorkspace,
} from '../../../state/timeline/state';

import {mimeLookup} from '../../../services/evidences/data/evidence/content-type';
import WorkspaceSelectionModal from '../modals/workspace-selection-modal';
import TimelineBaseView from './timeline-base.view';

type TimelineProps = {
  selectedWorkspaceId: string | undefined;
  setWorkspaces(Array: TWorkspace[]): void;
  setFolders(Array: TCaseFolder[]): void;
  setTags(Array: TCaseTag[]): void;
  setTypes(Array: TEvidenceMimeType[]): void;
  setEvidences(Array: TTimelineEvidence[]): void;
};

const TimelineBaseControllerComponent = ({
  selectedWorkspaceId,
  setWorkspaces,
  setFolders,
  setTags,
  setTypes,
  setEvidences,
}: TimelineProps): ReactElement => {
  const [localWorkspaceId, setLocalWorkspaceId] = useState<string | undefined>(undefined);
  const data = useLazyLoadQuery<timelineBaseQuery>(
    graphql`
      query timelineBaseQuery {
        currentSession {
          ... on Session {
            account {
              workspaces {
                id
                name
                modified
                filters {
                  folders: folderIds
                  tags: tagIds
                  types: typeIds
                }
                tags {
                  tagId
                  text
                  coordinates {
                    x
                    y
                  }
                  color
                  lockHighlight
                }
                options {
                  optionId
                  value
                }
                coordinates {
                  x
                  y
                }
                zoomIndex
              }
              evidences {
                id
                fileName: name
                storedOn: created
                eventDate
                size
                tags: tagsWithIds {
                  id
                }
                folders {
                  id
                }
                ... on File {
                  mimeType
                }
                type: __typename
              }
              folders: userFolders {
                id
                name
              }
              tags: activeTagsWithIds {
                id
                text
              }
            }
          }
        }
      }
    `,
    {},
  );

  const account = data.currentSession?.account ?? undefined;
  if (account === undefined) {
    throw new Error('no account');
  }

  const workspaces = [
    ...(account?.workspaces.map((w) => ({
      ...w,
      options: [...w.options],
      tags: [...w.tags],
      filters: {
        folders: [...w.filters.folders],
        tags: [...w.filters.tags],
        types: [...w.filters.types],
      },
      showLines: false,
      showEvidenceCounts: false,
      // reset legacy workspace position to 0, 100
      coordinates: w.zoomIndex === -1 ? w.coordinates : {x: 0, y: 100},
      // set zoomIndex to -1 for when it will be saved
      zoomIndex: -1,
    })) || []),
  ];

  const evidences = [
    ...(account?.evidences.map((e) => ({
      ...e,
      folders: e.folders.map((f) => f.id),
      tags: e.tags.map((t) => Number(t.id)),
      mimeType: e?.mimeType ? mimeLookup(e?.mimeType) : mimeLookup('text/journalentry'),
    })) || []),
  ];

  const folders = [...(account?.folders || [])];
  const tags = [...(account?.tags.map((t) => ({...t, id: Number(t.id)})) || [])];

  const types = evidences?.map((e) => e?.mimeType);
  const distinctTypes = [...new Set(types)] ?? [];

  useEffect(() => {
    if (workspaces !== undefined) {
      setWorkspaces(workspaces);
    }
    if (evidences !== undefined) {
      setEvidences(evidences);
    }
    if (tags !== undefined) {
      setTags(tags);
    }
    if (folders !== undefined) {
      setFolders(folders);
    }
    if (types !== undefined) {
      setTypes(distinctTypes);
    }
  }, [data]);

  useEffect(() => {
    setLocalWorkspaceId(selectedWorkspaceId);
  }, [selectedWorkspaceId]);

  if (localWorkspaceId === undefined) {
    return <WorkspaceSelectionModal onClose={() => undefined} open />;
  }

  return <TimelineBaseView />;
};

export default TimelineBaseControllerComponent;
