import React, {ReactElement} from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';

import {ACCOUNT_RECOVERY} from '../../../config/routes';

import ContentCard from '../../../components/surfaces/content-card';

// TODO - Change this interaction to not require sending reset email
const SecurityView = (): ReactElement => {
  return (
    <Grid alignItems={'center'} container direction={'column'} spacing={4}>
      <Grid item xs={12}>
        <ContentCard
          header={'Reset your password'}
          subheader={
            'Click the button below to start the password reset process (this will take you to another page)'
          }
        >
          <Grid alignItems={'center'} container direction={'column'}>
            <Grid item>
              <Button
                color={'primary'}
                component={Link}
                to={ACCOUNT_RECOVERY}
                variant={'contained'}
              >
                Reset password
              </Button>
            </Grid>
          </Grid>
        </ContentCard>
      </Grid>
    </Grid>
  );
};

export default SecurityView;
