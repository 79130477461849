import React, {Fragment, ReactElement, useCallback, useEffect, useState} from 'react';

import {Button, Grid, Typography, makeStyles} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ClearIcon from '@material-ui/icons/Clear';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import {DataGridPro, GridColDef, GridRowData} from '@mui/x-data-grid-pro';
import {useDispatch} from 'react-redux';

import LoadingScreen from '../../../components/feedback/loading-screen';
import AutocompleteFreeSoloCreateOption, {
  OptionType as OptionTypeSolo,
} from '../../../components/inputs/autocompletes/autocomplete-free-solo-create-option';
import AutocompleteMultiple, {
  OptionType as OptionTypeMultiple,
} from '../../../components/inputs/autocompletes/autocomplete-multiple';

import useConsolidateTags from '../../../hooks/mutations/consolidate-tags.hook';

import {hasIntersection} from '../../../utils/utils';

import {setApiFeedback} from '../../../state/common/actions';

import {TEvidence, TTag} from '../../../services/evidences/data/types';

const useStyles = makeStyles({
  grid: {
    marginTop: '2rem',
  },
  arrow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.75rem',
  },
  buttonGroupFirst: {
    marginTop: '0.75rem',
  },
});

type Props = {
  allTags: TTag[] | undefined;
  allEvidence: TEvidence[] | undefined;
};

const TagsAdminView = ({allTags, allEvidence}: Props): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sourceValue, setSourceValue] = useState<OptionTypeMultiple[] | null>(null);
  const [targetValue, setTargetValue] = useState<OptionTypeSolo | null>(null);
  const [rows, setRows] = useState<GridRowData[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [consolidateTags, loading] = useConsolidateTags();

  if (!allTags) {
    return <LoadingScreen />;
  }

  // outside of useEffect since it never changes
  const columnsBase: GridColDef[] = [
    {
      field: 'fileName',
      headerName: 'File name',
      flex: 2,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    if (sourceValue === null || sourceValue.length < 1) {
      setRows([]);
    } else if (allEvidence !== undefined) {
      const sourceTagIds = sourceValue.map((v) => v.inputValue);

      const dynamicRowContent = (tagIds: number[]) => {
        return sourceValue
          ?.map((v, i) => {
            return {[`dynamicColumn${i}`]: tagIds.includes(v.inputValue)};
          })
          .reduce((o, el) => ({...o, ...el}), {});
      };

      const rowsBase = allEvidence
        .filter((e) => {
          return hasIntersection(sourceTagIds, e.tagIds);
        })
        .map(({id, fileName, tagIds}) => {
          return {
            id,
            fileName,
            ...dynamicRowContent(tagIds),
          };
        });

      setRows(rowsBase);

      const dynamicColumns = sourceValue?.map((v, i) => {
        return {
          field: `dynamicColumn${i}`,
          headerName: `Tag: ${v.title}`,
          flex: 1,
          minWidth: 100,
          renderCell: (params) => {
            const Icon = params.value === true ? CheckBoxIcon : CheckBoxOutlineBlankIcon;
            return <Icon />;
          },
          disableColumnMenu: true,
        } as GridColDef;
      });

      const newColumns = columnsBase;
      if (dynamicColumns !== undefined) {
        newColumns.push(...dynamicColumns);
      }
      setColumns(newColumns);
    }
  }, [sourceValue, allEvidence]);

  const handleConsolidate = useCallback(() => {
    const sourceTagIds = sourceValue?.map((v) => v.inputValue);
    const sourceTagTexts = sourceValue?.map((v) => v.title).join(', ');
    const targetTagText = targetValue?.title;
    const callback = () => {
      dispatch(
        setApiFeedback({
          severity: 'success',
          message: `Tag(s) "${sourceTagTexts}" consolidated and reassigned to "${targetTagText}".`,
        }),
      );
    };
    if (sourceTagIds !== undefined && targetTagText !== undefined) {
      consolidateTags(sourceTagIds, targetTagText, callback);
    }
  }, [consolidateTags, sourceValue, targetValue]);

  const handleReset = () => {
    setSourceValue(null);
    setTargetValue(null);
  };

  return (
    <Fragment>
      {loading && <LoadingScreen />}
      <Typography variant={'h1'}>Tags Admin</Typography>
      <Grid alignItems={'center'} className={classes.grid} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h4'}>Consolidate tags.</Typography>
          <Typography>
            Select source tags to transform to the target tag on all evidence for the currently
            logged in user.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <AutocompleteMultiple
            label={'Source tags'}
            options={allTags.map(({id: inputValue, text: title}) => ({
              title,
              inputValue,
            }))}
            setValue={setSourceValue}
            value={sourceValue}
          />
        </Grid>
        <Grid className={classes.arrow} item xs={1}>
          &rarr;
        </Grid>
        <Grid item xs={5}>
          <AutocompleteFreeSoloCreateOption
            label={'Target tag'}
            options={allTags.map(({text: title}) => ({
              title,
            }))}
            setValue={setTargetValue}
            value={targetValue}
          />
        </Grid>
      </Grid>
      <Grid className={classes.buttonGroupFirst} container spacing={1}>
        <Grid item>
          <Button
            color={'primary'}
            fullWidth
            onClick={handleConsolidate}
            startIcon={<FindReplaceIcon />}
            variant={'contained'}
          >
            Replace
          </Button>
        </Grid>
        <Grid item>
          <Button
            color={'default'}
            fullWidth
            onClick={handleReset}
            startIcon={<ClearIcon />}
            variant={'contained'}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      <Grid className={classes.grid} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h4'}>Preview</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGridPro
            autoHeight
            columns={columns}
            density={'compact'}
            pageSize={100}
            rows={rows}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                color={'primary'}
                fullWidth
                onClick={handleConsolidate}
                startIcon={<FindReplaceIcon />}
                variant={'contained'}
              >
                Replace
              </Button>
            </Grid>
            <Grid item>
              <Button
                color={'default'}
                fullWidth
                onClick={handleReset}
                startIcon={<ClearIcon />}
                variant={'contained'}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TagsAdminView;
