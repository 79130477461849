import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {setOpenedWorkspaceTag} from '../../../state/timeline/actions';
import {getHighlightedEvidences, getVisibleWorkspaceTags} from '../../../state/timeline/selectors';

import TagEvidenceConnectionLinesComponent from './tag-evidence-connection-lines';

const mapStateToProps = (state: TRootState) => ({
  visibleTags: getVisibleWorkspaceTags(state),
  highlightedEvidences: getHighlightedEvidences(state),
});

const mapDispatchToProps = {
  setOpenedWorkspaceTag,
};

const TagEvidenceConnectionLines = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TagEvidenceConnectionLinesComponent);

export default TagEvidenceConnectionLines;
