/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UserRole = "ADMIN" | "%future added value";
export type userDetailsSessionQueryVariables = {};
export type userDetailsSessionQueryResponse = {
    readonly currentSession: {
        readonly user?: {
            readonly roles: ReadonlyArray<UserRole> | null;
        } | undefined;
        readonly prevUser?: {
            readonly roles: ReadonlyArray<UserRole> | null;
        } | null | undefined;
    } | null;
};
export type userDetailsSessionQuery = {
    readonly response: userDetailsSessionQueryResponse;
    readonly variables: userDetailsSessionQueryVariables;
};



/*
query userDetailsSessionQuery {
  currentSession {
    __typename
    ... on Session {
      user {
        roles
        id
      }
      prevUser {
        roles
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "userDetailsSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "prevUser",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "userDetailsSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "prevUser",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "336df5af2ac7361d97c445fecfd94934",
    "id": null,
    "metadata": {},
    "name": "userDetailsSessionQuery",
    "operationKind": "query",
    "text": "query userDetailsSessionQuery {\n  currentSession {\n    __typename\n    ... on Session {\n      user {\n        roles\n        id\n      }\n      prevUser {\n        roles\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4d0041abfc2cccdc8996e7350702cde3';
export default node;
