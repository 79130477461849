import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  UpdateEvidenceEventDateInput,
  updateEvidenceOccurredOnDateMutation,
} from '../../graphql/__generated__/updateEvidenceOccurredOnDateMutation.graphql';

// const mutation = graphql`
//   mutation updateEvidenceOccurredOnDateMutation($input: UpdateEvidenceEventDateInput!) {
//     updateEvidenceEventDate(input: $input) {
//       clientMutationId
//     }
//   }
// `;

const mutation = graphql`
  mutation updateEvidenceOccurredOnDateMutation($input: UpdateEvidenceEventDateInput!) {
    updateEvidenceEventDate(input: $input) {
      evidenceNodes {
        node {
          ...evidenceDetail_metaData
        }
      }
    }
  }
`;

function useUpdateOccuredOnDate(): [
  (occurredOnDatetime: Date, evidenceIds: string[], callback: () => void) => Disposable,
] {
  const [commit] = useMutation<updateEvidenceOccurredOnDateMutation>(mutation);
  return [
    useCallback(
      (occurredOnDatetime, evidenceIds, callback) => {
        const input: UpdateEvidenceEventDateInput = {
          eventDate: occurredOnDatetime,
          evidenceIds,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted() {
            callback();
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
  ];
}

export default useUpdateOccuredOnDate;
