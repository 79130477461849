import React, {ReactElement, useEffect, useState} from 'react';

import {Grid, makeStyles} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {graphql, useFragment} from 'react-relay';
import {Link, useLocation} from 'react-router-dom';

import {EVIDENCE, HOME, TAGS_ADMIN, TIMELINE, USERS_ADMIN} from '../../../config/routes';

import {headerNav_prevUser$key} from '../../../graphql/__generated__/headerNav_prevUser.graphql';
import type {headerNav_userRoles$key} from '../../../graphql/__generated__/headerNav_userRoles.graphql';

import ImpersonatedUser from './impersonated-user';
import UtilityNav from './utility.nav';

const useStyles = makeStyles({
  appBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  activeUser: {
    marginLeft: 'auto',
    marginRight: '6rem',
  },
});

type LinksProps = {
  label: string;
  value: string;
  to: string;
  admin?: boolean;
};

const LINKS: Array<LinksProps> = [
  {label: 'Home', value: HOME, to: HOME},
  {label: 'Evidence', value: EVIDENCE, to: EVIDENCE},
  {label: 'Timeline', value: TIMELINE, to: TIMELINE},
  {label: 'Tags', value: TAGS_ADMIN, to: TAGS_ADMIN, admin: true},
  {label: 'Users', value: USERS_ADMIN, to: USERS_ADMIN, admin: true},
];

function a11yProps(index: number) {
  return {
    'id': `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

type NavMenuProps = {
  user: headerNav_userRoles$key | null;
  session: headerNav_prevUser$key | null;
};

const HeaderNav = ({user, session}: NavMenuProps): ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const [tabValue, setTabValue] = useState<string | false>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // this is now also available in common.authenticatedUser
  const data = useFragment(
    graphql`
      fragment headerNav_userRoles on User {
        id
        roles
      }
    `,
    user,
  );

  // this could be refactored into common.activeUser (doesn't yet exist)
  const sessionData = useFragment(
    graphql`
      fragment headerNav_prevUser on Session {
        user {
          email
        }
        prevUser {
          id
        }
      }
    `,
    session,
  );

  useEffect(() => {
    const userRoles = data?.roles;
    setIsAdmin(userRoles !== null && userRoles !== undefined && userRoles.includes('ADMIN'));
  }, [user]);

  useEffect(() => {
    const tab = LINKS.find((l) => location.pathname.includes(l.to));
    if (tab !== undefined) {
      setTabValue(tab.to);
    }
  }, [location]);

  return (
    <AppBar className={classes.appBar} position={'static'}>
      <Grid alignItems={'center'} container>
        <Grid item>
          <Tabs value={tabValue}>
            {LINKS.map(
              (link, index) =>
                (link.admin === undefined || (link.admin === true && isAdmin)) && (
                  <Tab
                    component={Link}
                    key={`link-key-${link.value}`}
                    label={link.label}
                    to={link.to}
                    value={link.value}
                    {...a11yProps(index)}
                  />
                ),
            )}
          </Tabs>
          <form action={'session'} id={'user_form'} method={'post'}>
            <div style={{margin: 0, padding: 0}}>
              <input
                name={'authenticity_token'}
                type={'hidden'}
                value={'use738f2c2761c6b87080d27103d92db5d2457f76e8r_form'}
              />
            </div>
          </form>
        </Grid>
        <Grid className={classes.activeUser} item>
          <ImpersonatedUser email={sessionData?.prevUser?.id ? sessionData.user.email : null} />
        </Grid>
        <Grid item>
          <UtilityNav />
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default HeaderNav;
