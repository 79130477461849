/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceBase_folderDropName = {
    readonly userFolders: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
    }>;
    readonly " $refType": "evidenceBase_folderDropName";
};
export type evidenceBase_folderDropName$data = evidenceBase_folderDropName;
export type evidenceBase_folderDropName$key = {
    readonly " $data"?: evidenceBase_folderDropName$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceBase_folderDropName">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "evidenceBase_folderDropName",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFolder",
      "kind": "LinkedField",
      "name": "userFolders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};
(node as any).hash = '44c206a3f5b30fa8c72b98e158b674bc';
export default node;
