"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpaqueString = exports.UsernameCasePreserved = exports.UsernameCaseMapped = void 0;
const baseclass_1 = require("./baseclass");
const bidi_1 = require("./bidi");
const error_1 = require("./error");
const width_1 = require("./width");
const prepareUsernameCaseMapped = (text) => {
    text = width_1.widthMappingRule(text);
    baseclass_1.validateIdentifierClass(text);
    return text;
};
const enforceUsernameCaseMapped = (text) => {
    text = prepareUsernameCaseMapped(text);
    text = text.toLowerCase();
    text = text.normalize('NFC');
    bidi_1.validateBidiRule(text);
    if (text.length === 0) {
        throw new error_1.EmptyStringError(text);
    }
    return text;
};
exports.UsernameCaseMapped = {
    prepare: prepareUsernameCaseMapped,
    enforce: enforceUsernameCaseMapped,
};
const prepareUsernameCasePreserved = (text) => {
    text = width_1.widthMappingRule(text);
    baseclass_1.validateIdentifierClass(text);
    return text;
};
const enforceUsernameCasePreserved = (text) => {
    text = prepareUsernameCasePreserved(text);
    text = text.normalize('NFC');
    bidi_1.validateBidiRule(text);
    if (text.length === 0) {
        throw new error_1.EmptyStringError(text);
    }
    return text;
};
exports.UsernameCasePreserved = {
    prepare: prepareUsernameCasePreserved,
    enforce: enforceUsernameCasePreserved,
};
const prepareOpaqueString = (text) => {
    baseclass_1.validateFreeformClass(text);
    return text;
};
const enforceOpaqueString = (text) => {
    text = prepareOpaqueString(text);
    text = text.replace(/\p{Zs}/gu, ' ');
    text = text.normalize('NFC');
    return text;
};
exports.OpaqueString = {
    prepare: prepareOpaqueString,
    enforce: enforceOpaqueString,
};
