import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  ImageList,
  ImageListItem,
  Typography,
} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/core/styles';
import {Star} from '@material-ui/icons';
import clsx from 'clsx';

import DialogHeader from '../../../../components/dialogs/dialog-header';

import {TWorkspace} from '../../../../state/timeline/state';

import {
  BACKGROUND_COLOR_OPTION_ID,
  DEFAULT_BG_COLOR_INDEX,
  WorkspaceBackgroundIndex,
  WorkspaceBackgroundShade,
  workspaceBackgroundShades,
} from '../../timeline.constants';
import {
  getIndexFromShade,
  getShadeFromIndex,
  getWorkspaceOptionValue,
} from '../../timeline.helpers';

type Props = {
  selectedWorkspace: TWorkspace | undefined;
  isOpen: boolean;
  setWorkspaceBackground(args: {workspaceId: string; value: WorkspaceBackgroundIndex}): void;
  handleClose(): void;
};

const useStyles = makeStyles((theme) => ({
  imageListItem: {display: 'flex'},
  colorBox: {
    display: 'inline-block',
    width: '100%',
  },
  starInvert: {color: grey[900]},
  caption: {
    display: 'block',
    marginTop: theme.spacing(1),
    color: theme.palette.grey[700],
  },
}));

const WorkspaceOptionsModalView: React.FC<Props> = ({
  isOpen,
  selectedWorkspace,
  setWorkspaceBackground,
  handleClose,
}) => {
  const classes = useStyles();

  const handleBackgroundChange = (wsBgColorShade: WorkspaceBackgroundShade) => {
    const newIndex = getIndexFromShade(wsBgColorShade);
    selectedWorkspace &&
      newIndex !== undefined &&
      setWorkspaceBackground({
        workspaceId: selectedWorkspace.id,
        value: newIndex,
      });
    handleClose();
  };

  const colorPaletteGrid = workspaceBackgroundShades.map((wsBgColorShade) => {
    const shade = grey[wsBgColorShade];
    const style = {backgroundColor: shade};

    const bgColorIndex =
      getWorkspaceOptionValue(selectedWorkspace?.options, BACKGROUND_COLOR_OPTION_ID) ??
      DEFAULT_BG_COLOR_INDEX;
    const bgColorShade = getShadeFromIndex(bgColorIndex);
    return (
      <ImageListItem
        classes={{item: classes.imageListItem}}
        component={Button}
        key={wsBgColorShade}
        onClick={() => handleBackgroundChange(wsBgColorShade)}
        style={style}
      >
        {selectedWorkspace && bgColorShade === wsBgColorShade && (
          <Star className={clsx({[classes.starInvert]: wsBgColorShade < 500})} />
        )}
      </ImageListItem>
    );
  });

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogHeader handleClose={handleClose}>
        {`Select background color for workspace ${selectedWorkspace?.name || 'unknown'}`}
      </DialogHeader>
      <DialogContent>
        <ImageList cols={5} rowHeight={65}>
          {colorPaletteGrid}
        </ImageList>
        <Typography className={classes.caption} variant={'caption'}>
          *Please note that background color is not permanent until you save the workspace.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default WorkspaceOptionsModalView;
