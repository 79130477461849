"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBidiClass = exports.getJoiningType = exports.getDerivedProperty = exports.isVirma = exports.isFullHalf = void 0;
const generated_1 = require("./generated");
const isFullHalf = (cp) => generated_1.IS_FULL_HALF.has(cp);
exports.isFullHalf = isFullHalf;
const isVirma = (cp) => generated_1.IS_VIRMA.has(cp);
exports.isVirma = isVirma;
const getDerivedProperty = (cp) => generated_1.DERIVED_PROPERTY_NAMES[getRecord(cp)[generated_1.DERIVED_PROPERTY_INDEX]];
exports.getDerivedProperty = getDerivedProperty;
const getJoiningType = (cp) => generated_1.JOINING_TYPE_VALUES[getRecord(cp)[generated_1.JOINING_TYPE_INDEX]];
exports.getJoiningType = getJoiningType;
const getBidiClass = (cp) => generated_1.BIDI_CLASS_VALUES[getRecord(cp)[generated_1.BIDI_CLASS_INDEX]];
exports.getBidiClass = getBidiClass;
const getRecord = (cp) => {
    const i1 = generated_1.INDEX1[cp >> generated_1.SHIFT];
    const i2 = generated_1.INDEX2[(i1 << generated_1.SHIFT) + (cp & ((1 << generated_1.SHIFT) - 1))];
    return generated_1.RECORDS[i2];
};
