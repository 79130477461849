/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetail_metaData = ReadonlyArray<{
    readonly id: string;
    readonly storedOn: Date;
    readonly fileName: string;
    readonly eventDate: Date;
    readonly size: number;
    readonly mimeType?: string | undefined;
    readonly " $refType": "evidenceDetail_metaData";
}>;
export type evidenceDetail_metaData$data = evidenceDetail_metaData;
export type evidenceDetail_metaData$key = ReadonlyArray<{
    readonly " $data"?: evidenceDetail_metaData$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_metaData">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "evidenceDetail_metaData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "storedOn",
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": "fileName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mimeType",
          "storageKey": null
        }
      ],
      "type": "File",
      "abstractKey": null
    }
  ],
  "type": "Evidence",
  "abstractKey": "__isEvidence"
};
(node as any).hash = 'd059ef51f60140cff7602b69b25bff73';
export default node;
