import React from 'react';

import {TWorkspace} from '../../../state/timeline/state';

import TimelineCanvasView from './timeline-canvas.view';

type Props = {
  selectedWorkspace: TWorkspace | undefined;
  isLoading: boolean;
};

const TimelineCanvasController: React.FC<Props> = ({isLoading, selectedWorkspace}) => {
  if (selectedWorkspace == undefined) {
    return null;
  }

  return <TimelineCanvasView isLoading={isLoading} selectedWorkspace={selectedWorkspace} />;
};

export default TimelineCanvasController;
