import React, {ReactElement, useEffect, useRef, useState} from 'react';

import {Button, Tooltip} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {evidenceDetail_actions$data} from '../../../graphql/__generated__/evidenceDetail_actions.graphql';

import {JOURNAL_ENTRY_MIMETYPE} from '../../../services/evidences/data/evidence/content-type';
import {parseBlob} from './preview-file.helpers';

type singleEvidence = evidenceDetail_actions$data[number];

type Props = {
  evidence: singleEvidence;
};

const PreviewFile = ({evidence}: Props): ReactElement => {
  const [src, setSrc] = useState('');
  const {fileName, url, subject, body, eventDate} = evidence;
  const mimeType = evidence.mimeType ?? JOURNAL_ENTRY_MIMETYPE;

  const setBlobAsSource = (blob: Blob | undefined) => {
    if (blob !== undefined) {
      const objectUrl = URL.createObjectURL(blob);
      setSrc(objectUrl);
    }
  };

  useEffect(() => {
    const getBlob = async () => {
      const blob = await parseBlob({
        mimeType,
        url,
        subject,
        body,
        eventDate,
      });
      setBlobAsSource(blob);
    };
    getBlob();

    return function cleanup() {
      if (!unmounted.current) {
        URL.revokeObjectURL(src);
      }
    };
  }, [evidence]);

  const unmounted = useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <Tooltip title={`Preview "${fileName}"`}>
      <Button
        endIcon={<OpenInNewIcon />}
        fullWidth
        href={src}
        rel={'noopener noreferrer'}
        target={'_blank'}
        variant={'contained'}
      >
        Preview
      </Button>
    </Tooltip>
  );
};

export default PreviewFile;
