/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceNav_foldersNav = {
    readonly unsortedFolder: {
        readonly type: string;
        readonly id: string;
        readonly name: string;
        readonly evidenceCount: number;
    };
    readonly allFilesFolder: {
        readonly type: string;
        readonly id: string;
        readonly name: string;
        readonly evidenceCount: number;
    };
    readonly userFolders: ReadonlyArray<{
        readonly type: string;
        readonly id: string;
        readonly name: string;
        readonly evidenceCount: number;
    }>;
    readonly " $refType": "evidenceNav_foldersNav";
};
export type evidenceNav_foldersNav$data = evidenceNav_foldersNav;
export type evidenceNav_foldersNav$key = {
    readonly " $data"?: evidenceNav_foldersNav$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceNav_foldersNav">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": "type",
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "evidenceCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "evidenceNav_foldersNav",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UnsortedFolder",
      "kind": "LinkedField",
      "name": "unsortedFolder",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllFilesFolder",
      "kind": "LinkedField",
      "name": "allFilesFolder",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFolder",
      "kind": "LinkedField",
      "name": "userFolders",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();
(node as any).hash = '76d9b1752b7a3335273b8dfa0284a397';
export default node;
