/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateFolderInput = {
    name: string;
    clientMutationId?: string | null | undefined;
};
export type createFolderMutationVariables = {
    input: CreateFolderInput;
};
export type createFolderMutationResponse = {
    readonly createFolder: {
        readonly account: {
            readonly userFolders: ReadonlyArray<{
                readonly type: string;
                readonly id: string;
                readonly name: string;
                readonly evidenceCount: number;
            }>;
        };
    } | null;
};
export type createFolderMutation = {
    readonly response: createFolderMutationResponse;
    readonly variables: createFolderMutationVariables;
};



/*
mutation createFolderMutation(
  $input: CreateFolderInput!
) {
  createFolder(input: $input) {
    account {
      userFolders {
        type: __typename
        id
        name
        evidenceCount
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserFolder",
  "kind": "LinkedField",
  "name": "userFolders",
  "plural": true,
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "evidenceCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFolderPayload",
        "kind": "LinkedField",
        "name": "createFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFolderPayload",
        "kind": "LinkedField",
        "name": "createFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5777091584273d64287ecf5e7a1ad71e",
    "id": null,
    "metadata": {},
    "name": "createFolderMutation",
    "operationKind": "mutation",
    "text": "mutation createFolderMutation(\n  $input: CreateFolderInput!\n) {\n  createFolder(input: $input) {\n    account {\n      userFolders {\n        type: __typename\n        id\n        name\n        evidenceCount\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1a72403e213d8c245de962f4bc9960fa';
export default node;
