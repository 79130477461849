import React, {Fragment, ReactElement} from 'react';

import {graphql, useLazyLoadQuery} from 'react-relay';

import {eventLogQuery} from '../../../../graphql/__generated__/eventLogQuery.graphql';

import EventLogView from './event-log.view';

type Props = {
  userId: string;
};

const EventLogController = ({userId}: Props): ReactElement => {
  const queryData = useLazyLoadQuery<eventLogQuery>(
    graphql`
      query eventLogQuery($userId: ID!) {
        user: node(id: $userId) {
          ... on User {
            firstName
            lastName
          }
          ...eventLog_user
        }
      }
    `,
    {userId},
  );

  const {user} = queryData;

  return (
    <Fragment>
      <EventLogView user={user} />
    </Fragment>
  );
};

export default EventLogController;
