import {connect} from 'react-redux';

import {setCurrentFolderId, setCurrentTagFilterIds} from '../../../state/evidence/actions';
import {getCurrentFolderId, getCurrentTagFilterIds} from '../../../state/evidence/selectors';
import {TRootState} from '../../../state/store';

import EvidenceNavController from './evidence-nav.controller';

const mapStateToProps = (state: TRootState) => ({
  currentFolderId: getCurrentFolderId(state),
  currentTagFilterIds: getCurrentTagFilterIds(state),
});

const mapDispatchToProps = {
  setCurrentFolderId,
  setCurrentTagFilterIds,
};

const EvidenceNav = connect(mapStateToProps, mapDispatchToProps)(EvidenceNavController);

export default EvidenceNav;
