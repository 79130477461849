import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {DefaultTheme} from '@material-ui/styles';
import clsx from 'clsx';
import {createHash} from 'crypto';
import {Base64} from 'js-base64';

import {TTimelineEvidence, TWorkspaceTag} from '../../../state/timeline/state';

type Props = {
  from: number[];
  to: number[];
  color: string;
  lockHighlight: boolean;
};

const useStyles = makeStyles<DefaultTheme, Pick<Props, 'color'>>({
  highlighted: {
    filter: ({color}) => `drop-shadow(0 0 1px ${color})`,
  },
});

const useStylesLines = makeStyles({
  svg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    pointerEvents: 'none',
  },
});

const EvidenceNodeConnectionLine: React.FC<Props> = ({
  from: [x1, y1],
  to: [x2, y2],
  color,
  lockHighlight,
}) => {
  const classes = useStyles({color});

  return (
    <line
      className={clsx(lockHighlight && classes.highlighted)}
      stroke={color}
      strokeWidth={1}
      x1={x1}
      x2={x2}
      y1={y1}
      y2={y2}
    />
  );
};

type LinesProps = {
  visibleTags: TWorkspaceTag[];
  highlightedEvidences: TTimelineEvidence[];
};

const TagEvidenceConnectionLinesComponent: React.FC<LinesProps> = ({
  visibleTags,
  highlightedEvidences,
}) => {
  const classes = useStylesLines();

  const connectionLines = highlightedEvidences.map((highlightedEvidence) => {
    const {id, tags: highlightedEvidenceTags} = highlightedEvidence;
    const svg = document.getElementById('timeline-canvas');
    if (!svg) return;
    const {x: ox, y: oy, width} = svg?.getBoundingClientRect();
    const to = document.querySelectorAll(`[data-evidence-id="${id}"]`)[1];
    if (!to) return;
    const {x: x2, y: y2, width: w2, height: h2} = to.getBoundingClientRect();
    if (x2 - ox < 0 || x2 - ox > width) return;

    return visibleTags
      .filter(({tagId}) => highlightedEvidenceTags.includes(tagId))
      .map((tag, index) => {
        const {tagId, ...rest} = tag;

        const from = document.getElementsByClassName(`tag-${tagId}`)[0];
        if (!from) return;
        const {x: x1, y: y1, width: w1, height: h1} = from.getBoundingClientRect();

        // unique key from tag to evidence
        const uuid = Base64.fromUint8Array(
          createHash('sha256').update(`${highlightedEvidence}-${tag}-${index}`).digest(),
        );
        const key = `evidence-line-${uuid}`;
        return (
          <EvidenceNodeConnectionLine
            from={[x1 - ox + w1 / 2, y1 - oy + h1 / 2]}
            key={key}
            to={[x2 - ox + w2 / 2, y2 - oy + h2 / 2]}
            {...rest}
          />
        );
      });
  });

  return <svg className={classes.svg}>{connectionLines}</svg>;
};

export default TagEvidenceConnectionLinesComponent;
