import React, {useRef} from 'react';

import {Grid, TextField, makeStyles} from '@material-ui/core';
import {EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';

import ConfirmationDialog from '../../../../components/dialogs/confirmation-dialog';

const useStyles = makeStyles({
  editorTextarea: {
    padding: '0 0.75rem',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    minHeight: '10rem',
  },
  editorWrapper: {
    'border': '1px solid rgba(0, 0, 0, 0.23)',
    'borderRadius': '0.25rem',
    'fontSize': '1rem',
    'minHeight': '10rem',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
  },
});

type Props = {
  open: boolean;
  subjectError: boolean;
  subjectHelperText: string | null;
  editorState: EditorState;
  onEditorStateChange(editorState: EditorState): void;
  onSubjectValueChange(value: string): void;
  handleSubmit(): void;
  onClose(): void;
};

const EvidenceNavLogEntryModalView: React.FC<Props> = ({
  open,
  subjectError,
  subjectHelperText,
  editorState,
  onEditorStateChange,
  onSubjectValueChange,
  handleSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const subjectInputRef = useRef<HTMLInputElement>();

  const handleSubjectChange = () => {
    if (subjectInputRef.current?.value !== undefined) {
      const subject = subjectInputRef.current.value;
      onSubjectValueChange(subject);
    }
  };

  return (
    <ConfirmationDialog
      ariaDescription={'evidence-log-entry-desc'}
      ariaTitle={`evidence-log-entry-title`}
      confirmText={'Submit'}
      denyText={'Cancel'}
      onConfirm={handleSubmit}
      open={!!open}
      setOpen={onClose}
      size={'md'}
      title={'Create Journal Entry'}
    >
      <Grid container direction={'column'} spacing={1}>
        <Grid item xs>
          <TextField
            color={'secondary'}
            error={subjectError}
            fullWidth
            helperText={subjectHelperText}
            inputRef={subjectInputRef}
            label={'Subject'}
            name={'subject'}
            onChange={handleSubjectChange}
            variant={'outlined'}
          />
        </Grid>
        <Grid item xs>
          <Editor
            editorClassName={classes.editorTextarea}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            placeholder={'Start your journal entry...'}
            toolbar={{
              options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
              inline: {options: ['bold', 'italic', 'underline', 'strikethrough']},
              textAlign: {
                inDropdown: true,
              },
            }}
            wrapperClassName={classes.editorWrapper}
          />
        </Grid>
      </Grid>
    </ConfirmationDialog>
  );
};

export default EvidenceNavLogEntryModalView;
