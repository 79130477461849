/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceBaseQueryVariables = {};
export type evidenceBaseQueryResponse = {
    readonly currentSession: {
        readonly account?: {
            readonly unsortedFolder: {
                readonly id: string;
            };
            readonly " $fragmentRefs": FragmentRefs<"evidenceNav_foldersNav" | "evidenceBase_folderDropName">;
        } | null | undefined;
        readonly message?: string | undefined;
    } | null;
};
export type evidenceBaseQuery = {
    readonly response: evidenceBaseQueryResponse;
    readonly variables: evidenceBaseQueryVariables;
};



/*
query evidenceBaseQuery {
  currentSession {
    __typename
    ... on Session {
      account {
        unsortedFolder {
          id
        }
        ...evidenceNav_foldersNav
        ...evidenceBase_folderDropName
        id
      }
    }
    ... on SessionError {
      message
    }
  }
}

fragment evidenceBase_folderDropName on Account {
  userFolders {
    id
    name
  }
}

fragment evidenceNav_foldersNav on Account {
  unsortedFolder {
    type: __typename
    id
    name
    evidenceCount
  }
  allFilesFolder {
    type: __typename
    id
    name
    evidenceCount
  }
  userFolders {
    type: __typename
    id
    name
    evidenceCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "SessionError",
  "abstractKey": null
},
v2 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "evidenceCount",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v0/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "evidenceBaseQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnsortedFolder",
                    "kind": "LinkedField",
                    "name": "unsortedFolder",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceNav_foldersNav"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceBase_folderDropName"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "evidenceBaseQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnsortedFolder",
                    "kind": "LinkedField",
                    "name": "unsortedFolder",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllFilesFolder",
                    "kind": "LinkedField",
                    "name": "allFilesFolder",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFolder",
                    "kind": "LinkedField",
                    "name": "userFolders",
                    "plural": true,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0aa814974afd4df9b6a4fefc798a2125",
    "id": null,
    "metadata": {},
    "name": "evidenceBaseQuery",
    "operationKind": "query",
    "text": "query evidenceBaseQuery {\n  currentSession {\n    __typename\n    ... on Session {\n      account {\n        unsortedFolder {\n          id\n        }\n        ...evidenceNav_foldersNav\n        ...evidenceBase_folderDropName\n        id\n      }\n    }\n    ... on SessionError {\n      message\n    }\n  }\n}\n\nfragment evidenceBase_folderDropName on Account {\n  userFolders {\n    id\n    name\n  }\n}\n\nfragment evidenceNav_foldersNav on Account {\n  unsortedFolder {\n    type: __typename\n    id\n    name\n    evidenceCount\n  }\n  allFilesFolder {\n    type: __typename\n    id\n    name\n    evidenceCount\n  }\n  userFolders {\n    type: __typename\n    id\n    name\n    evidenceCount\n  }\n}\n"
  }
};
})();
(node as any).hash = '2055e718d67fdca08f6d65599d8f0d12';
export default node;
