/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetailTaggedEvidenceQueryVariables = {
    ids: Array<string>;
    skip: boolean;
};
export type evidenceDetailTaggedEvidenceQueryResponse = {
    readonly evidence?: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_grid">;
    } | null> | undefined;
};
export type evidenceDetailTaggedEvidenceQuery = {
    readonly response: evidenceDetailTaggedEvidenceQueryResponse;
    readonly variables: evidenceDetailTaggedEvidenceQueryVariables;
};



/*
query evidenceDetailTaggedEvidenceQuery(
  $ids: [ID!]!
  $skip: Boolean!
) {
  evidence: nodes(ids: $ids) @skip(if: $skip) {
    __typename
    ...evidenceDetail_grid
    id
  }
}

fragment evidenceDetail_grid on Evidence {
  __isEvidence: __typename
  id
  fileName: name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "evidenceDetailTaggedEvidenceQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "evidence",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceDetail_grid"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "evidenceDetailTaggedEvidenceQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "evidence",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "fileName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "type": "Evidence",
                "abstractKey": "__isEvidence"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "9d03e193d4ee218678bc37ce46cc08ce",
    "id": null,
    "metadata": {},
    "name": "evidenceDetailTaggedEvidenceQuery",
    "operationKind": "query",
    "text": "query evidenceDetailTaggedEvidenceQuery(\n  $ids: [ID!]!\n  $skip: Boolean!\n) {\n  evidence: nodes(ids: $ids) @skip(if: $skip) {\n    __typename\n    ...evidenceDetail_grid\n    id\n  }\n}\n\nfragment evidenceDetail_grid on Evidence {\n  __isEvidence: __typename\n  id\n  fileName: name\n}\n"
  }
};
})();
(node as any).hash = '2f6b1334437b2110a79c814796441901';
export default node;
