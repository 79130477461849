import React, {Fragment, useState} from 'react';

import {TWorkspace} from '../../../../state/timeline/state';

import CloneWorkspace from '../clone-workspace';
import CreateWorkspaceModal from '../create-workspace-modal';
import DeleteWorkspaceModal from '../delete-workspace-modal';
import RenameWorkspaceModal from '../rename-workspace-modal';
import WorkspaceSelectionModalView from './workspace-selection-modal.view';

type Props = {
  selectedWorkspaceId: string | undefined;
  setSelectedWorkspaceId: (workspaceId: string) => void;
  unsetSelectedWorkspaceId(): void;
  open: boolean;
  onClose(): void;
  workspaces: TWorkspace[];
};

const WorkspaceSelectionModalController: React.FC<Props> = ({
  selectedWorkspaceId,
  setSelectedWorkspaceId,
  unsetSelectedWorkspaceId,
  open,
  onClose,
  workspaces,
}) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [workspaceToBeRenamed, setWorkspaceToBeRenamed] = useState<TWorkspace | undefined>();
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [workspaceToBeCloned, setWorkspaceToBeCloned] = useState<TWorkspace | undefined>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [workspaceToBeDeleted, setWorkspaceToBeDeleted] = useState<TWorkspace | undefined>();

  // useReducer here???
  const handleOpenRenameWorkspaceModal = async (id: string) => {
    const workspaceToBeRenamed = workspaces.find((w) => w.id === id);
    setWorkspaceToBeRenamed(workspaceToBeRenamed);
    setRenameDialogOpen(true);
  };

  const handleCloseRenameWorkspaceModal = async () => {
    setRenameDialogOpen(false);
  };

  const handleOpenCloneWorkspace = async (id: string) => {
    const workspaceToBeCloned = workspaces.find((w) => w.id === id);
    setWorkspaceToBeCloned(workspaceToBeCloned);
    setCloneDialogOpen(true);
  };

  const handleCloseCloneWorkspace = async () => {
    setCloneDialogOpen(false);
  };

  const handleOpenDeleteWorkspaceModal = async (id: string) => {
    const workspaceToBeDeleted = workspaces.find((w) => w.id === id);
    setWorkspaceToBeDeleted(workspaceToBeDeleted);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteWorkspaceModal = async () => {
    setDeleteDialogOpen(false);
    unsetSelectedWorkspaceId();
  };

  return (
    <Fragment>
      <WorkspaceSelectionModalView
        onCloneWorkspace={handleOpenCloneWorkspace}
        onClose={onClose}
        onCreateWorkspace={() => setCreateDialogOpen(true)}
        onDeleteWorkspace={handleOpenDeleteWorkspaceModal}
        onRenameWorkspace={handleOpenRenameWorkspaceModal}
        onSelectWorkspace={setSelectedWorkspaceId}
        open={open}
        selectedWorkspaceId={selectedWorkspaceId}
        workspaces={workspaces}
      />
      <CreateWorkspaceModal isOpen={createDialogOpen} onClose={() => setCreateDialogOpen(false)} />
      <RenameWorkspaceModal
        isOpen={renameDialogOpen}
        onClose={handleCloseRenameWorkspaceModal}
        workspaceToBeRenamed={workspaceToBeRenamed}
      />
      <CloneWorkspace
        isOpen={cloneDialogOpen}
        onClose={handleCloseCloneWorkspace}
        workspaceToBeCloned={workspaceToBeCloned}
      />
      <DeleteWorkspaceModal
        isOpen={deleteDialogOpen}
        onClose={handleCloseDeleteWorkspaceModal}
        workspaceToBeDeleted={workspaceToBeDeleted}
      />
    </Fragment>
  );
};

export default WorkspaceSelectionModalController;
