import React from 'react';

import {DialogContent, Drawer, Grid, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import {TCaseFolder, TCaseTag, TEvidenceMimeType} from '../../../state/timeline/state';

import FilterList from './filter-list';
import type {FilterToggle} from './filter-selection-drawer.controller';
import type {FilterValue} from './index';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    maxWidth: '33%',
  },
  drawerHeader: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 2,
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
}));

type Props = {
  isOpen: boolean;
  folderValues: FilterValue<TCaseFolder>[];
  tagValues: FilterValue<TCaseTag>[];
  typeValues: FilterValue<TEvidenceMimeType>[];
  toggleFilter(filter: FilterToggle): void;
  toggleAllFilters(type: 'folders' | 'tags' | 'types'): void;
  onClose(): void;
};

const FilterSelectionDrawerView: React.FC<Props> = ({
  isOpen,
  folderValues,
  tagValues,
  typeValues,
  toggleFilter,
  toggleAllFilters,
  onClose,
}) => {
  const classes = useStyles();

  const folderCheckboxValues = folderValues.map(({item, checked}) => ({
      id: item.id,
      label: item.name,
      checked: checked,
    })),
    tagCheckboxValues = tagValues.map(({item, checked}) => ({
      id: item.id,
      label: item.text,
      checked,
    })),
    typeCheckboxValues = typeValues.map(({item, checked}) => ({
      id: item.id,
      label: item.name,
      checked,
    }));

  return (
    <Drawer
      anchor={'right'}
      aria-labelledby={'filter-selection-drawer-title'}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onClose}
      open={isOpen}
      variant={'persistent'}
    >
      <div className={classes.drawerHeader} id={'filter-selection-drawer-title'}>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize={'inherit'} />
        </IconButton>
      </div>
      <DialogContent className={classes.drawerContent}>
        <Grid container>
          <Grid item>
            <FilterList
              labelPrefix={'folder'}
              toggleAll={() => toggleAllFilters('folders')}
              toggleFilter={(id) =>
                toggleFilter({
                  type: 'folder',
                  id: id as string,
                })
              }
              values={folderCheckboxValues}
            />
          </Grid>
          <Grid item>
            <FilterList
              labelPrefix={'tag'}
              toggleAll={() => toggleAllFilters('tags')}
              toggleFilter={(id) =>
                toggleFilter({
                  type: 'tag',
                  id: id as number,
                })
              }
              values={tagCheckboxValues}
            />
          </Grid>
          <Grid item>
            <FilterList
              labelPrefix={'type'}
              toggleAll={() => toggleAllFilters('types')}
              toggleFilter={(id) =>
                toggleFilter({
                  type: 'type',
                  id: id as number,
                })
              }
              values={typeCheckboxValues}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Drawer>
  );
};

export default FilterSelectionDrawerView;
