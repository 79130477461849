import React, {Fragment} from 'react';
import type {Dispatch, KeyboardEvent, ReactElement, SetStateAction} from 'react';

import {IconButton} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import SplitButton, {optionToString} from '../../../../components/selectors/SplitButton';

import type {FilterOption} from './UserFilterController';

const useStyles = makeStyles((theme) => ({
  searchUsersContainer: {
    background: '#efefef',
    borderRadius: '4px',
    paddingLeft: theme.spacing(1 / 2),
    paddingRight: theme.spacing(1 / 2),
  },
  filterButtons: {
    height: '40px',
    background: 'white',
  },
  filterSearchBtn: {
    width: '155px',
  },
  filterInputFormControl: {
    flexDirection: 'row',
  },
  filterInput: {
    minWidth: '250px',
    background: 'white',
  },
  btnMargin: {
    marginLeft: theme.spacing(1 / 2),
  },
  filterInputLabel: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  popperMenu: {
    width: '252px',
  },
}));

type Props<T> = {
  options: T;
  searchValue: string;
  errors: Error[];
  current: FilterOption;
  updateCurrent: Dispatch<SetStateAction<FilterOption>>;
  onChange(data: string): void;
  onSubmit(): void;
};

const UserFilterView = <T extends FilterOption[]>({
  options,
  searchValue,
  errors,
  current,
  updateCurrent,
  onChange,
  onSubmit,
}: Props<T>): ReactElement => {
  const classes = useStyles();

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }

  return (
    <Grid
      alignItems={'center'}
      className={classes.searchUsersContainer}
      container
      direction={'row'}
    >
      <Grid item>
        <TextField
          InputLabelProps={{classes: {outlined: classes.filterInputLabel}}}
          InputProps={{classes: {root: classes.filterInput}}}
          classes={{root: `${classes.filterInputFormControl} ${classes.filterButtons}`}}
          color={'secondary'}
          error={errors.length > 0}
          label={errors.length > 0 ? 'Enter valid filter' : 'Search users'}
          onChange={(event) => onChange(event.currentTarget.value)}
          onKeyPress={(event) => handleKeyDown(event)}
          value={searchValue}
          variant={'outlined'}
        />
      </Grid>
      <Grid className={classes.btnMargin} item>
        <SplitButton
          LeftButtonProps={{
            variant: 'outlined',
            disabled: true,
            classes: {root: classes.filterSearchBtn},
          }}
          buttonClassName={classes.filterButtons}
          currentValue={current}
          menuClassName={classes.popperMenu}
          menuPlacement={'bottom-start'}
          options={options}
          renderSelectedButtonText={(option: FilterOption) => (
            <Fragment>
              by &nbsp;
              <Typography color={'secondary'}>{optionToString(option)}</Typography>
            </Fragment>
          )}
          updateCurrentValue={updateCurrent}
        />
      </Grid>
      <Grid className={classes.btnMargin} item>
        <IconButton aria-label={'search-table-rows'} onClick={onSubmit} style={{padding: '9px'}}>
          <Tooltip title={'Search'}>
            <SearchRoundedIcon fontSize={'large'} />
          </Tooltip>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default UserFilterView;
