import {ElementType} from 'react';

import Tags from '../pages/admin/tags';
import UserList from '../pages/admin/user-list';
import EventLog from '../pages/admin/user/event-log';
import User from '../pages/admin/user/user';
import Evidence from '../pages/evidence';
import Home from '../pages/home/home';
import Settings from '../pages/settings';
import Page404 from '../pages/site/404';
import Timeline from '../pages/timeline';

export const BASE = '/';
export const LOGIN = '/login';
export const SETTINGS = '/settings';
export const ACCOUNT_RECOVERY = LOGIN + '/account-recovery';
export const RESET_PASSWORD = LOGIN + '/reset-password/:token';
export const HOME = '/home';
export const EVIDENCE = '/evidence';
export const TIMELINE = '/timeline';
export const ADMIN = '/admin';
export const USERS_ADMIN = ADMIN + '/users';
export const USER_BASE = ADMIN + '/user';
export const USER_PATH = USER_BASE + '/:id';
export const USER_EVENT_LOG = USER_PATH + '/log';
export const TAGS_ADMIN = ADMIN + '/tags';

export type RouteConfigProps = {
  path?: string;
  Component: ElementType;
  exact?: boolean;
  routes?: RouteConfigProps[];
};

export const routes = [
  {
    path: BASE,
    Component: Home,
    exact: true,
  },
  {
    path: HOME,
    Component: Home,
    exact: true,
  },
  {
    path: EVIDENCE,
    Component: Evidence,
  },
  {
    path: TIMELINE,
    Component: Timeline,
    exact: true,
  },
  {
    path: TAGS_ADMIN,
    Component: Tags,
    exact: true,
  },
  {
    path: USERS_ADMIN,
    Component: UserList,
    exact: true,
  },
  {
    path: USER_PATH,
    Component: User,
  },
  {
    path: USER_EVENT_LOG,
    Component: EventLog,
  },
  {
    path: SETTINGS,
    Component: Settings,
    exact: true,
  },
  {
    Component: Page404,
  },
];
