const entityKeys = {
  IDataEvidenceTagFacade: Symbol.for('@entities/IDataEvidenceTagFacade'),
  DataEvidenceTagFacade: Symbol.for('@entities/DataEvidenceTagFacade'),

  IFolderFacade: Symbol.for('@entities/IFolderFacade'),
  FolderFacade: Symbol.for('@entities/FolderFacade'),

  ITagFacade: Symbol.for('@entities/ITagFacade'),
  TagFacade: Symbol.for('@entities/TagFacade'),

  IDataEntityFacade: Symbol.for('@entities/IDataEntityFacade'),
  DataEntityFacade: Symbol.for('@entities/DataEntityFacade'),

  IEvidenceService: Symbol.for('@entities/IEvidenceService'),
  EntityService: Symbol.for('@entities/EntityService'),

  IDataEntityFolderFacade: Symbol.for('@entities/IDataEntityFolderFacade'),
  DataEntityFolderFacade: Symbol.for('@entities/DataEntityFolderFacade'),

  IEvidenceRepository: Symbol.for('@entities/IEvidenceRepository'),
};

export default entityKeys;
