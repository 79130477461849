import {connect} from 'react-redux';

import {setSelectedEvidenceIds} from '../../../state/evidence/actions';
import {getCurrentFolderId, getCurrentTagFilterIds} from '../../../state/evidence/selectors';
import {TRootState} from '../../../state/store';

import EvidenceListController from './evidence-list.controller';

const mapStateToProps = (state: TRootState) => ({
  currentFolderId: getCurrentFolderId(state),
  currentTagFilterIds: getCurrentTagFilterIds(state),
});

const mapDispatchToProps = {
  setSelectedEvidenceIds,
};

const EvidenceList = connect(mapStateToProps, mapDispatchToProps)(EvidenceListController);

export default EvidenceList;
