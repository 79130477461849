import {ValidationError} from 'class-validator';
import {format} from 'date-fns';
import {Base64} from 'js-base64';

export const isBase64 = (v: unknown): v is string => Base64.isValid(v);

export function uniqueIdGenerator(): string {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return `${S4()}${S4()}"-"${S4()}"-"${S4()}"-"${S4()}"-"${S4()}${S4()}${S4()}`;
}

export const sleep = (delay: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, delay));

// export function toggleItem<T>(item: T, arr: T[]): T[] {
//   if (arr.length === 0) {
//     return [item];
//   }

//   if (arr.some((i) => i === item)) {
//     return arr.filter((i) => i !== item);
//   } else {
//     return [...arr, item];
//   }
// }

export function hasIntersection<T>(arr1: T[], arr2: T[]): boolean {
  const commonValues = arrayPairIntersection(arr1, arr2);
  return commonValues.length > 0;
}

export function arrayPairIntersection<T>(arr1: T[], arr2: T[]): T[] {
  const set = new Set(arr1);

  return arr2.filter((item) => set.has(item));
}

export function arraySeveralIntersection(arrays: unknown[][]): unknown[] {
  return arrays.reduce((join, current) => join?.filter((el) => current?.includes(el)));
}

export function isNotNullish<T>(value: T): value is NonNullable<T> {
  return typeof value !== 'undefined' && value !== null;
}

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

/**
 * Format bytes as human-readable text.
 *
 * Courtesy of mpen on Stack Overflow:https://stackoverflow.com/a/14919494/3882704
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1): string => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

// const fileExtensionRegex =
// /\.(jpe?g|png|docx?|xlsx?|pptx?|od[pt]|xml|gif|bmp|tiff?|rtf|txt|mp[34]|mov|mpeg|pdf|mkv|wav)$/;
const fileExtensionRegex = /\.[\d\w]+$/;

export const validFileExtension = (file: File): boolean => {
  return fileExtensionRegex.test(file.name);
};

export function isValidDate(d: string): boolean {
  return !isNaN(Date.parse(d));
}

//  Converts timestamp to a readable/compact datetime format
//  Example: input: 1432239455000 --> output: "5/21/2015, 1:17:35 PM"
export const readableDateTimeString = (timestamp: number | string | Date): string => {
  return format(new Date(timestamp), 'MM/dd/yy, HH:mm');
};

// Converts timestamp to a readable/compact date format
export const readableDateString = (timestamp: number | string | Date): string => {
  return format(new Date(timestamp), 'MM/dd/yy');
};

// Converts timestamp to a readable/compact date format
export const customDateTimeString = (timestamp: number | string | Date): string => {
  return format(new Date(timestamp), 'MM/dd/yy, HH:mm');
};

// Converts timestamp to a MUI datepicker format
export const muiDateTimeString = (timestamp: number | string | Date): string => {
  return format(new Date(timestamp), "yyyy-MM-dd'T'HH:mm");
};

// Converts timestamp to a filename friendly format
export const downloadTimestampString = (timestamp: number | string | Date): string => {
  return format(new Date(timestamp), 'yyyy-MM-dd_hh-mmaa OOOO');
};

// Converts timestamp to just month abbr and year for sorting/grouping evidence by month
export const monthYearString = (timestamp: number | string | Date): string => {
  return format(new Date(timestamp), 'MMM yyyy');
};

/* Confirms if an array of class-validator errors matches a given property.

  Usage: User constructs a class and assigns class-validator decorators to given variables to enforce
         some validation, runs class-validator validateSync() which returns an array of errors based
         on any broken validation rules, then uses this function to determine which property/variable
         triggered a particular rule.
*/
export function hasClassValidationError(errors: ValidationError[], propertyName: string): boolean {
  return errors.some((e) => e.property === propertyName);
}

// Executes and returns results from async functions, restricting concurrent executions to number 'limit'
// Adapted from: https://gitlab.com/beeper/mx-puppet-monorepo/-/blob/5092c01e48656988af30e6f81596b5303796b813/packages/mx-puppet-slack/src/slack.ts#L39-57
export const parallelLimit = async <T>(
  tasks: Array<() => Promise<T>>,
  limit = 5,
): Promise<Array<T | Error>> => {
  const concurrencyLimit = Math.min(limit, tasks.length);
  const results: Array<T | Error> = [];
  let index = 0;

  const next = async () => {
    const i = index++;
    const task = tasks[i];
    try {
      results[i] = await task();
    } catch (e) {
      results[i] = e as Error;
    }
    if (index < tasks.length) {
      await next();
    }
  };

  const nextArr = Array(concurrencyLimit).fill(next);
  await Promise.all(nextArr.map((fn) => fn()));
  return results;
};

export const ucwords = (str: string): string => {
  str = str.toLowerCase();
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

export const capitalize = ([first, ...rest]: string): string => {
  return first.toUpperCase() + rest.join('').toLowerCase();
};

export const incrementFileName = (fileName: string, incrementValue: number): string => {
  const index = fileName.lastIndexOf('.');

  let before = '';
  let after = '';

  if (index !== -1) {
    before = fileName.slice(0, index);
    after = fileName.slice(index + 1);
  }

  return before + ' (' + incrementValue + ').' + after;
};

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

export type Writeable<T> = {-readonly [P in keyof T]: T[P]};

export type DeepWriteable<T> = {-readonly [P in keyof T]: DeepWriteable<T[P]>};

// observer utils

// const target = '#timeline-canvas [data-evidence-id]';
// const identifier = '[data-evidence-id]';

// export const reportInView = (target: string, identifier: string): string[] => {
//   const result: string[] = [];

//   const observerOptions = {
//     rootMargin: '0px',
//     threshold: 0,
//   };

//   const target = '#timeline-canvas [data-evidence-id]';
//   const identifier = 'data-evidence-id';

//   const observer = new IntersectionObserver(observerCallback, observerOptions);

//   function observerCallback(entries) {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         console.log(entry.target.getAttribute(identifier));
//         // result.push(entry.target.getAttribute(identifier) as string);
//       }
//     });
//   }

//   document.querySelectorAll(target).forEach((i) => {
//     if (i) {
//       observer.observe(i);
//     }
//   });

//   return result;
// };
