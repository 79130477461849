/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type restoreUserMutationVariables = {};
export type restoreUserMutationResponse = {
    readonly restoreUser: {
        readonly clientMutationId: string | null;
    } | null;
};
export type restoreUserMutation = {
    readonly response: restoreUserMutationResponse;
    readonly variables: restoreUserMutationVariables;
};



/*
mutation restoreUserMutation {
  restoreUser(input: {}) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "RestoreUserPayload",
    "kind": "LinkedField",
    "name": "restoreUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": "restoreUser(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "restoreUserMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "restoreUserMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f0c7845e78657db65859829b96105107",
    "id": null,
    "metadata": {},
    "name": "restoreUserMutation",
    "operationKind": "mutation",
    "text": "mutation restoreUserMutation {\n  restoreUser(input: {}) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fee72369407e92ffd0766481374bf0a2';
export default node;
