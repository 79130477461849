import React, {ReactNode} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

/*
<ContentCard />

Displays a component card, essentially an outlined mui-Paper with no elevation. Good for containing inputs and main page content.

> Component takes:
>> (optional) children: a ReactNode child to display in the content section of this card
>> (optional) className: string, a class applied to the card
>> (optional) elevate: boolean, whether to apply elevation (similar to mui Paper) to the card. Defaultt behavior is "outlined".
>> (optional) header: string, a primary heading
>> (optional) subheader: string, a secondary heading, displayed underneath the primary in smaller font

> Example usage:

    <ContentCard title={"My Title"} subheader={"My subheader"}> </ContentCard>
*/

type Props = {
  children?: ReactNode;
  header?: string;
  subheader?: string;
  className?: string;
  elevate?: boolean;
};

const ContentCard: React.FC<Props> = ({children, className, elevate, subheader, header}) => {
  const hasHeader: boolean = header !== undefined || subheader !== undefined;
  const hasElevation: boolean = elevate !== undefined && elevate;

  return (
    <Card className={className} variant={hasElevation ? 'elevation' : 'outlined'}>
      {hasHeader && <CardHeader subheader={subheader} title={header} />}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default ContentCard;
