import React, {Fragment, forwardRef, useImperativeHandle} from 'react';
import type {ReactElement, Ref} from 'react';

import {graphql, usePaginationFragment} from 'react-relay';

import type {UserListRow_user$key} from '../../../graphql/__generated__/UserListRow_user.graphql';
import type {UserListTableRowsPaginationQuery} from '../../../graphql/__generated__/UserListTableRowsPaginationQuery.graphql';
import type {UserListTableRows_admin$key} from '../../../graphql/__generated__/UserListTableRows_admin.graphql';

import UserListRow from './UserListRow';
import type {Filters} from './user-filter';

export interface RefetchRef {
  refetchData: (type?: Filters, value?: string) => void;
}

interface IRow {
  id: string;
}

type Props = {
  admin: UserListTableRows_admin$key;
};

const Rows = (props: Props, ref: Ref<RefetchRef>): ReactElement => {
  const {admin} = {...props};

  const {data, refetch} = usePaginationFragment<
    UserListTableRowsPaginationQuery,
    UserListTableRows_admin$key
  >(
    graphql`
      fragment UserListTableRows_admin on Query
      @argumentDefinitions(
        count: {type: "Int", defaultValue: null}
        cursor: {type: "String", defaultValue: null}
        email: {type: "String", defaultValue: null}
        fName: {type: "String", defaultValue: null}
        lName: {type: "String", defaultValue: null}
      )
      @refetchable(queryName: "UserListTableRowsPaginationQuery") {
        admin {
          users(
            query: {
              filter: {
                email: {includes: $email}
                firstName: {includes: $fName}
                lastName: {includes: $lName}
              }
              sort: CREATED
              sortDir: DESC
            }
            first: $count
            after: $cursor
          ) @connection(key: "UserListTableRows_admin_users") {
            edges {
              node {
                id
                ...UserListRow_user
              }
            }
          }
        }
      }
    `,
    admin,
  );

  const rows = data.admin?.users?.edges?.flatMap((e) => (e?.node ? [e.node] : [])) ?? [];

  useImperativeHandle(ref, () => ({
    refetchData: (type?: Filters, value?: string) => {
      const policy = 'network-only';

      switch (type ?? '') {
        case 'email':
          refetch({email: value}, {fetchPolicy: policy});
          break;
        case 'fName':
          refetch({fName: value}, {fetchPolicy: policy});
          break;
        case 'lName':
          refetch({lName: value}, {fetchPolicy: policy});
          break;
        default:
          refetch({}, {fetchPolicy: 'network-only'});
      }
    },
  }));

  return (
    <Fragment>
      {rows.map((user: IRow & UserListRow_user$key) => (
        <UserListRow key={user.id} user={user} />
      ))}
    </Fragment>
  );
};

const UserListTableRows = forwardRef(Rows);

export default UserListTableRows;
