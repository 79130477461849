import React, {Fragment, ReactElement} from 'react';

import {Chip, Typography, makeStyles} from '@material-ui/core';
import {graphql, useFragment} from 'react-relay';

import {evidenceDetail_folderGroup$key} from '../../graphql/__generated__/evidenceDetail_folderGroup.graphql';

import {arraySeveralIntersection} from '../../utils/utils';

import ChipGroup from '../layout/chip-group';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: '100%',
  },
  chipLabel: {
    whiteSpace: 'normal',
    fontSize: 'inherit',
    paddingBlock: '0.390625rem',
    wordBreak: 'break-all',
    color: theme.palette.secondary.contrastText,
  },
}));

type FolderChipGroupProps = {
  evidence: evidenceDetail_folderGroup$key;
};

const FolderChipGroup = ({evidence}: FolderChipGroupProps): ReactElement => {
  const classes = useStyles();

  const data = useFragment(
    graphql`
      fragment evidenceDetail_folderGroup on Evidence @relay(plural: true) {
        id
        folders {
          id
          name
        }
      }
    `,
    evidence,
  );

  // todo: could be made a util function and shared w tags
  const folders = data.flatMap((d) => d?.folders);
  const uniqueFolders = [...new Map(folders.map((item) => [item?.id, item])).values()];
  const evidenceFolderArrays = data.map((d) => d?.folders.slice().map((f) => f.id));
  const sharedFolderObjs = arraySeveralIntersection(evidenceFolderArrays);

  // still need to resort after finding intersection
  // todo: get shared tags via db query?
  const sharedFolders = uniqueFolders?.filter((f) => sharedFolderObjs?.includes(f.id));
  const sortedFolders = sharedFolders.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  if (sortedFolders.length > 0) {
    return (
      <ChipGroup>
        <Fragment>
          {sortedFolders.map((f) => (
            <Chip
              className={classes.chip}
              color={'secondary'}
              key={f?.id}
              label={<Typography className={classes.chipLabel}>{f?.name}</Typography>}
              style={{height: '100%'}}
            />
          ))}
        </Fragment>
      </ChipGroup>
    );
  } else {
    return <b> N/A</b>;
  }
};

export default FolderChipGroup;
