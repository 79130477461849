import React, {ReactElement, useEffect, useState} from 'react';

import {plainToClass} from 'class-transformer';
import {ValidationError, validateSync} from 'class-validator';
import {Base64} from 'js-base64';
import {useDispatch} from 'react-redux';
import {graphql, useMutation} from 'react-relay';
import {useHistory, useParams} from 'react-router-dom';

import {LOGIN} from '../../../config/routes';

import type {ResetPasswordController2Mutation} from '../../../graphql/__generated__/ResetPasswordController2Mutation.graphql';
import type {ResetPasswordControllerMutation} from '../../../graphql/__generated__/ResetPasswordControllerMutation.graphql';

import {clientAuth} from '../../../utils/auth';
import PasswordResetData from '../../../utils/validator-classes/password-reset.data';

import {setApiFeedback} from '../../../state/common/actions';

import PasswordResetView from './reset-password-view';

export type ChangeData =
  | {
      property: 'password';
      password: string;
    }
  | {
      property: 'passwordConfirm';
      passwordConfirm: string;
    };

const ResetPasswordController = (): ReactElement => {
  const [commit] = useMutation<ResetPasswordControllerMutation>(graphql`
    mutation ResetPasswordControllerMutation($input: SubmitPasswordResetInput!) {
      submitPasswordReset(input: $input) {
        passwordSubmissionData {
          token
          publicKey
        }
        clientMutationId
      }
    }
  `);
  const [commit2] = useMutation<ResetPasswordController2Mutation>(graphql`
    mutation ResetPasswordController2Mutation($input: UpdateUserPasswordInput!) {
      updateUserPassword(input: $input) {
        clientMutationId
      }
    }
  `);
  const {token} = useParams<{token: string}>();
  const history = useHistory();
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isValidated, setIsValidated] = useState(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [tokenVal, setTokenVal] = useState(token);
  const [publicKey, setPublicKey] = useState('');

  useEffect(() => {
    // TODO - Initial SubmitPasswordResetInput graphQL request using the URL token
    // Will receive new token, this token is then set to variable for use in PW reset request
    // Will receive public key, this is used to hash password for PW reset request
    commit({
      variables: {
        input: {
          token: token,
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onCompleted(data: any) {
        setTokenVal(data.submitPasswordReset.passwordSubmissionData.token);
        setPublicKey(data.submitPasswordReset.passwordSubmissionData.publicKey);
      },
      onError(e) {
        console.error(e);
      },
    });
  }, [token]);

  function onChange(data: ChangeData) {
    const {property, ...changeData} = data;

    const formData = plainToClass(
      PasswordResetData,
      Object.assign(
        {},
        {
          password,
          passwordConfirm,
        },
        changeData,
      ),
    );

    if (errors.length !== 0) {
      const formErrors = validateSync(formData);
      setErrors(formErrors);
    }

    if (property === 'password') {
      setPassword(formData.password);
    } else {
      setPasswordConfirm(formData.passwordConfirm);
    }
  }

  async function onSend() {
    const formData = plainToClass(PasswordResetData, {
      password,
      passwordConfirm,
    });

    const formErrors = validateSync(formData);
    if (formErrors.length !== 0) {
      setErrors(formErrors);
      setIsValidated(true);
      return;
    }

    // TODO - graphQL mutation + error handling on bad request
    const getPasswordEncrypted = async () => {
      const hashedPW = await clientAuth.encryptPassword({password, publicKey: publicKey});
      return Base64.fromUint8Array(hashedPW);
    };
    const passwordEncrypted = await getPasswordEncrypted();
    commit2({
      variables: {
        input: {
          token: tokenVal,
          passwordCiphertext: passwordEncrypted,
        },
      },
      onCompleted() {
        history.replace({
          pathname: LOGIN,
        });
        dispatch(setApiFeedback({message: 'password reset successful', severity: 'success'}));
      },
      onError(e) {
        dispatch(setApiFeedback({message: 'password reset failed'}));
        console.error(e);
      },
    });
  }

  return (
    <PasswordResetView
      errors={!isValidated ? [] : errors}
      onChange={onChange}
      onSend={onSend}
      password={password}
      passwordConfirm={passwordConfirm}
    />
  );
};

export default ResetPasswordController;
