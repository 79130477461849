import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import {useDroppable} from '@dnd-kit/core';
import {
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {deepOrange} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/core/styles';
import {CreateNewFolder} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';
import {graphql, useFragment} from 'react-relay';

import LoadingScreen from '../../../components/feedback/loading-screen';
import {ConditionalWrapper} from '../../../components/layout/conditional-wrapper';

import {evidenceNav_foldersNav$key} from '../../../graphql/__generated__/evidenceNav_foldersNav.graphql';

import useCreateFolder from '../../../hooks/mutations/create-folder.hook';
import useDeleteFolder from '../../../hooks/mutations/delete-folder.hook';

import {setApiFeedback} from '../../../state/common/actions';

import {FolderTypeEnum} from '../../../services/evidences/data/types';

type DroppableProps = {
  id: string;
  type: string;
  children: ReactNode;
};

export function Droppable(props: DroppableProps): ReactElement {
  const {id, type, children} = props;
  const {isOver, setNodeRef} = useDroppable({
    id: `droppable-${id}`,
    data: {
      accepts: [type],
    },
  });
  const style = {
    background: isOver ? deepOrange[100] : undefined,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
}

const useStyles = makeStyles({
  navFoldersWrapper: {
    position: 'relative',
  },
  listItemIconRoot: {minWidth: 32},
  listItemText: {wordBreak: 'break-all'},
  currentFolderIcon: {
    color: deepOrange[500],
  },
  currentFolderText: {
    fontWeight: 700,
  },
  deleteHoverFocus: {
    '&:hover, &.Mui-focusVisible': {color: 'black'},
  },
});

type Props = {
  currentFolderId: string;
  account: evidenceNav_foldersNav$key;
  setCurrentFolderId(id: string): void;
};

const EvidenceNavFolders = ({
  currentFolderId,
  account,
  setCurrentFolderId,
}: Props): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [createFolder, loadingCreate] = useCreateFolder();
  const [deleteFolder, loadingDelete] = useDeleteFolder();
  const [formError, setFormError] = useState<boolean>(false);
  const [formHelperText, setFormHelperText] = useState<string | null>();
  const folderNameInputRef = useRef<HTMLInputElement>();
  const [unsortedFolderId, setUnsortedFolderId] = useState<string>('');

  const data = useFragment(
    graphql`
      fragment evidenceNav_foldersNav on Account {
        unsortedFolder {
          type: __typename
          id
          name
          evidenceCount
        }
        allFilesFolder {
          type: __typename
          id
          name
          evidenceCount
        }
        userFolders {
          type: __typename
          id
          name
          evidenceCount
        }
      }
    `,
    account,
  );

  const navFolders = useMemo(() => {
    const userFolders = data.userFolders ?? undefined;
    const folders = userFolders?.map((f) => f).filter((f) => !!f) ?? [];
    const unsortedFolder = data.unsortedFolder ?? undefined;
    const allFilesFolder = data.allFilesFolder ?? undefined;
    folders?.unshift(unsortedFolder, allFilesFolder);

    setUnsortedFolderId(unsortedFolder.id);
    return folders.map((f) => ({...f, type: f?.type as FolderTypeEnum}));
  }, [data]);

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleFolderClick = (id: string) => {
    setCurrentFolderId(id);
  };

  // todo: replace w react hook form and zod/yup for validation
  const handleSubmit = () => {
    if (folderNameInputRef.current?.value !== undefined) {
      const folder = folderNameInputRef.current.value;
      const MIN_FOLDER_NAME_LENGTH = 3;

      if (folder.length >= MIN_FOLDER_NAME_LENGTH) {
        handleCreateFolder(folder);
        setFormError(false);
        setFormHelperText(null);
        folderNameInputRef.current.value = '';
      } else {
        setFormError(true);
        setFormHelperText(`Folder name must be at least ${MIN_FOLDER_NAME_LENGTH} characters`);
      }
    }
  };

  const handleCreateFolder = useCallback(
    (folderName: string) => {
      const callback = () => {
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Folder: ${folderName} was created`,
          }),
        );
      };
      createFolder(folderName, callback);
    },
    [createFolder],
  );

  const handleDeleteFolder = useCallback(
    (
      folderId: string,
      folderName: string,
      unsortedFolderId: string,
      localCurrentFolderId: string,
    ) => {
      const callback = () => {
        // if current folder id is being deleted, move to unsorted
        if (localCurrentFolderId === folderId) {
          setCurrentFolderId(unsortedFolderId);
        }

        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Folder: ${folderName} was deleted`,
          }),
        );
      };
      deleteFolder(folderId, callback);
    },
    [deleteFolder],
  );

  const firstUserFolderId = navFolders?.find((f) => f?.type === FolderTypeEnum['USERFOLDER'])?.id;

  return (
    <div className={classes.navFoldersWrapper}>
      {(loadingCreate || loadingDelete) && <LoadingScreen local />}
      <Typography variant={'h5'}>Folders</Typography>
      <List dense>
        {navFolders?.map(({id, name, evidenceCount, type}) => (
          <ConditionalWrapper
            condition={type === FolderTypeEnum['USERFOLDER']}
            key={`wrapper-${id}`}
            wrapper={(children) => (
              <Droppable id={id} type={'folder'}>
                {children}
              </Droppable>
            )}
          >
            <Fragment>
              {firstUserFolderId === id && <Divider />}
              <ListItem
                button
                key={`folder-list-item-${id}`}
                onClick={() => handleFolderClick(id)}
                selected={id === currentFolderId}
              >
                <ListItemIcon
                  className={clsx(id === currentFolderId && classes.currentFolderIcon)}
                  classes={{root: classes.listItemIconRoot}}
                >
                  <FolderIcon fontSize={'small'} />
                </ListItemIcon>
                <ListItemText
                  classes={
                    id === currentFolderId ? {primary: classes.currentFolderText} : undefined
                  }
                  primary={`${name} [${evidenceCount}]`}
                />
                {evidenceCount === 0 && (
                  <ListItemSecondaryAction>
                    <Tooltip placement={'top-end'} title={`Delete folder: ${name}`}>
                      <IconButton
                        aria-label={'delete'}
                        className={classes.deleteHoverFocus}
                        edge={'end'}
                        onClick={() =>
                          handleDeleteFolder(id, name, unsortedFolderId, currentFolderId)
                        }
                      >
                        <DeleteIcon fontSize={'small'} />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </Fragment>
          </ConditionalWrapper>
        ))}
      </List>
      <form onSubmit={handleSubmit}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <IconButton color={'primary'} edge={'end'} onClick={handleSubmit}>
                  <CreateNewFolder />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={formError}
          fullWidth
          helperText={formHelperText}
          inputRef={folderNameInputRef}
          label={'Create new folder'}
          name={'folder'}
          onKeyPress={onKeyPress}
          size={'small'}
          variant={'outlined'}
        />
      </form>
    </div>
  );
};

export default EvidenceNavFolders;
