import React, {ChangeEvent, Fragment} from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  AddAPhoto,
  Exposure,
  LooksOne,
  LooksOneOutlined,
  Save,
  ViewList,
  Visibility,
  VisibilityOutlined,
} from '@material-ui/icons';

import DownloadEvidence from '../../../components/download-evidence';

import {TWorkspace, TWorkspaceSortKey} from '../../../state/timeline/state';

import ViewSortButtonGroup from './view-sort-button-group';

export const TIMELINE_ACTION_BAR_HEIGHT_SPACING = 8;

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(TIMELINE_ACTION_BAR_HEIGHT_SPACING),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionBarLeft: {
    alignItems: 'center',
    display: 'flex',
  },
  actionBarRight: {
    alignItems: 'center',
    display: 'flex',
  },
  current: {
    'background': theme.palette.primary.main,
    'borderRadius': theme.spacing(0.5),
    'display': 'inline-flex',
    'paddingTop': theme.spacing(0.875),
    'paddingRight': theme.spacing(1.5),
    'paddingBottom': theme.spacing(0.875),
    'paddingLeft': theme.spacing(1.5),
    'whiteSpace': 'nowrap',
    '& span': {
      fontWeight: 'bold',
      marginLeft: theme.spacing(0.5),
    },
  },
  filters: {marginLeft: theme.spacing(2)},
  toggleableCheckbox: {
    color: `${theme.palette.common.white} !important`,
  },
}));

const actionButtonStyles = makeStyles({
  actionButtonProgress: {
    color: 'inherit',
    position: 'relative',
    zIndex: 1,
    marginLeft: '-40px',
  },
});

type Props = {
  selectedWorkspace: TWorkspace | undefined;
  inProgress: 'save' | undefined;
  viewSort: TWorkspaceSortKey;
  onViewSortChange(viewSort: TWorkspaceSortKey): void;
  onSaveWorkspace(): Promise<void>;
  onSelectFilters(): void;
  onSelectOptions(): void;
  onSelectWorkspace(): void;
  handleShowLines(event: ChangeEvent<HTMLInputElement>): void;
  handleShowEvidenceCounts(event: ChangeEvent<HTMLInputElement>): void;
  handleScreenshot(): void;
  activeFiltersCount: number;
  offlineMode: boolean;
};

type ActionButtonProps = {
  title: string;
  disabled?: boolean;
  offlineMode?: boolean;
  progress?: boolean;
  iconOnly?: boolean;
  onClick?: () => void | Promise<void>;
};

/**
 * Wraps an Icon in a tooltip and IconButton
 *
 * @param title - The tooltip title
 * @param disabled - Whether the button should be disabled
 * @param offlineMode - Set if the button is disabled due to being in offline mode
 * @param onClick - Click handler for the button
 * @param children - The icon to be wrapped
 * @param iconOnly - Hide title
 */
const ActionButton: React.FC<ActionButtonProps> = ({
  title,
  disabled,
  offlineMode,
  progress,
  iconOnly,
  onClick,
  children,
}) => {
  const classes = actionButtonStyles();
  const tooltipTitle = offlineMode ? `${title} is not available` : title;

  return (
    <Tooltip title={tooltipTitle}>
      <Fragment>
        {iconOnly && (
          <IconButton
            color={'inherit'}
            disabled={disabled || !!offlineMode || progress}
            onClick={onClick}
          >
            {children}
          </IconButton>
        )}
        {!iconOnly && (
          <Button
            disabled={disabled || !!offlineMode || progress}
            onClick={onClick}
            startIcon={children}
            variant={'contained'}
          >
            {title}
          </Button>
        )}
        {progress && <CircularProgress className={classes.actionButtonProgress} size={32} />}
      </Fragment>
    </Tooltip>
  );
};

const TimelineActionBarView: React.FC<Props> = ({
  selectedWorkspace,
  inProgress,
  viewSort,
  onViewSortChange,
  onSaveWorkspace,
  onSelectFilters,
  onSelectOptions,
  onSelectWorkspace,
  handleShowLines,
  handleShowEvidenceCounts,
  handleScreenshot,
  activeFiltersCount,
  offlineMode,
}) => {
  const classes = useStyles();

  const showLinesActionWord = selectedWorkspace?.showLines ? 'hide' : 'show';
  const showEvidenceCountsActionWord = selectedWorkspace?.showEvidenceCounts ? 'hide' : 'show';

  return (
    <Toolbar className={classes.root}>
      <Grid alignItems={'center'} container justifyContent={'space-between'}>
        <Grid className={classes.actionBarLeft} item xs>
          <Grid
            alignItems={'center'}
            container
            justifyContent={'flex-start'}
            spacing={1}
            wrap={'nowrap'}
          >
            <Grid item>
              <Typography className={classes.current} color={'inherit'}>
                Current:<span>{selectedWorkspace?.name}</span>
              </Typography>
            </Grid>
            <Grid item>
              <ActionButton
                disabled={typeof selectedWorkspace === 'undefined'}
                offlineMode={offlineMode}
                onClick={onSaveWorkspace}
                progress={inProgress === 'save'}
                title={'Save'}
              >
                <Save />
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton onClick={onSelectWorkspace} title={'List'}>
                <ViewList />
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.actionBarRight}>
          <ViewSortButtonGroup setViewSort={onViewSortChange} viewSort={viewSort} />
          <Tooltip className={classes.filters} title={'Click to select filters for this workspace'}>
            <Button color={'inherit'} onClick={onSelectFilters}>
              {activeFiltersCount} Active Filter
              {activeFiltersCount !== 1 && 's'}
            </Button>
          </Tooltip>
          <Tooltip title={`Click to ${showLinesActionWord} timeline connection lines`}>
            <Checkbox
              checked={selectedWorkspace?.showLines ?? false}
              checkedIcon={<Visibility />}
              className={classes.toggleableCheckbox}
              color={'default'}
              icon={<VisibilityOutlined />}
              onChange={handleShowLines}
            />
          </Tooltip>
          <Tooltip title={`Click to ${showEvidenceCountsActionWord} tag evidence counts`}>
            <Checkbox
              checked={selectedWorkspace?.showEvidenceCounts ?? false}
              checkedIcon={<LooksOne />}
              className={classes.toggleableCheckbox}
              color={'default'}
              icon={<LooksOneOutlined />}
              onChange={handleShowEvidenceCounts}
            />
          </Tooltip>
          <ActionButton iconOnly onClick={onSelectOptions} title={'Workspace options'}>
            <Exposure />
          </ActionButton>
          <ActionButton
            iconOnly
            onClick={handleScreenshot}
            title={'Take screenshot and copy to clipboard'}
          >
            <AddAPhoto />
          </ActionButton>
          <DownloadEvidence origin={'timeline'} />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default TimelineActionBarView;
