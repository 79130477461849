/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetail_addTag = ReadonlyArray<{
    readonly id: string;
    readonly fileName: string;
    readonly " $refType": "evidenceDetail_addTag";
}>;
export type evidenceDetail_addTag$data = evidenceDetail_addTag;
export type evidenceDetail_addTag$key = ReadonlyArray<{
    readonly " $data"?: evidenceDetail_addTag$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_addTag">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "evidenceDetail_addTag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "fileName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Evidence",
  "abstractKey": "__isEvidence"
};
(node as any).hash = 'eac07d02a19435c909ec68e39e392c77';
export default node;
