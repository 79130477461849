import {inject, injectable} from 'inversify';
import {OutputOf} from 'io-ts';

import {RootStore} from '../../state/store';
import {getAllEvidences} from '../../state/timeline/selectors';

import keys from '../common/container/keys';
import {EvidenceT} from './data/types';
import {IEvidenceRepository} from './repositories/evidence-repository';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEvidenceService {
  cacheFileBlobs(): Promise<void>;

  cacheThumbnailBlobs(): Promise<void>;

  downloadEvidenceFile(
    evidence: Pick<OutputOf<typeof EvidenceT>, 'id' | 'fileName'>,
  ): Promise<void>;
}

@injectable()
export default class EvidenceService implements IEvidenceService {
  @inject(keys.store)
  private store!: RootStore;

  @inject(keys.evidences.IEvidenceRepository)
  private evidenceRepository!: IEvidenceRepository;

  public async cacheFileBlobs(): Promise<void> {
    const entities = getAllEvidences(this.store.getState());

    const promises = entities.map((evidence) => this.evidenceRepository.getEvidenceFile(evidence));

    const results = await Promise.allSettled(promises);

    const reasons = results
      .filter((result) => result.status === 'rejected')
      .map((result) => (result as PromiseRejectedResult).reason);

    if (reasons.length !== 0) {
      console.error('The following file blob fetches failed', reasons);
    }
  }

  public async cacheThumbnailBlobs(): Promise<void> {
    const entities = getAllEvidences(this.store.getState());

    const promises = entities.map((evidence) =>
      this.evidenceRepository.getEvidenceThumbnail(evidence),
    );

    const results = await Promise.allSettled(promises);

    const reasons = results
      .filter((result) => result.status === 'rejected')
      .map((result) => (result as PromiseRejectedResult).reason);

    if (reasons.length !== 0) {
      console.error('The following thumbnail blob fetches failed', reasons);
    }
  }

  public async downloadEvidenceFile(
    evidence: Pick<OutputOf<typeof EvidenceT>, 'id' | 'fileName'>,
  ): Promise<void> {
    const evidenceData = await this.evidenceRepository.getEvidenceFile(evidence);

    if (!evidenceData) {
      return;
    }

    const downloadElement = document.createElement('a');
    downloadElement.setAttribute('download', evidence.fileName);
    downloadElement.setAttribute('href', evidenceData.data);
    downloadElement.click();
  }
}
