/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AddEvidenceTagInput = {
    folderId: string;
    evidenceIds: Array<string>;
    tag: string;
    clientMutationId?: string | null | undefined;
};
export type addTagToEvidenceMutationVariables = {
    input: AddEvidenceTagInput;
};
export type addTagToEvidenceMutationResponse = {
    readonly addEvidenceTag: {
        readonly folder: {
            readonly " $fragmentRefs": FragmentRefs<"evidenceNav_tagsNav" | "evidenceMain_tagFilter">;
        };
        readonly evidenceNodes: ReadonlyArray<{
            readonly node: {
                readonly tagCount: number;
                readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_tagGroup" | "evidenceDetail_addTag">;
            };
        }>;
    } | null;
};
export type addTagToEvidenceMutation = {
    readonly response: addTagToEvidenceMutationResponse;
    readonly variables: addTagToEvidenceMutationVariables;
};



/*
mutation addTagToEvidenceMutation(
  $input: AddEvidenceTagInput!
) {
  addEvidenceTag(input: $input) {
    folder {
      __typename
      ...evidenceNav_tagsNav
      ...evidenceMain_tagFilter
      id
    }
    evidenceNodes {
      node {
        __typename
        ...evidenceDetail_tagGroup
        ...evidenceDetail_addTag
        tagCount
        id
      }
    }
  }
}

fragment evidenceDetail_addTag on Evidence {
  __isEvidence: __typename
  id
  fileName: name
}

fragment evidenceDetail_tagGroup on Evidence {
  __isEvidence: __typename
  id
  fileName: name
  tags: tagsWithIds {
    id
    text
  }
}

fragment evidenceMain_tagFilter on Folder {
  __isFolder: __typename
  tags {
    id
    tagId
    text
  }
}

fragment evidenceNav_tagsNav on Folder {
  __isFolder: __typename
  id
  tags {
    id
    tagId
    text
    evidenceCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tagCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addTagToEvidenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddEvidenceTagPayload",
        "kind": "LinkedField",
        "name": "addEvidenceTag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "folder",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceNav_tagsNav"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceMain_tagFilter"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceDetail_tagGroup"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceDetail_addTag"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addTagToEvidenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddEvidenceTagPayload",
        "kind": "LinkedField",
        "name": "addEvidenceTag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "folder",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isFolder"
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FolderTag",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tagId",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "evidenceCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEvidence"
                  },
                  (v4/*: any*/),
                  {
                    "alias": "fileName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": "tags",
                    "args": null,
                    "concreteType": "TagWithId",
                    "kind": "LinkedField",
                    "name": "tagsWithIds",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58133a95031540d34f239d3788562a90",
    "id": null,
    "metadata": {},
    "name": "addTagToEvidenceMutation",
    "operationKind": "mutation",
    "text": "mutation addTagToEvidenceMutation(\n  $input: AddEvidenceTagInput!\n) {\n  addEvidenceTag(input: $input) {\n    folder {\n      __typename\n      ...evidenceNav_tagsNav\n      ...evidenceMain_tagFilter\n      id\n    }\n    evidenceNodes {\n      node {\n        __typename\n        ...evidenceDetail_tagGroup\n        ...evidenceDetail_addTag\n        tagCount\n        id\n      }\n    }\n  }\n}\n\nfragment evidenceDetail_addTag on Evidence {\n  __isEvidence: __typename\n  id\n  fileName: name\n}\n\nfragment evidenceDetail_tagGroup on Evidence {\n  __isEvidence: __typename\n  id\n  fileName: name\n  tags: tagsWithIds {\n    id\n    text\n  }\n}\n\nfragment evidenceMain_tagFilter on Folder {\n  __isFolder: __typename\n  tags {\n    id\n    tagId\n    text\n  }\n}\n\nfragment evidenceNav_tagsNav on Folder {\n  __isFolder: __typename\n  id\n  tags {\n    id\n    tagId\n    text\n    evidenceCount\n  }\n}\n"
  }
};
})();
(node as any).hash = '2a7808f1acd6278994f4781316580477';
export default node;
