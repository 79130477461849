import produce from 'immer';
import * as t from 'io-ts';
import {ActionType, createReducer} from 'typesafe-actions';

import {
  setAccessedAccount,
  setApiFeedback,
  setAuthenticatedUser,
  setImpersonatedUser,
  setIsAuthenticated,
} from './actions';
import * as actions from './actions';
import {CommonState, DEFAULT_COMMON_STATE} from './state';

export type CommonAction = ActionType<typeof actions>;

const commonReducer = createReducer<t.OutputOf<typeof CommonState>, CommonAction>(
  DEFAULT_COMMON_STATE,
)
  .handleAction(
    setIsAuthenticated,
    produce((state, action) => {
      state.isAuthenticated = action.payload;
    }),
  )
  .handleAction(
    setApiFeedback,
    produce((state, action) => {
      if (action.payload !== false) {
        let message = 'Something went wrong with your request...';
        let severity = 'error';

        if (action.payload.severity !== undefined) {
          severity = action.payload.severity;
        }

        if (action.payload.error !== null && action.payload.error !== undefined) {
          message = action.payload.error.toString();
        }

        if (action.payload.message !== null && action.payload.message !== undefined) {
          message = action.payload.message;
        }

        state.apiFeedback = {message: message, severity: severity};
      } else {
        state.apiFeedback = action.payload;
      }
    }),
  )
  .handleAction(
    setAccessedAccount,
    produce((state, action) => {
      state.accessedAccount = action.payload;
    }),
  )
  .handleAction(
    setAuthenticatedUser,
    produce((state, action) => {
      state.authenticatedUser = action.payload;
    }),
  )
  .handleAction(
    setImpersonatedUser,
    produce((state, action) => {
      state.impersonatedUser = action.payload;
    }),
  );

export default commonReducer;
