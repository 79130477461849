/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateUserPasswordInput = {
    token: string;
    passwordCiphertext: unknown;
    clientMutationId?: string | null | undefined;
};
export type ResetPasswordController2MutationVariables = {
    input: UpdateUserPasswordInput;
};
export type ResetPasswordController2MutationResponse = {
    readonly updateUserPassword: {
        readonly clientMutationId: string | null;
    } | null;
};
export type ResetPasswordController2Mutation = {
    readonly response: ResetPasswordController2MutationResponse;
    readonly variables: ResetPasswordController2MutationVariables;
};



/*
mutation ResetPasswordController2Mutation(
  $input: UpdateUserPasswordInput!
) {
  updateUserPassword(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPasswordPayload",
    "kind": "LinkedField",
    "name": "updateUserPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordController2Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordController2Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f36dce1ce750f747101b2edebb9c6747",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordController2Mutation",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordController2Mutation(\n  $input: UpdateUserPasswordInput!\n) {\n  updateUserPassword(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '2958fa03290c69ccdbe9f6ba4b177380';
export default node;
