import React, {ReactElement, useCallback, useState} from 'react';

import {makeStyles} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from '@material-ui/lab';
import {Link} from 'react-router-dom';

import {SETTINGS} from '../../../config/routes';

import useLogout from '../../../hooks/mutations/logout.hook';

import {destroyTokens} from '../../../utils/tokens';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    'position': 'absolute',
    'top': theme.spacing(2.25), // 50% of height of header now
    'right': theme.spacing(2),
    '& > .MuiSpeedDial-fab': {backgroundColor: theme.palette.text.primary},
  },
}));

const UtilityNav = (): ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [logout] = useLogout();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = useCallback(() => {
    const callback = () => {
      destroyTokens();
      window.location.replace('/login');
    };
    logout(callback);
  }, [logout]);

  const withLink = (to: string, children: ReactElement) => <Link to={to}>{children}</Link>;

  return (
    <SpeedDial
      ariaLabel={'Quick Link Options'}
      className={classes.speedDial}
      direction={'down'}
      icon={<SpeedDialIcon icon={<MoreVertIcon />} openIcon={<MoreHorizIcon />} />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
    >
      <SpeedDialAction
        icon={withLink(SETTINGS, <PersonOutlineIcon />)}
        tooltipTitle={'my settings'}
      />
      <SpeedDialAction icon={<HelpOutlineIcon />} onClick={handleClose} tooltipTitle={'help'} />
      <SpeedDialAction icon={<ExitToAppIcon />} onClick={handleLogout} tooltipTitle={'logout'} />
    </SpeedDial>
  );
};

export default UtilityNav;
