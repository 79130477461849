/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import UserListTableRowsPaginationQuery from "./UserListTableRowsPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type UserListTableRows_admin = {
    readonly admin: {
        readonly users: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly " $fragmentRefs": FragmentRefs<"UserListRow_user">;
                };
            } | null> | null;
        };
    } | null;
    readonly " $refType": "UserListTableRows_admin";
};
export type UserListTableRows_admin$data = UserListTableRows_admin;
export type UserListTableRows_admin$key = {
    readonly " $data"?: UserListTableRows_admin$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserListTableRows_admin">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "admin",
  "users"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "email"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lName"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": UserListTableRowsPaginationQuery
    }
  },
  "name": "UserListTableRows_admin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Admin",
      "kind": "LinkedField",
      "name": "admin",
      "plural": false,
      "selections": [
        {
          "alias": "users",
          "args": [
            {
              "fields": [
                {
                  "fields": [
                    {
                      "fields": [
                        {
                          "kind": "Variable",
                          "name": "includes",
                          "variableName": "email"
                        }
                      ],
                      "kind": "ObjectValue",
                      "name": "email"
                    },
                    {
                      "fields": [
                        {
                          "kind": "Variable",
                          "name": "includes",
                          "variableName": "fName"
                        }
                      ],
                      "kind": "ObjectValue",
                      "name": "firstName"
                    },
                    {
                      "fields": [
                        {
                          "kind": "Variable",
                          "name": "includes",
                          "variableName": "lName"
                        }
                      ],
                      "kind": "ObjectValue",
                      "name": "lastName"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "filter"
                },
                {
                  "kind": "Literal",
                  "name": "sort",
                  "value": "CREATED"
                },
                {
                  "kind": "Literal",
                  "name": "sortDir",
                  "value": "DESC"
                }
              ],
              "kind": "ObjectValue",
              "name": "query"
            }
          ],
          "concreteType": "UserConnection",
          "kind": "LinkedField",
          "name": "__UserListTableRows_admin_users_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserListRow_user"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = 'b6f36963e4ed6c087123e0060e814210';
export default node;
