import React, {ReactElement} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

import {getLastLogin} from '../../state/common/selectors';
import {TRootState} from '../../state/store';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  content: {
    marginTop: 130,
  },
});

type Props = {
  lastLogin: string;
};

const HomeComponent = ({lastLogin}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h1>Welcome to EVDense</h1>
        <p>Logged in successfully</p>
        <p>{lastLogin}</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: TRootState) => ({
  lastLogin: getLastLogin(state),
});

const Home = connect(mapStateToProps)(HomeComponent);

export default Home;
