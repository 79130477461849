/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type StartAuthInput = {
    nonce: string;
    username: string;
    clientMutationId?: string | null | undefined;
};
export type loginFormControllerStartAuthMutationVariables = {
    input: StartAuthInput;
};
export type loginFormControllerStartAuthMutationResponse = {
    readonly startAuth: {
        readonly token: string;
        readonly challenge: {
            readonly nonce: string;
            readonly salt: unknown;
            readonly iterations: number;
        };
    } | null;
};
export type loginFormControllerStartAuthMutation = {
    readonly response: loginFormControllerStartAuthMutationResponse;
    readonly variables: loginFormControllerStartAuthMutationVariables;
};



/*
mutation loginFormControllerStartAuthMutation(
  $input: StartAuthInput!
) {
  startAuth(input: $input) {
    token
    challenge {
      nonce
      salt
      iterations
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StartAuthPayload",
    "kind": "LinkedField",
    "name": "startAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PasswordAuthChallenge",
        "kind": "LinkedField",
        "name": "challenge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nonce",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "salt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iterations",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loginFormControllerStartAuthMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginFormControllerStartAuthMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f91284de8895b9ece2160c62db8513f2",
    "id": null,
    "metadata": {},
    "name": "loginFormControllerStartAuthMutation",
    "operationKind": "mutation",
    "text": "mutation loginFormControllerStartAuthMutation(\n  $input: StartAuthInput!\n) {\n  startAuth(input: $input) {\n    token\n    challenge {\n      nonce\n      salt\n      iterations\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd1110cb7fe3a3584e84a4fe18855bda6';
export default node;
