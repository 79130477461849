import * as t from 'io-ts';
import * as tt from 'io-ts-types';

// new types for normalized redux store with one only a single workspace at a time
export const CaseFolder = t.type({
  id: t.string,
  name: t.string,
});

export type TCaseFolder = t.OutputOf<typeof CaseFolder>;

export const CaseTag = t.type({
  id: t.number,
  text: t.string,
});

export const Coordinates = t.type({
  x: t.number,
  y: t.number,
});

export const Filters = t.type({
  folders: t.array(t.string),
  tags: t.array(t.number),
  types: t.array(t.number),
});

const WorkspaceOption = t.type({
  optionId: t.number,
  value: t.union([t.number, t.null]),
  // description: t.string,
});

export const EvidenceMimeType = t.type({
  id: t.number,
  name: t.string,
});

export type TEvidenceMimeType = t.OutputOf<typeof EvidenceMimeType>;

export const WorkspaceTag = t.type({
  tagId: t.number,
  text: t.string,
  coordinates: Coordinates,
  color: t.string,
  lockHighlight: t.boolean,
});

export type TWorkspaceTag = t.OutputOf<typeof WorkspaceTag>;

export const WorkspaceSortKey = t.keyof({
  storedOn: null,
  eventDate: null,
});

export type TWorkspaceSortKey = t.OutputOf<typeof WorkspaceSortKey>;

export const Workspace = t.type({
  id: t.string,
  name: t.string,
  options: t.array(WorkspaceOption),
  tags: t.array(WorkspaceTag),
  filters: Filters,
  coordinates: Coordinates,
  modified: tt.date,
  showLines: t.boolean,
  showEvidenceCounts: t.boolean,
  zoomIndex: t.number,
});

export const TimelineEvidence = t.type({
  id: t.string,
  fileName: t.string,
  storedOn: tt.date,
  eventDate: tt.date,
  mimeType: EvidenceMimeType,
  size: t.number,
  tags: t.array(t.number),
  folders: t.array(t.string),
});

// need to add optional fields like url, body, subject
// maybe i should move the gql query from evidence directly into the download hook

export type TTimelineEvidence = t.OutputOf<typeof TimelineEvidence>;

export type TCaseTag = t.OutputOf<typeof CaseTag>;

const WorkspaceById = t.record(t.string, Workspace);
const AllWorkspaces = t.array(t.string);

export type TWorkspace = t.OutputOf<typeof Workspace>;
export type TWorkspaceById = t.OutputOf<typeof WorkspaceById>;
export type TAllWorkspaces = t.OutputOf<typeof AllWorkspaces>;

const EvidenceById = t.record(t.string, TimelineEvidence);
const AllEvidence = t.array(t.string);

const FoldersById = t.record(t.string, CaseFolder);
const AllFolders = t.array(t.string);

const TagsById = t.record(t.string, CaseTag);
const AllTags = t.array(t.string);

const TypesById = t.record(t.string, EvidenceMimeType);
const AllTypes = t.array(t.string);

const VisibleEvidenceIds = t.record(t.string, t.boolean);
export type TVisibleEvidenceIds = t.OutputOf<typeof VisibleEvidenceIds>;

export const TimelineState = t.type({
  entities: t.type({
    workspaces: t.type({
      byId: WorkspaceById,
      allIds: AllWorkspaces,
    }),
    evidences: t.type({
      byId: EvidenceById,
      allIds: AllEvidence,
    }),
    folders: t.type({
      byId: FoldersById,
      allIds: AllFolders,
    }),
    tags: t.type({
      byId: TagsById,
      allIds: AllTags,
    }),
    types: t.type({
      byId: TypesById,
      allIds: AllTypes,
    }),
  }),
  filters: Filters,
  isLoading: t.boolean,
  offlineMode: t.boolean,
  selectedWorkspaceId: t.union([t.string, t.undefined]),
  workspaceSortKey: WorkspaceSortKey,
  openedWorkspaceTag: t.union([t.number, t.undefined]),
  openedEvidenceNode: t.union([t.string, t.undefined]),
  visibleEvidences: VisibleEvidenceIds,
  visibleEvidenceIds: t.array(t.string),
});

export type TTimelineState = t.OutputOf<typeof TimelineState>;

export const DEFAULT_TIMELINE_STATE: TTimelineState = {
  entities: {
    workspaces: {
      byId: {},
      allIds: [],
    },
    evidences: {
      byId: {},
      allIds: [],
    },
    folders: {
      byId: {},
      allIds: [],
    },
    tags: {
      byId: {},
      allIds: [],
    },
    types: {
      byId: {},
      allIds: [],
    },
  },
  // having separate timeline filters might be redundant, will revisit
  filters: {
    folders: [],
    tags: [],
    types: [],
  },
  isLoading: false,
  offlineMode: false,
  selectedWorkspaceId: undefined,
  workspaceSortKey: 'eventDate',
  openedWorkspaceTag: undefined,
  openedEvidenceNode: undefined,
  visibleEvidences: {},
  visibleEvidenceIds: [],
};

export const DEFAULT_WORKSPACE_STATE: TWorkspace = {
  id: '',
  name: '',
  options: [],
  tags: [],
  filters: {
    folders: [],
    tags: [],
    types: [],
  },
  coordinates: {x: 0, y: 100},
  modified: new Date(),
  showLines: false,
  showEvidenceCounts: false,
  zoomIndex: -1,
};
