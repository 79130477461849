import {createSelector} from 'reselect';

import {TRootState} from '../store';
import {TCommonState} from './state';

export const getCommon = (state: TRootState): TCommonState => state.common;

export const getIsAuthenticated = createSelector([getCommon], (common) => common.isAuthenticated);
export const getLastLogin = createSelector([getCommon], (common) => common.lastLogin);
export const getApiFeedback = createSelector([getCommon], (common) => common.apiFeedback);
export const getAccessedAccount = createSelector([getCommon], (common) => common.accessedAccount);
export const getAuthenticatedUser = createSelector(
  [getCommon],
  (common) => common.authenticatedUser,
);
export const getImpersonatedUser = createSelector([getCommon], (common) => common.impersonatedUser);
