/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetail_folderGroup = ReadonlyArray<{
    readonly id: string;
    readonly folders: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
    }>;
    readonly " $refType": "evidenceDetail_folderGroup";
}>;
export type evidenceDetail_folderGroup$data = evidenceDetail_folderGroup;
export type evidenceDetail_folderGroup$key = ReadonlyArray<{
    readonly " $data"?: evidenceDetail_folderGroup$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_folderGroup">;
}>;



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "evidenceDetail_folderGroup",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFolder",
      "kind": "LinkedField",
      "name": "folders",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Evidence",
  "abstractKey": "__isEvidence"
};
})();
(node as any).hash = '9cd6dc7332c07211c444dc992c925cd0';
export default node;
