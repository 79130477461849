import React, {useState} from 'react';
import type {Dispatch, ReactElement, SetStateAction} from 'react';

import {plainToClass} from 'class-transformer';
import {ValidationError, validateSync} from 'class-validator';
import {useDispatch} from 'react-redux';
import {graphql, useMutation} from 'react-relay';

import type {CreateUserControllerMutation} from '../../../graphql/__generated__/CreateUserControllerMutation.graphql';

import {CreateNewUserData} from '../../../utils/validator-classes/create-new-user.data';

import {setApiFeedback} from '../../../state/common/actions';

import CreateUserView from './CreateUserView';

export type ChangeData =
  | {
      property: 'email';
      email: string;
    }
  | {
      property: 'fName';
      fName: string | undefined;
    }
  | {
      property: 'lName';
      lName: string | undefined;
    };

export type FileData = {
  id: string;
  name: string;
  size: number;
  time: string;
};

type Props = {
  open: boolean;
  handleClose: Dispatch<SetStateAction<boolean>> | (() => void);
  refetchData: () => void;
};

const CreateUserController = ({open, handleClose, refetchData}: Props): ReactElement => {
  const dispatch = useDispatch();

  const [isValidated, setIsValidated] = useState(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const [email, setEmail] = useState('');
  const [fName, setFName] = useState<string | undefined>(undefined);
  const [lName, setLName] = useState<string | undefined>(undefined);

  const [commitCreateUser] = useMutation<CreateUserControllerMutation>(graphql`
    mutation CreateUserControllerMutation($input: CreateUserInput!) {
      createUser(input: $input) {
        user {
          email
        }
      }
    }
  `);

  const onChange = (data: ChangeData) => {
    const {property, ...changeData} = data;

    const formData = plainToClass(
      CreateNewUserData,
      Object.assign(
        {},
        {
          email,
          fName,
          lName,
        },
        changeData,
      ),
    );

    if (errors.length !== 0) {
      const formErrors = validateSync(formData);
      setErrors(formErrors);
    }

    if (property === 'email') {
      setEmail(formData.email);
    } else if (property === 'fName') {
      setFName(formData.fName);
    } else if (property === 'lName') {
      setLName(formData.lName);
    }
  };

  async function onSend() {
    const formData = plainToClass(CreateNewUserData, {
      email,
      fName,
      lName,
    });
    const formErrors = validateSync(formData);

    if (formErrors.length !== 0) {
      setErrors(formErrors);
      setIsValidated(true);
      return;
    }

    commitCreateUser({
      variables: {
        input: {
          email: email,
          firstName: fName,
          lastName: lName,
        },
      },
      onCompleted() {
        handleClosing();
        dispatch(setApiFeedback({message: 'user created successful', severity: 'success'}));
        refetchData();
      },
      onError() {
        dispatch(setApiFeedback({message: 'user creation failed'}));
      },
    });
  }

  const handleClosing = () => {
    setEmail('');
    setFName(undefined);
    setLName(undefined);
    setIsValidated(false);
    handleClose(false);
  };

  return (
    <CreateUserView
      email={email}
      errors={!isValidated ? [] : errors}
      fName={fName}
      handleClose={handleClosing}
      lName={lName}
      loading={false}
      onChange={onChange}
      onSubmit={onSend}
      open={open}
    />
  );
};

export default CreateUserController;
