import React, {useEffect, useState} from 'react';

import {Checkbox, FormControlLabel, Tooltip, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {unstable_batchedUpdates} from 'react-dom';

const useStyles = (columns?: number) =>
  makeStyles({
    headingLabel: {
      textTransform: 'capitalize',
    },
    heading: {
      backgroundColor: '#fff',
      borderBottom: '1px solid #eee',
      width: '100%',
      position: 'sticky',
      display: 'flex',
      top: 0,
      zIndex: 1,
      paddingTop: '1rem',
      boxShadow: '0 2px 0px -2px #fff, 0 4px 2px -2px #fff',
    },
    item: {
      width: '100%',
    },
    listColumns: {
      columns: columns ?? 1,
    },
  })();

type CheckBoxValue = {
  id: string | number;
  label: string;
  checked: boolean;
};

type Props = {
  columns?: number;
  labelPrefix: string;
  values: CheckBoxValue[];
  toggleFilter(id: string | number): void;
  toggleAll(): void;
};

const FilterList: React.FC<Props> = ({columns, labelPrefix, values, toggleFilter, toggleAll}) => {
  const classes = useStyles(columns);

  const [checkedValues, setCheckedValues] = useState([] as boolean[]);

  const allSelected = values.length > 0 && !values.some(({checked}) => !checked);
  const noneSelected = values.length > 0 && !values.some(({checked}) => checked);

  useEffect(() => {
    setCheckedValues(values.map((v) => v.checked));
  }, []);

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>, id: string | number) => {
    unstable_batchedUpdates(() => {
      toggleFilter(id);
      setCheckedValues((alreadyChecked: boolean[]) => {
        return [event.target.checked, ...alreadyChecked];
      });
    });
  };

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    unstable_batchedUpdates(() => {
      toggleAll();
      setCheckedValues(checkedValues.fill(event.target.checked));
    });
  };

  const checkboxItems = values.map(({id, label, checked}) => {
    const labelId = `checkbox-${labelPrefix}-filter-${id}`;

    return (
      <FormControlLabel
        className={classes.item}
        control={<Checkbox checked={checked} onChange={(e) => handleCheckChange(e, id)} />}
        key={labelId}
        label={label}
      />
    );
  });

  return (
    <div>
      {/* <List subheader={subheader}> */}
      <FormControlLabel
        className={classes.heading}
        control={
          <Checkbox
            checked={allSelected}
            indeterminate={!allSelected && !noneSelected}
            onChange={handleAllChange}
          />
        }
        label={
          <Tooltip title={allSelected ? 'Unselect All' : 'Select All'}>
            <Typography className={classes.headingLabel} variant={'h6'}>
              {labelPrefix}s
            </Typography>
          </Tooltip>
        }
      />
      <div className={clsx(classes.listColumns, columns)}>{checkboxItems}</div>
    </div>
  );
};

export default FilterList;
