import React, {ReactElement} from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Variant} from '@material-ui/core/styles/createTypography';

const useStyles = makeStyles({
  root: {
    paddingLeft: '15px',
  },
  headerPaperDisabled: {
    'background-color': 'transparent',
  },
  srOnly: {
    'border': '0',
    'clip': 'rect(0 0 0 0)',
    'clip-path': 'polygon(0px 0px, 0px 0px, 0px 0px)',
    '-webkit-clip-path': 'polygon(0px 0px, 0px 0px, 0px 0px)',
    'height': '1px',
    'margin': '-1px',
    'overflow': 'hidden',
    'padding': '0',
    'position': 'absolute',
    'width': '1px',
    'white-space': 'nowrap',
  },
  iconHolder: {
    'textAlign': 'center',
    'marginRight': '.75rem',
    '& svg': {
      height: '100%',
    },
  },
  container: {
    marginLeft: '1rem',
  },
  heading: {
    'paddingRight': '1rem',
    '& h1': {
      margin: '10px 0 7px 0',
    },
    '& h2': {
      margin: '10px 0 4px 0',
    },
  },
  center: {
    justifyContent: 'center',
  },
});

export type ContentHeadingProps = {
  /** headingText: string, text inside heading element (eg. <h1>'your text here'</h1>)
   */
  headingText: string;
  /** headingType: number [1-6], determines what heading level element to create (eg. <h1></h1>)
   */
  headingType: 1 | 2 | 3 | 4 | 5 | 6;
  /** (optional) id: string, applies ID to mui Typography component used in title
   */
  id?: string;
  /** (optional) colorDecoration: string, must be in format "rgba(242, 9, 2123, 0.74)". Defines the
   * color scheme of heading decoration.
   */
  colorDecoration?: string;
  /** (optional) textClass: string, applies className to the Typography component
   */
  textClass?: string;
  /** (optional) paperClass: string, applies className to the Paper component
   */
  paperClass?: string;
  /** (optional) muiIcon: mui svg Icon component (ie. BusinessRoundedIcon from '@material-ui/icons/BusinessRounded')
                          to be displayed left of heading text

      > Note: Various icons tend to look best with various fonts. To match icon font size to text, modify the icon
        component directly when passing it in to <ContentHeading />. Example below:

        <BusinessRoundedIcon fontSize="large" />
        <BusinessRoundedIcon style={{fontSize: '.75rem'}} />

      > Also use the style={} property to change icon color.
   */
  muiIcon?: ReactElement;
  /** (optional) paperElevation: number, defaults to {2}. Controls elevation of paper.
   */
  paperElevation?: number;
  /** (optional) noBackground: boolean, removes the paper component background.
   */
  noBackground?: boolean;
  /** (optional) screenReaderOnly: boolean, applies css to hide the component from sight (but not screen readers).
   */
  screenReaderOnly?: boolean;
  /** (optional) squared: boolean, squares the component corners (defaults to rounded).
   */
  squared?: boolean;
  /** (optional) outlined: boolean, applies 'outline' instead of default 'elevation' type to the component.
   */
  outlined?: boolean;
  /** (optional) variant: number [1-6], determines what heading level element styling to display (does not replace semantic element)
   */
  variant?: 1 | 2 | 3 | 4 | 5 | 6;
  /** (optional) center: boolean, justifies content to the center and disables extra padding. Defaults to false.
   */
  center?: boolean;
};

const ContentHeading = ({
  colorDecoration,
  textClass,
  paperClass,
  headingText,
  headingType,
  muiIcon,
  id,
  paperElevation,
  noBackground,
  screenReaderOnly,
  squared,
  outlined,
  variant,
  center,
}: ContentHeadingProps): ReactElement => {
  const classes = useStyles();

  const elevate: number = paperElevation === undefined ? 2 : paperElevation;
  const srOnly: boolean = screenReaderOnly !== undefined && screenReaderOnly;
  const outline: boolean = outlined !== undefined && outlined;
  const hVariant: number = variant !== undefined ? variant : headingType;
  const isCentered: boolean = center !== undefined && center;

  // prettier-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headingComponent: React.ElementType<React.HTMLAttributes<HTMLElement>> = `h${headingType}` as any;

  return (
    <Paper
      className={`${classes.root} ${noBackground ? classes.headerPaperDisabled : ''} ${
        srOnly ? classes.srOnly : ''
      } ${paperClass}`}
      elevation={elevate}
      square={squared}
      style={colorDecoration ? {borderLeft: `4mm ridge ${colorDecoration}`} : undefined}
      variant={outline ? 'outlined' : 'elevation'}
    >
      <Grid
        className={`${isCentered ? classes.center : classes.heading}`}
        container
        direction={'row'}
      >
        {typeof muiIcon !== 'undefined' && <div className={classes.iconHolder}>{muiIcon}</div>}
        <Typography
          className={textClass}
          component={headingComponent}
          id={id}
          variant={`h${hVariant}` as Variant}
        >
          {headingText}
        </Typography>
      </Grid>
    </Paper>
  );
};

export default ContentHeading;
