/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RenameFolderInput = {
    id: string;
    name: string;
    clientMutationId?: string | null | undefined;
};
export type renameFolderMutationVariables = {
    input: RenameFolderInput;
};
export type renameFolderMutationResponse = {
    readonly renameFolder: {
        readonly account: {
            readonly userFolders: ReadonlyArray<{
                readonly " $fragmentRefs": FragmentRefs<"evidenceMain_folderName">;
            }>;
        };
    } | null;
};
export type renameFolderMutation = {
    readonly response: renameFolderMutationResponse;
    readonly variables: renameFolderMutationVariables;
};



/*
mutation renameFolderMutation(
  $input: RenameFolderInput!
) {
  renameFolder(input: $input) {
    account {
      userFolders {
        ...evidenceMain_folderName
        id
      }
      id
    }
  }
}

fragment evidenceMain_folderName on Folder {
  __isFolder: __typename
  type: __typename
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "renameFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RenameFolderPayload",
        "kind": "LinkedField",
        "name": "renameFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserFolder",
                "kind": "LinkedField",
                "name": "userFolders",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceMain_folderName"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "renameFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RenameFolderPayload",
        "kind": "LinkedField",
        "name": "renameFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserFolder",
                "kind": "LinkedField",
                "name": "userFolders",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "type",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "type": "Folder",
                    "abstractKey": "__isFolder"
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6fca0f82ea0d4a0a0104e6d54f99da3a",
    "id": null,
    "metadata": {},
    "name": "renameFolderMutation",
    "operationKind": "mutation",
    "text": "mutation renameFolderMutation(\n  $input: RenameFolderInput!\n) {\n  renameFolder(input: $input) {\n    account {\n      userFolders {\n        ...evidenceMain_folderName\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment evidenceMain_folderName on Folder {\n  __isFolder: __typename\n  type: __typename\n  name\n}\n"
  }
};
})();
(node as any).hash = '09ac728dcef0322a3b1eed7329f2cf18';
export default node;
