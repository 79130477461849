import {OpaqueString} from '@tynor/precis';
import {Base64} from 'js-base64';

export type AuthMessageParams = {
  username: string;
  nonce: string;
  salt: Uint8Array;
  iterations: number;
};

export interface CommonAuth {
  authMessage(params: Readonly<AuthMessageParams>): AuthMessage;
}

export type SaltedPasswordParams = {
  password: string;
  salt: Uint8Array;
  iterations: number;
};

export type CreateClientProofParams = {
  password: SaltedPassword;
  authMessage: AuthMessage;
};

export type VerifyServerProofParams = {
  password: SaltedPassword;
  authMessage: AuthMessage;
  serverProof: string;
};

export type EncryptPasswordParams = {
  password: string;
  publicKey: string;
};

export interface ClientAuth extends CommonAuth {
  nonce(): Promise<string>;
  saltedPassword(params: Readonly<SaltedPasswordParams>): Promise<SaltedPassword>;
  storedKey(spw: SaltedPassword): Promise<StoredKey>;
  serverKey(spw: SaltedPassword): Promise<ServerKey>;
  createProof(params: Readonly<CreateClientProofParams>): Promise<Uint8Array>;
  verifyServerProof(params: Readonly<VerifyServerProofParams>): Promise<boolean>;

  encryptPassword(params: Readonly<EncryptPasswordParams>): Promise<Uint8Array>;
}

export type VerifyClientProofParams = {
  storedKey: StoredKey;
  authMessage: AuthMessage;
  clientProof: Uint8Array;
};

export type CreateServerProofParams = {
  serverKey: ServerKey;
  authMessage: AuthMessage;
};

export interface ServerAuth extends CommonAuth {
  verifyClientProof(params: Readonly<VerifyClientProofParams>): Promise<boolean>;
  createProof(params: Readonly<CreateServerProofParams>): Promise<Uint8Array>;
}

export const createCommonAuth = (): CommonAuth => ({
  authMessage,
});

export const authMessage = ({
  username,
  nonce,
  salt,
  iterations,
}: AuthMessageParams): AuthMessage => {
  const msg =
    username + ',' + nonce + ',' + Base64.fromUint8Array(salt) + ',' + iterations.toString(10);
  return {authMessage: new TextEncoder().encode(msg)};
};

export type RandomBytes = (n: number) => Promise<Uint8Array>;

export type H = (data: Uint8Array) => Promise<Uint8Array>;

export type HMAC = (key: Uint8Array, data: Uint8Array) => Promise<Uint8Array>;

export type Hi = (data: Uint8Array, salt: Uint8Array, i: number) => Promise<Uint8Array>;

export type RSAEncrypt = (key: string, data: Uint8Array) => Promise<Uint8Array>;

export type CreateClientAuthParams = {
  randomBytes: RandomBytes;
  h: H;
  hmac: HMAC;
  hi: Hi;
  rsaEncrypt: RSAEncrypt;
};

export const createClientAuth = ({
  randomBytes,
  h,
  hmac,
  hi,
  rsaEncrypt,
}: Readonly<CreateClientAuthParams>): ClientAuth => {
  const saltedPassword = createSaltedPassword(hi);
  const clientKey = createClientKey(hmac);
  const storedKey = createStoredKey(h);
  const clientSig = createClientSig(hmac);
  const serverKey = createServerKey(hmac);
  const serverSig = createServerSig(hmac);
  return {
    ...createCommonAuth(),
    nonce: async () => {
      const data = await randomBytes(16);
      return Base64.fromUint8Array(data);
    },
    saltedPassword: async ({password, salt, iterations}) => {
      return await saltedPassword(password, salt, iterations);
    },
    storedKey: async (spw) => {
      const ck = await clientKey(spw);
      return await storedKey(ck);
    },
    serverKey,
    createProof: async ({password, authMessage}) => {
      const ck = await clientKey(password);
      const sk = await storedKey(ck);
      const cs = await clientSig(sk, authMessage);
      return xor(ck.clientKey, cs.clientSignature);
    },
    verifyServerProof: async ({password, authMessage, serverProof}) => {
      const sk = await serverKey(password);
      const {serverSignature} = await serverSig(sk, authMessage);
      const serverProofU8 = Base64.toUint8Array(serverProof);
      return eq(serverSignature, serverProofU8);
    },
    encryptPassword: async ({password, publicKey}) => {
      const enc = new TextEncoder();
      const data = enc.encode(password);
      return rsaEncrypt(publicKey, data);
    },
  };
};

export type SafeCompare = (a: Uint8Array, b: Uint8Array) => boolean;

export type CreateServerAuthParams = {
  h: H;
  hmac: HMAC;
  safeCompare: SafeCompare;
};

export const createServerAuth = ({h, hmac, safeCompare}: CreateServerAuthParams): ServerAuth => {
  const clientSig = createClientSig(hmac);
  const serverSig = createServerSig(hmac);
  return {
    ...createCommonAuth(),
    verifyClientProof: async ({storedKey, authMessage, clientProof}) => {
      const cs = await clientSig(storedKey, authMessage);
      const ck = xor(cs.clientSignature, clientProof);
      const ckh = await h(ck);
      return safeCompare(storedKey.storedKey, ckh);
    },
    createProof: async ({serverKey, authMessage}) => {
      const ss = await serverSig(serverKey, authMessage);
      return ss.serverSignature;
    },
  };
};

export const createSaltedPassword: (
  hi: Hi,
) => (password: string, salt: Uint8Array, iterations: number) => Promise<SaltedPassword> =
  (hi) => async (password, salt, iterations) => {
    let npw;
    try {
      npw = OpaqueString.enforce(password);
    } catch (e) {
      throw new Error('Invalid password');
    }
    const enc = new TextEncoder();
    const bpw = enc.encode(npw);
    const data = await hi(bpw, salt, iterations);
    return {
      saltedPassword: data,
    };
  };

export const createClientKey: (hmac: HMAC) => (spw: SaltedPassword) => Promise<ClientKey> = (
  hmac,
) => {
  const clientKeyData = new TextEncoder().encode('Client Key');
  return async ({saltedPassword}) => {
    const data = await hmac(saltedPassword, clientKeyData);
    return {
      clientKey: data,
    };
  };
};

export const createStoredKey: (h: H) => (ck: ClientKey) => Promise<StoredKey> =
  (h) =>
  async ({clientKey}) => {
    const data = await h(clientKey);
    return {
      storedKey: data,
    };
  };

export const createClientSig: (
  hmac: HMAC,
) => (sk: StoredKey, authMsg: AuthMessage) => Promise<ClientSignature> =
  (hmac) =>
  async ({storedKey}, {authMessage}) => {
    const data = await hmac(storedKey, authMessage);
    return {
      clientSignature: data,
    };
  };

export const createServerKey: (hmac: HMAC) => (spw: SaltedPassword) => Promise<ServerKey> = (
  hmac,
) => {
  const serverKeyData = new TextEncoder().encode('Server Key');
  return async ({saltedPassword}) => {
    const data = await hmac(saltedPassword, serverKeyData);
    return {
      serverKey: data,
    };
  };
};

export const createServerSig: (
  hmac: HMAC,
) => (sk: ServerKey, am: AuthMessage) => Promise<ServerSignature> =
  (hmac) =>
  async ({serverKey}, {authMessage}) => {
    const data = await hmac(serverKey, authMessage);
    return {
      serverSignature: data,
    };
  };

export type AuthMessage = Readonly<{
  authMessage: Uint8Array;
}>;

export type SaltedPassword = Readonly<{
  saltedPassword: Uint8Array;
}>;

export type ClientKey = Readonly<{
  clientKey: Uint8Array;
}>;

export type StoredKey = Readonly<{
  storedKey: Uint8Array;
}>;

export type ClientSignature = Readonly<{
  clientSignature: Uint8Array;
}>;

export type ServerKey = Readonly<{
  serverKey: Uint8Array;
}>;

export type ServerSignature = Readonly<{
  serverSignature: Uint8Array;
}>;

export const xor = (a: Uint8Array, b: Uint8Array): Uint8Array => {
  if (a.byteLength !== b.byteLength) {
    throw new Error('Mismatched array lengths');
  }
  const a8 = new Uint8Array(a);
  const b8 = new Uint8Array(b);
  const r = new Uint8Array(a8.length);
  for (let i = 0; i < a8.length; ++i) {
    r[i] = a8[i] ^ b8[i];
  }
  return r;
};

export const eq = (a: Uint8Array, b: Uint8Array): boolean => {
  if (a.byteLength !== b.byteLength) {
    return false;
  }
  const aU8 = new Uint8Array(a);
  const bU8 = new Uint8Array(b);
  for (let i = 0; i < aU8.length; ++i) {
    if (aU8[i] !== bU8[i]) {
      return false;
    }
  }
  return true;
};
