import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  DeleteFolderInput,
  deleteFolderMutation,
} from '../../graphql/__generated__/deleteFolderMutation.graphql';

const mutation = graphql`
  mutation deleteFolderMutation($input: DeleteFolderInput!) {
    deleteFolder(input: $input) {
      userFolder {
        id @deleteRecord
        evidences {
          edges {
            node {
              id
              folderCount
            }
          }
        }
      }
    }
  }
`;

function useDeleteFolder(): [(folderId: string, callback: () => void) => Disposable, boolean] {
  const [commit, isInFlight] = useMutation<deleteFolderMutation>(mutation);
  return [
    useCallback(
      (folderId, callback) => {
        const input: DeleteFolderInput = {
          folderId,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted() {
            callback();
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useDeleteFolder;
