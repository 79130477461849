/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateJournalEntryInput = {
    eventDate: Date;
    subject: string;
    body: string;
    clientMutationId?: string | null | undefined;
};
export type createJournalEntryMutationVariables = {
    input: CreateJournalEntryInput;
};
export type createJournalEntryMutationResponse = {
    readonly createJournalEntry: {
        readonly unsortedFolder: {
            readonly evidenceCount: number;
            readonly " $fragmentRefs": FragmentRefs<"evidenceList_folderEvidenceFull">;
        };
        readonly allFilesFolder: {
            readonly evidenceCount: number;
        };
        readonly journalEntry: {
            readonly id: string;
        };
    } | null;
};
export type createJournalEntryMutation = {
    readonly response: createJournalEntryMutationResponse;
    readonly variables: createJournalEntryMutationVariables;
};



/*
mutation createJournalEntryMutation(
  $input: CreateJournalEntryInput!
) {
  createJournalEntry(input: $input) {
    unsortedFolder {
      evidenceCount
      ...evidenceList_folderEvidenceFull
      id
    }
    allFilesFolder {
      evidenceCount
      id
    }
    journalEntry {
      id
    }
  }
}

fragment evidenceList_folderEvidenceFull on Folder {
  __isFolder: __typename
  evidences {
    edges {
      node {
        __typename
        id
        fileName: name
        storedOn: created
        eventDate
        size
        folderCount
        tagCount
        commentCount
        tags: tagsWithIds {
          id
          text
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "evidenceCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "JournalEntry",
  "kind": "LinkedField",
  "name": "journalEntry",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createJournalEntryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateJournalEntryPayload",
        "kind": "LinkedField",
        "name": "createJournalEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnsortedFolder",
            "kind": "LinkedField",
            "name": "unsortedFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceList_folderEvidenceFull"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AllFilesFolder",
            "kind": "LinkedField",
            "name": "allFilesFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createJournalEntryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateJournalEntryPayload",
        "kind": "LinkedField",
        "name": "createJournalEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnsortedFolder",
            "kind": "LinkedField",
            "name": "unsortedFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EvidenceConnection",
                    "kind": "LinkedField",
                    "name": "evidences",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EvidenceEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              {
                                "alias": "fileName",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": "storedOn",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "created",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "eventDate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "size",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "folderCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tagCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "commentCount",
                                "storageKey": null
                              },
                              {
                                "alias": "tags",
                                "args": null,
                                "concreteType": "TagWithId",
                                "kind": "LinkedField",
                                "name": "tagsWithIds",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "text",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Folder",
                "abstractKey": "__isFolder"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AllFilesFolder",
            "kind": "LinkedField",
            "name": "allFilesFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25a44b0b355c7eaa40041c8282b721c8",
    "id": null,
    "metadata": {},
    "name": "createJournalEntryMutation",
    "operationKind": "mutation",
    "text": "mutation createJournalEntryMutation(\n  $input: CreateJournalEntryInput!\n) {\n  createJournalEntry(input: $input) {\n    unsortedFolder {\n      evidenceCount\n      ...evidenceList_folderEvidenceFull\n      id\n    }\n    allFilesFolder {\n      evidenceCount\n      id\n    }\n    journalEntry {\n      id\n    }\n  }\n}\n\nfragment evidenceList_folderEvidenceFull on Folder {\n  __isFolder: __typename\n  evidences {\n    edges {\n      node {\n        __typename\n        id\n        fileName: name\n        storedOn: created\n        eventDate\n        size\n        folderCount\n        tagCount\n        commentCount\n        tags: tagsWithIds {\n          id\n          text\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'aab2112a8e303559e28d34531e5ad8eb';
export default node;
