import {connect} from 'react-redux';

import {setEvidence, setTags} from '../../../state/evidence/actions';
import {getAllEvidence, getAllTags} from '../../../state/evidence/selectors';
import {TRootState} from '../../../state/store';

import TagsAdminController from './tags.controller';

const mapStateToProps = (state: TRootState) => ({
  allTags: getAllTags(state),
  allEvidence: getAllEvidence(state),
});

const mapDispatchToProps = {
  setTags,
  setEvidence,
};

const TagsAdmin = connect(mapStateToProps, mapDispatchToProps)(TagsAdminController);

export default TagsAdmin;
