import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  AddEvidenceToFolderInput,
  addEvidenceToFolderMutation,
} from '../../graphql/__generated__/addEvidenceToFolderMutation.graphql';

const mutation = graphql`
  mutation addEvidenceToFolderMutation($input: AddEvidenceToFolderInput!) {
    addEvidenceToFolder(input: $input) {
      sourceFolder {
        ...evidenceNav_tagsNav
        ...evidenceMain_tagFilter
        evidenceCount
      }
      targetFolder {
        evidenceCount
      }
      evidenceNodes {
        node {
          folderCount
          ...evidenceDetail_folderGroup
        }
      }
    }
  }
`;

function useAddEvidenceToFolder(): [
  (
    sourceFolderId: string,
    targetFolderId: string,
    evidenceIds: string[],
    callback: () => void,
  ) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<addEvidenceToFolderMutation>(mutation);
  return [
    useCallback(
      (sourceFolderId, targetFolderId, evidenceIds, callback) => {
        const input: AddEvidenceToFolderInput = {
          sourceFolderId,
          targetFolderId,
          evidenceIds,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted() {
            callback();
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useAddEvidenceToFolder;
