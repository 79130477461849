import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {setOpenedWorkspaceTag} from '../../../state/timeline/actions';
import {getFilteredEvidenceWorkspaceTags} from '../../../state/timeline/selectors';

import TimelineTagNodesView from './timeline-tag-nodes.view';

const mapStateToProps = (state: TRootState) => ({
  filteredEvidenceWorkspaceTags: getFilteredEvidenceWorkspaceTags(state),
});

const mapDispatchToProps = {setOpenedWorkspaceTag};

const TimelineTagNodes = connect(mapStateToProps, mapDispatchToProps)(TimelineTagNodesView);

export default TimelineTagNodes;
