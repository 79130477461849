import React, {Dispatch, ReactElement, ReactNode, SetStateAction} from 'react';

import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
  ariaTitle: string;
  ariaDescription: string;
  title: string | undefined;
  children: ReactNode;
  confirmText: string | undefined;
  denyText: string;
  open: boolean;
  size?: 'md' | 'lg' | 'xl';
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
};

const ConfirmationDialog = ({
  ariaTitle,
  ariaDescription,
  title,
  children,
  confirmText,
  denyText,
  open,
  size,
  setOpen,
  onConfirm,
}: Props): ReactElement => {
  return (
    <Dialog
      aria-describedby={ariaDescription}
      aria-labelledby={ariaTitle}
      fullWidth={size !== undefined}
      maxWidth={size}
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle disableTypography id={ariaTitle}>
        <Typography component={'h4'} variant={'h4'}>
          {title ?? 'Confirmation Dialog'}
        </Typography>
      </DialogTitle>
      <DialogContent id={ariaDescription}>{children}</DialogContent>
      <DialogActions>
        <Button color={'secondary'} onClick={() => setOpen(false)} variant={'contained'}>
          {denyText ?? 'No'}
        </Button>
        <Button autoFocus color={'primary'} onClick={() => onConfirm()} variant={'contained'}>
          {confirmText ?? 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
