import React, {Fragment, useEffect} from 'react';
import type {ReactElement} from 'react';

import {Typography} from '@material-ui/core';

import GenericDialog from '../../../components/dialogs/generic-dialog';
import EvidenceDetail from '../../../components/evidence-detail';

import {TWorkspaceTag} from '../../../state/timeline/state';

type Props = {
  workspaceTag: TWorkspaceTag;
  workspaceTagEvidenceIds: {tagId: number; evidenceIds: string[]}[];
  onClose(): void;
  setSelectedEvidenceIds(ids: string[]): void;
};

const WorkspaceTagDetailView = ({
  workspaceTag,
  workspaceTagEvidenceIds,
  onClose,
  setSelectedEvidenceIds,
}: Props): ReactElement => {
  const taggedEvidence = workspaceTagEvidenceIds?.find(
    (t) => t.tagId === workspaceTag.tagId,
  )?.evidenceIds;

  useEffect(() => {
    if (taggedEvidence && taggedEvidence[0] !== undefined)
      setSelectedEvidenceIds([taggedEvidence[0]]);
  }, [workspaceTag, workspaceTagEvidenceIds]);

  const dialogTitle = (
    <Fragment>
      <Typography component={'h4'} variant={'h4'}>{`Tag: ${
        workspaceTag?.text || 'unknown'
      }`}</Typography>
      <Typography component={'h6'} variant={'h6'}>
        {`${taggedEvidence?.length} files tagged `}&quot;
        <b>{workspaceTag?.text || 'unknown'}</b>&quot;
      </Typography>
    </Fragment>
  );

  return (
    <GenericDialog
      ariaDescription={`workspace-tag-details-${workspaceTag?.tagId}`}
      ariaTitle={`workspace-tag-detail-${workspaceTag?.tagId}`}
      hasDataGridChild
      onClose={onClose}
      open={!!workspaceTag}
      title={dialogTitle}
    >
      <EvidenceDetail referrer={'workspace-tag-detail'} taggedEvidence={taggedEvidence} />
    </GenericDialog>
  );
};

export default WorkspaceTagDetailView;
