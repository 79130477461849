/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ConsolidateTagsInput = {
    sourceTagIds: Array<number>;
    targetTagText: string;
    clientMutationId?: string | null | undefined;
};
export type consolidateTagsMutationVariables = {
    input: ConsolidateTagsInput;
};
export type consolidateTagsMutationResponse = {
    readonly consolidateTags: {
        readonly evidenceNodes: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
            };
        }>;
    } | null;
};
export type consolidateTagsMutation = {
    readonly response: consolidateTagsMutationResponse;
    readonly variables: consolidateTagsMutationVariables;
};



/*
mutation consolidateTagsMutation(
  $input: ConsolidateTagsInput!
) {
  consolidateTags(input: $input) {
    evidenceNodes {
      node {
        __typename
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "consolidateTagsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConsolidateTagsPayload",
        "kind": "LinkedField",
        "name": "consolidateTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "consolidateTagsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConsolidateTagsPayload",
        "kind": "LinkedField",
        "name": "consolidateTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c3514c9153751bc553b8ae8dfb4d11e",
    "id": null,
    "metadata": {},
    "name": "consolidateTagsMutation",
    "operationKind": "mutation",
    "text": "mutation consolidateTagsMutation(\n  $input: ConsolidateTagsInput!\n) {\n  consolidateTags(input: $input) {\n    evidenceNodes {\n      node {\n        __typename\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8336105d16c9116d4d016426b434249e';
export default node;
