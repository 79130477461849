import React, {useEffect} from 'react';
import type {ReactElement} from 'react';

import {Base64} from 'js-base64';
import {graphql, useLazyLoadQuery} from 'react-relay';

import LoadingScreen from '../../../components/feedback/loading-screen';

import {toolsTagExportQuery} from '../../../graphql/__generated__/toolsTagExportQuery.graphql';

export type TagExportProps = {
  onDone: (ok: boolean) => void;
};

export const TagExport = ({onDone}: TagExportProps): ReactElement => {
  const data = useLazyLoadQuery<toolsTagExportQuery>(
    graphql`
      query toolsTagExportQuery {
        currentSession {
          ... on Session {
            account {
              activeTags {
                text
              }
            }
          }
        }
      }
    `,
    {},
  );

  useEffect(() => {
    const tags = data.currentSession?.account?.activeTags?.map(({text}) => text);
    if (tags === undefined) {
      onDone(false);
      return;
    }
    const content = Base64.encode(tags.join('\n'));
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = `data:text/plain;base64,${content}`;
    a.download = 'tags.txt';
    a.dispatchEvent(new MouseEvent('click'));
    onDone(true);
  }, []);

  return <LoadingScreen />;
};

export default TagExport;
