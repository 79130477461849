/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetail_actions = ReadonlyArray<{
    readonly id: string;
    readonly size: number;
    readonly eventDate: Date;
    readonly fileName: string;
    readonly type: string;
    readonly folders: ReadonlyArray<{
        readonly name: string;
    }>;
    readonly mimeType?: string | undefined;
    readonly url?: string | undefined;
    readonly subject?: string | undefined;
    readonly body?: string | undefined;
    readonly " $refType": "evidenceDetail_actions";
}>;
export type evidenceDetail_actions$data = evidenceDetail_actions;
export type evidenceDetail_actions$key = ReadonlyArray<{
    readonly " $data"?: evidenceDetail_actions$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_actions">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "evidenceDetail_actions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventDate",
      "storageKey": null
    },
    {
      "alias": "fileName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFolder",
      "kind": "LinkedField",
      "name": "folders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mimeType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "type": "File",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        }
      ],
      "type": "JournalEntry",
      "abstractKey": null
    }
  ],
  "type": "Evidence",
  "abstractKey": "__isEvidence"
};
(node as any).hash = 'ee29da854de049df465ec0a03801e1f3';
export default node;
