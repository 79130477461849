import {IsNumber, IsString} from 'class-validator';

import {mimeTypeToId} from './mime-type';
import type MimeType from './mime-type';

export interface IContentType {
  type: MimeType | string;
  name: string;
  id: number;
}

export const mimeLookup = (mimeType: string | MimeType): IContentType => {
  return {
    id: mimeTypeToId.get(mimeType as MimeType) as number,
    name: mimeType,
    type: mimeType,
  };
};

export const JOURNAL_ENTRY_MIMETYPE = 'text/journalentry';

export default class ContentType implements IContentType {
  @IsNumber()
  public id!: number;

  @IsString()
  public name!: string;

  @IsString()
  public type!: MimeType;
}
