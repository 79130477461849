import React, {ReactElement, useEffect, useState} from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {connect} from 'react-redux';

import {setApiFeedback} from '../../state/common/actions';
import {getApiFeedback} from '../../state/common/selectors';
import {TRootState} from '../../state/store';

type Severity = 'success' | 'info' | 'warning' | 'error';

export type ApiFeedbackRedux =
  | {
      error: Error;
      severity?: Severity;
    }
  | {
      message: string;
      severity?: Severity;
    }
  | false;

type Props = {
  apiFeedback: {message: string; severity: Severity} | false;
  setApiFeedback(value: ApiFeedbackRedux): void;
};

const ApiFeedbackComponent = ({apiFeedback, setApiFeedback}: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<Severity>('error');

  useEffect(() => {
    if (apiFeedback === false) {
      setOpen(false);
    } else {
      setMessage(apiFeedback.message);
      setSeverity(apiFeedback.severity);
      setOpen(true);
    }
  }, [apiFeedback]);

  const handleClose = (_event: React.SyntheticEvent, _reason?: string) => {
    setApiFeedback(false);
  };

  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={6000}
      onClose={handleClose}
      open={open}
    >
      <Alert elevation={6} onClose={handleClose} severity={severity} variant={'filled'}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state: TRootState) => ({
  apiFeedback: getApiFeedback(state),
});

const mapDispatchToProps = {
  setApiFeedback,
};

const ApiFeedback = connect(mapStateToProps, mapDispatchToProps)(ApiFeedbackComponent);

export default ApiFeedback;
