import React, {Fragment, useMemo, useState} from 'react';

import {Button, Grid, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import {useDispatch} from 'react-redux';
import {graphql, useFragment, useLazyLoadQuery} from 'react-relay';

import {evidenceDetail_actions$key} from '../../graphql/__generated__/evidenceDetail_actions.graphql';
import {evidenceDetailsActionsQuery} from '../../graphql/__generated__/evidenceDetailsActionsQuery.graphql';

import useDeleteEvidence from '../../hooks/mutations/delete-evidence.hook';
import useRemoveEvidenceFromFolder from '../../hooks/mutations/remove-evidence-from-folder.hook';

import {setApiFeedback} from '../../state/common/actions';

import {FolderTypeEnum, TFolder} from '../../services/evidences/data/types';
import PreviewFile from '../display/preview-file';
import DownloadEvidence from '../download-evidence';
import LoadingScreen from '../feedback/loading-screen';
import {Referrer} from './evidence-detail.controller';
import EvidenceDetailModal from './evidence-detail.modal';

const useStyles = makeStyles({
  actionsGrid: {
    marginBottom: '0.25rem',
    paddingBottom: '1rem',
    width: '100%',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  deleteButton: {
    'backgroundColor': '#c00',
    'color': 'white',
    '&:hover': {
      backgroundColor: '#900',
    },
  },
});

type Folder = Omit<TFolder, 'evidenceCount'>;

type Props = {
  referrer: Referrer['referrer'];
  evidence: evidenceDetail_actions$key;
  currentFolderId: string;
};

const EvidenceDetailActions: React.FC<Props> = ({referrer, evidence, currentFolderId}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [removeEvidence, loadingRemove] = useRemoveEvidenceFromFolder();
  const [deleteEvidence, loadingDelete] = useDeleteEvidence();
  const [modalOpen, setModalOpen] = useState(false);
  const [evidenceIds, setEvidenceIds] = useState<string[]>([]);
  const [evidenceFilenames, setEvidenceFilenames] = useState<string[]>([]);

  const folderData = useLazyLoadQuery<evidenceDetailsActionsQuery>(
    graphql`
      query evidenceDetailsActionsQuery($id: ID!) {
        folder: node(id: $id) {
          ... on Folder {
            id
            name
            type: __typename
          }
        }
      }
    `,
    {
      id: currentFolderId, // we need to pass something because of the query $id type decleration
    },
  );

  const currentFolder = folderData.folder as Folder;
  const inUserFolder = currentFolder?.type === FolderTypeEnum['USERFOLDER'];

  const data = useFragment(
    graphql`
      fragment evidenceDetail_actions on Evidence @relay(plural: true) {
        id
        size
        eventDate
        fileName: name
        type: __typename
        eventDate
        ... on File {
          mimeType
          url
        }
        ... on JournalEntry {
          subject
          body
        }
        folders {
          name
        }
      }
    `,
    evidence,
  );

  useMemo(() => {
    const evIds = data?.map((e) => e?.id);
    setEvidenceIds(evIds);
    const evFilenames = data?.map((e) => e?.fileName);
    setEvidenceFilenames(evFilenames);
  }, [data]);

  const deleteCallback = () => {
    dispatch(
      setApiFeedback({
        severity: 'success',
        message: `Evidence: "${evidenceFilenames.join(', ')}" deleted from everywhere`,
      }),
    );
  };

  const removeCallback = () => {
    if (currentFolder !== undefined) {
      dispatch(
        setApiFeedback({
          severity: 'success',
          message: `Evidence: "${evidenceFilenames.join(', ')}" removed from Folder: ${
            currentFolder?.name
          }`,
        }),
      );
    }
  };

  const handleDelete = () => {
    switch (currentFolder?.type) {
      case FolderTypeEnum['UNSORTEDFOLDER']:
      case FolderTypeEnum['ALLFILESFOLDER']:
        deleteEvidence(currentFolderId, evidenceIds, deleteCallback);
        setModalOpen(false);
        break;
      case FolderTypeEnum['USERFOLDER']:
        if (currentFolderId !== undefined) {
          removeEvidence(currentFolderId, evidenceIds, removeCallback);
        }
        setModalOpen(false);
        break;
      default:
        // shouldn't be possible, do nothing
        break;
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Fragment>
      {(loadingRemove || loadingDelete) && <LoadingScreen />}
      <Grid className={classes.actionsGrid} container spacing={1}>
        {data.length === 1 && (
          <Grid item xs={referrer !== 'evidence-list' ? 6 : 12}>
            <PreviewFile evidence={data[0]} />
          </Grid>
        )}
        <Grid item xs={6}>
          <DownloadEvidence origin={'evidence'} />
        </Grid>
        {currentFolderId && referrer === 'evidence-list' && (
          <Grid item xs={6}>
            <Tooltip title={inUserFolder ? 'Remove from folder' : 'Delete from everywhere'}>
              <Button
                className={classes.deleteButton}
                endIcon={<DeleteIcon />}
                fullWidth
                onClick={() => setModalOpen(true)}
                variant={'contained'}
              >
                {inUserFolder ? 'Remove' : 'Delete'}
              </Button>
            </Tooltip>
            <EvidenceDetailModal
              evidence={data}
              folder={currentFolder}
              onClose={handleModalClose}
              onConfirm={handleDelete}
              open={modalOpen}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default EvidenceDetailActions;
