import React from 'react';

import {useDispatch} from 'react-redux';
import {useMutation} from 'react-relay';
import {graphql} from 'relay-runtime';

import {setApiFeedback} from '../../../../state/common/actions';
import {TWorkspace} from '../../../../state/timeline/state';

import DeleteWorkspaceModalView from './delete-workspace-modal.view';

type Props = {
  workspaceToBeDeleted: TWorkspace | undefined;
  isOpen: boolean;
  onClose(): void;
  deleteWorkspace(workspaceId: string): void;
};

const DeleteWorkspaceModalController: React.FC<Props> = ({
  workspaceToBeDeleted,
  isOpen,
  onClose,
  deleteWorkspace,
}) => {
  const dispatch = useDispatch();

  const [commitWorkspace] = useMutation(graphql`
    mutation deleteWorkspaceModalDeleteWorkspaceMutation($input: DeleteWorkspaceInput!) {
      deleteWorkspace(input: $input) {
        account {
          name
        }
      }
    }
  `);

  const handleDeleteWorkspace = async () => {
    commitWorkspace({
      variables: {
        input: {
          workspaceId: workspaceToBeDeleted?.id,
        },
      },
      onCompleted() {
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Workspace "${workspaceToBeDeleted?.name}" deleted successfully`,
          }),
        );
        if (workspaceToBeDeleted?.id) deleteWorkspace(workspaceToBeDeleted?.id);
        onClose();
      },
    });
  };

  return (
    <DeleteWorkspaceModalView
      isOpen={isOpen}
      onClose={onClose}
      onDelete={handleDeleteWorkspace}
      workspaceName={workspaceToBeDeleted?.name}
    />
  );
};

export default DeleteWorkspaceModalController;
