import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {
  getFolderFilterValues,
  getTagFilterValues,
  getTypeFilterValues,
} from '../../../state/timeline/selectors';

import FilterSelectionDrawerController from './filter-selection-drawer.controller';

export type FilterValue<T> = {
  item: T;
  checked: boolean;
};

const mapStateToProps = (state: TRootState) => ({
  folderValues: getFolderFilterValues(state),
  tagValues: getTagFilterValues(state),
  typeValues: getTypeFilterValues(state),
});

const FilterSelectionDrawer = connect(mapStateToProps)(FilterSelectionDrawerController);

export default FilterSelectionDrawer;
