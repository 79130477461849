import React, {ReactElement} from 'react';

import LoadingScreen from '../../../components/feedback/loading-screen';

import {evidenceList_folderEvidenceFull$key} from '../../../graphql/__generated__/evidenceList_folderEvidenceFull.graphql';

import EvidenceListView from './evidence-list.view';

type Props = {
  currentFolderId: string | undefined;
  currentTagFilterIds: number[];
  folder: evidenceList_folderEvidenceFull$key;
  setSelectedEvidenceIds(ids: string[]): void;
};

export type EvidenceRow = {
  id?: string;
  fileName?: string;
  storedOn?: Date;
  eventDate?: Date;
  size?: number;
  folderCount?: number;
  tagCount?: number;
  commentCount?: number;
  tags?: string[] | undefined;
};

export type EvidenceIdAndName = Array<Pick<EvidenceRow, 'id' | 'fileName'>> | undefined;

function EvidenceListController({
  currentFolderId,
  currentTagFilterIds,
  folder,
  setSelectedEvidenceIds,
}: Props): ReactElement {
  if (currentFolderId === undefined) {
    return <LoadingScreen />;
  }

  return (
    <EvidenceListView
      currentFolderId={currentFolderId}
      currentTagFilterIds={currentTagFilterIds}
      folder={folder as evidenceList_folderEvidenceFull$key}
      setSelectedEvidenceIds={setSelectedEvidenceIds}
    />
  );
}

export default EvidenceListController;
