import {Container} from 'inversify';

import {store} from '../../../state/store';

import entityModule from '../../evidences/container/config-module';
import timelineModule from '../../timeline/container/config-module';
// import fakeConfig from '../../fakes/config-module';
import RequestService, {IRequestService} from '../request-service';
import keys from './keys';

export const container = new Container();

// the node environment variable NODE_ENV is bound to process.env.NODE_ENV at build time
container.bind<string>(keys.NODE_ENV).toConstantValue(process.env.NODE_ENV || 'development');

let baseUrl = '/';
switch (process.env.NODE_ENV) {
  case 'development':
    baseUrl = 'https://dev-api.evdense.com/';
    break;
  case 'test':
    baseUrl = 'https://dev-api.evdense.com/';
    break;
}
if (process.env.BASE_URL) {
  baseUrl = process.env.BASE_URL;
}

container.bind<string>(keys.BASE_URL).toConstantValue(baseUrl);

container.bind(keys.store).toConstantValue(store);

container.bind<IRequestService>(keys.IRequestService).to(RequestService).inSingletonScope();

container.load(entityModule);
container.load(timelineModule);
