import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  AddEvidenceTagInput,
  addTagToEvidenceMutation,
} from '../../graphql/__generated__/addTagToEvidenceMutation.graphql';

const mutation = graphql`
  mutation addTagToEvidenceMutation($input: AddEvidenceTagInput!) {
    addEvidenceTag(input: $input) {
      folder {
        ...evidenceNav_tagsNav
        ...evidenceMain_tagFilter
      }
      evidenceNodes {
        node {
          ...evidenceDetail_tagGroup
          ...evidenceDetail_addTag
          tagCount
        }
      }
    }
  }
`;

// todo add folderId to input so we can also return it in query of mutation
function useAddTagToEvidence(): [
  (tagText: string, evidenceIds: string[], folderId: string, callback: () => void) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<addTagToEvidenceMutation>(mutation);
  return [
    useCallback(
      (tagText, evidenceIds, folderId, callback) => {
        const input: AddEvidenceTagInput = {
          tag: tagText,
          evidenceIds,
          folderId,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted(response) {
            if (response.addEvidenceTag?.evidenceNodes !== undefined) {
              callback();
            }
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useAddTagToEvidence;
