import React, {Fragment} from 'react';
import type {ReactElement} from 'react';

import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    height: '100%',
    minHeight: '20em',
    width: '100%',
    background: '#fafafa',
  },
}));

const Page404 = (): ReactElement => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid
        alignContent={'center'}
        className={classes.container}
        container
        justifyContent={'center'}
      >
        <Grid item>
          <h1> 404 - Page not found</h1>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Page404;
