import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {
  getCalendarMonths,
  getSelectedWorkspaceId,
  getWorkspacePosition,
  getWorkspaceSortKey,
} from '../../../state/timeline/selectors';

import TimelineFilmstripView from './timeline-filmstrip.view';

const mapStateToProps = (state: TRootState) => ({
  months: getCalendarMonths(state),
  sortKey: getWorkspaceSortKey(state),
  selectedWorkspaceId: getSelectedWorkspaceId(state),
  workspacePosition: getWorkspacePosition(state),
});

const TimelineFilmstrip = connect(mapStateToProps)(TimelineFilmstripView);

export default TimelineFilmstrip;
