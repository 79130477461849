import React, {Fragment} from 'react';
import type {Dispatch, ReactElement, SetStateAction} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {ValidationError} from 'class-validator';

import LoadingScreen from '../../../components/feedback/loading-screen';

import {hasClassValidationError as hasError} from '../../../utils/utils';

import {ChangeData} from './CreateUserController';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  inputs: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: '#626262',
  },
  dialogPaper: {
    height: '60%',
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
  },
}));

type Props = {
  open: boolean;
  loading: boolean;
  email: string;
  fName: string | undefined;
  lName: string | undefined;
  errors: ValidationError[];
  onChange(data: ChangeData): void;
  onSubmit(): void;
  handleClose: Dispatch<SetStateAction<boolean>> | (() => void);
};

const CreateUserView = ({
  open,
  loading,
  email,
  fName,
  lName,
  errors,
  onChange,
  handleClose,
  onSubmit,
}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby={'create-user-dialog'}
      classes={{paper: classes.dialogPaper}}
      onClose={() => handleClose(false)}
      open={open}
    >
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <DialogTitle id={'create-user-dialog'}>Create new user</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.subtitle}>
              Please provide a unique email &amp; full name for the new user
            </DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  classes={{root: classes.inputs}}
                  color={'secondary'}
                  error={hasError(errors, 'email')}
                  fullWidth
                  helperText={hasError(errors, 'email') && 'Please enter a valid email'}
                  label={'Email'}
                  onChange={(event) =>
                    onChange({
                      property: 'email',
                      email: event.currentTarget.value,
                    })
                  }
                  type={'email'}
                  value={email}
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  classes={{root: classes.inputs}}
                  color={'secondary'}
                  error={hasError(errors, 'fName')}
                  fullWidth
                  helperText={hasError(errors, 'fName') && 'Please enter a valid first name'}
                  label={'First name'}
                  onChange={(event) =>
                    onChange({
                      property: 'fName',
                      fName: event.currentTarget.value,
                    })
                  }
                  value={fName ?? ''}
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  classes={{root: classes.inputs}}
                  color={'secondary'}
                  error={hasError(errors, 'lName')}
                  fullWidth
                  helperText={hasError(errors, 'lName') && 'Please enter a valid last name'}
                  label={'Last name'}
                  onChange={(event) =>
                    onChange({
                      property: 'lName',
                      lName: event.currentTarget.value,
                    })
                  }
                  value={lName ?? ''}
                  variant={'outlined'}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              classes={{label: classes.bold}}
              color={'primary'}
              disabled={errors.length !== 0 || email.length === 0}
              fullWidth
              onClick={onSubmit}
              variant={'contained'}
            >
              Submit
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
};

export default CreateUserView;
