import * as t from 'io-ts';

import {getAccessTokens} from '../../utils/tokens';

import {fromEnum} from '../../services/common/data/types';

export const ApiFeedbackT = t.type({
  message: t.string,
  severity: t.union([
    t.literal('success'),
    t.literal('info'),
    t.literal('warning'),
    t.literal('error'),
  ]),
});

// a dup of import type {UserRole} from '../../graphql/__generated__/headerNav_userRoles.graphql';
export enum UserRolesEnum {
  ADMIN = 'Admin',
}

const UserRolesCodec = fromEnum('UserRolesEnum', UserRolesEnum);

export const UserAccountT = t.type({
  firstName: t.union([t.string, t.nullType]),
  lastName: t.union([t.string, t.nullType]),
  email: t.string,
  roles: t.union([t.array(UserRolesCodec), t.nullType]),
});

export type UserAccount = t.OutputOf<typeof UserAccountT>;

export const CommonState = t.type({
  isAuthenticated: t.boolean,
  lastLogin: t.string,
  apiFeedback: t.union([ApiFeedbackT, t.literal(false)]),
  accessedAccount: t.union([t.string, t.nullType]),
  authenticatedUser: t.union([UserAccountT, t.nullType]),
  impersonatedUser: t.union([UserAccountT, t.nullType]),
});

export type TCommonState = t.OutputOf<typeof CommonState>;

export const DEFAULT_COMMON_STATE: t.TypeOf<typeof CommonState> = {
  isAuthenticated: getAccessTokens() !== undefined,
  lastLogin: new Date().toDateString(),
  apiFeedback: false,
  accessedAccount: null,
  authenticatedUser: null,
  impersonatedUser: null,
};
