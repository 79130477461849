import React from 'react';

import {useInjection} from '../../../components/providers/container-provider';

import {TCaseFolder, TCaseTag, TEvidenceMimeType} from '../../../state/timeline/state';

import keys from '../../../services/common/container/keys';
import {ITimelineService} from '../../../services/timeline/timeline-service';
import FilterSelectionDrawerView from './filter-selection-drawer.view';
import {FilterValue} from './index';

export type FilterToggle =
  | {
      type: 'folder';
      id: string;
    }
  | {
      type: 'tag' | 'type';
      id: number;
    };

type Props = {
  folderValues: FilterValue<TCaseFolder>[];
  tagValues: FilterValue<TCaseTag>[];
  typeValues: FilterValue<TEvidenceMimeType>[];
  isOpen: boolean;
  onClose(): void;
};

const FilterSelectionDrawerController: React.FC<Props> = ({
  folderValues,
  tagValues,
  typeValues,
  isOpen,
  onClose,
}) => {
  // todo: move this to redux
  const timelineService = useInjection<ITimelineService>(keys.timeline.ITimelineService);

  async function toggleFilter(filter: FilterToggle): Promise<void> {
    await timelineService?.toggleFilter(filter);
  }

  async function toggleAllFilter(type: 'folders' | 'tags' | 'types'): Promise<void> {
    await timelineService?.toggleAllFilters(type);
  }

  return (
    <FilterSelectionDrawerView
      folderValues={folderValues}
      isOpen={isOpen}
      onClose={onClose}
      tagValues={tagValues}
      toggleAllFilters={toggleAllFilter}
      toggleFilter={toggleFilter}
      typeValues={typeValues}
    />
  );
};

export default FilterSelectionDrawerController;
