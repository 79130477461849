import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';

import {
  FinalizeFileUploadInput,
  finalizeUploadMutation,
} from '../../graphql/__generated__/finalizeUploadMutation.graphql';

import {FileData} from '../../pages/evidence/evidence-nav/evidence-nav.upload-files.modal';

export type FinalizeFileUploadResponse = Omit<FileData, 'status'>;

const mutation = graphql`
  mutation finalizeUploadMutation($input: FinalizeFileUploadInput!) {
    finalizeFileUpload(input: $input) {
      unsortedFolder {
        evidenceCount
        ...evidenceList_folderEvidenceFull
      }
      allFilesFolder {
        evidenceCount
      }
      file {
        id
        # name: filename
        # size
        # time: created
        # url
      }
    }
  }
`;

function useFinalizeUpload(): [(uploadToken: string) => Promise<FinalizeFileUploadResponse>] {
  const [commit] = useMutation<finalizeUploadMutation>(mutation);

  return [
    useCallback(
      async (uploadToken) => {
        const input: FinalizeFileUploadInput = {
          uploadToken,
        };
        return await new Promise((resolve, reject) => {
          commit({
            variables: {
              input,
            },
            onCompleted: (response, errors) => {
              if (errors) {
                reject(errors[0]);
              } else if (response === null) {
                reject(new Error('response was null'));
              } else {
                resolve(response.finalizeFileUpload?.file as FinalizeFileUploadResponse);
              }
            },
            onError: (error) => reject(error),
          });
        });
      },
      [commit],
    ),
  ];
}

export default useFinalizeUpload;
