import React, {Fragment, Suspense, useEffect} from 'react';
import type {ReactElement} from 'react';

import {graphql, useLazyLoadQuery} from 'react-relay';
import {Route, Switch} from 'react-router-dom';

import {RouteConfigProps, routes} from '../../config/routes';

import {routerViewQuery} from '../../graphql/__generated__/routerViewQuery.graphql';

import {UserAccount} from '../../state/common/state';

import LoadingScreen from '../feedback/loading-screen';
import Main from '../layout/main';
import HeaderNav from '../navigation/header/header.nav';

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route: RouteConfigProps) {
  const {path, Component, exact, routes} = route;
  return (
    <Route
      path={path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <Component {...props} exact={!!exact} routes={routes} />
      )}
    />
  );
}

type Props = {
  setAuthenticatedUser(value: UserAccount | null): void;
};

const RouterView = ({setAuthenticatedUser}: Props): ReactElement => {
  const data = useLazyLoadQuery<routerViewQuery>(
    graphql`
      query routerViewQuery {
        currentSession {
          ... on Session {
            ...headerNav_prevUser
            user {
              firstName
              lastName
              email
              roles
              ...headerNav_userRoles
            }
          }
        }
      }
    `,
    {},
  );

  // todo: get roles from prevUser so we can show tag admin and user admin

  useEffect(() => {
    if (data.currentSession !== undefined) {
      const user = data?.currentSession?.user ?? null;
      if (user) {
        const {firstName, lastName, email, roles} = user;
        setAuthenticatedUser({firstName, lastName, email, roles} as UserAccount);
      }
    }
  }, []);

  return (
    <Fragment>
      <HeaderNav session={data?.currentSession ?? null} user={data?.currentSession?.user ?? null} />
      <Main>
        <Suspense fallback={<LoadingScreen />}>
          <Switch>
            {routes.map((route) => (
              <RouteWithSubRoutes key={`route-${route.path}`} {...route} />
            ))}
          </Switch>
        </Suspense>
      </Main>
    </Fragment>
  );
};

export default RouterView;
