/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CloneWorkspaceInput = {
    name: string;
    zoomIndex: number;
    x: number;
    y: number;
    backgroundColor: number;
    workspaceTags?: WorkspaceTagsInput | null | undefined;
    workspaceFilters?: WorkspaceFiltersInput | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type WorkspaceTagsInput = {
    tags: Array<WorkspaceTagInput>;
};
export type WorkspaceTagInput = {
    text: string;
    tagId: number;
    color: string;
    x: number;
    y: number;
    lockHighlight: boolean;
};
export type WorkspaceFiltersInput = {
    folderIds?: Array<string> | null | undefined;
    tagIds?: Array<number> | null | undefined;
    typeIds?: Array<number> | null | undefined;
};
export type useCloneWorkspaceMutationVariables = {
    input: CloneWorkspaceInput;
};
export type useCloneWorkspaceMutationResponse = {
    readonly cloneWorkspace: {
        readonly workspace: {
            readonly id: string;
            readonly modified: Date;
        };
    } | null;
};
export type useCloneWorkspaceMutation = {
    readonly response: useCloneWorkspaceMutationResponse;
    readonly variables: useCloneWorkspaceMutationVariables;
};



/*
mutation useCloneWorkspaceMutation(
  $input: CloneWorkspaceInput!
) {
  cloneWorkspace(input: $input) {
    workspace {
      id
      modified
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CloneWorkspacePayload",
    "kind": "LinkedField",
    "name": "cloneWorkspace",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modified",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCloneWorkspaceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCloneWorkspaceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0c780f36c2116ac884a00b32f2e9d55a",
    "id": null,
    "metadata": {},
    "name": "useCloneWorkspaceMutation",
    "operationKind": "mutation",
    "text": "mutation useCloneWorkspaceMutation(\n  $input: CloneWorkspaceInput!\n) {\n  cloneWorkspace(input: $input) {\n    workspace {\n      id\n      modified\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c9c2f0083fbb86c08c59fac2200096ec';
export default node;
