import React, {ChangeEvent, FormEvent, Fragment, useEffect, useState} from 'react';
import type {ReactElement} from 'react';

import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {useDispatch} from 'react-redux';
import {graphql, useFragment, useLazyLoadQuery, useMutation} from 'react-relay';

import type {userDetailsAddUserRoleMutation} from '../../../graphql/__generated__/userDetailsAddUserRoleMutation.graphql';
import type {userDetailsRemoveUserRoleMutation} from '../../../graphql/__generated__/userDetailsRemoveUserRoleMutation.graphql';
import {userDetailsSessionQuery} from '../../../graphql/__generated__/userDetailsSessionQuery.graphql';
import type {userDetailsUpdateUserMutation} from '../../../graphql/__generated__/userDetailsUpdateUserMutation.graphql';
import type {
  userDetails_userDetails,
  userDetails_userDetails$key,
} from '../../../graphql/__generated__/userDetails_userDetails.graphql';
import type {
  UserRole,
  userDetails_userRoles,
  userDetails_userRoles$key,
} from '../../../graphql/__generated__/userDetails_userRoles.graphql';

import {ucwords} from '../../../utils/utils';

import {setApiFeedback} from '../../../state/common/actions';

// types
enum Gender {
  MALE = '0',
  FEMALE = '1',
  OTHER = '2',
}

export const GenderList: {
  label: string;
  value: string;
}[] = Object.entries(Gender).map(([label, value]) => ({label, value}));

type Props = {
  user: userDetails_userDetails$key & userDetails_userRoles$key;
};

const US_TIMEZONES = ['US/Eastern', 'US/Central', 'US/Mountain', 'US/Pacific', 'US/Other'] as const;

type TimezoneTuple = typeof US_TIMEZONES;
export type Timezone = TimezoneTuple[number];

export const TimezoneList: {
  label: string;
  value: string;
}[] = Object.entries(US_TIMEZONES).map(([label, value]) => ({label, value}));

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      margin: '0 auto',
      maxWidth: '100rem',
      padding: '0 5rem',
    },
    buttonWrapper: {
      marginLeft: 'auto',
    },
    divider: {
      margin: '3rem auto 1.5rem',
      maxWidth: '80%',
    },
  });
});

const UserDetails = ({user}: Props): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // User details

  const [email, setEmail] = useState('');
  const [timezone, setTimezone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState<userDetails_userDetails['gender']>();
  const [active, setActive] = useState(false);
  const [activeDate, setActiveDate] = useState<Date | null>(null);
  const [promoContact, setPromoContact] = useState(false);

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleChangeTimeZone = (event: ChangeEvent<HTMLInputElement>) => {
    setTimezone(event.target.value);
  };
  const handleChangeFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };
  const handleChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };
  const handleChangeGender = (event: ChangeEvent<HTMLInputElement>) => {
    setGender(parseInt(event.target.value, 10));
  };
  const handleChangeActive = (event: ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };
  const handleChangeActiveDate = (date: Date | null) => {
    setActiveDate(date);
  };
  const handleChangePromoContact = (event: ChangeEvent<HTMLInputElement>) => {
    setPromoContact(event.target.checked);
  };

  const data: userDetails_userDetails = useFragment(
    graphql`
      fragment userDetails_userDetails on User {
        id
        firstName
        lastName
        email
        gender
        timezone
        active
        activeDate
        promoContact
      }
    `,
    user,
  );

  useEffect(() => {
    if (data.email !== undefined) setEmail(data.email);
    if (data.timezone !== null) setTimezone(data.timezone);
    if (data.firstName !== null) setFirstName(data.firstName);
    if (data.lastName !== null) setLastName(data.lastName);
    if (data.gender !== null) setGender(data.gender);
    if (data.active !== null) setActive(data.active);
    if (data.activeDate !== null) setActiveDate(data.activeDate as Date);
    if (data.promoContact !== null) setPromoContact(data.promoContact);
  }, []);

  const [commitDetails] = useMutation<userDetailsUpdateUserMutation>(graphql`
    mutation userDetailsUpdateUserMutation($input: UpdateUserInput!) {
      updateUser(input: $input) {
        clientMutationId
        user {
          firstName
          lastName
          email
          gender
          timezone
          active
          activeDate
          promoContact
        }
      }
    }
  `);

  const handleDetailsSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    commitDetails({
      variables: {
        input: {
          // first existing data then overwrite with new data if it exists
          ...data,
          active: active,
          activeDate: activeDate,
          email: email,
          firstName: firstName,
          gender: gender,
          lastName: lastName,
          promoContact,
          timezone: timezone,
        },
      },
      onCompleted() {
        dispatch(
          setApiFeedback({severity: 'success', message: 'User Details updated successfully'}),
        );
      },
      onError(e) {
        console.error(e);
        dispatch(setApiFeedback({error: e}));
      },
    });
  };

  // User roles
  const adminRole: UserRole = 'ADMIN';
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const rolesData: userDetails_userRoles = useFragment(
    graphql`
      fragment userDetails_userRoles on User {
        id
        roles
      }
    `,
    user,
  );

  useEffect(() => {
    if (rolesData.roles !== null && rolesData.roles.includes(adminRole)) setIsUserAdmin(true);
  }, []);

  const handleChangeisUserAdmin = (event: ChangeEvent<HTMLInputElement>) => {
    setIsUserAdmin(event.target.checked);
  };

  const [commitAddUserRole] = useMutation<userDetailsAddUserRoleMutation>(graphql`
    mutation userDetailsAddUserRoleMutation($input: AddUserRoleInput!) {
      addUserRole(input: $input) {
        clientMutationId
      }
    }
  `);

  const [commitRemoveUserRole] = useMutation<userDetailsRemoveUserRoleMutation>(graphql`
    mutation userDetailsRemoveUserRoleMutation($input: RemoveUserRoleInput!) {
      removeUserRole(input: $input) {
        clientMutationId
      }
    }
  `);

  const handleRolesSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (isUserAdmin) {
      commitAddUserRole({
        variables: {
          input: {
            userId: rolesData.id,
            role: adminRole,
          },
        },
        onCompleted() {
          dispatch(
            setApiFeedback({
              severity: 'success',
              message: `Admin role successfully granted to ${firstName} ${lastName}`,
            }),
          );
        },
        onError(e) {
          console.error(e);
          dispatch(setApiFeedback({error: e}));
        },
      });
    } else {
      commitRemoveUserRole({
        variables: {
          input: {
            userId: rolesData.id,
            role: adminRole,
          },
        },
        onCompleted() {
          dispatch(
            setApiFeedback({
              severity: 'warning',
              message: `Admin role successfully revoked for ${firstName} ${lastName}`,
            }),
          );
        },
        onError(e) {
          console.error(e);
          dispatch(setApiFeedback({error: e}));
        },
      });
    }
  };

  // get currentSession info
  const sessionData = useLazyLoadQuery<userDetailsSessionQuery>(
    graphql`
      query userDetailsSessionQuery {
        currentSession {
          ... on Session {
            user {
              roles
            }
            prevUser {
              roles
            }
          }
        }
      }
    `,
    {},
  );

  const [showAdminFields, setShowAdminFields] = useState(false);

  useEffect(() => {
    // if prev user then we're impersonating another user
    // else if current session user is admin
    // then show admin fields
    if (
      (sessionData?.currentSession?.prevUser?.roles !== null &&
        sessionData?.currentSession?.prevUser?.roles.includes(adminRole)) ||
      (sessionData?.currentSession?.user?.roles !== null &&
        sessionData?.currentSession?.user?.roles.includes(adminRole))
    )
      setShowAdminFields(true);
  }, []);

  return (
    <Fragment>
      <form className={classes.root} onSubmit={handleDetailsSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h4'}>Contact Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id={'email'}
              label={'Email'}
              onChange={handleChangeEmail}
              value={email}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl component={'fieldset'} fullWidth>
              <FormLabel component={'legend'}>Allow promo contact</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={promoContact}
                    inputProps={{'role': 'switch', 'aria-label': 'promo contact opt in/out'}}
                    onChange={handleChangePromoContact}
                  />
                }
                label={promoContact ? 'Opt-in' : 'Opt-out'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id={'timezone'}
                label={'Timezone'}
                onChange={handleChangeTimeZone}
                select
                value={timezone}
                variant={'outlined'}
              >
                {TimezoneList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <br />
            <Typography variant={'h4'}>Personal Info</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id={'first-name'}
                label={'First Name'}
                onChange={handleChangeFirstName}
                required
                value={firstName}
                variant={'outlined'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id={'last-name'}
                label={'Last Name'}
                onChange={handleChangeLastName}
                required
                value={lastName}
                variant={'outlined'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'} fullWidth>
              <FormLabel component={'legend'}>Gender</FormLabel>
              <RadioGroup
                aria-label={'gender'}
                defaultValue={'female'}
                name={'radio-buttons-group'}
                onChange={handleChangeGender}
                row
              >
                {GenderList.map((option) => (
                  <FormControlLabel
                    checked={gender === parseInt(option.value, 10)}
                    control={<Radio />}
                    key={option.value}
                    label={ucwords(option.label)}
                    value={option.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {showAdminFields && (
            <Fragment>
              <Grid item xs={12}>
                <br />
                <Typography variant={'h4'}>Account Info</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl component={'fieldset'} fullWidth>
                  <FormLabel component={'legend'}>Activation status</FormLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={active}
                        inputProps={{'role': 'switch', 'aria-label': 'is activated'}}
                        onChange={handleChangeActive}
                      />
                    }
                    label={active ? 'Active' : 'Inactive'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <KeyboardDatePicker
                    InputAdornmentProps={{position: 'start'}}
                    KeyboardButtonProps={{
                      'aria-label': 'activation date',
                    }}
                    autoOk
                    format={'MM/dd/yyyy'}
                    inputVariant={'outlined'}
                    label={'Activation date'}
                    onChange={(date) => handleChangeActiveDate(date)}
                    value={activeDate}
                  />
                </FormControl>
              </Grid>
            </Fragment>
          )}
          <Grid className={classes.buttonWrapper} item>
            <Button type={'submit'} variant={'contained'}>
              Save Details
            </Button>
          </Grid>
        </Grid>
      </form>
      {showAdminFields && (
        <form className={classes.root} onSubmit={handleRolesSubmit}>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <br />
              <Typography variant={'h4'}>User Access Control</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component={'fieldset'} fullWidth>
                <FormLabel component={'legend'}>Is admin?</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isUserAdmin}
                      inputProps={{'role': 'switch', 'aria-label': 'promo contact opt in/out'}}
                      onChange={handleChangeisUserAdmin}
                    />
                  }
                  label={isUserAdmin ? 'Admin' : 'Not admin'}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.buttonWrapper} item>
              <Button type={'submit'} variant={'contained'}>
                Save Access
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Fragment>
  );
};

export default UserDetails;
