import {IsEmail, IsOptional, IsString, MinLength} from 'class-validator';

export class CreateNewUserData {
  @IsString()
  @MinLength(4)
  @IsEmail()
  public email = '';

  @IsString()
  @MinLength(1)
  @IsOptional()
  public fName = '';

  @IsString()
  @MinLength(1)
  @IsOptional()
  public lName = '';
}
