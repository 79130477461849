import React from 'react';

import {evidenceDetail_actions$data} from '../../graphql/__generated__/evidenceDetail_actions.graphql';

import {FolderTypeEnum, TFolder} from '../../services/evidences/data/types';
import ConfirmationDialog from '../dialogs/confirmation-dialog';

type Props = {
  evidence: evidenceDetail_actions$data;
  folder: Omit<TFolder, 'evidenceCount'> | undefined;
  open: boolean;
  onClose(): void;
  onConfirm(): void;
};

const EvidenceNodeDetailView: React.FC<Props> = ({evidence, folder, open, onClose, onConfirm}) => {
  if (!evidence || !folder) {
    return null;
  }

  const fileNames = evidence.map((e) => e?.fileName).join(', ');

  let confirmText, modalTitle, modalText;
  // if folder is not undefined, then we're removing from the folder
  switch (folder.type) {
    case FolderTypeEnum['USERFOLDER']:
      confirmText = 'Remove';
      modalTitle = 'Remove evidence from folder';
      modalText = `Are you sure that you want to remove Evidence: "${fileNames}" from Folder: ${folder.name}?`;
      break;
    case FolderTypeEnum['ALLFILESFOLDER']:
    case FolderTypeEnum['UNSORTEDFOLDER']:
      confirmText = 'Delete';
      modalTitle = 'Delete evidence from everywhere';
      modalText = `Are you sure that you want to permanently delete Evidence: "${fileNames}"`;
      break;
    default:
      // shouldn't be possible, do nothing
      break;
  }

  return (
    <ConfirmationDialog
      ariaDescription={`confirmation-dialog-desc-evidence-${fileNames}`}
      ariaTitle={`confirmation-dialog-title-evidence-${fileNames}`}
      confirmText={confirmText}
      denyText={'Cancel'}
      onConfirm={onConfirm}
      open={open}
      setOpen={onClose}
      title={modalTitle}
    >
      {modalText}
    </ConfirmationDialog>
  );
};

export default EvidenceNodeDetailView;
