/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountRelationshipType = "OWNER" | "%future added value";
export type RespondPasswordAuthChallengeInput = {
    token: string;
    proof: unknown;
    clientMutationId?: string | null | undefined;
};
export type loginFormControllerRespondPasswordMutationVariables = {
    input: RespondPasswordAuthChallengeInput;
};
export type loginFormControllerRespondPasswordMutationResponse = {
    readonly respondPasswordAuthChallenge: {
        readonly result: {
            readonly session?: {
                readonly user: {
                    readonly " $fragmentRefs": FragmentRefs<"headerNav_userRoles">;
                };
            } | undefined;
            readonly options?: ReadonlyArray<{
                readonly accounts?: ReadonlyArray<{
                    readonly account: {
                        readonly id: string;
                        readonly name: string;
                    };
                    readonly type: AccountRelationshipType;
                }> | undefined;
            } | null> | null | undefined;
        };
    } | null;
};
export type loginFormControllerRespondPasswordMutation = {
    readonly response: loginFormControllerRespondPasswordMutationResponse;
    readonly variables: loginFormControllerRespondPasswordMutationVariables;
};



/*
mutation loginFormControllerRespondPasswordMutation(
  $input: RespondPasswordAuthChallengeInput!
) {
  respondPasswordAuthChallenge(input: $input) {
    result {
      __typename
      ... on AuthOK {
        session {
          user {
            ...headerNav_userRoles
            id
          }
        }
      }
      ... on AuthNext {
        options {
          __typename
          ... on SelectAccountAuthChallenge {
            accounts {
              account {
                id
                name
              }
              type
            }
          }
        }
      }
    }
  }
}

fragment headerNav_userRoles on User {
  id
  roles
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountRelationship",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SelectAccountAuthChallenge",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loginFormControllerRespondPasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RespondPasswordAuthChallengePayload",
        "kind": "LinkedField",
        "name": "respondPasswordAuthChallenge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Session",
                    "kind": "LinkedField",
                    "name": "session",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "headerNav_userRoles"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "AuthOK",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "options",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "AuthNext",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginFormControllerRespondPasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RespondPasswordAuthChallengePayload",
        "kind": "LinkedField",
        "name": "respondPasswordAuthChallenge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Session",
                    "kind": "LinkedField",
                    "name": "session",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "roles",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "AuthOK",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "options",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "AuthNext",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c64102d8ff8b0310f3d240816d6a6b27",
    "id": null,
    "metadata": {},
    "name": "loginFormControllerRespondPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation loginFormControllerRespondPasswordMutation(\n  $input: RespondPasswordAuthChallengeInput!\n) {\n  respondPasswordAuthChallenge(input: $input) {\n    result {\n      __typename\n      ... on AuthOK {\n        session {\n          user {\n            ...headerNav_userRoles\n            id\n          }\n        }\n      }\n      ... on AuthNext {\n        options {\n          __typename\n          ... on SelectAccountAuthChallenge {\n            accounts {\n              account {\n                id\n                name\n              }\n              type\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment headerNav_userRoles on User {\n  id\n  roles\n}\n"
  }
};
})();
(node as any).hash = '3bc8c30cb695a28e0b4bc502ce6a5ee2';
export default node;
