import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  ConsolidateTagsInput,
  consolidateTagsMutation,
} from '../../graphql/__generated__/consolidateTagsMutation.graphql';

const mutation = graphql`
  mutation consolidateTagsMutation($input: ConsolidateTagsInput!) {
    consolidateTags(input: $input) {
      evidenceNodes {
        node {
          id
          name
        }
      }
    }
  }
`;

function useConsolidateTags(): [
  (sourceTagIds: number[], targetTagText: string, callback: () => void) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<consolidateTagsMutation>(mutation);
  return [
    useCallback(
      (sourceTagIds, targetTagText, callback) => {
        const input: ConsolidateTagsInput = {
          sourceTagIds,
          targetTagText,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted(response) {
            if (response.consolidateTags?.evidenceNodes !== undefined) {
              callback();
            }
          },
          onError: (e) => {
            console.error(e);
          },
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useConsolidateTags;
