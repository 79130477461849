import React, {Fragment, ReactElement} from 'react';

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {deepOrange} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import LabelIcon from '@material-ui/icons/Label';
import clsx from 'clsx';
import {graphql, useFragment} from 'react-relay';

import {evidenceNav_tagsNav$key} from '../../../graphql/__generated__/evidenceNav_tagsNav.graphql';

const useStyles = makeStyles({
  listItemIconRoot: {minWidth: 32},
  listItemText: {wordBreak: 'break-all'},
  currentTagIcon: {
    color: deepOrange[500],
  },
  currentTagText: {
    fontWeight: 700,
  },
  deleteHoverFocus: {
    '&:hover, &.Mui-focusVisible': {color: 'black'},
  },
});

type Props = {
  folder: evidenceNav_tagsNav$key;
  currentFolderId: string;
  currentTagFilterIds: number[];
  setCurrentTagFilterIds(ids: number[]): void;
};

const EvidenceNavTags = ({
  folder,
  currentFolderId,
  currentTagFilterIds,
  setCurrentTagFilterIds,
}: Props): ReactElement => {
  const classes = useStyles();

  const data = useFragment(
    graphql`
      fragment evidenceNav_tagsNav on Folder {
        id
        tags {
          id
          tagId
          text
          evidenceCount
        }
      }
    `,
    folder,
  );

  const folderTagsWithCounts = data?.tags.map((t) => ({
    id: t.id,
    tagId: t.tagId,
    text: t.text,
    evidenceCount: t.evidenceCount ?? 0,
    current: currentTagFilterIds?.includes(Number(t.tagId)),
  }));
  const sortedTags = folderTagsWithCounts?.sort((a, b) => {
    if (typeof a.current === 'undefined' || typeof b.current === 'undefined') {
      return 0;
    } else {
      return Number(b.current) - Number(a.current);
    }
  });

  const handleAddTagFilter = (id: number) => {
    let currentIds = [] as Props['currentTagFilterIds'];
    if (currentTagFilterIds.length > 0) {
      currentIds = [...new Set([...currentTagFilterIds, id])];
    } else {
      currentIds = [id];
    }

    setCurrentTagFilterIds(currentIds);
  };

  const handleRemoveTagFilter = (id: number) => {
    const currentIds = currentTagFilterIds?.filter((tfid) => tfid !== id);
    setCurrentTagFilterIds(currentIds);
  };

  const [lastCurrentTagFilterId] =
    currentTagFilterIds.length > 0 ? currentTagFilterIds.slice(-1) : [];

  return (
    <Fragment key={currentFolderId}>
      <Typography variant={'h5'}>Tags</Typography>
      {sortedTags?.length === 0 && (
        <ListItem>
          <ListItemText primary={'No tags in this folder'} />
        </ListItem>
      )}
      <List dense>
        {sortedTags?.map(({id, tagId, text, evidenceCount, current}) => (
          <Fragment key={`${currentFolderId}-${id}`}>
            <ListItem button onClick={() => handleAddTagFilter(tagId)} selected={current}>
              <ListItemIcon
                className={clsx(current && classes.currentTagIcon)}
                classes={{root: classes.listItemIconRoot}}
              >
                <LabelIcon fontSize={'small'} />
              </ListItemIcon>
              <ListItemText
                classes={current ? {primary: classes.currentTagText} : undefined}
                primary={`${text} [${evidenceCount}]`}
              />
              {current && (
                <ListItemSecondaryAction>
                  <Tooltip placement={'top-end'} title={`Remove filter: ${text}`}>
                    <IconButton
                      aria-label={'remove filter'}
                      className={classes.deleteHoverFocus}
                      edge={'end'}
                      onClick={() => handleRemoveTagFilter(tagId)}
                    >
                      <CancelIcon fontSize={'small'} />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {current && lastCurrentTagFilterId !== Number(id) && <Divider />}
          </Fragment>
        ))}
      </List>
    </Fragment>
  );
};

export default EvidenceNavTags;
