import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';

export interface OptionType {
  inputValue?: string;
  title: string;
}

type AutocompleteProps = {
  options: OptionType[];
  label: string;
  value: OptionType | null;
  setValue: React.Dispatch<React.SetStateAction<OptionType | null>>;
};

const filter = createFilterOptions<OptionType>();

export default function AutocompleteFreeSoloCreateOption({
  options,
  label,
  value,
  setValue,
}: AutocompleteProps): JSX.Element {
  const id = label.replace(/\W+/g, '-').toLowerCase();

  return (
    <Autocomplete
      clearOnBlur
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      freeSolo
      fullWidth
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      handleHomeEndKeys
      id={id}
      onChange={(_event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} variant={'outlined'} />}
      renderOption={(option) => option.title}
      selectOnFocus
      size={'small'}
      value={value}
    />
  );
}
