import React, {Fragment} from 'react';

import {Tooltip} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {getDaysInMonth} from 'date-fns';
import {useInView} from 'react-intersection-observer';
import {connect, useDispatch} from 'react-redux';

import {setEvidenceVisibilityStatus, setOpenedEvidenceNode} from '../../../state/timeline/actions';
import {TTimelineEvidence, TWorkspaceSortKey} from '../../../state/timeline/state';

const useStyles = makeStyles<Theme, {x: number; y: number}>((theme) => ({
  evidenceDot: {
    'position': 'absolute',
    'background': theme.palette.common.white,
    'borderRadius': '50%',
    'zIndex': 2,
    'top': ({y}) => `${y}%`,
    'left': ({x}) => `${x}%`,
    'height': theme.spacing(0.75),
    'width': theme.spacing(0.75),
    'translate': '-50% -50%',
    'transition-duration': '0.2s',
    '&:hover': {
      scale: 1.5,
    },
  },
}));

type Props = {
  evidence: TTimelineEvidence;
  sortKey: TWorkspaceSortKey;
  trackVisibility: boolean;
  connectionTarget?: boolean;
  // setDotVisibilityStatus(args: Record<string, boolean>): void;
  setOpenedEvidenceNode(evidenceNode: string | undefined): void;
};

const EvidenceNodeDotComponent: React.FC<Props> = ({
  evidence,
  sortKey,
  trackVisibility,
  connectionTarget,
  // setDotVisibilityStatus,
  setOpenedEvidenceNode,
}) => {
  const dispatch = useDispatch();
  const sortDate = new Date(evidence[sortKey]),
    x = (sortDate.getDate() / getDaysInMonth(sortDate)) * 100,
    y = (sortDate.getHours() / 24) * 100;

  const classes = useStyles({x, y});

  const {ref, inView} = useInView({
    root: document.getElementById('timeline-canvas') || undefined,
    threshold: 0,
    onChange: () =>
      dispatch(setEvidenceVisibilityStatus({evidenceId: evidence.id, visible: inView})),
  });

  const handleClick = connectionTarget
    ? () => {
        setOpenedEvidenceNode(evidence.id);
      }
    : undefined;

  const dot = (
    <Tooltip title={`Entity: ${evidence.fileName}`}>
      <div
        className={clsx(classes.evidenceDot)}
        data-evidence-id={evidence.id}
        key={evidence.id}
        onClick={handleClick}
        ref={trackVisibility ? ref : null}
      />
    </Tooltip>
  );

  return <Fragment>{dot}</Fragment>;
};

const mapDispatchToProps = {
  setOpenedEvidenceNode,
  setEvidenceVisibilityStatus,
};

const EvidenceNodeDot = connect(undefined, mapDispatchToProps)(EvidenceNodeDotComponent);

export default EvidenceNodeDot;
