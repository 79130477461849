/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateUserInput = {
    id: string;
    email?: string | null | undefined;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    userName?: string | null | undefined;
    gender?: number | null | undefined;
    timezone?: string | null | undefined;
    active?: boolean | null | undefined;
    activeDate?: Date | null | undefined;
    promoContact?: boolean | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type userDetailsUpdateUserMutationVariables = {
    input: UpdateUserInput;
};
export type userDetailsUpdateUserMutationResponse = {
    readonly updateUser: {
        readonly clientMutationId: string | null;
        readonly user: {
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly email: string;
            readonly gender: number | null;
            readonly timezone: string | null;
            readonly active: boolean | null;
            readonly activeDate: Date | null;
            readonly promoContact: boolean | null;
        };
    } | null;
};
export type userDetailsUpdateUserMutation = {
    readonly response: userDetailsUpdateUserMutationResponse;
    readonly variables: userDetailsUpdateUserMutationVariables;
};



/*
mutation userDetailsUpdateUserMutation(
  $input: UpdateUserInput!
) {
  updateUser(input: $input) {
    clientMutationId
    user {
      firstName
      lastName
      email
      gender
      timezone
      active
      activeDate
      promoContact
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gender",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "promoContact",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userDetailsUpdateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userDetailsUpdateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a23fce83923e2c4f9c78167448bec829",
    "id": null,
    "metadata": {},
    "name": "userDetailsUpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation userDetailsUpdateUserMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    clientMutationId\n    user {\n      firstName\n      lastName\n      email\n      gender\n      timezone\n      active\n      activeDate\n      promoContact\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd496b583804d41b50fea0f9ee8a75dde';
export default node;
