import {useCallback} from 'react';

import {useDispatch} from 'react-redux';
import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {LogoutInput, logoutMutation} from '../../graphql/__generated__/logoutMutation.graphql';

import {setApiFeedback} from '../../state/common/actions';

const mutation = graphql`
  mutation logoutMutation($input: LogoutInput!) {
    logout(input: $input) {
      clientMutationId
    }
  }
`;

function useLogout(): [(callback: () => void) => Disposable] {
  const dispatch = useDispatch();
  const [commit] = useMutation<logoutMutation>(mutation);
  return [
    useCallback(
      (callback) => {
        const input: LogoutInput = {
          clientMutationId: 'dummyId',
        };
        return commit({
          variables: {
            input,
          },
          onCompleted(response) {
            if (response.logout === null) {
              dispatch(setApiFeedback({message: 'Log out request failed'}));
              console.error(new Error('no response from mutation'));
              return;
            }
            callback();
          },
          onError: (e) => {
            dispatch(setApiFeedback({message: 'Log out request failed'}));
            console.error(e);
          },
        });
      },
      [commit],
    ),
  ];
}

export default useLogout;
