import React, {ReactElement} from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {ValidationError} from 'class-validator';
import {Link as RouterLink} from 'react-router-dom';

import {LOGIN} from '../../../config/routes';

import {hasClassValidationError as hasError} from '../../../utils/utils';

import {ChangeData} from './AccountRecoveryController';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    flexDirection: 'row-reverse',
  },
  bold: {
    fontWeight: 'bold',
  },
  inputs: {
    marginBottom: theme.spacing(2),
  },
  headerRoot: {
    paddingTop: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  action: {
    order: -1,
  },
  subtitle: {
    color: '#626262',
  },
  captcha: {
    textAlign: 'center',
  },
}));

type Props = {
  email: string;
  token: string | null;
  errors: ValidationError[];
  onChange(data: ChangeData): void;
  onSend(): void;
  onVerifyCaptcha(token: string): void;
  onCaptchaExpire(): void;
  onCaptchaError(err: string): void;
};

const AccountRecoveryView = ({
  email,
  token,
  errors,
  onVerifyCaptcha,
  onCaptchaExpire,
  onCaptchaError,
  onChange,
  onSend,
}: Props): ReactElement => {
  const classes = useStyles();

  const disableButton =
    process.env.NODE_ENV === 'development'
      ? false
      : errors.length !== 0 || token === null || email.length === 0;

  return (
    <Card id={'password-recovery-form'}>
      <CardHeader
        action={
          <IconButton aria-label={'back-to-login'} component={RouterLink} to={LOGIN}>
            <ArrowBackIcon />
          </IconButton>
        }
        classes={{
          root: classes.headerRoot,
          action: classes.action,
        }}
      />
      <CardContent onKeyUp={(event) => event.key === 'Enter' && onSend()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography classes={{subtitle1: classes.subtitle}} paragraph variant={'subtitle1'}>
              Enter your email to recover password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              classes={{root: classes.inputs}}
              color={'secondary'}
              error={hasError(errors, 'email')}
              fullWidth
              helperText={hasError(errors, 'email') && 'Please enter a valid email and try again'}
              label={'Email'}
              onChange={(event) =>
                onChange({
                  property: 'email',
                  email: event.currentTarget.value,
                })
              }
              value={email}
              variant={'outlined'}
            />
          </Grid>
          <Grid className={classes.captcha} item xs={12}>
            {
              // TODO - Finalize captcha
            }
            {/* Testing requires either using the test api key or configuring your local machine
                as follows: https://docs.hcaptcha.com/#local-development

                Ours: 5e21085c-b2d1-4963-859d-22f98ef0f288
                Test: 10000000-ffff-ffff-ffff-000000000001 */}
            <HCaptcha
              onError={onCaptchaError}
              onExpire={onCaptchaExpire}
              onVerify={onVerifyCaptcha}
              sitekey={'5e21085c-b2d1-4963-859d-22f98ef0f288'}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          classes={{label: classes.bold}}
          color={'primary'}
          disabled={disableButton}
          fullWidth
          onClick={onSend}
          variant={'contained'}
        >
          Request reset link
        </Button>
      </CardActions>
    </Card>
  );
};

export default AccountRecoveryView;
