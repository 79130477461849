import * as crypto from 'crypto';

import {createClientAuth, createServerAuth} from './crypto';
import type {
  ClientAuth,
  H,
  HMAC,
  Hi,
  RSAEncrypt,
  RandomBytes,
  SafeCompare,
  ServerAuth,
} from './crypto';

const randomBytes: RandomBytes = (n) =>
  new Promise((res, rej) => {
    crypto.randomBytes(n, (err, buf) => {
      if (err) {
        rej(err);
      } else {
        res(buf);
      }
    });
  });

const h: H = (data) => {
  const hash = crypto.createHash('sha256');
  hash.update(new Uint8Array(data));
  return Promise.resolve(hash.digest());
};

const hmac: HMAC = (key, data) => {
  const hmac = crypto.createHmac('sha256', new Uint8Array(key));
  hmac.update(new Uint8Array(data));
  return Promise.resolve(hmac.digest());
};

const hi: Hi = (data, salt, iterations) =>
  new Promise((res, rej) => {
    crypto.pbkdf2(
      new Uint8Array(data),
      new Uint8Array(salt),
      iterations,
      32,
      'sha256',
      (err, k) => {
        if (err) {
          rej(err);
        } else {
          res(k);
        }
      },
    );
  });

const rsaEncrypt: RSAEncrypt = async (key, data) =>
  crypto.publicEncrypt({key, oaepHash: 'sha256'}, data);

const safeCompare: SafeCompare = (a, b) =>
  crypto.timingSafeEqual(new Uint8Array(a), new Uint8Array(b));

export const serverAuth: ServerAuth = createServerAuth({
  h,
  hmac,
  safeCompare,
});

export const clientAuth: ClientAuth = createClientAuth({
  randomBytes,
  h,
  hmac,
  hi,
  rsaEncrypt,
});
