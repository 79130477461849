import React, {useEffect, useState} from 'react';

import {minLength} from 'class-validator';

import LoadingScreen from '../../../../components/feedback/loading-screen';

import {TWorkspace} from '../../../../state/timeline/state';

import CloneWorkspaceView from './clone-workspace.view';
import useCloneWorkspace from './use-clone-workspace.hook';

type Props = {
  workspaceToBeCloned: TWorkspace | undefined;
  isOpen: boolean;
  onClose(): void;
};

const CloneWorkspaceController: React.FC<Props> = ({workspaceToBeCloned, isOpen, onClose}) => {
  const [clonedWorkspaceName, setClonedWorkspaceName] = useState('');
  const [error, setError] = useState(false);
  const [cloneWorkspace, loading] = useCloneWorkspace();

  useEffect(() => {
    if (workspaceToBeCloned?.name) {
      setClonedWorkspaceName(`Clone of ${workspaceToBeCloned?.name}`);
    }
  }, [workspaceToBeCloned]);

  const handleCloneWorkspace = async () => {
    if (!minLength(clonedWorkspaceName, 1)) {
      setError(true);
      return;
    }

    const callback = () => {
      onClose();
    };

    if (workspaceToBeCloned) {
      cloneWorkspace(workspaceToBeCloned, clonedWorkspaceName, callback);
    }
  };

  if (loading) return <LoadingScreen />;

  return (
    <CloneWorkspaceView
      clonedWorkspaceName={clonedWorkspaceName}
      error={error}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleCloneWorkspace}
      setClonedWorkspaceName={setClonedWorkspaceName}
    />
  );
};

export default CloneWorkspaceController;
