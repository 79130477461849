/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetail_info = ReadonlyArray<{
    readonly id: string;
    readonly folders: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly " $refType": "evidenceDetail_info";
}>;
export type evidenceDetail_info$data = evidenceDetail_info;
export type evidenceDetail_info$key = ReadonlyArray<{
    readonly " $data"?: evidenceDetail_info$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_info">;
}>;



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "evidenceDetail_info",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFolder",
      "kind": "LinkedField",
      "name": "folders",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Evidence",
  "abstractKey": "__isEvidence"
};
})();
(node as any).hash = 'f51086b9fe3c37fa3fd7aa8fc7993d9f';
export default node;
