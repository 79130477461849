import React, {ReactElement, useEffect, useState} from 'react';

import DetailsView from './details.view';

const DetailsController = (): ReactElement => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    // TODO - Retrieve the below with real graphQL queries
    setUsername('sample-username-1');
    setEmail('sample@mail.com');
  }, []);

  return <DetailsView email={email} username={username} />;
};

export default DetailsController;
