import {connect} from 'react-redux';

import {addWorkspace, setWorkspaceTags} from '../../../../state/timeline/actions';

import CreateWorkspaceModalController from './create-workspace-modal.controller';

const mapStateToProps = undefined;

const mapDispatchToProps = {addWorkspace, setWorkspaceTags};

const CreateWorkspaceModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateWorkspaceModalController);

export default CreateWorkspaceModal;
