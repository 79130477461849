/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type timelineBaseQueryVariables = {};
export type timelineBaseQueryResponse = {
    readonly currentSession: {
        readonly account?: {
            readonly workspaces: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly modified: Date;
                readonly filters: {
                    readonly folders: ReadonlyArray<string>;
                    readonly tags: ReadonlyArray<number>;
                    readonly types: ReadonlyArray<number>;
                };
                readonly tags: ReadonlyArray<{
                    readonly tagId: number;
                    readonly text: string;
                    readonly coordinates: {
                        readonly x: number;
                        readonly y: number;
                    };
                    readonly color: string;
                    readonly lockHighlight: boolean;
                }>;
                readonly options: ReadonlyArray<{
                    readonly optionId: number;
                    readonly value: number | null;
                }>;
                readonly coordinates: {
                    readonly x: number;
                    readonly y: number;
                };
                readonly zoomIndex: number;
            }>;
            readonly evidences: ReadonlyArray<{
                readonly id: string;
                readonly fileName: string;
                readonly storedOn: Date;
                readonly eventDate: Date;
                readonly size: number;
                readonly tags: ReadonlyArray<{
                    readonly id: string;
                }>;
                readonly folders: ReadonlyArray<{
                    readonly id: string;
                }>;
                readonly type: string;
                readonly mimeType?: string | undefined;
            }>;
            readonly folders: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
            }>;
            readonly tags: ReadonlyArray<{
                readonly id: string;
                readonly text: string;
            }>;
        } | null | undefined;
    } | null;
};
export type timelineBaseQuery = {
    readonly response: timelineBaseQueryResponse;
    readonly variables: timelineBaseQueryVariables;
};



/*
query timelineBaseQuery {
  currentSession {
    __typename
    ... on Session {
      account {
        workspaces {
          id
          name
          modified
          filters {
            folders: folderIds
            tags: tagIds
            types: typeIds
          }
          tags {
            tagId
            text
            coordinates {
              x
              y
            }
            color
            lockHighlight
            id
          }
          options {
            optionId
            value
          }
          coordinates {
            x
            y
          }
          zoomIndex
        }
        evidences {
          __typename
          id
          fileName: name
          storedOn: created
          eventDate
          size
          tags: tagsWithIds {
            id
          }
          folders {
            id
          }
          ... on File {
            mimeType
          }
          type: __typename
        }
        folders: userFolders {
          id
          name
        }
        tags: activeTagsWithIds {
          id
          text
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "WorkspaceFilters",
  "kind": "LinkedField",
  "name": "filters",
  "plural": false,
  "selections": [
    {
      "alias": "folders",
      "args": null,
      "kind": "ScalarField",
      "name": "folderIds",
      "storageKey": null
    },
    {
      "alias": "tags",
      "args": null,
      "kind": "ScalarField",
      "name": "tagIds",
      "storageKey": null
    },
    {
      "alias": "types",
      "args": null,
      "kind": "ScalarField",
      "name": "typeIds",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tagId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Coordinates",
  "kind": "LinkedField",
  "name": "coordinates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "x",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "y",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lockHighlight",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Options",
  "kind": "LinkedField",
  "name": "options",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zoomIndex",
  "storageKey": null
},
v11 = {
  "alias": "fileName",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": "storedOn",
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v15 = [
  (v0/*: any*/)
],
v16 = {
  "alias": "tags",
  "args": null,
  "concreteType": "TagWithId",
  "kind": "LinkedField",
  "name": "tagsWithIds",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "UserFolder",
  "kind": "LinkedField",
  "name": "folders",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mimeType",
      "storageKey": null
    }
  ],
  "type": "File",
  "abstractKey": null
},
v20 = {
  "alias": "folders",
  "args": null,
  "concreteType": "UserFolder",
  "kind": "LinkedField",
  "name": "userFolders",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": "tags",
  "args": null,
  "concreteType": "TagWithId",
  "kind": "LinkedField",
  "name": "activeTagsWithIds",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "timelineBaseQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Workspace",
                    "kind": "LinkedField",
                    "name": "workspaces",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkspaceTag",
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v6/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "evidences",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "timelineBaseQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          (v22/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Workspace",
                    "kind": "LinkedField",
                    "name": "workspaces",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkspaceTag",
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v6/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "evidences",
                    "plural": true,
                    "selections": [
                      (v22/*: any*/),
                      (v0/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b4155a25a119e186f2458c057f189a3",
    "id": null,
    "metadata": {},
    "name": "timelineBaseQuery",
    "operationKind": "query",
    "text": "query timelineBaseQuery {\n  currentSession {\n    __typename\n    ... on Session {\n      account {\n        workspaces {\n          id\n          name\n          modified\n          filters {\n            folders: folderIds\n            tags: tagIds\n            types: typeIds\n          }\n          tags {\n            tagId\n            text\n            coordinates {\n              x\n              y\n            }\n            color\n            lockHighlight\n            id\n          }\n          options {\n            optionId\n            value\n          }\n          coordinates {\n            x\n            y\n          }\n          zoomIndex\n        }\n        evidences {\n          __typename\n          id\n          fileName: name\n          storedOn: created\n          eventDate\n          size\n          tags: tagsWithIds {\n            id\n          }\n          folders {\n            id\n          }\n          ... on File {\n            mimeType\n          }\n          type: __typename\n        }\n        folders: userFolders {\n          id\n          name\n        }\n        tags: activeTagsWithIds {\n          id\n          text\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0f2636cf85377203774453bd8f3c8c0e';
export default node;
