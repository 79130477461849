import React, {ReactElement, SyntheticEvent, useRef} from 'react';

import {Badge, Chip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {KeyboardArrowDown} from '@material-ui/icons';
import {DefaultTheme} from '@material-ui/styles';
import clsx from 'clsx';
import Draggable, {DraggableEventHandler} from 'react-draggable';

import theme from '../../../config/theme';

import {colorValueToCss} from '../../../services/timeline/utils/utils';

import {TWorkspaceTag} from '../../../state/timeline/state';

const FONT_SIZE_CALC_MIN = 3;
const FONT_SIZE_CALC_MAX = 11;

type StyleProps = {
  showEvidenceCounts: boolean;
  evidenceCount: number;
  backgroundColor: string;
};

const useStyles = makeStyles<DefaultTheme, StyleProps>({
  badgeRoot: {
    zIndex: 3,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  chipRoot: {
    'position': ({showEvidenceCounts}) => (showEvidenceCounts ? 'relative' : 'absolute'),
    'zIndex': ({showEvidenceCounts}) => (showEvidenceCounts ? 'auto' : 3),
    'top': ({showEvidenceCounts}) => (showEvidenceCounts ? 'auto' : 0),
    'left': ({showEvidenceCounts}) => (showEvidenceCounts ? 'auto' : 0),
    'backgroundColor': ({backgroundColor}) => backgroundColor,
    'color': ({backgroundColor}) => theme.palette.getContrastText(colorValueToCss(backgroundColor)),
    'fontSize': ({evidenceCount}) =>
      `calc(((${Math.max(
        FONT_SIZE_CALC_MIN,
        Math.min(evidenceCount, FONT_SIZE_CALC_MAX),
      )} * 2) + 6) / 16 * 1rem)`,
    'padding': ({evidenceCount}) =>
      `calc((${Math.max(
        FONT_SIZE_CALC_MIN,
        Math.min(evidenceCount, FONT_SIZE_CALC_MAX),
      )} + 9) / 128 * 1rem)`,
    'height': 'auto',
    '&.MuiChip-deletable:focus': {
      backgroundColor: ({backgroundColor}) => backgroundColor,
    },
  },
  highlighted: {
    boxShadow: ({backgroundColor}) => `0 0 5px 1px ${colorValueToCss(backgroundColor)}`,
  },
  contextMenuIcon: {
    color: ({backgroundColor}) => theme.palette.getContrastText(colorValueToCss(backgroundColor)),
  },
});

type Props = {
  tag: TWorkspaceTag;
  evidenceCount: number;
  showEvidenceCounts: boolean;
  isDragging: boolean;
  isVisible: boolean;
  selectedWorkspaceId: string | undefined;
  onContextMenuOpen(event: SyntheticEvent): void;
  onDragStart: DraggableEventHandler;
  onDragStop: DraggableEventHandler;
};

const TimelineTagNodeView: React.FC<Props> = ({
  tag,
  evidenceCount,
  showEvidenceCounts,
  isDragging,
  isVisible,
  selectedWorkspaceId,
  onContextMenuOpen,
  onDragStart,
  onDragStop,
}) => {
  const {
    color,
    coordinates: {x, y},
    text,
    tagId,
    lockHighlight,
  } = tag;
  const backgroundColor = isVisible ? colorValueToCss(color) : 'rgba(0,0,0,.5)';
  const classes = useStyles({showEvidenceCounts, evidenceCount, backgroundColor});
  const ref = useRef(null);

  let TagChip: ReactElement;
  if (showEvidenceCounts) {
    TagChip = (
      <Badge
        badgeContent={evidenceCount}
        className={classes.badgeRoot}
        color={'primary'}
        max={9999}
        ref={ref}
      >
        <Chip
          className={clsx(classes.chipRoot, lockHighlight && classes.highlighted, {
            [`tag-${tagId}`]: !isDragging,
          })}
          classes={{deleteIcon: classes.contextMenuIcon}}
          deleteIcon={<KeyboardArrowDown />}
          label={text}
          onDelete={onContextMenuOpen}
          size={'small'}
        />
      </Badge>
    );
  } else {
    TagChip = (
      <Chip
        className={clsx(classes.chipRoot, lockHighlight && classes.highlighted, {
          [`tag-${tagId}`]: !isDragging,
        })}
        classes={{deleteIcon: classes.contextMenuIcon}}
        deleteIcon={<KeyboardArrowDown />}
        label={text}
        onDelete={onContextMenuOpen}
        ref={ref}
        size={'small'}
      />
    );
  }

  return (
    <Draggable
      bounds={'parent'}
      defaultPosition={{x, y}}
      key={`tag-node-${selectedWorkspaceId}-${tagId}`}
      nodeRef={ref}
      onStart={onDragStart}
      onStop={onDragStop}
    >
      {TagChip}
    </Draggable>
  );
};

export default TimelineTagNodeView;
