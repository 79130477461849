import React, {FormEvent, useState} from 'react';

import useUploadFiles from '../../../../hooks/files/upload-files.hook';

import {uniqueIdGenerator} from '../../../../utils/utils';

import EvidenceNavUploadFilesModalView from './evidence-nav.upload-files.modal.view';

export type FileUploadStatus = 'Pending' | 'Success' | 'Failure';

export type FileData = {
  id: string;
  name: string;
  size: number;
  time: Date;
  url: string | undefined;
  status: FileUploadStatus;
};

type Props = {
  open: boolean;
  onClose(): void;
};

const EvidenceNavUploadFilesModal: React.FC<Props> = ({open, onClose}) => {
  const [stagedFiles, setStagedFiles] = useState<FileData[]>([]);
  const [uploadFiles] = useUploadFiles();

  const handleFileSelect = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const fileInput = document.getElementById('file-input-field');
    const files = Array.from((fileInput as HTMLInputElement)?.files ?? new FileList());
    const fileData: FileData[] = files.map((file) => ({
      id: uniqueIdGenerator(),
      name: file.name,
      size: file.size,
      time: new Date(),
      url: undefined,
      status: 'Pending',
    }));

    setStagedFiles(fileData.concat(stagedFiles));

    uploadFiles(files, fileData);
  };

  return (
    <EvidenceNavUploadFilesModalView
      files={stagedFiles}
      handleFileSelect={handleFileSelect}
      onClose={onClose}
      open={open}
    />
  );
};

export default EvidenceNavUploadFilesModal;
