import React, {ReactElement} from 'react';

import {TTimelineEvidence} from '../../../state/timeline/state';

import EvidenceNodeDetailView from './evidence-node-detail.view';

type Props = {
  openedEvidenceNode: TTimelineEvidence | undefined;
  setOpenedEvidenceNode(evidenceNode: string | undefined): void;
  setSelectedEvidenceIds(ids: string[]): void;
};

const EvidenceNodeDetailController = ({
  openedEvidenceNode,
  setOpenedEvidenceNode,
  setSelectedEvidenceIds,
}: Props): ReactElement => {
  return (
    <EvidenceNodeDetailView
      evidence={openedEvidenceNode}
      onClose={() => setOpenedEvidenceNode(undefined)}
      setSelectedEvidenceIds={setSelectedEvidenceIds}
    />
  );
};

export default EvidenceNodeDetailController;
