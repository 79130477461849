import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {getIsLoading, getSelectedWorkspace} from '../../../state/timeline/selectors';

import TimelineCanvasController from './timeline-canvas.controller';

const mapStateToProps = (state: TRootState) => ({
  selectedWorkspace: getSelectedWorkspace(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {};

const TimelineCanvas = connect(mapStateToProps, mapDispatchToProps)(TimelineCanvasController);

export default TimelineCanvas;
