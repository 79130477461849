/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type downloadEvidenceQueryVariables = {
    ids: Array<string>;
};
export type downloadEvidenceQueryResponse = {
    readonly evidence: ReadonlyArray<{
        readonly id?: string | undefined;
        readonly size?: number | undefined;
        readonly eventDate?: Date | undefined;
        readonly fileName?: string | undefined;
        readonly type?: string | undefined;
        readonly folders?: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        }> | undefined;
        readonly mimeType?: string | undefined;
        readonly url?: string | undefined;
        readonly subject?: string | undefined;
        readonly body?: string | undefined;
    } | null>;
};
export type downloadEvidenceQuery = {
    readonly response: downloadEvidenceQueryResponse;
    readonly variables: downloadEvidenceQueryVariables;
};



/*
query downloadEvidenceQuery(
  $ids: [ID!]!
) {
  evidence: nodes(ids: $ids) {
    __typename
    ... on Evidence {
      __isEvidence: __typename
      id
      size
      eventDate
      fileName: name
      type: __typename
      ... on File {
        mimeType
        url
      }
      ... on JournalEntry {
        subject
        body
      }
      folders {
        id
        name
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventDate",
  "storageKey": null
},
v5 = {
  "alias": "fileName",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserFolder",
  "kind": "LinkedField",
  "name": "folders",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mimeType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "File",
  "abstractKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    }
  ],
  "type": "JournalEntry",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "downloadEvidenceQuery",
    "selections": [
      {
        "alias": "evidence",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Evidence",
            "abstractKey": "__isEvidence"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "downloadEvidenceQuery",
    "selections": [
      {
        "alias": "evidence",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Evidence",
            "abstractKey": "__isEvidence"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c65cc246a2549f1ef884051aa339c04",
    "id": null,
    "metadata": {},
    "name": "downloadEvidenceQuery",
    "operationKind": "query",
    "text": "query downloadEvidenceQuery(\n  $ids: [ID!]!\n) {\n  evidence: nodes(ids: $ids) {\n    __typename\n    ... on Evidence {\n      __isEvidence: __typename\n      id\n      size\n      eventDate\n      fileName: name\n      type: __typename\n      ... on File {\n        mimeType\n        url\n      }\n      ... on JournalEntry {\n        subject\n        body\n      }\n      folders {\n        id\n        name\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '34aca6c8af48bea8aefe1e1c277b77c9';
export default node;
