import React, {Fragment, useRef, useState} from 'react';
import type {ReactElement} from 'react';

import {Grid, Tooltip} from '@material-ui/core';
import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import {useLazyLoadQuery} from 'react-relay';
import {graphql} from 'relay-runtime';

import {UserListTableQuery} from '../../../graphql/__generated__/UserListTableQuery.graphql';

import CreateUserModal from '../modal-create-user';
import Table from './BaseTable';
import UserListTableRows from './UserListTableRows';
import type {RefetchRef} from './UserListTableRows';
import type {Filters} from './user-filter';
import UserFilter from './user-filter';

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#fafafa',
  },
  tableHeader: {
    'paddingLeft': 0,
    '& div': {
      '& h1': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  },
  tablePaper: {marginRight: '4px'},
  filterBtnContainer: {
    marginRight: theme.spacing(1),
  },
}));

const UserList = (): ReactElement => {
  const classes = useStyles();
  const ref = useRef<RefetchRef>(null);

  const data = useLazyLoadQuery<UserListTableQuery>(
    graphql`
      query UserListTableQuery {
        ...UserListTableRows_admin
      }
    `,
    {},
  );

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const refetchRows = (type?: Filters, value?: string): void => {
    if (ref.current) {
      ref.current.refetchData(type, value);
    }

    return;
  };

  return (
    <Fragment>
      <CreateUserModal
        handleClose={setIsCreateModalOpen}
        open={isCreateModalOpen}
        refetchData={refetchRows}
      />
      <Table
        columnHeaders={[
          {
            id: 'email',
            alignRight: false,

            label: 'Email',
          },
          {
            id: 'firstName',
            alignRight: false,

            label: 'First',
          },
          {
            id: 'lastName',
            alignRight: false,

            label: 'Last',
          },
          {
            id: 'created',
            alignRight: false,

            label: 'Created on',
          },
          {
            id: 'empty',
            alignRight: false,
            label: '',
          },
        ]}
        headerProps={{
          noBackground: true,
          paperClass: classes.tableHeader,
          paperElevation: 0,
          variant: 2,
        }}
        headingButtons={
          <Grid alignItems={'center'} container direction={'row'}>
            <Grid className={classes.filterBtnContainer} item>
              <UserFilter onSubmit={refetchRows} />
            </Grid>
            <Grid item>
              <IconButton
                aria-label={'refresh-table-data'}
                onClick={() => refetchRows()}
                style={{padding: '9px'}}
              >
                <Tooltip title={'Refresh data'}>
                  <RefreshIcon fontSize={'large'} />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label={'add-new-user'} onClick={() => setIsCreateModalOpen(true)}>
                <Tooltip title={'Add new user'}>
                  <PersonAddIcon fontSize={'large'} />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        }
        headingText={'User List'}
        paperElevation={0}
        paperProps={{className: classes.tablePaper}}
        stickyHeader
        toolbarClassName={classes.background}
      >
        <UserListTableRows admin={data} ref={ref} />
      </Table>
    </Fragment>
  );
};

export default UserList;
