import React, {Fragment, useEffect, useState} from 'react';
import type {ReactElement} from 'react';

import {DndContext, DragEndEvent, DragOverlay, DragStartEvent, pointerWithin} from '@dnd-kit/core';
import {Badge, makeStyles} from '@material-ui/core';
import {DragIndicator} from '@material-ui/icons';
import {useDispatch} from 'react-redux';
import {graphql, useFragment} from 'react-relay';

import LoadingScreen from '../../../components/feedback/loading-screen';

import {evidenceBase_dragOverlay$key} from '../../../graphql/__generated__/evidenceBase_dragOverlay.graphql';
import {evidenceBase_folderDropName$key} from '../../../graphql/__generated__/evidenceBase_folderDropName.graphql';
import {evidenceList_folderEvidenceFull$key} from '../../../graphql/__generated__/evidenceList_folderEvidenceFull.graphql';
import {evidenceMain_folderName$key} from '../../../graphql/__generated__/evidenceMain_folderName.graphql';
import {evidenceMain_tagFilter$key} from '../../../graphql/__generated__/evidenceMain_tagFilter.graphql';
import {evidenceNav_foldersNav$key} from '../../../graphql/__generated__/evidenceNav_foldersNav.graphql';
import {evidenceNav_tagsNav$key} from '../../../graphql/__generated__/evidenceNav_tagsNav.graphql';

import useAddEvidenceToFolder from '../../../hooks/mutations/add-evidence-to-folder.hook';

import {isNotNullish} from '../../../utils/utils';

import {setApiFeedback} from '../../../state/common/actions';

import {EvidenceIdAndName} from '../evidence-list/evidence-list.controller';
import EvidenceMain from '../evidence-main';
import EvidenceNav from '../evidence-nav';

const useStyles = makeStyles({
  folderLayout: {
    display: 'flex',
  },
  overlayDiv: {
    borderTop: '1px dashed #999',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-0.25rem',
    marginTop: '-1px',
    minWidth: '400px',
    paddingLeft: '0.25rem',
  },
  overlayRowDiv: {
    alignItems: 'center',
    background: '#ddd',
    border: '1px dashed #999',
    borderTop: 'none',
    display: 'flex',
    fontSize: '0.875rem',
    lineHeight: '2.1875rem',
    marginLeft: '-0.25rem',
    paddingLeft: '0.25rem',
    width: 'calc(100% + 0.25rem)',
  },
  dragHandle: {
    marginRight: '0.75rem',
  },
});

type Props = {
  account: evidenceNav_foldersNav$key | evidenceBase_folderDropName$key;
  folder:
    | evidenceBase_dragOverlay$key
    | evidenceMain_tagFilter$key
    | evidenceNav_tagsNav$key
    | evidenceMain_folderName$key
    | evidenceList_folderEvidenceFull$key;
  currentFolderId: string;
  unsortedFolderId: string;
  selectedEvidenceIds: string[];

  setSelectedEvidenceIds(ids: string[]): void;
};

export const EvidenceBaseView = ({
  account,
  folder,
  currentFolderId,
  unsortedFolderId,
  selectedEvidenceIds,
  setSelectedEvidenceIds,
}: Props): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [addEvidenceToFolder, loading] = useAddEvidenceToFolder();
  const [overlayEvidence, setOverlayEvidence] = useState<EvidenceIdAndName>([]);

  function handleDragStart(event: DragStartEvent) {
    const {active} = event;
    const activeIdString = active.id as string;
    const activeId = activeIdString.split('-')[1];
    if (!selectedEvidenceIds?.includes(activeId)) {
      setSelectedEvidenceIds([activeId]);
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    const overIdString = over?.id as string;
    if (over && over.data.current?.accepts.includes(active.data.current?.type)) {
      const folderId = overIdString.split('-')[1];
      const folderName = folderData?.userFolders?.find((f) => f.id === folderId)?.name ?? 'Unknown';
      if (selectedEvidenceIds && selectedEvidenceIds.length > 0) {
        const evidence = evidenceData?.evidences?.edges
          ?.map((e) => ({...e?.node}))
          ?.filter((fe) => isNotNullish(fe.id) && selectedEvidenceIds.includes(fe.id));
        if (evidence !== undefined) {
          handleAddEvidenceToFolder(folderId, folderName, evidence);
        }
      }
    }
  }

  const handleAddEvidenceToFolder = (
    targetFolderId: string,
    targetFolderName: string,
    evidence: EvidenceIdAndName,
  ) => {
    const evidenceIds = evidence?.map((e) => e.id ?? '').filter((e) => e.length > 0);

    if (evidenceIds !== undefined) {
      const evidenceFilenames = evidence?.map(({fileName}) => fileName).join(', ');
      const callback = () => {
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Evidence: ${evidenceFilenames} added to folder(s): ${targetFolderName}`,
          }),
        );
        // callback also must remove evidence from selectedEvidenceIds if we're in the unsortedFolder
        if (targetFolderId === unsortedFolderId) {
          setSelectedEvidenceIds(
            selectedEvidenceIds?.filter((id) => !evidenceIds.includes(id)) ?? [],
          );
        }
      };
      addEvidenceToFolder(currentFolderId, targetFolderId, evidenceIds, callback);
    }
  };

  const folderData = useFragment(
    graphql`
      fragment evidenceBase_folderDropName on Account {
        userFolders {
          id
          name
        }
      }
    `,
    account as evidenceBase_folderDropName$key,
  );

  const evidenceData = useFragment(
    graphql`
      fragment evidenceBase_dragOverlay on Folder {
        evidences {
          edges {
            node {
              id
              fileName: name
            }
          }
        }
      }
    `,
    folder as evidenceBase_dragOverlay$key,
  );

  useEffect(() => {
    const overlay = evidenceData?.evidences?.edges
      ?.map((e) => ({
        ...e?.node,
      }))
      ?.filter((e) => isNotNullish(e.id) && selectedEvidenceIds?.includes(e.id));
    setOverlayEvidence(overlay);
  }, [selectedEvidenceIds]);

  return (
    <Fragment>
      {loading && <LoadingScreen />}
      <DndContext
        collisionDetection={pointerWithin}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <div className={classes.folderLayout}>
          <EvidenceNav
            account={account as evidenceNav_foldersNav$key}
            folder={folder as evidenceNav_tagsNav$key}
          />
          <EvidenceMain folder={folder as evidenceMain_folderName$key} />
        </div>
        {overlayEvidence && (
          <DragOverlay>
            <Badge badgeContent={overlayEvidence?.length} color={'primary'}>
              <div className={classes.overlayDiv}>
                {overlayEvidence?.map(({id, fileName}) => (
                  <div className={classes.overlayRowDiv} key={`${currentFolderId}-${id}`}>
                    <DragIndicator className={classes.dragHandle} />
                    {fileName}
                  </div>
                ))}
              </div>
            </Badge>
          </DragOverlay>
        )}
      </DndContext>
    </Fragment>
  );
};

export default EvidenceBaseView;
