import React, {FormEvent, Fragment, ReactElement, useEffect, useRef, useState} from 'react';

import {Box, Button, Typography, makeStyles} from '@material-ui/core';
import {Update} from '@material-ui/icons';
import Alert, {Color} from '@material-ui/lab/Alert';
import {DataGridPro, GridColDef, GridRowData, GridValueFormatterParams} from '@mui/x-data-grid-pro';

import GenericDialog from '../../../../components/dialogs/generic-dialog';

import {customDateTimeString, humanFileSize} from '../../../../utils/utils';

import {FileData} from '.';

const useStyles = makeStyles({
  fileUploadInput: {
    display: 'none',
  },
  alert: {
    width: '100%',
  },
});

type Props = {
  open: boolean;
  files: FileData[];
  onClose(): void;
  handleFileSelect(event: FormEvent): void;
};

const EvidenceNavUploadFilesModalView = ({
  open,
  files,
  onClose,
  handleFileSelect,
}: Props): ReactElement => {
  const backgroundColor = '#ffff00';
  const classes = useStyles(backgroundColor);
  const [rows, setRows] = useState<GridRowData[]>([]);
  const fileInputField = useRef(null);

  const dialogTitle = (
    <Fragment>
      <Typography variant={'h4'}>Upload files form</Typography>
    </Fragment>
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
      minWidth: 200,
    },
    {
      field: 'time',
      headerName: 'Uploaded On',
      width: 140,
      editable: true,
      type: 'dateTime',
      valueFormatter: (params: GridValueFormatterParams) => {
        return customDateTimeString(params.value as string);
      },
    },
    {
      field: 'size',
      headerName: 'Size',
      width: 140,
      align: 'right',
      renderCell: (params) => {
        return humanFileSize(params.row.size);
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 140,
      width: 140,
      renderCell: (params) => {
        const status = params.row.status;
        let severity = 'info' as Color;
        switch (status) {
          case 'Success':
            severity = 'success';
            break;
          case 'Failure':
            severity = 'error';
            break;
          default:
            break;
        }
        return (
          <Alert
            className={classes.alert}
            iconMapping={{info: <Update fontSize={'inherit'} />}}
            severity={severity}
            variant={'filled'}
          >
            {status}
          </Alert>
        );
      },
    },
  ];

  useEffect(() => {
    if (files !== undefined) {
      setRows(files);
    }
  }, [files]);

  return (
    <GenericDialog
      ariaDescription={'evidence-upload-files-desc'}
      ariaTitle={`evidence-upload-files-title`}
      onClose={onClose}
      open={!!open}
      size={'md'}
      title={dialogTitle}
    >
      <Box marginBottom={2}>
        <input
          // accept={'image/*'}
          className={classes.fileUploadInput}
          id={'file-input-field'}
          multiple
          onChange={handleFileSelect}
          ref={fileInputField}
          type={'file'}
        />
        <label htmlFor={'file-input-field'}>
          <Button color={'primary'} component={'span'} variant={'contained'}>
            Select files
          </Button>
        </label>
      </Box>
      <DataGridPro autoHeight columns={columns} hideFooter rows={rows} />
    </GenericDialog>
  );
};

export default EvidenceNavUploadFilesModalView;
