import React, {ReactElement} from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {ValidationError} from 'class-validator';

import {hasClassValidationError as hasError} from '../../../utils/utils';

import {ChangeData} from './ResetPasswordController';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    flexDirection: 'row-reverse',
  },
  title: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  inputs: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: '#626262',
  },
}));

type Props = {
  password: string;
  passwordConfirm: string;
  errors: ValidationError[];
  onChange(data: ChangeData): void;
  onSend(): void;
};

const ResetPasswordView = ({
  password,
  passwordConfirm,
  errors,
  onChange,
  onSend,
}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <Card id={'password-reset-form'}>
      <CardHeader
        classes={{
          root: classes.title,
          title: classes.bold,
        }}
        title={'Reset your password'}
      />
      <CardContent onKeyUp={(event) => event.key === 'Enter' && onSend()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography classes={{subtitle1: classes.subtitle}} paragraph variant={'subtitle1'}>
              Enter, confirm, and submit your new password below then try logging in again
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              classes={{root: classes.inputs}}
              color={'secondary'}
              error={hasError(errors, 'password')}
              fullWidth
              helperText={
                hasError(errors, 'password') &&
                'Please enter a password that is 6 characters or longer'
              }
              label={'Password'}
              onChange={(event) =>
                onChange({
                  property: 'password',
                  password: event.currentTarget.value,
                })
              }
              type={'password'}
              value={password}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color={'secondary'}
              error={hasError(errors, 'passwordConfirm')}
              fullWidth
              helperText={hasError(errors, 'passwordConfirm') && 'Please ensure passwords match'}
              label={'Confirm password'}
              onChange={(event) =>
                onChange({
                  property: 'passwordConfirm',
                  passwordConfirm: event.currentTarget.value,
                })
              }
              type={'password'}
              value={passwordConfirm}
              variant={'outlined'}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          classes={{label: classes.bold}}
          color={'primary'}
          disabled={errors.length !== 0 || password.length === 0 || passwordConfirm.length === 0}
          fullWidth
          onClick={onSend}
          variant={'contained'}
        >
          Submit
        </Button>
      </CardActions>
    </Card>
  );
};

export default ResetPasswordView;
