import React, {ReactElement, useCallback} from 'react';

import {Tooltip} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {USERS_ADMIN} from '../../../config/routes';

import useRestoreUser from '../../../hooks/mutations/restore-user.hook';

type Props = {
  email: string | null;
};

const ImpersonatedUser = ({email}: Props): ReactElement => {
  const [restoreUser] = useRestoreUser();

  const handleClick = useCallback(() => {
    const callback = () => {
      location.replace(USERS_ADMIN);
    };
    restoreUser(callback);
  }, [restoreUser]);

  return (
    <Grid alignItems={'center'} container direction={'row'} justifyContent={'space-between'}>
      <Grid item>
        {email && (
          <Tooltip title={`Click to stop impersonating ${email}`}>
            <Button onClick={handleClick} variant={'contained'}>
              Impersonating&nbsp;<Typography color={'secondary'}>{email}</Typography>
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default ImpersonatedUser;
