import {isInstance} from 'class-validator';
import {inject, injectable} from 'inversify';

import {getIsAuthenticated} from '../../state/common/selectors';
import {RootStore} from '../../state/store';

import keys from './container/keys';

export interface IRequestError {
  code: number;
  message: string;
}

export class RequestError implements IRequestError {
  public code: number;
  public message: string;

  constructor(code: number, message: string) {
    this.code = code;
    this.message = message;
  }
}

type ExtraOptions = {
  requestInit?: RequestInit;
  mustBeAuthenticated?: boolean;
};

type ExtraPostOptions = ExtraOptions & {
  requiresCsrf?: boolean;
};

type ExtraGetOptions = ExtraOptions & {
  searchParams?: Record<string, string>;
};

export interface IRequestService {
  postToApi<T>(
    path: string,
    formData: FormData | Record<string, string>,
    options?: ExtraPostOptions,
  ): Promise<T>;
  getFromApi<T>(path: string, options?: ExtraGetOptions): Promise<T>;
  getRawFromApi(path: string, options?: ExtraGetOptions): Promise<Response>;
}

@injectable()
export default class RequestService implements IRequestService {
  @inject(keys.BASE_URL)
  private baseUrl!: string;

  @inject(keys.store)
  private store!: RootStore;

  private csrfToken: string | undefined;

  public async postToApi<T>(
    path: string,
    formData: FormData | Record<string, string>,
    options?: ExtraPostOptions,
  ): Promise<T> {
    if (options?.requiresCsrf) {
      if (!this.csrfToken) {
        try {
          this.csrfToken = await this.getCsrfToken();
        } catch (e) {
          console.error(e);
        } finally {
          console.log('csrf token', this.csrfToken);
        }
      }

      if (this.csrfToken) {
        if (isInstance(formData, FormData)) {
          (formData as FormData).append('authenticity_token', this.csrfToken);
        } else {
          (formData as Record<string, string>).authenticity_token = this.csrfToken;
        }
      }
    }

    const requestInit: RequestInit = Object.assign(
      {
        method: 'post',
        mode: 'cors',
        credentials: 'include',
      },
      options?.requestInit,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {body: new URLSearchParams(formData as any)},
    );

    const response = await fetch(`${this.baseUrl}${path}`, requestInit),
      responseText = await response.text();

    if (!response.ok) {
      throw new RequestError(response.status, response.statusText);
    }

    return responseText as unknown as T;
  }

  public async getFromApi<T>(path: string, options?: ExtraGetOptions): Promise<T> {
    if (options?.mustBeAuthenticated && !getIsAuthenticated(this.store.getState())) {
      throw new RequestError(401, 'You must be authenticated to send that request');
    }

    const requestInit: RequestInit = Object.assign(
        {
          mode: 'cors',
          credentials: 'include',
        },
        options?.requestInit,
      ),
      urlSearchParams = options?.searchParams
        ? `?${new URLSearchParams(options.searchParams)}`
        : '';

    const response = await fetch(`${this.baseUrl}${path}${urlSearchParams}`, requestInit),
      responseText = await response.text();

    if (!response.ok) {
      throw new RequestError(response.status, response.statusText);
    }

    return responseText as unknown as T;
  }

  public async getRawFromApi(path: string, options?: ExtraGetOptions): Promise<Response> {
    if (options?.mustBeAuthenticated && !getIsAuthenticated(this.store.getState())) {
      throw new RequestError(401, 'You must be authenticated to send that request');
    }

    const requestInit: RequestInit = Object.assign(
      {
        mode: 'cors',
        credentials: 'include',
      },
      options?.requestInit,
    );

    return fetch(`${this.baseUrl}${path}`, requestInit);
  }

  private async getCsrfToken(): Promise<string> {
    const pageToScrape = await fetch(`${this.baseUrl}`, {
      credentials: 'include',
      mode: 'cors',
    });
    const domParser = new DOMParser(),
      doc = domParser.parseFromString(await pageToScrape.text(), 'text/html'),
      authenticityTokenElement = doc.getElementsByName('authenticity_token');

    if (authenticityTokenElement.length === 0) {
      this.csrfToken = undefined;
      throw new Error('Unable to get authenticity token');
    }

    const authenticityTokenValue = authenticityTokenElement[0].getAttribute('value');

    if (!authenticityTokenValue) {
      this.csrfToken = undefined;
      throw new Error('Authenticity token value not set');
    }

    return authenticityTokenValue;
  }
}
