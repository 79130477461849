import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';

import {
  StartFileUploadInput,
  startUploadMutation,
} from '../../graphql/__generated__/startUploadMutation.graphql';

type StartUploadMutationResponse = {
  readonly uploadUrl: string;
  readonly uploadToken: string;
};

const mutation = graphql`
  mutation startUploadMutation($input: StartFileUploadInput!) {
    startFileUpload(input: $input) {
      uploadUrl
      uploadToken
    }
  }
`;

function useStartUpload(): [
  (eventDate: Date, filename: string, size: number) => Promise<StartUploadMutationResponse>,
] {
  const [commit] = useMutation<startUploadMutation>(mutation);

  return [
    useCallback(
      async (eventDate, filename, size) => {
        const input: StartFileUploadInput = {
          eventDate,
          filename,
          size,
        };
        return await new Promise((resolve, reject) => {
          commit({
            variables: {
              input,
            },
            onCompleted: (response, errors) => {
              if (errors) {
                reject(errors[0]);
              } else if (response === null) {
                reject(new Error('response was null'));
              } else {
                resolve(response.startFileUpload as StartUploadMutationResponse);
              }
            },
            onError: (error) => reject(error),
          });
        });
      },
      [commit],
    ),
  ];
}

export default useStartUpload;
