import React, {ChangeEvent, Fragment, RefObject, useCallback, useMemo, useState} from 'react';

import {toBlob} from 'html-to-image';
import {useDispatch} from 'react-redux';
import {graphql, useMutation} from 'react-relay';

import {
  SaveWorkspaceInput,
  timelineActionBarSaveWorkspaceMutation,
} from '../../../graphql/__generated__/timelineActionBarSaveWorkspaceMutation.graphql';

import {setApiFeedback} from '../../../state/common/actions';
import {setShowEvidenceCounts, setShowLines} from '../../../state/timeline/actions';
import {
  DEFAULT_WORKSPACE_STATE,
  TCaseFolder,
  TWorkspace,
  TWorkspaceSortKey,
  TWorkspaceTag,
} from '../../../state/timeline/state';

import WorkspaceOptionsModal from '../modals/workspace-options-modal';
import WorkspaceSelectionModal from '../modals/workspace-selection-modal';
import {BACKGROUND_COLOR_OPTION_ID} from '../timeline.constants';
import {getWorkspaceOption} from '../timeline.helpers';
import TimelineActionBarView from './timeline-action-bar.view';

type Props = {
  selectedWorkspace: TWorkspace | undefined;
  workspaceFolders: TCaseFolder[];
  folderFilters: string[];
  tagFilters: number[];
  availableTagFilters: number[];
  typeFilters: number[];
  availableTypeFilters: number[];
  sortKey: TWorkspaceSortKey;
  offlineMode: boolean;
  workspaceTags: TWorkspaceTag[];
  htmlToImageRef: RefObject<HTMLDivElement>;
  openFilter(): void;
  setSortKey(sortKey: TWorkspaceSortKey): void;
  updateWorkspace(workspace: TWorkspace): void;
};

const TimelineActionBarController: React.FC<Props> = ({
  workspaceFolders,
  selectedWorkspace,
  folderFilters,
  tagFilters,
  availableTagFilters,
  typeFilters,
  availableTypeFilters,
  sortKey,
  offlineMode,
  workspaceTags,
  htmlToImageRef,
  openFilter,
  setSortKey,
  updateWorkspace,
}) => {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState<'save' | undefined>();
  const [workspaceSelectionDialogOpen, setWorkspaceSelectionDialogOpen] = useState(false);
  const [workspaceOptionsDialogOpen, setWorkspaceOptionsDialogOpen] = useState(false);

  const activeFolderFilters = useMemo(
      () => workspaceFolders.filter((folder) => folderFilters && folderFilters.includes(folder.id)),
      [selectedWorkspace, folderFilters],
    ),
    activeTagFilters = useMemo(
      () => availableTagFilters.filter((tagId) => tagFilters && tagFilters.includes(tagId)),
      [availableTagFilters, tagFilters],
    ),
    activeTypeFilters = useMemo(
      () => availableTypeFilters.filter((typeId) => typeFilters && typeFilters.includes(typeId)),
      [availableTypeFilters, typeFilters],
    ),
    activeFiltersCount =
      activeFolderFilters?.length + activeTagFilters?.length + activeTypeFilters?.length ?? 0;

  const [commitWorkspace] = useMutation<timelineActionBarSaveWorkspaceMutation>(graphql`
    mutation timelineActionBarSaveWorkspaceMutation($input: SaveWorkspaceInput!) {
      saveWorkspace(input: $input) {
        workspace {
          id
        }
      }
    }
  `);

  const handleShowLines = (event: ChangeEvent<HTMLInputElement>) => {
    if (!selectedWorkspace) {
      return;
    }

    dispatch(setShowLines({workspaceId: selectedWorkspace.id, showLines: event.target.checked}));
  };

  const handleShowEvidenceCounts = (event: ChangeEvent<HTMLInputElement>) => {
    if (!selectedWorkspace) {
      return;
    }

    dispatch(
      setShowEvidenceCounts({
        workspaceId: selectedWorkspace.id,
        showEvidenceCounts: event.target.checked,
      }),
    );
  };

  const handleSaveWorkspace = async () => {
    if (!selectedWorkspace) {
      return;
    }

    setInProgress('save');

    const bgColorOption = getWorkspaceOption(selectedWorkspace.options, BACKGROUND_COLOR_OPTION_ID);

    // organize variables for GQL and Redux
    const workspaceInputForGQL: SaveWorkspaceInput = {
      workspaceId: selectedWorkspace.id,
      name: selectedWorkspace.name,
      x: selectedWorkspace.coordinates.x ?? DEFAULT_WORKSPACE_STATE.coordinates.x,
      y: selectedWorkspace.coordinates.y ?? DEFAULT_WORKSPACE_STATE.coordinates.y,
      backgroundColor: bgColorOption?.value ?? 0,
      workspaceTags: {
        tags: selectedWorkspace?.tags?.map((t) => ({
          tagId: t.tagId,
          text: t.text,
          color: t.color,
          x: t.coordinates.x,
          y: t.coordinates.y,
          lockHighlight: t.lockHighlight,
        })),
      },
      workspaceFilters: {
        folderIds: activeFolderFilters.map((f) => f.id),
        tagIds: activeTagFilters,
        typeIds: activeTypeFilters,
      },
      zoomIndex: DEFAULT_WORKSPACE_STATE.zoomIndex,
    };

    const workspaceInputForRedux: TWorkspace = {
      id: selectedWorkspace.id,
      name: selectedWorkspace.name,
      modified: new Date(),
      coordinates: selectedWorkspace.coordinates ?? DEFAULT_WORKSPACE_STATE.coordinates,
      options: selectedWorkspace.options,
      tags: workspaceTags,
      filters: {
        folders: activeFolderFilters.map((f) => f.id),
        tags: activeTagFilters,
        types: activeTypeFilters,
      },
      showLines: selectedWorkspace.showLines || DEFAULT_WORKSPACE_STATE.showLines,
      showEvidenceCounts:
        selectedWorkspace.showEvidenceCounts || DEFAULT_WORKSPACE_STATE.showEvidenceCounts,
      zoomIndex: DEFAULT_WORKSPACE_STATE.zoomIndex,
    };

    commitWorkspace({
      variables: {
        input: {
          ...workspaceInputForGQL,
        },
      },
      onCompleted() {
        updateWorkspace(workspaceInputForRedux);
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Workspace "${selectedWorkspace.name}" saved successfully`,
          }),
        );
      },
      onError(e) {
        dispatch(setApiFeedback({message: 'workspace save failed'}));
        console.error(e);
      },
    });

    setInProgress(undefined);
  };

  const handleScreenshot = useCallback(() => {
    if (htmlToImageRef.current === null) {
      return;
    }

    toBlob(htmlToImageRef.current, {cacheBust: true})
      .then((blob) => {
        navigator.clipboard.write([
          new ClipboardItem({
            'image/png': blob as Blob,
          }),
        ]);
      })
      .then(() => {
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Screenshot captured and copied to clipboard`,
          }),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [htmlToImageRef]);

  return (
    <Fragment>
      <TimelineActionBarView
        activeFiltersCount={activeFiltersCount}
        handleScreenshot={handleScreenshot}
        handleShowEvidenceCounts={handleShowEvidenceCounts}
        handleShowLines={handleShowLines}
        inProgress={inProgress}
        offlineMode={offlineMode}
        onSaveWorkspace={handleSaveWorkspace}
        onSelectFilters={openFilter}
        onSelectOptions={() => setWorkspaceOptionsDialogOpen(true)}
        onSelectWorkspace={() => setWorkspaceSelectionDialogOpen(true)}
        onViewSortChange={setSortKey}
        selectedWorkspace={selectedWorkspace}
        viewSort={sortKey}
      />
      <WorkspaceSelectionModal
        onClose={() => setWorkspaceSelectionDialogOpen(false)}
        open={workspaceSelectionDialogOpen}
      />
      <WorkspaceOptionsModal
        handleClose={() => setWorkspaceOptionsDialogOpen(false)}
        isOpen={workspaceOptionsDialogOpen}
      />
    </Fragment>
  );
};

export default TimelineActionBarController;
