/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserListTableQueryVariables = {};
export type UserListTableQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"UserListTableRows_admin">;
};
export type UserListTableQuery = {
    readonly response: UserListTableQueryResponse;
    readonly variables: UserListTableQueryVariables;
};



/*
query UserListTableQuery {
  ...UserListTableRows_admin
}

fragment UserListRow_user on User {
  id
  created
  email
  firstName
  lastName
}

fragment UserListTableRows_admin on Query {
  admin {
    users(query: {filter: {email: {}, firstName: {}, lastName: {}}, sort: CREATED, sortDir: DESC}) {
      edges {
        node {
          id
          ...UserListRow_user
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "includes",
    "value": null
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": (v0/*: any*/),
            "kind": "ObjectValue",
            "name": "email"
          },
          {
            "fields": (v0/*: any*/),
            "kind": "ObjectValue",
            "name": "firstName"
          },
          {
            "fields": (v0/*: any*/),
            "kind": "ObjectValue",
            "name": "lastName"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      },
      {
        "kind": "Literal",
        "name": "sort",
        "value": "CREATED"
      },
      {
        "kind": "Literal",
        "name": "sortDir",
        "value": "DESC"
      }
    ],
    "kind": "ObjectValue",
    "name": "query"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserListTableQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserListTableRows_admin"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserListTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "users(query:{\"filter\":{\"email\":{\"includes\":null},\"firstName\":{\"includes\":null},\"lastName\":{\"includes\":null}},\"sort\":\"CREATED\",\"sortDir\":\"DESC\"})"
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "query"
            ],
            "handle": "connection",
            "key": "UserListTableRows_admin_users",
            "kind": "LinkedHandle",
            "name": "users"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "91699e2f49657a334e2738a210d96481",
    "id": null,
    "metadata": {},
    "name": "UserListTableQuery",
    "operationKind": "query",
    "text": "query UserListTableQuery {\n  ...UserListTableRows_admin\n}\n\nfragment UserListRow_user on User {\n  id\n  created\n  email\n  firstName\n  lastName\n}\n\nfragment UserListTableRows_admin on Query {\n  admin {\n    users(query: {filter: {email: {}, firstName: {}, lastName: {}}, sort: CREATED, sortDir: DESC}) {\n      edges {\n        node {\n          id\n          ...UserListRow_user\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c26a6071851f9861e35112f489366d3d';
export default node;
