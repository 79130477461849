/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EventSort = "TS" | "%future added value";
export type EventType = "EVIDENCE_CREATED" | "EVIDENCE_FOLDER_ADDED" | "EVIDENCE_FOLDER_REMOVED" | "EVIDENCE_TAG_ADDED" | "EVIDENCE_TAG_REMOVED" | "FOLDER_CREATED" | "USER_LOGGED_IN" | "USER_LOGGED_OUT" | "WORKSPACE_CREATED" | "%future added value";
export type eventQueryFilter = {
    type?: EventType | null | undefined;
};
export type eventLogPaginationQueryVariables = {
    after?: string | null | undefined;
    first?: number | null | undefined;
    sort?: EventSort | null | undefined;
    type?: eventQueryFilter | null | undefined;
    id: string;
};
export type eventLogPaginationQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"eventLog_user">;
    } | null;
};
export type eventLogPaginationQuery = {
    readonly response: eventLogPaginationQueryResponse;
    readonly variables: eventLogPaginationQueryVariables;
};



/*
query eventLogPaginationQuery(
  $after: String
  $first: Int = 25
  $sort: EventSort = TS
  $type: eventQueryFilter
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...eventLog_user_15yi17
    id
  }
}

fragment eventLog_user_15yi17 on User {
  events(query: {filter: {type: $type}, sort: $sort, sortDir: DESC}, first: $first, after: $after) {
    edges {
      node {
        id
        ip
        ts
        type
        user {
          email
          id
        }
        evidence {
          __typename
          name
          id
        }
        folder {
          name
          id
        }
        tag
        workspace {
          name
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    totalCount
  }
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": 25,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": "TS",
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v9 = {
  "kind": "Variable",
  "name": "type",
  "variableName": "type"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "fields": [
      {
        "fields": [
          (v9/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "filter"
      },
      (v8/*: any*/),
      {
        "kind": "Literal",
        "name": "sortDir",
        "value": "DESC"
      }
    ],
    "kind": "ObjectValue",
    "name": "query"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  (v13/*: any*/),
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "eventLogPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "eventLog_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "eventLogPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "FactimizeEventConnection",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FactimizeEventEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FactimizeEvent",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ip",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ts",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "evidence",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v13/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserFolder",
                            "kind": "LinkedField",
                            "name": "folder",
                            "plural": false,
                            "selections": (v14/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tag",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Workspace",
                            "kind": "LinkedField",
                            "name": "workspace",
                            "plural": false,
                            "selections": (v14/*: any*/),
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [
                  "query"
                ],
                "handle": "connection",
                "key": "eventLog_user_events",
                "kind": "LinkedHandle",
                "name": "events"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be21635d0c53ff1946f890af2ebf561a",
    "id": null,
    "metadata": {},
    "name": "eventLogPaginationQuery",
    "operationKind": "query",
    "text": "query eventLogPaginationQuery(\n  $after: String\n  $first: Int = 25\n  $sort: EventSort = TS\n  $type: eventQueryFilter\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...eventLog_user_15yi17\n    id\n  }\n}\n\nfragment eventLog_user_15yi17 on User {\n  events(query: {filter: {type: $type}, sort: $sort, sortDir: DESC}, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        ip\n        ts\n        type\n        user {\n          email\n          id\n        }\n        evidence {\n          __typename\n          name\n          id\n        }\n        folder {\n          name\n          id\n        }\n        tag\n        workspace {\n          name\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasPreviousPage\n      hasNextPage\n    }\n    totalCount\n  }\n  id\n}\n"
  }
};
})();
(node as any).hash = '9db5d47c861bc05e3985990cd448f71a';
export default node;
