import React, {Fragment} from 'react';

import {TWorkspaceTag} from '../../../state/timeline/state';

import WorkspaceTagDetailView from './workspace-tag-detail.view';

type Props = {
  openedWorkspaceTag: TWorkspaceTag | undefined;
  workspaceTagEvidenceIds: {tagId: number; evidenceIds: string[]}[];
  setOpenedWorkspaceTag(tagId: number | undefined): void;
  setSelectedEvidenceIds(ids: string[]): void;
};

const WorkspaceTagDetailController: React.FC<Props> = ({
  openedWorkspaceTag,
  workspaceTagEvidenceIds,
  setOpenedWorkspaceTag,
  setSelectedEvidenceIds,
}) => {
  if (!openedWorkspaceTag) return <Fragment></Fragment>;
  return (
    openedWorkspaceTag && (
      <WorkspaceTagDetailView
        onClose={() => setOpenedWorkspaceTag(undefined)}
        setSelectedEvidenceIds={setSelectedEvidenceIds}
        workspaceTag={openedWorkspaceTag}
        workspaceTagEvidenceIds={workspaceTagEvidenceIds}
      />
    )
  );
};

export default WorkspaceTagDetailController;
