/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserListRow_user = {
    readonly id: string;
    readonly created: Date;
    readonly email: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly " $refType": "UserListRow_user";
};
export type UserListRow_user$data = UserListRow_user;
export type UserListRow_user$key = {
    readonly " $data"?: UserListRow_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserListRow_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserListRow_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'ce002f06e6d093460dcb652947500797';
export default node;
