import {useCallback} from 'react';

import {fetchWithSession} from '../../utils/tokens';
import {parallelLimit, sleep, validFileExtension} from '../../utils/utils';

import {FileData} from '../../pages/evidence/evidence-nav/evidence-nav.upload-files.modal';
import useFinalizeUpload from '../mutations/finalize-upload.hook';
import {FinalizeFileUploadResponse} from '../mutations/finalize-upload.hook';
import useStartUpload from '../mutations/start-upload.hook';

// Check for errors we can retry (e.g. network timeout) vs ones we cannot (e.g. permission denied)
const isFatalError = (_e: Error): boolean => {
  //  TODO - identify fatal errors and handle appropriately
  return false;
};

const UPLOAD_RETRY_LIMIT = 3;
const UPLOAD_CONCURRENCY_LIMIT = 5;

function useUploadFiles(): [(files: File[], fileData: FileData[]) => Promise<void>] {
  const [startUpload] = useStartUpload();
  const [finalizeUpload] = useFinalizeUpload();

  const uploadFile = async (file: File): Promise<FinalizeFileUploadResponse> => {
    if (!validFileExtension(file)) {
      throw new Error(`Invalid file name: ${file.name}`);
    }

    const startUploadPromise = startUpload(new Date(), file.name, file.size);
    return await startUploadPromise.then(({uploadUrl, uploadToken}) =>
      fetchWithSession(uploadUrl, {
        method: 'PUT',
        body: file,
      }).then((resp) => {
        if (resp.status >= 300) {
          // TODO: error handling
          throw new Error('File upload did not complete successfully');
        }
        return finalizeUpload(uploadToken);
      }),
    );
  };

  const uploadFiles = async (files: Array<File>, fileData: FileData[]): Promise<void> => {
    const tasks = files.map((file, index) => async () => {
      let attempt = 0;

      for (;;) {
        try {
          await uploadFile(file).then(() => {
            fileData[index].status = 'Success';
          });
          return;
        } catch (e) {
          ++attempt;

          if (attempt === UPLOAD_RETRY_LIMIT || isFatalError(e as Error)) {
            fileData[index].status = 'Failure';
            throw e;
          }

          await sleep(Math.min(1000, Math.pow(50, attempt)));
        }
      }
    });

    await parallelLimit(tasks, UPLOAD_CONCURRENCY_LIMIT);
  };

  return [
    useCallback(
      (files, fileData) => {
        return uploadFiles(files, fileData);
      },
      [startUpload, finalizeUpload],
    ),
  ];
}

export default useUploadFiles;
