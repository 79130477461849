/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetailQueryVariables = {
    ids: Array<string>;
    skip: boolean;
};
export type evidenceDetailQueryResponse = {
    readonly evidence?: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_actions" | "evidenceDetail_addTag" | "evidenceDetail_comments" | "evidenceDetail_folderGroup" | "evidenceDetail_info" | "evidenceDetail_metaData" | "evidenceDetail_multiple" | "evidenceDetail_tagGroup">;
    } | null> | undefined;
};
export type evidenceDetailQuery = {
    readonly response: evidenceDetailQueryResponse;
    readonly variables: evidenceDetailQueryVariables;
};



/*
query evidenceDetailQuery(
  $ids: [ID!]!
  $skip: Boolean!
) {
  evidence: nodes(ids: $ids) @skip(if: $skip) {
    __typename
    ...evidenceDetail_actions
    ...evidenceDetail_addTag
    ...evidenceDetail_comments
    ...evidenceDetail_folderGroup
    ...evidenceDetail_info
    ...evidenceDetail_metaData
    ...evidenceDetail_multiple
    ...evidenceDetail_tagGroup
    id
  }
}

fragment evidenceDetail_actions on Evidence {
  __isEvidence: __typename
  id
  size
  eventDate
  fileName: name
  type: __typename
  ... on File {
    mimeType
    url
  }
  ... on JournalEntry {
    subject
    body
  }
  folders {
    name
    id
  }
}

fragment evidenceDetail_addTag on Evidence {
  __isEvidence: __typename
  id
  fileName: name
}

fragment evidenceDetail_comments on Evidence {
  __isEvidence: __typename
  comments {
    id
    created
    body
    authorName
  }
}

fragment evidenceDetail_folderGroup on Evidence {
  __isEvidence: __typename
  id
  folders {
    id
    name
  }
}

fragment evidenceDetail_info on Evidence {
  __isEvidence: __typename
  id
  folders {
    id
  }
}

fragment evidenceDetail_metaData on Evidence {
  __isEvidence: __typename
  id
  storedOn: created
  fileName: name
  eventDate
  size
  ... on File {
    mimeType
  }
}

fragment evidenceDetail_multiple on Evidence {
  __isEvidence: __typename
  id
  size
}

fragment evidenceDetail_tagGroup on Evidence {
  __isEvidence: __typename
  id
  fileName: name
  tags: tagsWithIds {
    id
    text
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "evidenceDetailQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "evidence",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceDetail_actions"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceDetail_addTag"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceDetail_comments"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceDetail_folderGroup"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceDetail_info"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceDetail_metaData"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceDetail_multiple"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceDetail_tagGroup"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "evidenceDetailQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "evidence",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eventDate",
                    "storageKey": null
                  },
                  {
                    "alias": "fileName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": "type",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFolder",
                    "kind": "LinkedField",
                    "name": "folders",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comments",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "authorName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "storedOn",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": "tags",
                    "args": null,
                    "concreteType": "TagWithId",
                    "kind": "LinkedField",
                    "name": "tagsWithIds",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mimeType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "type": "File",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subject",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "type": "JournalEntry",
                    "abstractKey": null
                  }
                ],
                "type": "Evidence",
                "abstractKey": "__isEvidence"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "337b29e41c0db04a5e5bebf70e9fe92a",
    "id": null,
    "metadata": {},
    "name": "evidenceDetailQuery",
    "operationKind": "query",
    "text": "query evidenceDetailQuery(\n  $ids: [ID!]!\n  $skip: Boolean!\n) {\n  evidence: nodes(ids: $ids) @skip(if: $skip) {\n    __typename\n    ...evidenceDetail_actions\n    ...evidenceDetail_addTag\n    ...evidenceDetail_comments\n    ...evidenceDetail_folderGroup\n    ...evidenceDetail_info\n    ...evidenceDetail_metaData\n    ...evidenceDetail_multiple\n    ...evidenceDetail_tagGroup\n    id\n  }\n}\n\nfragment evidenceDetail_actions on Evidence {\n  __isEvidence: __typename\n  id\n  size\n  eventDate\n  fileName: name\n  type: __typename\n  ... on File {\n    mimeType\n    url\n  }\n  ... on JournalEntry {\n    subject\n    body\n  }\n  folders {\n    name\n    id\n  }\n}\n\nfragment evidenceDetail_addTag on Evidence {\n  __isEvidence: __typename\n  id\n  fileName: name\n}\n\nfragment evidenceDetail_comments on Evidence {\n  __isEvidence: __typename\n  comments {\n    id\n    created\n    body\n    authorName\n  }\n}\n\nfragment evidenceDetail_folderGroup on Evidence {\n  __isEvidence: __typename\n  id\n  folders {\n    id\n    name\n  }\n}\n\nfragment evidenceDetail_info on Evidence {\n  __isEvidence: __typename\n  id\n  folders {\n    id\n  }\n}\n\nfragment evidenceDetail_metaData on Evidence {\n  __isEvidence: __typename\n  id\n  storedOn: created\n  fileName: name\n  eventDate\n  size\n  ... on File {\n    mimeType\n  }\n}\n\nfragment evidenceDetail_multiple on Evidence {\n  __isEvidence: __typename\n  id\n  size\n}\n\nfragment evidenceDetail_tagGroup on Evidence {\n  __isEvidence: __typename\n  id\n  fileName: name\n  tags: tagsWithIds {\n    id\n    text\n  }\n}\n"
  }
};
})();
(node as any).hash = 'dc69fbae457c51373289a3d5db116f56';
export default node;
