import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  AddCommentInput,
  addCommentToEvidenceMutation,
} from '../../graphql/__generated__/addCommentToEvidenceMutation.graphql';

const mutation = graphql`
  mutation addCommentToEvidenceMutation($input: AddCommentInput!) {
    addComment(input: $input) {
      evidenceNodes {
        node {
          id
          ...evidenceDetail_comments
          commentCount
        }
      }
    }
  }
`;

function useAddCommentToEvidence(): [
  (evidenceIds: string[], commentText: string, callback: () => void) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<addCommentToEvidenceMutation>(mutation);
  return [
    useCallback(
      (evidenceIds, commentText, callback) => {
        const input: AddCommentInput = {
          evidenceIds,
          body: commentText,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted(response) {
            if (response.addComment?.evidenceNodes !== undefined) {
              callback();
            }
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useAddCommentToEvidence;
