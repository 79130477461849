/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetail_comments = ReadonlyArray<{
    readonly comments: ReadonlyArray<{
        readonly id: string;
        readonly created: Date;
        readonly body: string;
        readonly authorName: string;
    }>;
    readonly " $refType": "evidenceDetail_comments";
}>;
export type evidenceDetail_comments$data = evidenceDetail_comments;
export type evidenceDetail_comments$key = ReadonlyArray<{
    readonly " $data"?: evidenceDetail_comments$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_comments">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "evidenceDetail_comments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "comments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "authorName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Evidence",
  "abstractKey": "__isEvidence"
};
(node as any).hash = '5db3ebf8d74675adc0ead36492a47e91';
export default node;
