import React, {Fragment, SyntheticEvent, useState} from 'react';

import {DraggableEventHandler} from 'react-draggable';

import {TWorkspaceTag} from '../../../state/timeline/state';

import TagNodeContextMenu from '../tag-node-context-menu';
import TimelineTagNodeView from './timeline-tag-node.view';

type Props = {
  workspaceTagEvidenceIds: {tagId: number; evidenceIds: string[]}[];
  showEvidenceCounts: boolean;
  selectedWorkspaceId: string | undefined;
  workspaceTag: TWorkspaceTag;
  visibleTagIds: number[];
  handleOpenDetail(): void;
  handleOpenColorPicker(): void;
  handleMove(): void;
  setWorkspaceTagPosition(args: {
    workspaceId: string;
    tagId: number;
    coordinates: {x: number; y: number};
  }): void;
};

const TimelineTagNodeController: React.FC<Props> = ({
  workspaceTagEvidenceIds,
  showEvidenceCounts,
  selectedWorkspaceId,
  workspaceTag,
  visibleTagIds,
  handleMove,
  handleOpenDetail,
  handleOpenColorPicker,
  setWorkspaceTagPosition,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null),
    [isMoving, setIsMoving] = useState(false);

  function handleMenu(event: SyntheticEvent) {
    setAnchorEl(event.currentTarget);
  }

  function openPicker() {
    setAnchorEl(null);
    handleOpenColorPicker();
  }

  function openDetail() {
    setAnchorEl(null);
    handleOpenDetail();
  }

  const dragStop: DraggableEventHandler = (_event, {x, y}) => {
    setIsMoving(false);
    handleMove();

    selectedWorkspaceId &&
      setWorkspaceTagPosition({
        workspaceId: selectedWorkspaceId,
        tagId: workspaceTag.tagId,
        coordinates: {x, y},
      });
  };

  const dragStart: DraggableEventHandler = () => {
    setIsMoving(true);
    handleMove();
  };

  const isVisible = visibleTagIds?.includes(workspaceTag.tagId) || !!workspaceTag.lockHighlight;

  const evidenceCount = workspaceTagEvidenceIds?.find((obj) => obj.tagId === workspaceTag.tagId)
    ?.evidenceIds.length;

  if (!evidenceCount) return <Fragment></Fragment>;

  return (
    <Fragment>
      <TimelineTagNodeView
        evidenceCount={evidenceCount}
        isDragging={isMoving}
        isVisible={isVisible}
        onContextMenuOpen={handleMenu}
        onDragStart={dragStart}
        onDragStop={dragStop}
        selectedWorkspaceId={selectedWorkspaceId}
        showEvidenceCounts={showEvidenceCounts}
        tag={workspaceTag}
      />
      <TagNodeContextMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        handleOpenColorPicker={openPicker}
        handleOpenDetail={openDetail}
        style={{zIndex: 3}}
        workspaceTag={workspaceTag}
      />
    </Fragment>
  );
};

export default TimelineTagNodeController;
