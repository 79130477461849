import {graphql} from 'react-relay';

const tagsQueryGQL = graphql`
  query tagsQuery {
    currentSession {
      ... on Session {
        account {
          activeTagsWithIds {
            id
            text
          }
          allFilesFolder {
            type: __typename
            id
            evidences {
              edges {
                node {
                  id
                  storedOn: created
                  fileName: name
                  eventDate
                  size
                  folders {
                    id
                    name
                  }
                  tags: tagsWithIds {
                    id
                    text
                  }
                  comments {
                    id
                    created
                    body
                    authorName
                  }
                  ... on File {
                    mimeType
                    url
                  }
                  ... on JournalEntry {
                    subject
                    body
                  }
                }
              }
            }
          }
        }
      }
      ... on SessionError {
        message
      }
    }
  }
`;

export default tagsQueryGQL;
