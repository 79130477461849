export const BACKGROUND_COLOR_OPTION_ID = 3; // from db

export const DEFAULT_BG_COLOR_INDEX = 2; // medium grey (500)

export const workspaceBackgroundShades = [100, 300, 500, 700, 900] as const;

export type WorkspaceBackgroundShade = typeof workspaceBackgroundShades[number];

export const workspaceBackgroundIndices = Array.from(workspaceBackgroundShades.keys());

export type WorkspaceBackgroundIndex = typeof workspaceBackgroundIndices[number];
