import * as t from 'io-ts';
import {createSelector} from 'reselect';

import {TRootState} from '../store';
import {EvidencesState} from './state';

export const getEvidences = (state: TRootState): t.OutputOf<typeof EvidencesState> => {
  return state.evidences;
};

export const getEvidenceFiles = createSelector(
  [getEvidences],
  (evidences) => evidences.evidenceFiles,
);

export const getEvidenceThumbnails = createSelector(
  [getEvidences],
  (evidences) => evidences.evidenceThumbnails,
);

export const getAllEvidence = createSelector([getEvidences], (evidences) => evidences.evidences);

export const getCachedEvidenceCount = createSelector(
  [getEvidenceFiles],
  (evidences) => Object.keys(evidences).length,
);

export const getCachedEvidenceSize = createSelector(
  [getAllEvidence, getEvidenceFiles],
  (allEvidence, cachedEvidence) => {
    const cachedEntityIds = Object.keys(cachedEvidence);
    return allEvidence
      .filter((e) => cachedEntityIds.includes(e.id.toString()))
      .reduce((count: number, e) => {
        return count + e.size;
      }, 0);
  },
);

export const getCurrentFolderId = createSelector([getEvidences], (evidences) => {
  return evidences.currentFolderId;
});

export const getAllTags = createSelector([getEvidences], (evidences) => {
  const tags = [...evidences.tags];
  tags.sort((a, b) => {
    return a.text.localeCompare(b.text);
  });
  return tags;
});

export const getSelectedEvidenceIds = createSelector([getEvidences], (evidences) => {
  return evidences.selectedEvidenceIds;
});

export const getCurrentTagFilterIds = createSelector([getEvidences], (evidences) => {
  return evidences.currentTagFilterIds;
});
