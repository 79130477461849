import {blue, green, indigo, orange, red, yellow} from '@material-ui/core/colors';

export const workspaceTagColors = [
  red[500],
  indigo[500],
  blue[500],
  green[500],
  yellow[500],
  orange[500],
];

export const DEFAULT_TAG_COLOR = workspaceTagColors[1];

export type WorkspaceTagColors = typeof workspaceTagColors;
