import React, {Fragment, ReactElement} from 'react';

import {Box, TableCell, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {graphql, useFragment} from 'react-relay';

import {evidenceDetail_activeTags$key} from '../../graphql/__generated__/evidenceDetail_activeTags.graphql';
import {evidenceDetail_addTag$key} from '../../graphql/__generated__/evidenceDetail_addTag.graphql';
import {evidenceDetail_comments$key} from '../../graphql/__generated__/evidenceDetail_comments.graphql';
import {evidenceDetail_folderGroup$key} from '../../graphql/__generated__/evidenceDetail_folderGroup.graphql';
import {evidenceDetail_info$key} from '../../graphql/__generated__/evidenceDetail_info.graphql';
import {evidenceDetail_tagGroup$key} from '../../graphql/__generated__/evidenceDetail_tagGroup.graphql';

import EvidenceDetailAddComment from './evidence-detail.add-comment';
import EvidenceDetailAddTag from './evidence-detail.add-tag';
import CommentGroup from './evidence-detail.comment-group';
import {Referrer} from './evidence-detail.controller';
import FolderChipGroup from './evidence-detail.folder-group';
import TagChipGroup from './evidence-detail.tag-group';

const useStyles = makeStyles((theme) => ({
  fileName: {wordBreak: 'break-all'},
  freeSoloCreateOption: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  account: evidenceDetail_activeTags$key;
  evidence: evidenceDetail_info$key &
    evidenceDetail_comments$key &
    evidenceDetail_folderGroup$key &
    evidenceDetail_tagGroup$key &
    evidenceDetail_addTag$key;
  referrer: Referrer['referrer'];
  currentFolderId: string;
};

const EvidenceDetailInfo = ({
  account,
  evidence,
  referrer,
  currentFolderId,
}: Props): ReactElement => {
  const classes = useStyles();

  const data = useFragment(
    graphql`
      fragment evidenceDetail_info on Evidence @relay(plural: true) {
        id
        folders {
          id
        }
      }
    `,
    evidence as evidenceDetail_info$key,
  );

  if (!data) {
    return <div />;
  }

  const {id, folders} = data[0];

  return (
    <Fragment>
      {folders.length > 0 && (
        <TableRow>
          <TableCell colSpan={2} component={'th'} scope={'row'}>
            Folders:
            <FolderChipGroup evidence={evidence} />
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell colSpan={2} component={'th'} scope={'row'}>
          Tags:
          <TagChipGroup currentFolderId={currentFolderId} evidence={evidence} referrer={referrer} />
          {(referrer === 'evidence-list' ||
            referrer === 'evidence-node-detail' ||
            referrer === 'workspace-tag-detail') && (
            <Box className={classes.freeSoloCreateOption}>
              <EvidenceDetailAddTag
                account={account}
                currentFolderId={currentFolderId}
                evidence={evidence}
              />
            </Box>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} component={'th'} scope={'row'}>
          Comments:
          <CommentGroup currentFolderId={currentFolderId} evidence={evidence} referrer={referrer} />
          {referrer === 'evidence-list' && <EvidenceDetailAddComment evidenceIds={[id]} />}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default EvidenceDetailInfo;
