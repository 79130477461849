"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyStringError = exports.BidiError = exports.InvalidCodepointError = void 0;
class InvalidCodepointError extends Error {
    constructor(text, index) {
        super(`Invalid codepoint '${[...text][index]}' at index ${index}`);
        this.text = text;
        this.index = index;
    }
}
exports.InvalidCodepointError = InvalidCodepointError;
class BidiError extends Error {
    constructor(text, index) {
        super(`Invalid bidirectional codepoint '${[...text][index]}' at index ${index}`);
        this.text = text;
        this.index = index;
    }
}
exports.BidiError = BidiError;
class EmptyStringError extends Error {
    constructor(text) {
        super('Result string is empty');
        this.text = text;
    }
}
exports.EmptyStringError = EmptyStringError;
