import React, {Fragment, useCallback} from 'react';
import {ReactElement} from 'react';

import {Button, Grid, LinearProgress, makeStyles} from '@material-ui/core';
import {
  DataGridPro,
  GridOverlay,
  GridRowData,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import {usePaginationFragment} from 'relay-hooks';
import {graphql} from 'relay-runtime';

import {eventLogPaginationQuery} from '../../../../graphql/__generated__/eventLogPaginationQuery.graphql';
import {eventLog_user$key} from '../../../../graphql/__generated__/eventLog_user.graphql';

import {readableDateTimeString} from '../../../../utils/utils';

const composeActionMsg = (rowData: GridRowData): JSX.Element => {
  const {type, evidence, tag, folder, workspace} = rowData;
  let msg = <div>No action</div>;
  switch (type) {
    case 'user-logged-in':
      msg = <div>Login</div>;
      break;
    case 'user-logged-out':
      msg = <div>Logout</div>;
      break;
    case 'evidence-tag-added':
      msg = (
        <div>
          Tag: <b>{tag}</b> added to Evidence: <b>{evidence.name}</b>
        </div>
      );
      break;
    case 'evidence-tag-removed':
      msg = (
        <div>
          Tag: <b>{tag}</b> removed from Evidence: <b>{evidence.name}</b>
        </div>
      );
      break;
    case 'evidence-folder-added':
      msg = (
        <div>
          Evidence: <b>{evidence.name}</b> Added to Folder: <b>{folder.name}</b>
        </div>
      );
      break;
    case 'evidence-folder-removed':
      msg = (
        <div>
          Evidence: <b>{evidence.name}</b> Removed from Folder: <b>{folder.name}</b>
        </div>
      );
      break;
    case 'evidence-created':
      msg = (
        <div>
          File: <b>{evidence.name}</b> Uploaded
        </div>
      );
      break;
    case 'folder-created':
      msg = (
        <div>
          Folder: <b>{folder.name}</b> Created
        </div>
      );
      break;
    case 'workspace-created':
      msg = (
        <div>
          Workspace: <b>{workspace.name}</b> Created
        </div>
      );
      break;
    default:
      break;
  }
  return msg;
};

const useStyles = makeStyles({
  dataGridWrapper: {
    height: '65vh',
  },
  customGridFooter: {
    marginTop: '1rem',
  },
});

const PAGE_SIZE = 25;

type Props = {
  user: eventLog_user$key | null;
};

const EventLogView = ({user}: Props): ReactElement => {
  if (user === null) {
    return <div>User is empty.</div>;
  }
  const {
    data,
    loadNext,
    // loadPrevious,
    hasNext,
    // hasPrevious,
    isLoadingNext,
    // loadingPrevious,
    refetch, // For refetching connection
  } = usePaginationFragment<eventLogPaginationQuery, eventLog_user$key>(
    graphql`
      fragment eventLog_user on User
      @argumentDefinitions(
        first: {type: "Int", defaultValue: 25}
        after: {type: "String"}
        type: {type: eventQueryFilter, defaultValue: null}
        sort: {type: EventSort, defaultValue: TS}
      )
      @refetchable(queryName: "eventLogPaginationQuery") {
        events(
          query: {filter: {type: $type}, sort: $sort, sortDir: DESC}
          first: $first
          after: $after
        ) @connection(key: "eventLog_user_events") {
          edges {
            node {
              id
              ip
              ts
              type
              user {
                email
              }
              evidence {
                name
              }
              folder {
                name
              }
              tag
              workspace {
                name
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          totalCount
        }
      }
    `,
    user,
  );

  const rows = data?.events?.edges?.map((e) => e?.node ?? []) ?? [];
  // const pageInfo = data?.events?.pageInfo;
  const rowCount = data?.events?.totalCount;

  const columns = [
    {
      field: 'ts',
      headerName: 'Timestamp',
      valueFormatter: (params: GridValueFormatterParams) => {
        return readableDateTimeString(params.value as number);
      },
      minWidth: 200,
    },
    {
      field: 'ip',
      headerName: 'IP',
      minWidth: 200,
    },
    {
      flex: 1,
      field: 'type',
      headerName: 'Action',
      renderCell: (params: GridValueFormatterParams) => {
        return <div>{composeActionMsg(params.row)}</div>;
      },
      minWidth: 400,
    },
    {
      field: 'user',
      headerName: 'User',
      type: 'string',
      minWidth: 200,
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.row.user.email;
      },
    },
  ];

  const classes = useStyles();

  const refresh = useCallback(() => {
    refetch({}, {fetchPolicy: 'network-only'});
  }, []);

  return (
    <Fragment>
      <div className={classes.dataGridWrapper}>
        <DataGridPro
          columns={columns}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          density={'compact'}
          disableSelectionOnClick
          hideFooter
          loading={isLoadingNext}
          // onStateChange={() => console.log(eventRows)}
          // onPageChange={handlePageChange}
          // page={page}
          // paginationMode={'server'}
          // rowCount={rowCount}
          rows={rows}
        />
      </div>

      <Grid
        alignItems={'center'}
        className={classes.customGridFooter}
        container
        justifyContent={'center'}
        spacing={4}
      >
        <Grid item>
          <Button color={'secondary'} onClick={() => refresh()} variant={'outlined'}>
            Refresh
          </Button>
        </Grid>
        <Grid item>
          Showing {rows.length} of {rowCount} rows
        </Grid>
        <Grid item>
          <Button
            color={'primary'}
            disabled={isLoadingNext || !hasNext}
            onClick={() => {
              loadNext(PAGE_SIZE);
            }}
            variant={'contained'}
          >
            Load more
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{position: 'absolute', top: 0, width: '100%', zIndex: 9999}}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

export default EventLogView;
