import React, {Fragment, ReactNode} from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  settingValue: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  infoContainer: {
    '-webkit-box-align': 'center',
    'box-align': 'center',
    'align-items': 'center',
    'display': 'flex',
    'padding-top': '4px',
  },
  myDisabled: {
    'background-color': 'rgba(0, 0, 0, 0.03)',
    'paddingRight': '35px',
  },
  childrenContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  saveButton: {
    color: '#08f',
  },
}));

export type PanelId = string | number;

type Props = {
  /** panelId: string | number, a unique id used to populate id & aria fields
   * */
  panelId: PanelId;
  /** settingName: string, the name of the setting/header for the component
   * */
  settingName: string;
  /** settingValue: string, the value associated with the settingName
   * */
  settingValue: string | null;
  /** (optional) onSave: function, what to do when clicking the save button. Passes optional panelId back to function.
   * */
  onSave?: (id?: PanelId) => void;
  /** (optional) onClose: function, what to do when clicking the close button. Passes optional panelId back to function.
   *                      If not provided, and setExpanded is provided, defaults to setExpanded(false)
   * */
  onClose?: (id?: PanelId) => void;
  /** (optional) children: ReactNode, components to be rendered in the AccordionDetails (only available
   *  if expanded & not disabled).
   * */
  children?: ReactNode;
  /** (optional) disabled: boolean, used for immutable setting values. Applies special styling to the
   *  accordion and prevents it from opening.
   * */
  disabled?: boolean;
  /** (optional) expanded: string | false, either the panelNum (e.g. "panel-2") to expand that particular
   *  panel, or [false] to prevent opening.
   * */
  expanded?: string | false;
  /** (optional) setExpanded: callback function, used to update the "expanded" value for displaying
   *  accordion contents
   * */
  setExpanded?: React.Dispatch<React.SetStateAction<string | false>>;
  /** (optional) unmountOnExit: boolean, determines if components inside the Accordion unmount after
   *  it is closed again
   * */
  unmountOnExit?: boolean;
};

const InputsAccordion: React.FC<Props> = ({
  children,
  disabled,
  expanded,
  panelId,
  settingName,
  settingValue,
  unmountOnExit,
  setExpanded,
  onSave,
  onClose,
}) => {
  const classes = useStyles();
  const panelUid = `panel-${panelId}`;

  const handleChange = (isExpanded: boolean, panel: string) => {
    if (setExpanded !== undefined) {
      setExpanded(isExpanded ? panel : false);
    }
  };

  return (
    <Accordion
      TransitionProps={unmountOnExit ? {unmountOnExit: true} : undefined}
      className={`${disabled ? classes.myDisabled : ''}`}
      expanded={disabled ? false : expanded === panelUid}
      onChange={
        setExpanded !== undefined
          ? (_event: React.ChangeEvent<unknown>, isExpanded: boolean) =>
              handleChange(isExpanded, panelUid)
          : undefined
      }
    >
      <AccordionSummary
        aria-controls={`${panelUid}-content`}
        className={classes.infoContainer}
        expandIcon={!disabled && <ExpandMoreIcon />}
        id={`${panelUid}-header`}
      >
        <Typography variant={'overline'}>{settingName}</Typography>
        {expanded !== panelUid && (
          <Typography className={classes.settingValue}>{settingValue}</Typography>
        )}
      </AccordionSummary>
      {!disabled && (
        <Fragment>
          <AccordionDetails className={classes.childrenContainer}>{children}</AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button
              onClick={() => {
                onClose ? onClose(panelId) : setExpanded ? setExpanded(false) : null;
              }}
              size={'small'}
            >
              Cancel
            </Button>
            <Button
              className={classes.saveButton}
              onClick={() => {
                onSave && onSave(panelId);
              }}
              size={'small'}
            >
              Save
            </Button>
          </AccordionActions>
        </Fragment>
      )}
    </Accordion>
  );
};

export default InputsAccordion;
