/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserListTableRowsPaginationQueryVariables = {
    count?: number | null | undefined;
    cursor?: string | null | undefined;
    email?: string | null | undefined;
    fName?: string | null | undefined;
    lName?: string | null | undefined;
};
export type UserListTableRowsPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"UserListTableRows_admin">;
};
export type UserListTableRowsPaginationQuery = {
    readonly response: UserListTableRowsPaginationQueryResponse;
    readonly variables: UserListTableRowsPaginationQueryVariables;
};



/*
query UserListTableRowsPaginationQuery(
  $count: Int
  $cursor: String
  $email: String
  $fName: String
  $lName: String
) {
  ...UserListTableRows_admin_VnGkG
}

fragment UserListRow_user on User {
  id
  created
  email
  firstName
  lastName
}

fragment UserListTableRows_admin_VnGkG on Query {
  admin {
    users(query: {filter: {email: {includes: $email}, firstName: {includes: $fName}, lastName: {includes: $lName}}, sort: CREATED, sortDir: DESC}, first: $count, after: $cursor) {
      edges {
        node {
          id
          ...UserListRow_user
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lName"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "includes",
                "variableName": "email"
              }
            ],
            "kind": "ObjectValue",
            "name": "email"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "includes",
                "variableName": "fName"
              }
            ],
            "kind": "ObjectValue",
            "name": "firstName"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "includes",
                "variableName": "lName"
              }
            ],
            "kind": "ObjectValue",
            "name": "lastName"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      },
      {
        "kind": "Literal",
        "name": "sort",
        "value": "CREATED"
      },
      {
        "kind": "Literal",
        "name": "sortDir",
        "value": "DESC"
      }
    ],
    "kind": "ObjectValue",
    "name": "query"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserListTableRowsPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          },
          {
            "kind": "Variable",
            "name": "fName",
            "variableName": "fName"
          },
          {
            "kind": "Variable",
            "name": "lName",
            "variableName": "lName"
          }
        ],
        "kind": "FragmentSpread",
        "name": "UserListTableRows_admin"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserListTableRowsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "query"
            ],
            "handle": "connection",
            "key": "UserListTableRows_admin_users",
            "kind": "LinkedHandle",
            "name": "users"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "139aeca91905cf818854b2a05fb1bbcc",
    "id": null,
    "metadata": {},
    "name": "UserListTableRowsPaginationQuery",
    "operationKind": "query",
    "text": "query UserListTableRowsPaginationQuery(\n  $count: Int\n  $cursor: String\n  $email: String\n  $fName: String\n  $lName: String\n) {\n  ...UserListTableRows_admin_VnGkG\n}\n\nfragment UserListRow_user on User {\n  id\n  created\n  email\n  firstName\n  lastName\n}\n\nfragment UserListTableRows_admin_VnGkG on Query {\n  admin {\n    users(query: {filter: {email: {includes: $email}, firstName: {includes: $fName}, lastName: {includes: $lName}}, sort: CREATED, sortDir: DESC}, first: $count, after: $cursor) {\n      edges {\n        node {\n          id\n          ...UserListRow_user\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b6f36963e4ed6c087123e0060e814210';
export default node;
