import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {getDaysInMonth} from 'date-fns';

import {TTimelineEvidence, TWorkspaceSortKey} from '../../../state/timeline/state';

import EvidenceNodeDot from './evidence-node-dot';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: '2fr auto 3fr',
    width: '100%',
    color: 'var(--workspace-contrast-text-color)',
  },
  monthAndYear: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  dayLabels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '0.5rem',
  },
  timeLabel: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    paddingLeft: '2.5%',
    fontSize: '0.5rem',
    height: '100%',
  },
  evidenceDots: {
    height: '100%',
    background: theme.palette.primary.dark,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    position: 'relative',
  },
}));

type Props = {
  trackVisibility?: boolean;
  monthDate: Date;
  evidences: TTimelineEvidence[];
  sortKey: TWorkspaceSortKey;
  size?: number;
  connectionTarget?: boolean;
  // setDotVisibilityStatus(args: Record<string, boolean>): void;
};

const EvidenceNodeMonth: React.FC<Props> = ({
  trackVisibility,
  monthDate,
  evidences,
  sortKey,
  connectionTarget,
  // setDotVisibilityStatus,
}) => {
  const classes = useStyles();
  const totalDaysInMonth = getDaysInMonth(monthDate);
  const daysOfTheMonth = [
    1,
    ...new Array(6).fill(0).map((_, index) => Math.round(totalDaysInMonth * ((index + 1) / 6))),
  ];

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx('monthAndYear', classes.monthAndYear)}>
        <span>{monthDate.toLocaleString('en-US', {month: 'short'})}</span>
        <span>{monthDate.getFullYear()}</span>
      </div>
      <div className={clsx('monthDays', classes.dayLabels)}>
        {daysOfTheMonth.map((day) => (
          <div key={`day-${day}`}>{day}</div>
        ))}
      </div>
      <div className={clsx(classes.evidenceDots)}>
        <div className={clsx('hours', classes.timeLabel)}>
          {[6, 12, 18].map((time) => (
            <div key={`time-${time}`}>{time}</div>
          ))}
        </div>
        {evidences.map((evidence) => {
          return (
            <EvidenceNodeDot
              connectionTarget={connectionTarget}
              evidence={evidence}
              key={evidence.id}
              // setDotVisibilityStatus={setDotVisibilityStatus}
              sortKey={sortKey}
              trackVisibility={!!trackVisibility}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EvidenceNodeMonth;
