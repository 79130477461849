import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  RemoveEvidenceTagByIdInput,
  removeTagFromEvidenceMutation,
} from '../../graphql/__generated__/removeTagFromEvidenceMutation.graphql';

const mutation = graphql`
  mutation removeTagFromEvidenceMutation($input: RemoveEvidenceTagByIdInput!) {
    removeEvidenceTagById(input: $input) {
      folder {
        ...evidenceNav_tagsNav
        ...evidenceMain_tagFilter
      }
      evidenceNodes {
        node {
          ...evidenceDetail_tagGroup
          ...evidenceDetail_addTag
          tagCount
        }
      }
    }
  }
`;

function useRemoveTagFromEvidence(): [
  (tagId: number, evidenceIds: string[], folderId: string, callback: () => void) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<removeTagFromEvidenceMutation>(mutation);
  return [
    useCallback(
      (tagId, evidenceIds, folderId, callback) => {
        const input: RemoveEvidenceTagByIdInput = {
          tagId,
          evidenceIds,
          folderId,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted() {
            callback();
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useRemoveTagFromEvidence;
