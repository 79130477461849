import React, {Fragment, ReactElement, useCallback} from 'react';

import {Chip, Typography, makeStyles} from '@material-ui/core';
import {connect, useDispatch} from 'react-redux';
import {graphql, useFragment} from 'react-relay';

import {evidenceDetail_tagGroup$key} from '../../graphql/__generated__/evidenceDetail_tagGroup.graphql';

import useRemoveTagFromEvidence from '../../hooks/mutations/remove-tag-from-evidence.hook';

import {arraySeveralIntersection} from '../../utils/utils';

import {setApiFeedback} from '../../state/common/actions';
import {setCurrentTagFilterIds} from '../../state/evidence/actions';
import {getCurrentTagFilterIds} from '../../state/evidence/selectors';
import {TRootState} from '../../state/store';

import LoadingScreen from '../feedback/loading-screen';
import ChipGroup from '../layout/chip-group';
import {Referrer} from './evidence-detail.controller';

const mapStateToProps = (state: TRootState) => ({
  currentTagFilterIds: getCurrentTagFilterIds(state),
});

const mapDispatchToProps = {
  setCurrentTagFilterIds,
};

const useStyles = makeStyles({
  chip: {
    height: '100%',
  },
  chipLabel: {
    whiteSpace: 'normal',
    fontSize: 'inherit',
    paddingBlock: '0.390625rem',
    wordBreak: 'break-all',
  },
  chipGroupWrapper: {
    position: 'relative',
  },
});

type TagChipGroupProps = {
  evidence: evidenceDetail_tagGroup$key;
  currentFolderId: string;
  currentTagFilterIds: number[];
  referrer: Referrer['referrer'];
  setCurrentTagFilterIds(ids: number[]): void;
};

const TagChipGroupComponent = ({
  evidence,
  currentFolderId,
  currentTagFilterIds,
  referrer,
  setCurrentTagFilterIds,
}: TagChipGroupProps): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [removeTagFromEvidence, loading] = useRemoveTagFromEvidence();

  const handleDeleteTag = useCallback(
    (tag) => {
      const evidenceIds = data.map((e) => e.id);
      const evidenceFilenames = data.map((e) => e.fileName).join(', ');
      const tagId = parseInt(tag.id, 10);
      const callback = () => {
        // this also deletes the tag filter even if there are other evidence still in the list
        // might be a bug...
        setCurrentTagFilterIds(currentTagFilterIds.filter((tid) => tid !== tagId));
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Tag: ${tag.text} removed from evidences: ${evidenceFilenames}`,
          }),
        );
      };
      removeTagFromEvidence(tagId, evidenceIds, currentFolderId, callback);
    },
    [removeTagFromEvidence],
  );

  const data = useFragment(
    graphql`
      fragment evidenceDetail_tagGroup on Evidence @relay(plural: true) {
        id
        fileName: name
        tags: tagsWithIds {
          id
          text
        }
      }
    `,
    evidence,
  );

  // todo: could be made a util function and shared w folders
  const tags = data.flatMap((d) => d?.tags);
  const uniqueTags = [...new Map(tags.map((item) => [item?.id, item])).values()];
  const evidenceTagIdArrays = data.map((d) => d?.tags.slice().map((t) => t.id));
  const sharedTagIds = arraySeveralIntersection(evidenceTagIdArrays);
  const sharedTags = uniqueTags?.filter((t) => sharedTagIds?.includes(t.id));

  // still need to resort after finding intersection
  // todo: get shared tags via db query?
  const sortedTags = sharedTags.sort((a, b) => {
    if (typeof a === 'undefined' || typeof b === 'undefined') {
      return 0;
    }
    return a.text.localeCompare(b.text);
  });

  if (sortedTags.length > 0) {
    return (
      <div className={classes.chipGroupWrapper}>
        {loading && <LoadingScreen local />}
        <ChipGroup>
          <Fragment>
            {sortedTags.map((t) => (
              <Chip
                className={classes.chip}
                key={t?.id}
                label={<Typography className={classes.chipLabel}>{t?.text}</Typography>}
                onDelete={referrer === 'evidence-list' ? () => handleDeleteTag(t) : undefined}
              />
            ))}
          </Fragment>
        </ChipGroup>
      </div>
    );
  } else {
    return <b> N/A</b>;
  }
};

const TagChipGroup = connect(mapStateToProps, mapDispatchToProps)(TagChipGroupComponent);

export default TagChipGroup;
