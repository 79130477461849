import React, {Fragment, ReactElement, useEffect, useState} from 'react';

import {Button, Dialog, DialogContent, IconButton, Tooltip} from '@material-ui/core';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridSelectionModel,
  MuiEvent,
} from '@mui/x-data-grid-pro';

import DialogHeader from '../../../../components/dialogs/dialog-header';

import {customDateTimeString} from '../../../../utils/utils';

import {TWorkspace} from '../../../../state/timeline/state';

type Props = {
  workspaces: TWorkspace[];
  selectedWorkspaceId: string | undefined;
  open: boolean;
  onClose(): void;
  onSelectWorkspace(workspaceId: string): void;
  onCreateWorkspace(): void;
  onRenameWorkspace(workspaceId: string): void;
  onCloneWorkspace(workspaceId: string): void;
  onDeleteWorkspace(workspaceId: string): void;
};

const WorkspaceSelectionModalView = ({
  workspaces,
  selectedWorkspaceId,
  open,
  onClose,
  onSelectWorkspace,
  onCreateWorkspace,
  onRenameWorkspace,
  onCloneWorkspace,
  onDeleteWorkspace,
}: Props): ReactElement => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  // select initial evidence
  useEffect(() => {
    if (selectedWorkspaceId !== undefined) {
      setSelectionModel([selectedWorkspaceId]);
    }
  }, [selectedWorkspaceId]);

  const columns: GridColDef[] = [
    // {field: 'id', headerName: 'ID', width: 90},
    {
      field: 'name',
      headerName: 'Workspace',
      flex: 2,
    },
    {
      field: 'modified',
      headerName: 'Last modified',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        const onRename = (e: MuiEvent<React.MouseEvent>) => {
          e.stopPropagation(); // don't select this row after clicking

          onRenameWorkspace(params.id as string);
          onClose();
        };

        const onClone = (e: MuiEvent<React.MouseEvent>) => {
          e.stopPropagation(); // don't select this row after clicking

          onCloneWorkspace(params.id as string);
          onClose();
        };

        const onDelete = (e: MuiEvent<React.MouseEvent>) => {
          e.stopPropagation(); // don't select this row after clicking

          onDeleteWorkspace(params.id as string);
          onClose();
        };

        return (
          <Fragment>
            <Tooltip placement={'top-end'} title={`Rename ${params.row.name}`}>
              <IconButton onClick={onRename}>
                <EditIcon>Rename</EditIcon>
              </IconButton>
            </Tooltip>
            <Tooltip placement={'top'} title={`Clone ${params.row.name}`}>
              <IconButton onClick={onClone}>
                <FileCopyIcon>Clone</FileCopyIcon>
              </IconButton>
            </Tooltip>
            <Tooltip placement={'top-start'} title={`Delete ${params.row.name}`}>
              <IconButton onClick={onDelete}>
                <DeleteForeverIcon>Delete</DeleteForeverIcon>
              </IconButton>
            </Tooltip>
          </Fragment>
        );
      },
    },
  ];

  const handleOnCellClick = (params: GridCellParams, e: MuiEvent<React.MouseEvent>) => {
    if (params.field == 'name') {
      e.stopPropagation(); // don't select this row after clicking
      onSelectWorkspace(params.id as string);
      onClose();
    }
  };

  const handleOnAdd = () => {
    onCreateWorkspace();
  };

  const rows = workspaces.map(({id, name, modified}) => ({
    id,
    name,
    modified: customDateTimeString(modified),
  }));

  return (
    <Dialog disableEnforceFocus fullWidth maxWidth={'md'} onClose={onClose} open={open}>
      <DialogHeader handleClose={onClose} id={'workspace-selection-modal-title'}>
        Workspaces
        <br />
        <Tooltip placement={'top-end'} title={'Add new workspace'}>
          <Button onClick={handleOnAdd} startIcon={<CreateNewFolderIcon />} variant={'contained'}>
            Add workspace
          </Button>
        </Tooltip>
      </DialogHeader>

      <DialogContent>
        <DataGridPro
          autoHeight
          columns={columns}
          density={'compact'}
          disableSelectionOnClick
          onCellClick={handleOnCellClick}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          pageSize={20}
          rows={rows}
          rowsPerPageOptions={[20]}
          selectionModel={selectionModel}
        />
      </DialogContent>
    </Dialog>
  );
};

export default WorkspaceSelectionModalView;
