import React from 'react';
import type {ReactElement} from 'react';

import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import {format} from 'date-fns';
import {useDispatch} from 'react-redux';
import {graphql, useFragment, useMutation} from 'react-relay';
import {Link} from 'react-router-dom';

import {USER_BASE} from '../../../config/routes';

import type {UserListRowImpersonateUserMutation} from '../../../graphql/__generated__/UserListRowImpersonateUserMutation.graphql';
import type {UserListRow_user$key} from '../../../graphql/__generated__/UserListRow_user.graphql';

import {setApiFeedback} from '../../../state/common/actions';

const useStyles = makeStyles((theme) => ({
  centerInCell: {
    textAlign: 'center',
  },
  loginIcon: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

type Props = {
  user: UserListRow_user$key;
};

const UserListRow = ({user}: Props): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [commitImpersonateUser] = useMutation<UserListRowImpersonateUserMutation>(graphql`
    mutation UserListRowImpersonateUserMutation($input: ImpersonateUserInput!) {
      impersonateUser(input: $input) {
        clientMutationId
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment UserListRow_user on User {
        id
        created
        email
        firstName
        lastName
      }
    `,
    user,
  );

  return (
    <TableRow hover key={data.id} tabIndex={-1}>
      <TableCell id={data.id} scope={'row'}>
        <Link to={`${USER_BASE}/${data.id}`}>{data.email}</Link>
      </TableCell>
      <TableCell>{data.firstName ?? null}</TableCell>
      <TableCell>{data.lastName ?? null}</TableCell>
      <TableCell>
        {typeof data.created === 'string'
          ? format(new Date(data.created), 'MM/dd/yy, HH:mm')
          : null}
      </TableCell>
      <TableCell className={classes.centerInCell}>
        <Tooltip
          aria-label={`Impersonate ${data.email}`}
          enterDelay={500}
          placement={'top'}
          title={`Impersonate ${data.email}`}
        >
          <Button
            color={'secondary'}
            onClick={() => {
              commitImpersonateUser({
                variables: {
                  input: {
                    userId: data.id,
                  },
                },
                onCompleted() {
                  location.replace('/evidence');
                },
                /* eslint-disable @typescript-eslint/no-explicit-any */
                onError(e: any) {
                  dispatch(setApiFeedback({message: e[0].message}));
                },
              });
            }}
            startIcon={<ExitToAppRoundedIcon className={classes.loginIcon} />}
            variant={'contained'}
          >
            Impersonate
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default UserListRow;
