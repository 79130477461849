import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {
  getCalendarMonths,
  getOpenedEvidenceNode,
  getOpenedWorkspaceTag,
  getSelectedWorkspaceId,
  getWorkspacePosition,
  getWorkspaceSortKey,
} from '../../../state/timeline/selectors';

import TimelineCanvasCalendarView from './timeline-canvas-calendar.view';

const mapStateToProps = (state: TRootState) => ({
  selectedWorkspaceId: getSelectedWorkspaceId(state),
  months: getCalendarMonths(state),
  sortKey: getWorkspaceSortKey(state),
  workspacePosition: getWorkspacePosition(state),
  openedEvidenceNode: getOpenedEvidenceNode(state),
  openedWorkspaceTag: getOpenedWorkspaceTag(state),
});

const mapDispatchToProps = {};

const TimelineCanvasCalendar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimelineCanvasCalendarView);

export default TimelineCanvasCalendar;
