import React, {ReactElement} from 'react';

import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {useDispatch} from 'react-redux';
import {graphql, useFragment} from 'react-relay';

import {evidenceDetail_comments$key} from '../../graphql/__generated__/evidenceDetail_comments.graphql';

import useDeleteComment from '../../hooks/mutations/delete-comment.hook';

import {arraySeveralIntersection, readableDateString} from '../../utils/utils';

import {setApiFeedback} from '../../state/common/actions';

import {TComment} from '../../services/evidences/data/types';
import LoadingScreen from '../feedback/loading-screen';
import {Referrer} from './evidence-detail.controller';

const useStyles = makeStyles((theme) => ({
  commentsList: {
    marginTop: theme.spacing(1),
    maxHeight: '200px',
    overflowY: 'auto',
    padding: 0,
    position: 'relative',
  },
  listItem: {
    backgroundColor: theme.palette.grey[100],
    margin: `${theme.spacing(0.5)}px 0`,
  },
  secondaryAction: {
    top: '1.5rem',
  },
  deleteButton: {
    'background': 'transparent',
    '&:hover, &.Mui-focusVisible': {color: 'black'},
  },
}));

type CommentGroupProps = {
  referrer: Referrer['referrer'];
  evidence: evidenceDetail_comments$key;
  currentFolderId: string;
};

const CommentGroup = ({referrer, evidence, currentFolderId}: CommentGroupProps): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleteComment, loading] = useDeleteComment();

  const data = useFragment(
    graphql`
      fragment evidenceDetail_comments on Evidence @relay(plural: true) {
        comments {
          id
          created
          body
          authorName
        }
      }
    `,
    evidence as evidenceDetail_comments$key,
  );

  interface SharedComment extends Omit<TComment, 'id' | 'evidenceId'> {
    ids: string[];
  }

  const comments: SharedComment[] = [];

  const commentsData = data.flatMap((d) => d?.comments.map((c) => ({...c})));

  if (data?.length > 0) {
    // using body is not ideal but id is not unique due to issues w db structure
    const evidenceCommentArrays = data.map((e) => e?.comments.map((c) => c?.body));
    const sharedCommentBodies = arraySeveralIntersection(evidenceCommentArrays);
    sharedCommentBodies?.forEach((scb) => {
      const sharedComments = commentsData.filter((f) => scb === f.body);
      const mergedComment = {
        ids: sharedComments.map((c) => c.id),
        body: sharedComments[0].body,
        created: new Date(sharedComments[0].created),
        authorName: sharedComments[0].authorName,
      };
      comments.push(mergedComment);
    });
  }

  const sortedComments = comments?.sort((a, b) => {
    if (typeof a === 'undefined' || typeof b === 'undefined') {
      return 0;
    }
    return b.created.getTime() - a.created.getTime();
  });

  const handleDeleteComment = (ids: string[]) => {
    const callback = () => {
      dispatch(
        setApiFeedback({
          severity: 'success',
          message: `Comment deleted.`,
        }),
      );
    };
    deleteComment(currentFolderId, ids, callback);
  };

  if (sortedComments?.length > 0) {
    return (
      <List className={classes.commentsList} dense>
        {loading && <LoadingScreen local />}
        {sortedComments.map(({ids, authorName, created, body}) => (
          <ListItem className={classes.listItem} key={ids.join('-')}>
            <ListItemText
              primary={body}
              secondary={`${authorName} | ${readableDateString(created)}`}
            />
            {referrer === 'evidence-list' && (
              <ListItemSecondaryAction className={classes.secondaryAction}>
                <Tooltip placement={'top-end'} title={'Delete comment'}>
                  <IconButton
                    aria-label={'delete'}
                    className={classes.deleteButton}
                    edge={'end'}
                    onClick={() => handleDeleteComment(ids)}
                  >
                    <Delete fontSize={'small'} />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    );
  } else {
    return <b> N/A</b>;
  }
};

export default CommentGroup;
