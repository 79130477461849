import React, {useCallback, useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import {graphql, useFragment} from 'react-relay';

import {evidenceDetail_activeTags$key} from '../../graphql/__generated__/evidenceDetail_activeTags.graphql';
import {evidenceDetail_addTag$key} from '../../graphql/__generated__/evidenceDetail_addTag.graphql';

import useAddTagToEvidence from '../../hooks/mutations/add-tag-to-evidence.hook';

import {setApiFeedback} from '../../state/common/actions';

import LoadingScreen from '../feedback/loading-screen';
import AutocompleteFreeSoloCreateOption, {
  OptionType,
} from '../inputs/autocompletes/autocomplete-free-solo-create-option';

const useStyles = makeStyles((theme) => ({
  freeSoloCreateOption: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
}));

type Props = {
  account: evidenceDetail_activeTags$key;
  evidence: evidenceDetail_addTag$key;
  currentFolderId: string;
};

const EvidenceDetailAddTag: React.FC<Props> = ({account, evidence, currentFolderId}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [addTagToEvidence, loading] = useAddTagToEvidence();
  const [value, setValue] = useState<OptionType | null>(null);

  const data = useFragment(
    graphql`
      fragment evidenceDetail_addTag on Evidence @relay(plural: true) {
        id
        fileName: name
      }
    `,
    evidence,
  );

  const activeTagsData = useFragment(
    graphql`
      fragment evidenceDetail_activeTags on Account {
        activeTagsWithIds {
          id
          text
        }
      }
    `,
    account,
  );

  const evidenceIds = data.map((e) => e?.id);
  const evidenceFilenames = data.map((e) => e?.fileName).join(', ');
  const tags = activeTagsData?.activeTagsWithIds ?? [];

  useEffect(() => {
    if (value !== null) {
      const tag = value.title;
      handleAddTagToEvidence(tag, evidenceIds, currentFolderId);
    }
  }, [value]);

  const handleAddTagToEvidence = useCallback(
    (tag, evidenceIds, currentFolderId) => {
      const callback = () => {
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Tag: ${tag} added to evidence: ${evidenceFilenames}`,
          }),
        );
      };
      addTagToEvidence(tag, evidenceIds, currentFolderId, callback);
      setValue(null);
    },
    [addTagToEvidence],
  );

  if (tags === undefined) {
    return <div>No options</div>;
  }

  return (
    <div className={classes.freeSoloCreateOption}>
      {loading && <LoadingScreen local />}
      <AutocompleteFreeSoloCreateOption
        label={'Add existing/new tag'}
        options={tags.map(({text: title}) => ({
          title,
        }))}
        setValue={setValue}
        value={value}
      />
    </div>
  );
};

export default EvidenceDetailAddTag;
