import React, {ReactElement, ReactNode} from 'react';

import {DialogTitle, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Close} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
  },
  titleTextWrapper: {paddingRight: theme.spacing(4)},
}));

type Props = {
  id?: string; // for aria-labelledby
  children: ReactNode | string;
  handleClose(): void;
};

const DialogHeader = ({id, children, handleClose}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <DialogTitle id={id}>
      <div className={classes.titleTextWrapper}>{children}</div>
      <IconButton aria-label={'close'} className={classes.closeButton} onClick={handleClose}>
        <Close />
      </IconButton>
    </DialogTitle>
  );
};

export default DialogHeader;
