/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetail_grid = ReadonlyArray<{
    readonly id: string;
    readonly fileName: string;
    readonly " $refType": "evidenceDetail_grid";
}>;
export type evidenceDetail_grid$data = evidenceDetail_grid;
export type evidenceDetail_grid$key = ReadonlyArray<{
    readonly " $data"?: evidenceDetail_grid$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_grid">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "evidenceDetail_grid",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "fileName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Evidence",
  "abstractKey": "__isEvidence"
};
(node as any).hash = '077fbc58e748f00dda0cd32b7bd38dd5';
export default node;
