import React, {ChangeEvent, useState} from 'react';

import {Slider} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';

import {setWorkspacePosition} from '../../../state/timeline/actions';

const useStyles = makeStyles<Theme>(() => ({
  root: {
    '& > .MuiSlider-track': {
      '--track-height': '100px',
      'top': 'calc(-1 * var(--track-height) + 13px)',
      'height': 'var(--track-height)',
      'opacity': 0.3,
    },
  },
}));

type Props = {
  workspacePosition: {x: number; y: number} | undefined;
  selectedWorkspaceId: string | undefined;
};

const TimelineFilmstripIndicator: React.FC<Props> = ({workspacePosition, selectedWorkspaceId}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const minDistance = 20;
  const {x, y} = workspacePosition ?? {x: 0, y: 100};
  const [value, setValue] = useState<number[]>([x, y]);

  const handleChange = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: ChangeEvent<Record<string, unknown>>,
    newValue: number | number[],
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[0] !== value[0]) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  const handleChangeCommitted = () => {
    if (selectedWorkspaceId) {
      dispatch(setWorkspacePosition({workspaceId: selectedWorkspaceId, x: value[0], y: value[1]}));
    }
  };

  return (
    <Slider
      className={classes.root}
      onChange={handleChange}
      onChangeCommitted={handleChangeCommitted}
      step={0.001}
      value={value}
      valueLabelDisplay={'off'}
    />
  );
};

export default TimelineFilmstripIndicator;
