import {compareAsc} from 'date-fns';
import {createSelector} from 'reselect';

import {
  arrayPairIntersection,
  hasIntersection,
  isNotNullish,
  monthYearString,
} from '../../utils/utils';

import {DEFAULT_TAG_COLOR} from '../../pages/timeline/tag-node-color-picker/color-picker.constants';
import {Timestamp} from '../../services/common/data/types';
import {TRootState} from '../store';
import {
  DEFAULT_WORKSPACE_STATE,
  TCaseTag,
  TTimelineEvidence,
  TTimelineState,
  TWorkspace,
} from './state';

export const getTimeline = (state: TRootState): TTimelineState => {
  return state.timeline;
};

export const getIsLoading = createSelector([getTimeline], (timeline) => timeline.isLoading);

// export const getVisibleEvidenceIds = createSelector([getTimeline], (timeline) => {
//   return Object.entries(timeline.visibleEvidences)
//     .filter((e) => e[1]) // visible = true
//     .map((e) => e[0]); // map to the evidence id
// });

export const getVisibleEvidenceIds = createSelector(
  [getTimeline],
  (timeline) => timeline.visibleEvidenceIds,
);

export const getFolderFilters = createSelector(
  [getTimeline],
  (timeline) => timeline.filters.folders,
);

export const getTagFilters = createSelector([getTimeline], (timeline) => timeline.filters.tags);

export const getTypeFilters = createSelector([getTimeline], (timeline) => timeline.filters.types);

export const getAllFolders = createSelector([getTimeline], (timeline) =>
  timeline.entities.folders.allIds?.map((id) => timeline.entities.folders.byId[id]),
);

export const getAllTags = createSelector([getTimeline], (timeline) =>
  timeline.entities.tags.allIds
    .map((id) => timeline.entities.tags.byId[id])
    .sort((a, b) => {
      return a.text.localeCompare(b.text);
    }),
);

export const getAllTagIds = createSelector(
  [getTimeline],
  (timeline) => timeline.entities.tags.allIds,
);

export const getTagByIdObject = createSelector(
  [getTimeline],
  (timeline) => timeline.entities.tags.byId,
);

export const getAllEvidences = createSelector([getTimeline], (timeline) =>
  timeline.entities.evidences.allIds?.map((id) => timeline.entities.evidences.byId[id]),
);

export const getEvidenceByIdObject = createSelector(
  [getTimeline],
  (timeline) => timeline.entities.evidences.byId,
);

export const getAllTypes = createSelector([getTimeline], (timeline) =>
  timeline.entities.types.allIds?.map((id) => timeline.entities.types.byId[id]),
);

export const getEvidenceCount = createSelector([getAllEvidences], (evidences) => evidences.length);

export const getEvidenceSize = createSelector([getAllEvidences], (evidences) =>
  evidences.reduce((count: number, evidence) => {
    return count + evidence.size;
  }, 0),
);

export const getSelectedWorkspaceId = createSelector(
  [getTimeline],
  (timeline) => timeline.selectedWorkspaceId,
);

export const getWorkspaces = createSelector([getTimeline], (timeline) =>
  timeline.entities.workspaces.allIds
    .map((id) => timeline.entities.workspaces.byId[id])
    .sort((a, b) => {
      return new Date(b.modified).valueOf() - new Date(a.modified).valueOf();
    }),
);

type GetWorkspaceByIdReturn = (state: TRootState) => TWorkspace | undefined;

export const getWorkspaceById = (workspaceId: string): GetWorkspaceByIdReturn =>
  createSelector([getTimeline], (timeline) => timeline.entities.workspaces.byId[workspaceId]);

export const getSelectedWorkspace = createSelector(
  [getSelectedWorkspaceId, getTimeline],
  // The ternary here is necessary to generate an inferred output type of `TWorkspace | undefined`
  (selectedId, timeline) =>
    selectedId ? timeline.entities.workspaces.byId[selectedId] : undefined,
);

export const getWorkspaceFilters = createSelector(
  [getSelectedWorkspace],
  (workspace) => workspace?.filters,
);

export const getEvidencesInSelectedFolders = createSelector(
  [getAllEvidences, getFolderFilters],
  (evidences, folderIds) => {
    if (folderIds && folderIds.length === 0) {
      return [];
    }
    if (!isNotNullish(folderIds)) {
      return [];
    }
    return evidences.filter((e) => hasIntersection(e.folders, folderIds));
  },
);

export const getAvailableTagFilters = createSelector(
  [getEvidencesInSelectedFolders],
  (evidences) => {
    return [...new Set(evidences.map((e) => e.tags).flat())] as number[];
  },
);

export const getAvailableTypeFilters = createSelector(
  [getEvidencesInSelectedFolders],
  (evidences) => {
    return [
      ...new Set(
        evidences.reduce((nonNullEvidenceMimeTypes: number[], e) => {
          if (e.mimeType.id !== undefined) {
            nonNullEvidenceMimeTypes.push(e.mimeType.id);
          }
          return nonNullEvidenceMimeTypes;
        }, []),
      ),
    ] as number[];
  },
);

export const getAvailableTags = createSelector(
  [getAllTags, getAvailableTagFilters],
  (tags, tagFilters) => {
    return tags.filter((tag) => tagFilters.includes(tag.id));
  },
);

export const getAvailableTypes = createSelector(
  [getAllTypes, getAvailableTypeFilters],
  (types, typeFilters) => {
    return typeFilters.map((typeId) => types.find((mimeType) => mimeType.id === typeId));
  },
);

export const getActiveFilterSet = createSelector(
  [getFolderFilters, getTagFilters, getTypeFilters],
  (folderIds, tagIds, typeIds) => ({
    folderIds,
    tagIds,
    typeIds,
  }),
);

export const getWorkspaceSortKey = createSelector(
  [getTimeline],
  (timeline) => timeline.workspaceSortKey,
);

export const getWorkspaceZoomIndex = createSelector(
  [getSelectedWorkspace],
  (workspace) => workspace?.zoomIndex,
);

/**
 * Returns all evidence after applying all active filters
 */
export const getFilteredEvidences = createSelector(
  [getEvidencesInSelectedFolders, getActiveFilterSet],
  (evidences, filterSet) => {
    return (
      evidences
        // .filter((e) => hasIntersection(filterSet.folderIds, e.folders))
        .filter((e) => hasIntersection(filterSet.tagIds, e.tags))
        .filter((e) => filterSet.typeIds && filterSet.typeIds.includes(e.mimeType.id))
    );
  },
);

export const getFilteredEvidencesTags = createSelector([getFilteredEvidences], (evidences) => {
  if (evidences && evidences.length <= 0) {
    return [];
  }
  const tags = evidences.flatMap((e) => e?.tags);
  const uniqueTags = [...new Map(tags.map((item) => [item, item])).values()];

  return uniqueTags;
});

export const getFilteredEvidencesByMonth = createSelector(
  [getFilteredEvidences, getWorkspaceSortKey],
  (evidences, sortKey) => {
    // const uniqueEvidencesArray = [...new Map(evidences.map((e) => [e.id, e])).values()];
    return evidences.reduce((months, evidence) => {
      const evidenceDate = evidence[sortKey] as Date;
      const sortDate = new Date(monthYearString(evidenceDate)).valueOf();
      const existing = typeof months[sortDate] !== 'undefined' ? months[sortDate] : [];
      return Object.assign({}, months, {[sortDate.valueOf()]: [...existing, evidence]});
    }, {} as Record<Timestamp, TTimelineEvidence[]>) as Record<Timestamp, TTimelineEvidence[]>;
  },
);

export const getCalendarMonths = createSelector([getFilteredEvidencesByMonth], (evidencesByMonth) =>
  Object.entries(evidencesByMonth)
    .map(([monthStr, evidences]) => ({
      month: new Date(Number.parseInt(monthStr, 10)),
      evidences,
    }))
    .sort(({month: aMonth}, {month: bMonth}) => compareAsc(aMonth, bMonth)),
);

const toDate = (val: string | Date) => (typeof val === 'string' ? new Date(val) : val);

export const getFilteredEvidenceDates = createSelector(
  [getFilteredEvidences, getWorkspaceSortKey],
  (evidences, sortKey) =>
    evidences
      .map((e) => e[sortKey])
      .map(toDate)
      .sort((a, b) => a.valueOf() - b.valueOf()),
);

export const getFolderFilterValues = createSelector(
  [getFolderFilters, getAllFolders],
  (folderFilters, folders) => {
    return folders.map((folder) => ({
      item: folder,
      checked: !!(folderFilters && folderFilters.includes(folder.id)),
    }));
  },
);

export const getTagFilterValues = createSelector(
  [getTagFilters, getAvailableTags],
  (tagFilters, tags) => {
    return tags.map((tag: TCaseTag) => ({
      item: tag,
      checked: !!(tagFilters && tagFilters.includes(tag.id)),
    }));
  },
);

export const getTypeFilterValues = createSelector(
  [getTypeFilters, getAvailableTypes],
  (typeFilters, types) => {
    return types.filter(isNotNullish).map((type) => ({
      item: type,
      checked: !!(typeFilters && typeFilters.includes(type.id)),
    }));
  },
);

export const getSelectedWorkspaceTags = createSelector(
  [getTimeline, getSelectedWorkspaceId],
  (timeline, selectedWorkspaceId) =>
    selectedWorkspaceId ? timeline.entities.workspaces.byId[selectedWorkspaceId]?.tags : [],
);

export const getHighlightedWorkspaceTagIds = createSelector(
  [getSelectedWorkspaceTags],
  (workspaceTags) =>
    workspaceTags ? workspaceTags.filter((tag) => tag.lockHighlight).map((tag) => tag.tagId) : [],
);

/**
 * For each filtered evidence, return all tags, with the workspace's tag settings applied if possible
 */
export const getFilteredEvidenceWorkspaceTags = createSelector(
  [getFilteredEvidencesTags, getSelectedWorkspaceTags, getTagByIdObject, getActiveFilterSet],
  (filteredEvidencesTags, selectedWorkspaceTags, tagByIdObject, activeFilterSet) => {
    const filteredTags = arrayPairIntersection(filteredEvidencesTags, activeFilterSet.tagIds)
      .map((tid: number) => {
        if (selectedWorkspaceTags && selectedWorkspaceTags?.length > 0) {
          const foundTag = selectedWorkspaceTags?.find((t) => t.tagId === tid);
          if (foundTag !== undefined) return foundTag;
        }

        // was not found, so create a new one
        const {id, text} = tagByIdObject[tid];
        return {
          tagId: id,
          text: text,
          coordinates: {x: 0, y: 0},
          color: DEFAULT_TAG_COLOR,
          lockHighlight: false,
        };
      })
      .filter(isNotNullish);

    return filteredTags;
  },
);

export const getOpenedWorkspaceTag = createSelector(
  [getTimeline, getSelectedWorkspaceTags],
  (timeline, selectedWorkspaceTags) =>
    selectedWorkspaceTags?.find((t) => t.tagId === timeline.openedWorkspaceTag),
);
export const getOpenedEvidenceNode = createSelector([getTimeline], (timeline) => {
  if (!timeline.openedEvidenceNode) return;
  return timeline.entities.evidences.byId[timeline.openedEvidenceNode];
});

export const getVisibleEvidences = createSelector(
  [getEvidenceByIdObject, getVisibleEvidenceIds],
  (evidenceByIdObject, visibleIds) => visibleIds.map((id) => evidenceByIdObject[id]),
);

export const getVisibleFilteredEvidences = createSelector(
  [getFilteredEvidences, getVisibleEvidenceIds],
  (filteredEvidences, visibleIds) => {
    return filteredEvidences.filter((evidence) => visibleIds.includes(evidence.id));
  },
);

export const getVisibleTagIds = createSelector([getVisibleEvidences], (evidences) => [
  ...new Set(evidences.map((evidence) => evidence.tags).flat()),
]);

// evidences w highlighted tags OR visible evidences
export const getHighlightedEvidences = createSelector(
  [getFilteredEvidences, getVisibleEvidenceIds, getHighlightedWorkspaceTagIds],
  (filteredEvidences, visibleEvidenceIds, highlightedTagIds) =>
    filteredEvidences.filter(
      (evidence) =>
        hasIntersection(evidence.tags, highlightedTagIds) ||
        visibleEvidenceIds.includes(evidence.id),
    ),
);

export const getVisibleWorkspaceTags = createSelector(
  [getSelectedWorkspaceTags, getVisibleTagIds],
  (workspaceTags, tagIds) =>
    workspaceTags ? workspaceTags.filter((tag) => tagIds.includes(tag.tagId)) : [],
);

export const getWorkspacePosition = createSelector([getSelectedWorkspace], (workspace) => {
  if (!workspace) return;

  return workspace.coordinates; // {x, y}
});

export const getOfflineMode = createSelector([getTimeline], (timeline) => timeline.offlineMode);

export const getShowEvidenceCounts = createSelector([getSelectedWorkspace], (workspace) => {
  return workspace?.showEvidenceCounts ?? DEFAULT_WORKSPACE_STATE.showEvidenceCounts;
});

// note: selectors CAN take properts and this would work better as getWorkspaceTagEvidenceIdsByTag
export const getWorkspaceTagEvidenceIds = createSelector(
  [getFilteredEvidenceWorkspaceTags, getFilteredEvidences, getVisibleFilteredEvidences],
  (filteredWorkspaceTags, filteredEvidences, visibleFilteredEvidences) => {
    if (!filteredWorkspaceTags) return [];

    return filteredWorkspaceTags.map((tag) => {
      const evidences = tag?.lockHighlight ? filteredEvidences : visibleFilteredEvidences;
      return {
        tagId: tag.tagId,
        evidenceIds: evidences.filter((e) => e.tags.includes(tag.tagId)).map((e) => e.id) ?? 0,
      };
    });
  },
);
