import {connect} from 'react-redux';

import {setCurrentTagFilterIds} from '../../../state/evidence/actions';
import {getCurrentFolderId, getCurrentTagFilterIds} from '../../../state/evidence/selectors';
import {TRootState} from '../../../state/store';

import EvidenceMainController from './evidence-main.controller';

const mapStateToProps = (state: TRootState) => ({
  currentFolderId: getCurrentFolderId(state),
  currentTagFilterIds: getCurrentTagFilterIds(state),
});

const mapDispatchToProps = {
  setCurrentTagFilterIds,
};

const EvidenceMain = connect(mapStateToProps, mapDispatchToProps)(EvidenceMainController);

export default EvidenceMain;
