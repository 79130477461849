import React, {useState} from 'react';

import {Button, Dialog, DialogActions, DialogContent} from '@material-ui/core';

import DialogHeader from '../../../components/dialogs/dialog-header';

import {TWorkspaceTag} from '../../../state/timeline/state';

import PaletteSelectionGrid from '../palette-selection-grid';
import {workspaceTagColors} from './color-picker.constants';

type Props = {
  tag: TWorkspaceTag | undefined;
  handleClose(): void;
  handleSave(color: string): void;
};

const TagNodeColorPickerView: React.FC<Props> = ({tag, handleClose, handleSave}) => {
  if (tag == undefined) {
    return null;
  }

  const [colorIndex, setColorIndex] = useState(
    workspaceTagColors.findIndex((c) => c === tag.color) || 0,
  );

  return (
    <Dialog onClose={handleClose} open={typeof tag !== 'undefined'}>
      <DialogHeader handleClose={handleClose}>{tag && `Pick a color for ${tag.text}`}</DialogHeader>
      <DialogContent>
        <PaletteSelectionGrid
          handleSelect={setColorIndex}
          palette={workspaceTagColors}
          selectedIndex={colorIndex}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSave(workspaceTagColors[colorIndex])}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagNodeColorPickerView;
