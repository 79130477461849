import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {setWorkspaceTagPosition} from '../../../state/timeline/actions';
import {
  getSelectedWorkspaceId,
  getShowEvidenceCounts,
  getVisibleTagIds,
  getWorkspaceTagEvidenceIds,
} from '../../../state/timeline/selectors';

import TimelineTagNodeController from './timeline-tag-node.controller';

const mapStateToProps = (state: TRootState) => ({
  selectedWorkspaceId: getSelectedWorkspaceId(state),
  showEvidenceCounts: getShowEvidenceCounts(state),
  visibleTagIds: getVisibleTagIds(state),
  workspaceTagEvidenceIds: getWorkspaceTagEvidenceIds(state),
});

const mapDispatchToProps = {setWorkspaceTagPosition};

const TimelineTagNode = connect(mapStateToProps, mapDispatchToProps)(TimelineTagNodeController);

export default TimelineTagNode;
