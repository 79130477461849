import React, {ChangeEvent, ReactElement, useState} from 'react';

import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {makeStyles} from '@material-ui/core/styles';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonPinIcon from '@material-ui/icons/PersonPin';

import TabPanel from '../../components/display/tabs/tab-panel';

import Details from './details';
import Security from './security';
import Tools from './tools';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabHeading: {
    flexGrow: 1,
    marginBottom: theme.spacing(6),
  },
}));

function a11yProps(index: number) {
  return {
    'aria-controls': `settings-configuration-tabpanel-${index}`,
    'id': `settings-configuration-tab-${index}`,
  };
}

const Settings = (): ReactElement => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  return (
    <div className={classes.root}>
      <Paper className={classes.tabHeading}>
        <Tabs
          aria-label={'Settings-Configuration-Options'}
          centered
          indicatorColor={'primary'}
          onChange={(_event: ChangeEvent<unknown>, newValue: number) => {
            setTab(newValue);
          }}
          textColor={'primary'}
          value={tab}
        >
          <Tab icon={<PersonPinIcon />} label={'My details'} {...a11yProps(0)} />
          <Tab icon={<LockOpenIcon />} label={'Security'} {...a11yProps(1)} />
          <Tab icon={<BuildRoundedIcon />} label={'Tools'} {...a11yProps(2)} />
        </Tabs>
      </Paper>
      <TabPanel index={0} value={tab}>
        <Details />
      </TabPanel>
      <TabPanel index={1} value={tab}>
        <Security />
      </TabPanel>
      <TabPanel index={2} value={tab}>
        <Tools />
      </TabPanel>
    </div>
  );
};

export default Settings;
