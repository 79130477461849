import React, {Fragment, useEffect, useRef, useState} from 'react';
import type {Dispatch, MouseEvent, ReactElement, ReactNode, SetStateAction} from 'react';

import Button from '@material-ui/core/Button';
import type {ButtonProps as MuiButtonProps} from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import type {ButtonGroupProps as MuiButtonGroupProps} from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {makeStyles} from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles({
  splitBtnPopper: {
    zIndex: 999,
  },
});

export type ObjectOption = {
  value: string;
};

export type Option = string | ObjectOption;

export type SplitButtonProps = Omit<MuiButtonProps, 'onClick'>;

export type SplitButtonGroupProps = Omit<MuiButtonGroupProps, 'ref' | 'className'>;

export const optionToString = (option: Option): string => {
  return typeof option === 'string' ? option : option.value;
};

type Props<T, U> = {
  options: U;
  onSubmit?: ((option: T) => void) | (() => void);
  menuPlacement?: 'bottom' | 'top' | 'bottom-start';
  renderSelectedButtonText?: (option: T) => ReactNode;
  buttonClassName?: string;
  menuClassName?: string;
  defaultSelectedIndex?: number;
  /** currentValue - Makes component controlled */
  currentValue?: T;
  /** updateCurrentValue - Updates currentValue on menu selection */
  updateCurrentValue?: Dispatch<SetStateAction<T>> | ((option: T) => void);
  LeftButtonProps?: SplitButtonProps;
  RightButtonProps?: SplitButtonProps;
  ButtonGroupProps?: SplitButtonGroupProps;
};

const SplitButton = <T extends Option, U extends T[]>({
  options,
  onSubmit,
  renderSelectedButtonText,
  buttonClassName,
  menuClassName,
  currentValue,
  updateCurrentValue,
  menuPlacement,
  defaultSelectedIndex,
  LeftButtonProps,
  RightButtonProps,
  ButtonGroupProps,
}: Props<T, U>): ReactElement => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultSelectedIndex ?? 0);
  const anchorRef = useRef<HTMLDivElement>(null);

  const {
    'color': bgColor,
    'aria-label': bgLabel,
    'variant': bgVariant,
    ...bgRest
  } = {
    ...ButtonGroupProps,
  };
  const {...lbRest} = {...LeftButtonProps};
  const {
    'color': rbColor,
    size,
    'aria-label': rbLabel,
    'aria-expanded': ariaExpanded,
    'aria-haspopup': ariaHasPopup,
    'aria-controls': ariaControls,
    ...rbRest
  } = {...RightButtonProps};

  useEffect(() => {
    if (currentValue) {
      const current: string = optionToString(currentValue);
      setSelectedIndex(options.findIndex((element) => current === optionToString(element)));
    }
  }, [currentValue]);

  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent<Document, globalThis.MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (
    _event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    if (updateCurrentValue) {
      updateCurrentValue(options[index]);
    }
    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup
        {...bgRest}
        aria-label={bgLabel ?? 'split-button'}
        className={buttonClassName}
        color={bgColor ?? 'inherit'}
        ref={anchorRef}
        variant={bgVariant ?? 'contained'}
      >
        <Button
          {...lbRest}
          onClick={() => (onSubmit ? onSubmit(options[selectedIndex]) : undefined)}
          type={'submit'}
        >
          {renderSelectedButtonText
            ? renderSelectedButtonText(options[selectedIndex])
            : optionToString(options[selectedIndex])}
        </Button>
        <Button
          {...rbRest}
          aria-controls={open ? ariaControls ?? 'split-button-menu' : undefined}
          aria-expanded={open ? ariaExpanded ?? 'true' : undefined}
          aria-haspopup={ariaHasPopup ?? 'menu'}
          aria-label={rbLabel ?? 'select-options'}
          color={rbColor ?? 'primary'}
          onClick={toggleOpen}
          size={size ?? 'small'}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef.current}
        className={`${classes.splitBtnPopper} ${menuClassName ?? ''}`}
        disablePortal
        open={open}
        placement={menuPlacement}
        role={undefined}
        transition
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id={ariaControls ?? 'split-button-menu'}>
                  {options.map((option, index) => {
                    const text = optionToString(option);
                    return (
                      <MenuItem
                        disabled={index === selectedIndex}
                        key={`menu-key-${text}`}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        selected={index === selectedIndex}
                      >
                        {text}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default SplitButton;
