import {fold} from 'fp-ts/Either';
import {pipe} from 'fp-ts/function';
import * as t from 'io-ts';
import {combineReducers, createStore} from 'redux';

import commonReducer from './common/reducer';
import {CommonState} from './common/state';
import evidencesReducer from './evidence/reducer';
import {EvidencesState} from './evidence/state';
import timelineReducer from './timeline/reducer';
import {TimelineState} from './timeline/state';

export const rootReducer = combineReducers({
  common: commonReducer,
  timeline: timelineReducer,
  evidences: evidencesReducer,
});

export const RootState = t.type({
  common: CommonState,
  timeline: TimelineState,
  evidences: EvidencesState,
});

const getInitialState = (): TRootState | undefined => {
  const encodedJson = document.getElementById('encoded_state_data')?.innerText;

  const onLeft = (errors: t.Errors): undefined => {
    console.warn('Failed to decode root state', errors);
    return;
  };
  const onRight = (state: TRootState): TRootState => state;

  return encodedJson
    ? pipe(RootState.decode(JSON.parse(encodedJson)), fold(onLeft, onRight))
    : undefined;
};

export const store = createStore(
  rootReducer,
  getInitialState(), // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export type RootStore = typeof store;

export type TRootState = t.OutputOf<typeof RootState>;
