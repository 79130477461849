import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  CreateFolderInput,
  createFolderMutation,
} from '../../graphql/__generated__/createFolderMutation.graphql';

const mutation = graphql`
  mutation createFolderMutation($input: CreateFolderInput!) {
    createFolder(input: $input) {
      account {
        userFolders {
          type: __typename
          id
          name
          evidenceCount
        }
      }
    }
  }
`;

function useCreateFolder(): [(folderName: string, callback: () => void) => Disposable, boolean] {
  const [commit, isInFlight] = useMutation<createFolderMutation>(mutation);
  return [
    useCallback(
      (name, callback) => {
        const input: CreateFolderInput = {
          name,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted() {
            callback();
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useCreateFolder;
