import SuperTokensLock from 'browser-tabs-lock';

export const fetchWithSession = async (input: string, init?: RequestInit): Promise<Response> => {
  let retried = false;
  for (;;) {
    const tokens = getAccessTokens();
    if (tokens !== undefined) {
      if (init === undefined) {
        init = {headers: tokens};
      } else {
        const headers = init.headers;
        if (headers === undefined) {
          init.headers = tokens;
        } else if (headers instanceof Headers) {
          for (const [name, value] of Object.entries(tokens)) {
            headers.set(name, value);
          }
        } else if (Array.isArray(headers)) {
          for (const [name, value] of Object.entries(tokens)) {
            headers.push([name, value]);
          }
        } else {
          for (const [name, value] of Object.entries(tokens)) {
            headers[name] = value;
          }
        }
      }
    }
    const resp = await fetch(input, init);
    if (resp.status === 401 && tokens !== undefined && !retried) {
      const idRefreshToken = tokens['facz-id-refresh-token'];
      const url = new URL(input);
      await refreshTokens(url.origin, idRefreshToken);
      retried = true;
      continue;
    }
    if (resp.headers !== undefined) {
      setAccessTokens(resp.headers);
    }
    return resp;
  }
};

export type AccessTokens = {
  'facz-access-token': string;
  'facz-id-refresh-token': string;
};

export const getAccessTokens = (): AccessTokens | undefined => {
  const accessToken = localStorage.getItem('facz-access-token');
  const idRefreshToken = localStorage.getItem('facz-id-refresh-token');
  if (accessToken === null || idRefreshToken === null) {
    return undefined;
  }
  return {
    'facz-access-token': accessToken,
    'facz-id-refresh-token': idRefreshToken,
  };
};

export const setAccessTokensObj = (h: {[name: string]: string}): void => {
  const accessToken = h['facz-access-token'];
  if (accessToken !== undefined) {
    localStorage.setItem('facz-access-token', accessToken);
  }
  const refreshToken = h['facz-refresh-token'];
  if (refreshToken !== undefined) {
    localStorage.setItem('facz-refresh-token', refreshToken);
  }
  const idRefreshToken = h['facz-id-refresh-token'];
  if (idRefreshToken !== undefined) {
    localStorage.setItem('facz-id-refresh-token', idRefreshToken);
  }
};

export const setAccessTokens = (h: Headers): void => {
  const accessToken = h.get('facz-access-token');
  if (accessToken !== null) {
    localStorage.setItem('facz-access-token', accessToken);
  }
  const refreshToken = h.get('facz-refresh-token');
  if (refreshToken !== null) {
    localStorage.setItem('facz-refresh-token', refreshToken);
  }
  const idRefreshToken = h.get('facz-id-refresh-token');
  if (idRefreshToken !== null) {
    localStorage.setItem('facz-id-refresh-token', idRefreshToken);
  }
};

type RefreshTokens = AccessTokens & {
  'facz-refresh-token': string;
};

const getRefreshTokens = (): RefreshTokens | undefined => {
  const accessTokens = getAccessTokens();
  if (accessTokens === undefined) {
    return undefined;
  }
  const refreshToken = localStorage.getItem('facz-refresh-token');
  if (refreshToken === null) {
    return undefined;
  }
  return {
    ...accessTokens,
    'facz-refresh-token': refreshToken,
  };
};

const tokenLock = new SuperTokensLock();

export const refreshTokens = async (base: string, idRefreshToken: string): Promise<void> => {
  if (await tokenLock.acquireLock('facz-session-refresh', 1000)) {
    try {
      const tokens = getRefreshTokens();
      if (tokens === undefined || tokens['facz-id-refresh-token'] !== idRefreshToken) {
        return;
      }
      const resp = await fetch(`${base}/session/refresh`, {
        method: 'POST',
        headers: tokens,
      });
      setAccessTokens(resp.headers);
    } finally {
      await tokenLock.releaseLock('facz-session-refresh');
    }
  }
};

export const destroyTokens = (): void => {
  if (getAccessTokens() !== undefined) {
    localStorage.removeItem('facz-access-token');
    localStorage.removeItem('facz-id-refresh-token');
  }
};
