import React, {ReactElement} from 'react';

import {Button, Dialog, DialogContent, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import DialogHeader from '../../../../components/dialogs/dialog-header';

const useStyles = makeStyles((theme) => ({
  dialogButtons: {
    flexDirection: 'row-reverse',
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  workspaceName?: string;
  isOpen: boolean;
  onDelete(): Promise<void>;
  onClose(): void;
};

const DeleteWorkspaceModalView = ({
  workspaceName,
  isOpen,
  onDelete,
  onClose,
}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <Dialog aria-labelledby={'delete-workspace-modal-title'} onClose={onClose} open={isOpen}>
      <DialogHeader handleClose={onClose} id={'delete-workspace-modal-title'}>
        Delete workspace{workspaceName ? ` "${workspaceName}"?` : '?'}
      </DialogHeader>
      <DialogContent>
        <Grid className={classes.dialogButtons} container spacing={1}>
          <Grid item>
            <Button color={'primary'} onClick={onDelete} variant={'contained'}>
              Yes, Delete
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant={'contained'}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteWorkspaceModalView;
