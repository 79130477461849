import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {
  setWorkspaceModified,
  setWorkspaceSortKey,
  updateWorkspace,
} from '../../../state/timeline/actions';
import {
  getAllFolders,
  getAvailableTagFilters,
  getAvailableTypeFilters,
  getFilteredEvidenceWorkspaceTags,
  getFolderFilters,
  getOfflineMode,
  getSelectedWorkspace,
  getTagFilters,
  getTypeFilters,
  getWorkspaceSortKey,
} from '../../../state/timeline/selectors';

import TimelineActionBarController from './timeline-action-bar.controller';

const mapStateToProps = (state: TRootState) => ({
  selectedWorkspace: getSelectedWorkspace(state),
  workspaceFolders: getAllFolders(state),
  folderFilters: getFolderFilters(state),
  tagFilters: getTagFilters(state),
  availableTagFilters: getAvailableTagFilters(state),
  typeFilters: getTypeFilters(state),
  availableTypeFilters: getAvailableTypeFilters(state),
  sortKey: getWorkspaceSortKey(state),
  offlineMode: getOfflineMode(state),
  workspaceTags: getFilteredEvidenceWorkspaceTags(state),
});

const matchDispatchToProps = {
  setSortKey: setWorkspaceSortKey,
  setWorkspaceModified,
  updateWorkspace,
};

const TimelineActionBar = connect(
  mapStateToProps,
  matchDispatchToProps,
)(TimelineActionBarController);

export default TimelineActionBar;
