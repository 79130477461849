/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetail_multiple = ReadonlyArray<{
    readonly id: string;
    readonly size: number;
    readonly " $refType": "evidenceDetail_multiple";
}>;
export type evidenceDetail_multiple$data = evidenceDetail_multiple;
export type evidenceDetail_multiple$key = ReadonlyArray<{
    readonly " $data"?: evidenceDetail_multiple$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_multiple">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "evidenceDetail_multiple",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    }
  ],
  "type": "Evidence",
  "abstractKey": "__isEvidence"
};
(node as any).hash = 'fde9dfae7e5ea95c5c81defdb1a5354b';
export default node;
