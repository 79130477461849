import React, {useEffect} from 'react';
import type {ReactElement} from 'react';

import {graphql, useLazyLoadQuery} from 'react-relay';

import LoadingScreen from '../../../components/feedback/loading-screen';

import {evidenceBaseFolderQuery} from '../../../graphql/__generated__/evidenceBaseFolderQuery.graphql';
import {evidenceBaseQuery} from '../../../graphql/__generated__/evidenceBaseQuery.graphql';

import EvidenceBaseView from './evidence-base.view';

type EvidenceProps = {
  currentFolderId: string | undefined;
  selectedEvidenceIds: string[];
  setCurrentFolderId(id: string): void;
  setSelectedEvidenceIds(ids: string[]): void;
};

const EvidenceBaseController = ({
  currentFolderId,
  selectedEvidenceIds,
  setCurrentFolderId,
  setSelectedEvidenceIds,
}: EvidenceProps): ReactElement => {
  const data = useLazyLoadQuery<evidenceBaseQuery>(
    graphql`
      query evidenceBaseQuery {
        currentSession {
          ... on Session {
            account {
              unsortedFolder {
                id
              }
              ...evidenceNav_foldersNav
              ...evidenceBase_folderDropName
              # ...evidenceDetail_activeTags
            }
          }
          ... on SessionError {
            message
          }
        }
      }
    `,
    {},
  );

  const folderData = useLazyLoadQuery<evidenceBaseFolderQuery>(
    graphql`
      query evidenceBaseFolderQuery($id: ID!, $skip: Boolean!) {
        folder: node(id: $id) @skip(if: $skip) {
          ...evidenceBase_dragOverlay
          ...evidenceList_folderEvidenceFull
          ...evidenceMain_folderName
          ...evidenceNav_tagsNav
          ...evidenceMain_tagFilter
        }
      }
    `,
    {
      id: currentFolderId ?? '', // we need to pass something because of the query $id type declaration
      skip: !currentFolderId, // skip when there is no currentFolderId
    },
    {fetchPolicy: 'store-and-network'},
  );

  const account = data.currentSession?.account ?? undefined;
  if (account === undefined) {
    throw new Error('no account');
  }
  const unsortedFolder = account.unsortedFolder ?? undefined;

  const folder = folderData.folder;

  useEffect(() => {
    if (currentFolderId === undefined) {
      setCurrentFolderId(unsortedFolder.id);
    } else {
      setCurrentFolderId(currentFolderId);
    }
  }, [currentFolderId]);

  if (folder === undefined || folder === null || currentFolderId === undefined)
    return <LoadingScreen />;

  return (
    <EvidenceBaseView
      account={account}
      currentFolderId={currentFolderId}
      folder={folder}
      selectedEvidenceIds={selectedEvidenceIds}
      setSelectedEvidenceIds={setSelectedEvidenceIds}
      unsortedFolderId={unsortedFolder.id}
    />
  );
};

export default EvidenceBaseController;
