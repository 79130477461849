import React from 'react';

import {Button, ImageList, ImageListItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Star} from '@material-ui/icons';

const useStyles = makeStyles({imageListItem: {display: 'flex'}});

type Props = {
  palette: string[];
  selectedIndex: number;
  handleSelect(index: number): void;
};

const PaletteSelectionGrid: React.FC<Props> = ({palette, selectedIndex, handleSelect}) => {
  const classes = useStyles();

  const tiles = palette.map((color, index) => (
    <ImageListItem
      classes={{item: classes.imageListItem}}
      component={Button}
      key={color}
      onClick={() => handleSelect(index)}
      style={{backgroundColor: color}}
    >
      {index === selectedIndex && <Star />}
    </ImageListItem>
  ));

  return <ImageList rowHeight={65}>{tiles}</ImageList>;
};

export default PaletteSelectionGrid;
