import React from 'react';

import {Button, Dialog, DialogContent, Grid, TextField} from '@material-ui/core';

import DialogHeader from '../../../../components/dialogs/dialog-header';

type Props = {
  isOpen: boolean;
  name: string;
  error: boolean;
  onNameChange(name: string): void;
  onSave(): Promise<void>;
  onClose(): void;
};

const RenameWorkspaceModalView: React.FC<Props> = ({
  isOpen,
  name,
  error,
  onNameChange,
  onClose,
  onSave,
}) => {
  return (
    <Dialog arial-labelledby={'rename-workspace-modal-title'} onClose={onClose} open={isOpen}>
      <DialogHeader handleClose={onClose} id={'rename-workspace-modal-title'}>
        Rename a new Workspace
      </DialogHeader>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              error={error}
              fullWidth
              helperText={error && 'Please enter a workspace name with at least one character'}
              label={'Workspace name'}
              onChange={(event) => onNameChange(event.currentTarget.value)}
              value={name}
            />
          </Grid>
          <Grid item xs={12}>
            <Button color={'primary'} onClick={onSave} variant={'contained'}>
              Rename
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RenameWorkspaceModalView;
