import produce from 'immer';
import * as t from 'io-ts';
import {ActionType, createReducer} from 'typesafe-actions';

import * as actions from './actions';
import {
  setCurrentFolderId,
  setCurrentTagFilterIds,
  setEvidence,
  setEvidenceFileData,
  setEvidenceThumbnail,
  setSelectedEvidenceIds,
  setTags,
} from './actions';
import {DEFAULT_EVIDENCES_STATE, EvidencesState} from './state';

type EvidencesAction = ActionType<typeof actions>;

const evidencesReducer = createReducer<t.OutputOf<typeof EvidencesState>, EvidencesAction>(
  DEFAULT_EVIDENCES_STATE,
)
  .handleAction(
    setTags,
    produce((state: t.OutputOf<typeof EvidencesState>, action) => {
      state.tags = action.payload;
    }),
  )
  .handleAction(
    setEvidence,
    produce((state: t.OutputOf<typeof EvidencesState>, action) => {
      state.evidences = action.payload;
    }),
  )
  .handleAction(
    setEvidenceFileData,
    produce(
      (
        state: t.OutputOf<typeof EvidencesState>,
        action: ReturnType<typeof setEvidenceFileData>,
      ) => {
        state.evidenceFiles[action.payload.id] = action.payload.file;
      },
    ),
  )
  .handleAction(
    setEvidenceThumbnail,
    produce(
      (
        state: t.OutputOf<typeof EvidencesState>,
        action: ReturnType<typeof setEvidenceThumbnail>,
      ) => {
        state.evidenceThumbnails[action.payload.id] = action.payload.thumbnail;
      },
    ),
  )
  .handleAction(
    setCurrentFolderId,
    produce((state: t.OutputOf<typeof EvidencesState>, action) => {
      state.currentFolderId = action.payload;
    }),
  )
  .handleAction(
    setSelectedEvidenceIds,
    produce((state: t.OutputOf<typeof EvidencesState>, action) => {
      state.selectedEvidenceIds = action.payload;
    }),
  )
  .handleAction(
    setCurrentTagFilterIds,
    produce((state: t.OutputOf<typeof EvidencesState>, action) => {
      state.currentTagFilterIds = action.payload;
    }),
  );

export default evidencesReducer;
