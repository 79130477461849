import {useCallback} from 'react';

import {useDispatch} from 'react-redux';
import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  CloneWorkspaceInput,
  useCloneWorkspaceMutation,
} from '../../../../graphql/__generated__/useCloneWorkspaceMutation.graphql';

import {setApiFeedback} from '../../../../state/common/actions';
import {addWorkspace} from '../../../../state/timeline/actions';
import {DEFAULT_WORKSPACE_STATE, TWorkspace} from '../../../../state/timeline/state';

import {BACKGROUND_COLOR_OPTION_ID} from '../../timeline.constants';

const mutation = graphql`
  mutation useCloneWorkspaceMutation($input: CloneWorkspaceInput!) {
    cloneWorkspace(input: $input) {
      workspace {
        id
        modified
      }
    }
  }
`;

function useCloneWorkspace(): [
  (
    workspaceToBeCloned: TWorkspace,
    clonedWorkspaceName: string,
    callback: () => void,
  ) => Disposable,
  boolean,
] {
  const dispatch = useDispatch();
  const [commit, isInFlight] = useMutation<useCloneWorkspaceMutation>(mutation);

  const cloneWorkspaceFn = useCallback(
    (workspaceToBeCloned: TWorkspace, clonedWorkspaceName: string, callback: () => void) => {
      const input: CloneWorkspaceInput = {
        ...{
          name: clonedWorkspaceName,
          x: workspaceToBeCloned?.coordinates.x,
          y: workspaceToBeCloned?.coordinates.y,
          backgroundColor:
            workspaceToBeCloned.options.find((o) => o.optionId === BACKGROUND_COLOR_OPTION_ID)
              ?.value ?? 0,
          workspaceTags: {
            tags: workspaceToBeCloned?.tags?.map((t) => ({
              tagId: t.tagId,
              text: t.text,
              color: t.color,
              x: t.coordinates.x,
              y: t.coordinates.y,
              lockHighlight: t.lockHighlight,
            })),
          },
          workspaceFilters: {
            folderIds: workspaceToBeCloned?.filters.folders,
            tagIds: workspaceToBeCloned?.filters.tags,
            typeIds: workspaceToBeCloned?.filters.types,
          },
          zoomIndex: DEFAULT_WORKSPACE_STATE.zoomIndex,
        },
      };

      return commit({
        variables: {
          input,
        },
        onCompleted(response) {
          if (response.cloneWorkspace?.workspace !== undefined) {
            const {id, modified} = response.cloneWorkspace?.workspace;

            dispatch(
              addWorkspace({
                id,
                modified,
                name: clonedWorkspaceName,
                coordinates: {
                  x: workspaceToBeCloned?.coordinates.x,
                  y: workspaceToBeCloned?.coordinates.y,
                },
                options: workspaceToBeCloned.options,
                tags: workspaceToBeCloned?.tags,
                filters: {
                  folders: workspaceToBeCloned?.filters.folders,
                  tags: workspaceToBeCloned?.filters.tags,
                  types: workspaceToBeCloned?.filters.types,
                },
                showLines: false,
                showEvidenceCounts: false,
                zoomIndex: DEFAULT_WORKSPACE_STATE.zoomIndex,
              }),
            );

            dispatch(
              setApiFeedback({
                severity: 'success',
                message: `Workspace "${clonedWorkspaceName}" cloned from "${workspaceToBeCloned.name}"`,
              }),
            );

            callback();
          }
        },
        onError: (e) => console.error(e),
      });
    },
    [commit],
  );
  return [cloneWorkspaceFn, isInFlight];
}

export default useCloneWorkspace;
