import React, {Fragment, useEffect} from 'react';

import {Typography} from '@material-ui/core';

import GenericDialog from '../../../components/dialogs/generic-dialog';
import EvidenceDetail from '../../../components/evidence-detail';

import {TTimelineEvidence} from '../../../state/timeline/state';

type Props = {
  evidence: TTimelineEvidence | undefined;
  onClose(): void;
  setSelectedEvidenceIds(ids: string[]): void;
};

const EvidenceNodeDetailView: React.FC<Props> = ({evidence, onClose, setSelectedEvidenceIds}) => {
  if (!evidence) {
    return null;
  }

  const dialogTitle = (
    <Fragment>
      <Typography variant={'h4'}>{`Entity: ${evidence.fileName || 'unknown'}`}</Typography>
    </Fragment>
  );

  useEffect(() => {
    setSelectedEvidenceIds([evidence.id]);
  }, [evidence]);

  return (
    <GenericDialog
      ariaDescription={`evidence-dot-details-${evidence.id}`}
      ariaTitle={`evidence-dot-detail-${evidence.id}`}
      hasDataGridChild
      onClose={onClose}
      open={!!evidence}
      title={dialogTitle}
    >
      <EvidenceDetail referrer={'evidence-node-detail'} />
    </GenericDialog>
  );
};

export default EvidenceNodeDetailView;
