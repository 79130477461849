import {createAction} from 'typesafe-actions';

import {WorkspaceBackgroundIndex} from '../../pages/timeline/timeline.constants';
import {
  TCaseFolder,
  TCaseTag,
  TEvidenceMimeType,
  TTimelineEvidence,
  TWorkspace,
  TWorkspaceSortKey,
  TWorkspaceTag,
} from './state';

type WorkspaceIdSpecifier = {workspaceId: string};

export const setEvidences = createAction('@timeline/setEvidences')<TTimelineEvidence[]>();

export const setSelectedWorkspaceId = createAction('@timeline/setSelectedWorkspaceId')<
  string | undefined
>();

export const unsetSelectedWorkspaceId = createAction('@timeline/unsetSelectedWorkspaceId')();

export const addWorkspace = createAction('@timeline/addWorkspace')<TWorkspace>();

export const updateWorkspace = createAction('@timeline/updateWorkspace')<TWorkspace>();

export const removeWorkspace = createAction('@timeline/removeWorkspace')<string>();

export const setWorkspaces = createAction('@timeline/setWorkspaces')<TWorkspace[]>();

export const setWorkspaceSortKey = createAction(
  '@timeline/setWorkspaceSortKey',
)<TWorkspaceSortKey>();

export const setWorkspaceBackground = createAction('@timeline/setWorkspaceBackground')<
  WorkspaceIdSpecifier & {value: WorkspaceBackgroundIndex}
>();

export const setWorkspaceModified = createAction('@timeline/setWorkspaceModified')<
  WorkspaceIdSpecifier & Pick<TWorkspace, 'modified'>
>();

export const setWorkspaceName = createAction('@timeline/setWorkspaceName')<
  WorkspaceIdSpecifier & Pick<TWorkspace, 'name'>
>();

export const setLockHighlight = createAction('@timeline/setLockHighlight')<
  WorkspaceIdSpecifier & {tagId: number; highlight: boolean}
>();

export const setWorkspaceTags = createAction('@timeline/setWorkspaceTags')<
  WorkspaceIdSpecifier & {tags: TWorkspaceTag[]}
>();

export const setFolderFilters = createAction('@timeline/setFolderFilters')<
  WorkspaceIdSpecifier & {folders: string[]}
>();

export const setTagFilters = createAction('@timeline/setTagFilters')<
  WorkspaceIdSpecifier & {tags: number[]}
>();

export const setTypeFilters = createAction('@timeline/setTypeFilters')<
  WorkspaceIdSpecifier & {types: number[]}
>();

export const toggleFolderFilter = createAction('@timeline/toggleFolderFilter')<{
  workspaceId: string;
  folderId: string;
}>();

export const toggleTagFilter = createAction('@timeline/toggleTagFilter')<{
  workspaceId: string;
  tagId: number;
}>();

export const toggleTypeFilter = createAction('@timeline/toggleTypeFilter')<{
  workspaceId: string;
  typeId: number;
}>();

export const setFolders = createAction('@timeline/setFolders')<TCaseFolder[]>();

export const setTags = createAction('@timeline/setTags')<TCaseTag[]>();

export const setTypes = createAction('@timeline/setTypes')<TEvidenceMimeType[]>();

export const setOpenedWorkspaceTag = createAction('@timeline/setOpenedWorkspaceTag')<
  number | undefined
>();

export const setOpenedEvidenceNode = createAction('@timeline/setOpenedEvidenceNode')<
  string | undefined
>();

export const setEvidenceVisibilityStatus = createAction('@timelines/setEvidenceVisibilityStatus')<{
  evidenceId: string;
  visible: boolean;
}>();

export const setWorkspaceTagColor = createAction('@timeline/setWorkspaceTagColor')<
  WorkspaceIdSpecifier & {tagId: number; color: string}
>();

export const setWorkspaceTagPosition = createAction('@timeline/setWorkspaceTagPosition')<
  WorkspaceIdSpecifier & {tagId: number; coordinates: {x: number; y: number}}
>();

export const setWorkspacePosition = createAction('@timeline/setWorkspacePosition')<
  WorkspaceIdSpecifier & {x?: number; y?: number}
>();

export const setIsLoading = createAction('@timeline/setIsLoading')<boolean>();

export const setShowLines = createAction('@timeline/setShowLines')<{
  workspaceId: string;
  showLines: boolean;
}>();

export const setShowEvidenceCounts = createAction('@timeline/setShowEvidenceCounts')<{
  workspaceId: string;
  showEvidenceCounts: boolean;
}>();

export const setWorkspaceZoomIndex = createAction('@timeline/setWorkspaceZoomIndex')<
  WorkspaceIdSpecifier & Pick<TWorkspace, 'zoomIndex'>
>();
