import {createAction} from 'typesafe-actions';

import {TEvidence, TTag} from '../../services/evidences/data/types';
import {EvidenceFile} from '../../services/evidences/types';

export const setEvidenceFileData = createAction('@evidences/setEvidenceFileData')<{
  id: string;
  file: EvidenceFile;
}>();

export const setEvidenceThumbnail = createAction('@evidences/setEvidenceThumbnail')<{
  id: string;
  thumbnail: string;
}>();

export const setCurrentFolderId = createAction('@timeline/setCurrentFolderId')<
  string | undefined
>();

export const setSelectedEvidenceIds = createAction('@timeline/setSelectedEvidenceIds')<
  string[] | undefined
>();

export const setCurrentTagFilterIds = createAction('@timeline/setCurrentTagFilterIds')<
  number[] | undefined
>();

// keep temporarily for tag admin
export const setTags = createAction('@evidences/setTags')<TTag[]>();

export const setEvidence = createAction('@evidences/setEvidence')<TEvidence[]>();
