import React, {RefObject, useRef, useState} from 'react';
import type {ReactElement} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import FilterSelectionDrawer from '../filter-selection-drawer';
import TimelineActionBar from '../timeline-action-bar';
import TimelineCanvas from '../timeline-canvas';
import TimelineFilmstrip from '../timeline-filmstrip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: `calc(100vh - 7rem)`, // 3rem from app navbar and 2rem each from layout padding
    overflowX: 'hidden',
    overflowY: 'visible',
    margin: '0px 0px 0px 0px',
    padding: '0px 0px 0px 0px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.primary.light}`,
  },
}));

export type RefProps = {htmlToImageRef: RefObject<HTMLDivElement>};

// eslint-disable-next-line react/display-name
export const TimelineBaseView = (): ReactElement => {
  const classes = useStyles();
  const [filterOpen, setFilterOpen] = useState(true);

  const htmlToImageRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classes.root} ref={htmlToImageRef}>
      <TimelineFilmstrip />
      <TimelineActionBar htmlToImageRef={htmlToImageRef} openFilter={() => setFilterOpen(true)} />
      <TimelineCanvas />
      <FilterSelectionDrawer isOpen={filterOpen} onClose={() => setFilterOpen(false)} />
    </div>
  );
};

export default TimelineBaseView;
