/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceList_folderEvidenceFull = {
    readonly evidences: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly fileName: string;
                readonly storedOn: Date;
                readonly eventDate: Date;
                readonly size: number;
                readonly folderCount: number;
                readonly tagCount: number;
                readonly commentCount: number;
                readonly tags: ReadonlyArray<{
                    readonly id: string;
                    readonly text: string;
                }>;
            };
        } | null> | null;
    };
    readonly " $refType": "evidenceList_folderEvidenceFull";
};
export type evidenceList_folderEvidenceFull$data = evidenceList_folderEvidenceFull;
export type evidenceList_folderEvidenceFull$key = {
    readonly " $data"?: evidenceList_folderEvidenceFull$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceList_folderEvidenceFull">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "evidenceList_folderEvidenceFull",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EvidenceConnection",
      "kind": "LinkedField",
      "name": "evidences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EvidenceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": "fileName",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": "storedOn",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "eventDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "size",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "folderCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tagCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "commentCount",
                  "storageKey": null
                },
                {
                  "alias": "tags",
                  "args": null,
                  "concreteType": "TagWithId",
                  "kind": "LinkedField",
                  "name": "tagsWithIds",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "text",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Folder",
  "abstractKey": "__isFolder"
};
})();
(node as any).hash = '6400c4e8e0b8ac6c83c426e3fc3a36da';
export default node;
