import React, {Fragment} from 'react';

import {Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {graphql, useFragment} from 'react-relay';

import {evidenceDetail_actions$key} from '../../graphql/__generated__/evidenceDetail_actions.graphql';
import {evidenceDetail_activeTags$key} from '../../graphql/__generated__/evidenceDetail_activeTags.graphql';
import {evidenceDetail_addTag$key} from '../../graphql/__generated__/evidenceDetail_addTag.graphql';
import {evidenceDetail_comments$key} from '../../graphql/__generated__/evidenceDetail_comments.graphql';
import {evidenceDetail_folderGroup$key} from '../../graphql/__generated__/evidenceDetail_folderGroup.graphql';
import {evidenceDetail_multiple$key} from '../../graphql/__generated__/evidenceDetail_multiple.graphql';
import {evidenceDetail_tagGroup$key} from '../../graphql/__generated__/evidenceDetail_tagGroup.graphql';

import {humanFileSize} from '../../utils/utils';

import EvidenceDetailActions from './evidence-detail.actions';
import EvidenceDetailAddComment from './evidence-detail.add-comment';
import EvidenceDetailAddTag from './evidence-detail.add-tag';
import CommentGroup from './evidence-detail.comment-group';
import {Referrer} from './evidence-detail.controller';
import FolderChipGroup from './evidence-detail.folder-group';
import TagChipGroup from './evidence-detail.tag-group';

const useStyles = makeStyles((theme) => ({
  filesSelectedCount: {
    margin: '0.375rem 1rem 0.75rem',
  },
  freeSoloCreateOption: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  account: evidenceDetail_activeTags$key;
  referrer: Referrer['referrer'];
  evidence: evidenceDetail_multiple$key &
    evidenceDetail_actions$key &
    evidenceDetail_folderGroup$key &
    evidenceDetail_tagGroup$key &
    evidenceDetail_addTag$key &
    evidenceDetail_comments$key;
  currentFolderId: string;
};

const EvidenceDetailMultiple: React.FC<Props> = ({
  account,
  referrer,
  evidence,
  currentFolderId,
}) => {
  const classes = useStyles();

  const data = useFragment(
    graphql`
      fragment evidenceDetail_multiple on Evidence @relay(plural: true) {
        id
        size
      }
    `,
    evidence as evidenceDetail_multiple$key,
  );

  if (!data) {
    return <div />;
  }

  const totalSize = data.reduce((sum, {size}) => sum + size, 0);
  const evidenceIds = data.map((e) => e.id);

  return (
    <Fragment>
      <Typography className={classes.filesSelectedCount} variant={'h5'}>
        {data.length} files selected
      </Typography>
      <EvidenceDetailActions
        currentFolderId={currentFolderId}
        evidence={evidence}
        referrer={referrer}
      />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component={'th'} scope={'row'} width={'50%'}>
              Combined Size:
            </TableCell>
            <TableCell>
              <b>{humanFileSize(totalSize, true)}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} component={'th'} scope={'row'}>
              Shared Folders:
              <FolderChipGroup evidence={evidence} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} component={'th'} scope={'row'}>
              Shared Tags:
              <TagChipGroup
                currentFolderId={currentFolderId}
                evidence={evidence}
                referrer={referrer}
              />
              <div className={classes.freeSoloCreateOption}>
                <EvidenceDetailAddTag
                  account={account}
                  currentFolderId={currentFolderId}
                  evidence={evidence}
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} component={'th'} scope={'row'}>
              Shared Comments:
              <CommentGroup
                currentFolderId={currentFolderId}
                evidence={evidence as evidenceDetail_comments$key}
                referrer={referrer}
              />
              <div className={classes.freeSoloCreateOption}>
                <EvidenceDetailAddComment evidenceIds={evidenceIds} />
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default EvidenceDetailMultiple;
