/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type StartFileUploadInput = {
    filename: string;
    eventDate: Date;
    size: number;
    mimeType?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type startUploadMutationVariables = {
    input: StartFileUploadInput;
};
export type startUploadMutationResponse = {
    readonly startFileUpload: {
        readonly uploadUrl: string;
        readonly uploadToken: string;
    } | null;
};
export type startUploadMutation = {
    readonly response: startUploadMutationResponse;
    readonly variables: startUploadMutationVariables;
};



/*
mutation startUploadMutation(
  $input: StartFileUploadInput!
) {
  startFileUpload(input: $input) {
    uploadUrl
    uploadToken
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StartFileUploadPayload",
    "kind": "LinkedField",
    "name": "startFileUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "startUploadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "startUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c2b78c8b029d10cbad443935ee6f56b4",
    "id": null,
    "metadata": {},
    "name": "startUploadMutation",
    "operationKind": "mutation",
    "text": "mutation startUploadMutation(\n  $input: StartFileUploadInput!\n) {\n  startFileUpload(input: $input) {\n    uploadUrl\n    uploadToken\n  }\n}\n"
  }
};
})();
(node as any).hash = '0f99e499caaa5caa751042c21ec6a128';
export default node;
