import {connect} from 'react-redux';

import {setSelectedEvidenceIds} from '../../../state/evidence/actions';
import {TRootState} from '../../../state/store';
import {setOpenedEvidenceNode} from '../../../state/timeline/actions';
import {getOpenedEvidenceNode} from '../../../state/timeline/selectors';

import EvidenceNodeDetailController from './evidence-node-detail.controller';

const mapStateToProps = (state: TRootState) => ({
  openedEvidenceNode: getOpenedEvidenceNode(state),
});

const mapDispatchToProps = {setOpenedEvidenceNode, setSelectedEvidenceIds};

const EvidenceNodeDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EvidenceNodeDetailController);

export default EvidenceNodeDetail;
