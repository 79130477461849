import React, {Fragment, useEffect, useRef} from 'react';

import {Theme, makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {differenceInMonths} from 'date-fns';

import {TTimelineEvidence, TWorkspaceSortKey} from '../../../state/timeline/state';

import EvidenceNodeMonth from '../evidence-node-month';

type Props = {
  trackVisibility?: boolean;
  connectionTarget?: boolean;
  months: {month: Date; evidences: TTimelineEvidence[]}[];
  sortKey: TWorkspaceSortKey;
  classes?: {
    root?: string;
    months?: string;
    spacers?: string;
  };
  onCalendarWidthChange?: (width: number) => void;
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10%',
  },
  spacer: {
    width: theme.spacing(1),
    height: '100%',
  },
}));

const TimelineCalendarView: React.FC<Props> = ({
  trackVisibility,
  months,
  sortKey,
  connectionTarget,
  classes: propClasses,
  onCalendarWidthChange,
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onCalendarWidthChange && onCalendarWidthChange(ref.current?.offsetWidth ?? 0);
  }, [ref.current, onCalendarWidthChange]);

  return (
    <Fragment>
      <div className={clsx(classes.root, propClasses?.root)} ref={ref}>
        {months.map(({month, evidences}, idx, allMonths) => {
          return (
            <Fragment key={month.valueOf()}>
              {idx > 0 && differenceInMonths(allMonths[idx - 1].month, month) < -1 && (
                <div className={clsx(classes.spacer, propClasses?.spacers)} />
              )}
              <EvidenceNodeMonth
                connectionTarget={connectionTarget}
                evidences={evidences}
                monthDate={month}
                sortKey={sortKey}
                trackVisibility={trackVisibility}
              />
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

export default TimelineCalendarView;
