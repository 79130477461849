import React, {ReactElement, useState} from 'react';

import {plainToClass} from 'class-transformer';
import {ValidationError, validateSync} from 'class-validator';
import {useDispatch} from 'react-redux';
import {graphql, useMutation} from 'react-relay';

import type {AccountRecoveryControllerMutation} from '../../../graphql/__generated__/AccountRecoveryControllerMutation.graphql';

import RecoveryData from '../../../utils/validator-classes/recovery-email.data';

import {setApiFeedback} from '../../../state/common/actions';

import AccountRecoveryView from './account-recovery.view';

export type ChangeData = {
  property: 'email';
  email: string;
};

const AccountRecoveryController = (): ReactElement => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const [isValidated, setIsValidated] = useState(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const [commitRequestPasswordReset] = useMutation<AccountRecoveryControllerMutation>(graphql`
    mutation AccountRecoveryControllerMutation($input: RequestPasswordResetInput!) {
      requestPasswordReset(input: $input) {
        clientMutationId
      }
    }
  `);

  function onChange(data: ChangeData) {
    const {property, ...changeData} = data;

    const formData = plainToClass(
      RecoveryData,
      Object.assign(
        {},
        {
          email,
        },
        changeData,
      ),
    );

    if (errors.length !== 0) {
      const formErrors = validateSync(formData);
      setErrors(formErrors);
    }

    if (property === 'email') {
      setEmail(formData.email);
    }
  }

  function onSend() {
    const formData = plainToClass(RecoveryData, {
      email,
    });

    const formErrors = validateSync(formData);
    if (formErrors.length !== 0) {
      setErrors(formErrors);
      setIsValidated(true);
      return;
    }
    commitRequestPasswordReset({
      variables: {
        input: {
          email: email,
          captchaToken: process.env.NODE_ENV === 'development' ? 'success' : captchaToken,
          // not sure how this is created
          clientMutationId: 'abc',
        },
      },
      onCompleted(response) {
        if (response.requestPasswordReset === null) {
          dispatch(setApiFeedback({message: 'something went wrong with password reset request'}));
          console.error(new Error('no response from mutation'));
          return;
        }

        dispatch(setApiFeedback({message: 'sent password reset request', severity: 'success'}));
      },
      onError(e) {
        console.error(e);
        dispatch(setApiFeedback({message: 'password reset request error'}));
      },
    });
  }

  function onVerifyCaptcha(token: string) {
    setCaptchaToken(token);
  }

  const onCaptchaExpire = () => {
    setCaptchaToken('');
  };

  const onCaptchaError = (err: string) => {
    console.log(`hCaptcha Error: ${err}`);
    dispatch(setApiFeedback({message: `Captcha Error: ${err}`}));
  };

  return (
    <AccountRecoveryView
      email={email}
      errors={!isValidated ? [] : errors}
      onCaptchaError={onCaptchaError}
      onCaptchaExpire={onCaptchaExpire}
      onChange={onChange}
      onSend={onSend}
      onVerifyCaptcha={onVerifyCaptcha}
      token={captchaToken}
    />
  );
};

export default AccountRecoveryController;
