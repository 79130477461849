import React, {useState} from 'react';
import type {ReactElement} from 'react';

import {ObjectOption} from '../../../../components/selectors/SplitButton';

import UserFilterView from './UserFilterView';

export type Filters = 'email' | 'fName' | 'lName';

export interface FilterOption extends ObjectOption {
  value: string;
  type: Filters;
}

const filterOptions: FilterOption[] = [
  {value: 'Email', type: 'email'},
  {value: 'Last name', type: 'lName'},
  {value: 'First name', type: 'fName'},
];

type Props = {
  onSubmit(type: Filters, searchValue: string | undefined): void;
};

const UserFilterController = ({onSubmit}: Props): ReactElement => {
  const [isValidated, setIsValidated] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentOption, setCurrentOption] = useState<FilterOption>(filterOptions[0]);

  const onChange = (data: string) => {
    if (errors.length !== 0 && data.length > 0) {
      setErrors([]);
    }

    setSearchValue(data);
  };

  async function onSend() {
    let searchText: string | undefined = searchValue;

    if (searchText.length > 100) {
      setErrors([new Error('Value field is too long')]);
      setIsValidated(true);
      return;
    }

    if (searchText.length < 1) {
      searchText = undefined;
    }

    onSubmit(currentOption.type ?? '', searchText);
  }

  return (
    <UserFilterView
      current={currentOption}
      errors={!isValidated ? [] : errors}
      onChange={onChange}
      onSubmit={onSend}
      options={filterOptions}
      searchValue={searchValue}
      updateCurrent={setCurrentOption}
    />
  );
};

export default UserFilterController;
