import React, {createContext, useContext} from 'react';

import {Container, interfaces} from 'inversify';

const InversifyContext = createContext<{container: Container | undefined}>({
  container: undefined,
});

type Props = {
  container: Container;
};

export const ContainerProvider: React.FC<Props> = ({container, children}) => {
  return <InversifyContext.Provider value={{container}}>{children}</InversifyContext.Provider>;
};

export function useContainer(): Container {
  const {container} = useContext(InversifyContext);

  if (!container) {
    throw new Error('container must be passed to ContainerProvider');
  }

  return container;
}

export function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>): T | undefined {
  const {container} = useContext(InversifyContext);

  if (!container) {
    throw new Error('container must be passed to ContainerProvider');
  }

  try {
    return container.get<T>(identifier);
  } catch {
    return undefined;
  }
}
