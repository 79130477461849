/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteCommentInput = {
    folderId: string;
    commentIds: Array<string>;
    clientMutationId?: string | null | undefined;
};
export type deleteCommentMutationVariables = {
    input: DeleteCommentInput;
};
export type deleteCommentMutationResponse = {
    readonly deleteComment: {
        readonly evidenceNodes: ReadonlyArray<{
            readonly node: {
                readonly commentCount: number;
                readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_comments">;
            };
        }>;
    } | null;
};
export type deleteCommentMutation = {
    readonly response: deleteCommentMutationResponse;
    readonly variables: deleteCommentMutationVariables;
};



/*
mutation deleteCommentMutation(
  $input: DeleteCommentInput!
) {
  deleteComment(input: $input) {
    evidenceNodes {
      node {
        __typename
        commentCount
        ...evidenceDetail_comments
        id
      }
    }
  }
}

fragment evidenceDetail_comments on Evidence {
  __isEvidence: __typename
  comments {
    id
    created
    body
    authorName
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commentCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCommentPayload",
        "kind": "LinkedField",
        "name": "deleteComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceDetail_comments"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCommentPayload",
        "kind": "LinkedField",
        "name": "deleteComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEvidence"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comments",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "authorName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b8f52a4781a3a42a7da6d4fef63dcbe",
    "id": null,
    "metadata": {},
    "name": "deleteCommentMutation",
    "operationKind": "mutation",
    "text": "mutation deleteCommentMutation(\n  $input: DeleteCommentInput!\n) {\n  deleteComment(input: $input) {\n    evidenceNodes {\n      node {\n        __typename\n        commentCount\n        ...evidenceDetail_comments\n        id\n      }\n    }\n  }\n}\n\nfragment evidenceDetail_comments on Evidence {\n  __isEvidence: __typename\n  comments {\n    id\n    created\n    body\n    authorName\n  }\n}\n"
  }
};
})();
(node as any).hash = '85caa6a70601faccc933557e2021cbb3';
export default node;
