import React, {ReactElement, useEffect, useRef, useState} from 'react';

import {
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Edit} from '@material-ui/icons';
import {graphql, useFragment} from 'react-relay';

import EvidenceDetail from '../../../components/evidence-detail';
import LoadingScreen from '../../../components/feedback/loading-screen';
import AutocompleteMultiple, {
  OptionType,
} from '../../../components/inputs/autocompletes/autocomplete-multiple';

import {evidenceList_folderEvidenceFull$key} from '../../../graphql/__generated__/evidenceList_folderEvidenceFull.graphql';
import {evidenceMain_folderName$key} from '../../../graphql/__generated__/evidenceMain_folderName.graphql';
import {
  evidenceMain_tagFilter$data,
  evidenceMain_tagFilter$key,
} from '../../../graphql/__generated__/evidenceMain_tagFilter.graphql';

import EvidenceList from '../evidence-list';

const useStyles = makeStyles({
  folderHeader: {
    marginBottom: '1rem',
    paddingRight: '1rem',
  },
  folderHeading: {
    marginBottom: '0',
  },
  folderEditing: {
    position: 'relative',
    marginRight: '1rem',
  },
  folderContent: {
    flexShrink: 1,
    flexGrow: 1,
    minHeight: '80vh',
  },
  listContainer: {
    height: '100%',
  },
  detailsContainer: {
    display: 'flex',
    position: 'relative',
  },
});

type Props = {
  folder:
    | evidenceList_folderEvidenceFull$key
    | evidenceMain_folderName$key
    | evidenceMain_tagFilter$key;
  folderEditable: boolean;
  currentFolderName: string;
  currentTagFilterIds: number[];
  loading?: boolean;
  setCurrentTagFilterIds(ids: number[]): void;
  handleRenameFolder(name: string): void;
};

const EvidenceMainView = ({
  folder,
  folderEditable,
  currentFolderName,
  currentTagFilterIds,
  loading,
  setCurrentTagFilterIds,
  handleRenameFolder,
}: Props): ReactElement => {
  const classes = useStyles();
  const [folderName, setFolderName] = useState<string>(currentFolderName);
  const [editing, setEditing] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [formHelperText, setFormHelperText] = useState<string | null>();
  const folderNameInputRef = useRef<HTMLInputElement>();

  const tagFilterData = useFragment(
    graphql`
      fragment evidenceMain_tagFilter on Folder {
        tags {
          id
          tagId
          text
        }
      }
    `,
    folder as evidenceMain_tagFilter$key,
  );

  const tagsData = tagFilterData as evidenceMain_tagFilter$data;
  const folderTags = tagsData?.tags.map((t) => ({
    inputValue: Number(t.tagId),
    title: t.text,
  }));

  const tagIdsToOptionType = (ids: number[] | null): OptionType[] | null => {
    if (!ids) return null;
    return folderTags?.filter((t) => ids?.includes(t.inputValue));
  };

  const handleAutocompleteSetValue = (values: OptionType[]): void => {
    const tagFilterIdsForState = values.map((v) => v.inputValue).filter(Boolean);
    setCurrentTagFilterIds(tagFilterIdsForState);
  };

  const handleClickAway = () => {
    setEditing(false);
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (folderNameInputRef.current?.value !== undefined) {
      const folderName = folderNameInputRef.current.value;
      const MIN_FOLDER_NAME_LENGTH = 3;

      if (folderName.length >= MIN_FOLDER_NAME_LENGTH) {
        handleRenameFolder(folderName);
        setEditing(false);
        setFormError(false);
        setFormHelperText(null);
        folderNameInputRef.current.value = '';
      } else {
        setFormError(true);
        setFormHelperText(`Folder name must be at least ${MIN_FOLDER_NAME_LENGTH} characters`);
      }
    }
  };

  useEffect(() => {
    setFolderName(currentFolderName);
  }, [currentFolderName]);

  return (
    <section className={classes.folderContent}>
      <Grid alignItems={'flex-start'} className={classes.folderHeader} container wrap={'nowrap'}>
        <Grid item xs={5}>
          {!editing && (
            <Typography
              className={classes.folderHeading}
              onDoubleClick={() => folderEditable && setEditing(true)}
              variant={'h2'}
            >
              {folderName}
            </Typography>
          )}
          {folderEditable && editing && (
            <div className={classes.folderEditing}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <IconButton color={'primary'} edge={'end'} onClick={handleSubmit}>
                          <Edit />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={currentFolderName}
                  error={formError}
                  fullWidth
                  helperText={formHelperText}
                  inputRef={folderNameInputRef}
                  label={'Rename folder'}
                  name={'folder'}
                  onChange={(e) => setFolderName(e.currentTarget.value)}
                  onKeyPress={onKeyPress}
                  size={'small'}
                  variant={'outlined'}
                />
              </ClickAwayListener>
              {loading && <LoadingScreen inline />}
            </div>
          )}
        </Grid>
        <Grid item xs={4}>
          <AutocompleteMultiple
            label={'Filter by tag'}
            options={folderTags ?? []}
            setValue={handleAutocompleteSetValue}
            value={tagIdsToOptionType(currentTagFilterIds)}
          />
        </Grid>
      </Grid>
      <Grid className={classes.listContainer} container spacing={6}>
        <Grid item xs={9}>
          <EvidenceList folder={folder as evidenceList_folderEvidenceFull$key} />
        </Grid>
        <Grid className={classes.detailsContainer} item xs={3}>
          <EvidenceDetail orientation={'vertical'} referrer={'evidence-list'} />
        </Grid>
      </Grid>
    </section>
  );
};

export default EvidenceMainView;
