import {connect} from 'react-redux';

import {TRootState} from '../../../state/store';
import {setLockHighlight} from '../../../state/timeline/actions';
import {getSelectedWorkspaceId} from '../../../state/timeline/selectors';

import TagNodeContextMenuView from './tag-node-context-menu.view';

const mapStateToProps = (state: TRootState) => ({
  workspaceId: getSelectedWorkspaceId(state),
});

const mapDispatchToProps = {setLockHighlight};

const TagNodeContextMenu = connect(mapStateToProps, mapDispatchToProps)(TagNodeContextMenuView);

export default TagNodeContextMenu;
