import React, {ReactElement} from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {ValidationError} from 'class-validator';
import {Link as RouterLink} from 'react-router-dom';

import {ACCOUNT_RECOVERY} from '../../../config/routes';

import {hasClassValidationError as hasError} from '../../../utils/utils';

import {ChangeData} from './login-form.controller';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  email: string;
  password: string;
  errors: ValidationError[];
  onChange(data: ChangeData): void;
  onSend(): void;
};

const LoginFormView = ({email, password, errors, onChange, onSend}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        style={{textAlign: 'center'}}
        title={'Login'}
        titleTypographyProps={{variant: 'h5'}}
      />
      <CardContent onKeyUp={(event) => event.key === 'Enter' && onSend()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              color={'secondary'}
              error={hasError(errors, 'email')}
              fullWidth
              helperText={hasError(errors, 'email') && 'Please enter an email'}
              label={'Email'}
              onChange={(event) =>
                onChange({
                  property: 'email',
                  email: event.currentTarget.value,
                })
              }
              type={'email'}
              value={email}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color={'secondary'}
              error={hasError(errors, 'password')}
              fullWidth
              helperText={hasError(errors, 'password') && 'Please enter a password'}
              label={'Password'}
              onChange={(event) =>
                onChange({
                  property: 'password',
                  password: event.currentTarget.value,
                })
              }
              type={'password'}
              value={password}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align={'right'} color={'secondary'}>
              <Link color={'inherit'} component={RouterLink} to={ACCOUNT_RECOVERY}>
                Forgot password?
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color={'primary'}
          disabled={errors.length !== 0 || password.length === 0 || email.length === 0}
          fullWidth
          onClick={onSend}
          style={{fontWeight: 'bold'}}
          variant={'contained'}
        >
          Login
        </Button>
      </CardActions>
    </Card>
  );
};

export default LoginFormView;
