import React, {useEffect, useState} from 'react';

import {minLength} from 'class-validator';
import {useDispatch} from 'react-redux';
import {useMutation} from 'react-relay';
import {graphql} from 'relay-runtime';

import {setApiFeedback} from '../../../../state/common/actions';
import {TWorkspace} from '../../../../state/timeline/state';

import RenameWorkspaceModalView from './rename-workspace-modal.view';

type Props = {
  workspaceToBeRenamed: TWorkspace | undefined;
  isOpen: boolean;
  onClose(): void;
  setWorkspaceName(value: {workspaceId: string} & Pick<TWorkspace, 'name'>): void;
  setWorkspaceModified(value: {workspaceId: string} & Pick<TWorkspace, 'modified'>): void;
};

const RenameWorkspaceModalController: React.FC<Props> = ({
  workspaceToBeRenamed,
  isOpen,
  onClose,
  setWorkspaceName,
  setWorkspaceModified,
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (workspaceToBeRenamed?.name) {
      setName(workspaceToBeRenamed?.name);
    }
  }, [workspaceToBeRenamed]);

  const [commitWorkspace] = useMutation(graphql`
    mutation renameWorkspaceModalRenameWorkspaceMutation($input: RenameWorkspaceInput!) {
      renameWorkspace(input: $input) {
        workspace {
          id
        }
      }
    }
  `);

  const handleRenameWorkspace = async () => {
    if (!minLength(name, 1)) {
      setError(true);
      return;
    }

    commitWorkspace({
      variables: {
        input: {
          workspaceId: workspaceToBeRenamed?.id,
          name: name,
        },
      },
      onCompleted() {
        setName('');
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Workspace "${name}" renamed successfully`,
          }),
        );
        onClose();
      },
      onError(e) {
        dispatch(setApiFeedback({message: 'workspace rename failed'}));
        console.error(e);
      },
      updater: (store) => {
        // Get the payload returned from the server
        const payload = store.getRootField('renameWorkspace');
        if (!payload) {
          return;
        }
        const workspace = payload.getLinkedRecord('workspace');
        const id = workspace?.getValue('id') as string;
        setWorkspaceName({workspaceId: id, name});
        setWorkspaceModified({workspaceId: id, modified: new Date()});
      },
    });
  };

  return (
    <RenameWorkspaceModalView
      error={error}
      isOpen={isOpen}
      name={name}
      onClose={onClose}
      onNameChange={setName}
      onSave={handleRenameWorkspace}
    />
  );
};

export default RenameWorkspaceModalController;
