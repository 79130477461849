import React, {Fragment} from 'react';

import {TWorkspaceTag} from '../../../state/timeline/state';

import TimelineTagNode from '../timeline-tag-node';

type Props = {
  filteredEvidenceWorkspaceTags: TWorkspaceTag[];
  incMoveCount(): void;
  setColorPickerTag(tag: TWorkspaceTag): void;
  setOpenedWorkspaceTag(tag: number): void;
};

const TimelineTagNodeView: React.FC<Props> = ({
  filteredEvidenceWorkspaceTags,
  incMoveCount,
  setColorPickerTag,
  setOpenedWorkspaceTag,
}) => {
  return (
    <Fragment>
      {filteredEvidenceWorkspaceTags.map((tag) => {
        return (
          <TimelineTagNode
            handleMove={incMoveCount}
            handleOpenColorPicker={() => setColorPickerTag(tag)}
            handleOpenDetail={() => setOpenedWorkspaceTag(tag.tagId)}
            key={`workspace-tag-node-${tag.tagId}`}
            workspaceTag={tag}
          />
        );
      })}
    </Fragment>
  );
};

export default TimelineTagNodeView;
