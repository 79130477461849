import React, {ChangeEvent, useEffect, useState} from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {unstable_batchedUpdates} from 'react-dom';

export interface OptionType {
  inputValue: number;
  title: string;
}

type AutocompleteProps = {
  options: OptionType[];
  label: string;
  value: OptionType[] | null;
  setValue(options: OptionType[]): void;
};

export default function AutocompleteMultiple({
  options,
  label,
  value,
  setValue,
}: AutocompleteProps): JSX.Element {
  const [autocompleteValues, setAutocompleteValues] = useState<OptionType[] | null>(null);
  const id = label.replace(/\W+/g, '-').toLowerCase();

  const handleChange = (_event: ChangeEvent<unknown>, values: OptionType[]) => {
    unstable_batchedUpdates(() => {
      setAutocompleteValues(values);
      setValue(values);
    });
  };

  useEffect(() => {
    setAutocompleteValues(value);
  }, [value]);

  if (options === undefined) {
    return <div>No options</div>;
  }

  return (
    <Autocomplete
      filterSelectedOptions
      getOptionLabel={(option) => option.title}
      getOptionSelected={(option, value) => option.inputValue === value.inputValue}
      id={id}
      multiple
      onChange={handleChange}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} variant={'outlined'} />}
      size={'small'}
      value={autocompleteValues ?? []}
    />
  );
}
