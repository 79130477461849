import React, {useMemo, useState} from 'react';

import {DataGridPro, GridColDef, GridSelectionModel} from '@mui/x-data-grid-pro';
import {unstable_batchedUpdates} from 'react-dom';
import {graphql, useFragment} from 'react-relay';

import {evidenceDetail_grid$key} from '../../graphql/__generated__/evidenceDetail_grid.graphql';

type Props = {
  evidence: evidenceDetail_grid$key;
  setSelectedEvidenceIds(nodes: string[]): void;
};

const EvidenceDetailGrid: React.FC<Props> = ({evidence, setSelectedEvidenceIds}) => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const columns: GridColDef[] = [
    {
      field: 'fileName',
      headerName: 'File name',
      flex: 1,
    },
  ];

  const data = useFragment(
    graphql`
      fragment evidenceDetail_grid on Evidence @relay(plural: true) {
        id
        fileName: name
      }
    `,
    evidence,
  );

  useMemo(() => {
    if (data && data[0]?.id !== undefined) {
      setSelectionModel([data[0].id]);
    }
  }, [data]);

  const handleSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    unstable_batchedUpdates(() => {
      setSelectionModel(newSelectionModel);
      setSelectedEvidenceIds(newSelectionModel as string[]);
    });
  };

  const gridKey = `grid-${data?.map(({id}) => id).join('-')}`;

  return (
    data && (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
        <DataGridPro
          autoHeight
          columns={columns}
          density={'compact'}
          key={gridKey}
          onSelectionModelChange={(newSelectionModel) => {
            handleSelectionModelChange(newSelectionModel);
          }}
          pageSize={10}
          rows={data}
          rowsPerPageOptions={[10]}
          selectionModel={selectionModel}
        />
      </div>
    )
  );
};

export default EvidenceDetailGrid;
