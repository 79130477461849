"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateFreeformClass = exports.validateIdentifierClass = void 0;
const error_1 = require("./error");
const prop_1 = require("./prop");
const util_1 = require("./util");
const validateIdentifierClass = (text) => {
    const cs = [...text];
    cs.forEach((c, i) => {
        const cp = util_1.codepoint(c);
        switch (prop_1.getDerivedProperty(cp)) {
            case 'PVALID':
                return;
            case 'CONTEXTO':
            case 'CONTEXTJ':
                enforceContextRule(text, cs, i);
                break;
            default:
                throw new error_1.InvalidCodepointError(text, i);
        }
    });
};
exports.validateIdentifierClass = validateIdentifierClass;
const validateFreeformClass = (text) => {
    const cs = [...text];
    cs.forEach((c, i) => {
        const cp = util_1.codepoint(c);
        switch (prop_1.getDerivedProperty(cp)) {
            case 'PVALID':
            case 'FREE_PVAL':
                return;
            case 'CONTEXTO':
            case 'CONTEXTJ':
                enforceContextRule(text, cs, i);
                break;
            default:
                throw new error_1.InvalidCodepointError(text, i);
        }
    });
};
exports.validateFreeformClass = validateFreeformClass;
const enforceContextRule = (text, cs, i) => {
    const cp = util_1.codepoint(cs[i]);
    let fn;
    switch (cp) {
        case 0x200c:
            fn = contextZeroWidthNonJoiner;
            break;
        case 0x200d:
            fn = contextZeroWidthJoiner;
            break;
        case 0x00b7:
            fn = contextMiddleDot;
            break;
        case 0x0375:
            fn = contextGreekKeraia;
            break;
        case 0x05f3:
        case 0x05f4:
            fn = contextHebrewPunctuation;
            break;
        case 0x30fb:
            fn = contextKatakanaMiddleDot;
            break;
        case 0x0660:
        case 0x0661:
        case 0x0662:
        case 0x0663:
        case 0x0664:
        case 0x0665:
        case 0x0666:
        case 0x0667:
        case 0x0668:
        case 0x0669:
            fn = contextArabicIndicDigits;
            break;
        case 0x06f0:
        case 0x06f1:
        case 0x06f2:
        case 0x06f3:
        case 0x06f4:
        case 0x06f5:
        case 0x06f6:
        case 0x06f7:
        case 0x06f8:
        case 0x06f9:
            fn = contextExtendedArabicIndicDigits;
            break;
    }
    if (fn === void 0 || !fn(cs, i, text)) {
        throw new error_1.InvalidCodepointError(text, i);
    }
};
const contextZeroWidthNonJoiner = (cs, i, text) => prop_1.isVirma(util_1.codepoint(before(text, cs, i))) ||
    (nonJoinerValidBefore(cs, i) && nonJoinerValidAfter(cs, i));
const contextZeroWidthJoiner = (cs, i, text) => prop_1.isVirma(util_1.codepoint(before(text, cs, i)));
const contextMiddleDot = (cs, i, text) => before(text, cs, i) === '\u006c' && after(text, cs, i) === '\u006c';
const contextGreekKeraia = (cs, i, text) => /^\p{Script=Hebrew}$/u.test(before(text, cs, i));
const contextHebrewPunctuation = (cs, i, text) => /^\p{Script=Hebrew}$/u.test(before(text, cs, i));
const contextKatakanaMiddleDot = cs => cs.every(c => /^\p{Script=Hiragana}|\p{Script=Katakana}|\p{Script=Han}$/u.test(c));
const contextArabicIndicDigits = cs => cs.every(c => /^[^\u06f0-\u06f9]$/u.test(c));
const contextExtendedArabicIndicDigits = cs => cs.every(c => /^[^\u0660-\u0669]$/u.test(c));
const nonJoinerValidBefore = (cs, i) => {
    for (let j = i - 1; j >= 0; --j) {
        const t = prop_1.getJoiningType(util_1.codepoint(cs[j]));
        switch (t) {
            case 'T':
                continue;
            case 'L':
            case 'D':
                return true;
            default:
                return false;
        }
    }
    return false;
};
const nonJoinerValidAfter = (cs, i) => {
    for (let j = i + 1; j < cs.length; ++j) {
        const t = prop_1.getJoiningType(util_1.codepoint(cs[j]));
        switch (t) {
            case 'T':
                continue;
            case 'R':
            case 'D':
                return true;
            default:
                return false;
        }
    }
    return false;
};
const before = (text, cs, i) => {
    if (i === 0) {
        throw new error_1.InvalidCodepointError(text, i);
    }
    return cs[i - 1];
};
const after = (text, cs, i) => {
    if (i === cs.length - 1) {
        throw new error_1.InvalidCodepointError(text, i);
    }
    return cs[i + 1];
};
