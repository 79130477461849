import React, {ReactElement} from 'react';

import {useDispatch} from 'react-redux';
import {graphql, useFragment} from 'react-relay';

import LoadingScreen from '../../../components/feedback/loading-screen';

import {evidenceMain_folderName$key} from '../../../graphql/__generated__/evidenceMain_folderName.graphql';

import useRenameFolder from '../../../hooks/mutations/rename-folder.hook';

import {setApiFeedback} from '../../../state/common/actions';

import EvidenceMainView from './evidence-main.view';

type Props = {
  folder: evidenceMain_folderName$key;
  currentFolderId: string | undefined;
  currentTagFilterIds: number[];
  setCurrentTagFilterIds(ids: number[]): void;
};

function EvidenceMainController({
  folder,
  currentFolderId,
  currentTagFilterIds,
  setCurrentTagFilterIds,
}: Props): ReactElement {
  const dispatch = useDispatch();
  const [renameFolder, loading] = useRenameFolder();
  const data = useFragment(
    graphql`
      fragment evidenceMain_folderName on Folder {
        type: __typename
        name
      }
    `,
    folder as evidenceMain_folderName$key,
  );

  const handleRenameFolder = async (name: string) => {
    if (currentFolderId === undefined) return;
    const callback = () => {
      dispatch(
        setApiFeedback({
          severity: 'success',
          message: `Folder: ${name} renamed.`,
        }),
      );
    };
    renameFolder(currentFolderId, name, callback);
  };

  if (currentFolderId === undefined || data === null) {
    return <LoadingScreen />;
  }

  return (
    <EvidenceMainView
      currentFolderName={data.name}
      currentTagFilterIds={currentTagFilterIds}
      folder={folder}
      folderEditable={data.type === 'UserFolder'}
      handleRenameFolder={handleRenameFolder}
      loading={loading}
      setCurrentTagFilterIds={setCurrentTagFilterIds}
    />
  );
}

export default EvidenceMainController;
