import React, {useState} from 'react';

import {minLength} from 'class-validator';
import {useDispatch} from 'react-redux';
import {useMutation} from 'react-relay';
import {graphql} from 'relay-runtime';

import {setApiFeedback} from '../../../../state/common/actions';
import {TWorkspace} from '../../../../state/timeline/state';

import CreateWorkspaceModalView from './create-workspace-modal.view';

type Props = {
  isOpen: boolean;
  onClose(): void;
  addWorkspace(workspace?: TWorkspace): void;
};

const CreateWorkspaceModalController: React.FC<Props> = ({isOpen, onClose, addWorkspace}) => {
  const [name, setName] = useState('');
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const [commitWorkspace] = useMutation(graphql`
    mutation createWorkspaceModalCreateWorkspaceMutation($input: CreateWorkspaceInput!) {
      createWorkspace(input: $input) {
        workspace {
          id
          name
          modified
        }
      }
    }
  `);

  const handleCreateWorkspace = async () => {
    if (!minLength(name, 1)) {
      setError(true);
      return;
    }

    commitWorkspace({
      variables: {
        input: {
          name: name,
        },
      },
      onCompleted() {
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: `Workspace "${name}" created successfully`,
          }),
        );
        setName('');
        onClose();
      },
      updater: (store) => {
        // Get the payload returned from the server
        const payload = store.getRootField('createWorkspace');
        if (!payload) {
          return;
        }
        const workspace = payload.getLinkedRecord('workspace');
        const id = workspace?.getValue('id') as string;
        const name = workspace?.getValue('name') as string;
        const modified = workspace?.getValue('modified') as string;
        addWorkspace({
          id,
          name,
          modified: new Date(modified),
        } as TWorkspace);
      },
    });
  };

  return (
    <CreateWorkspaceModalView
      error={error}
      isOpen={isOpen}
      name={name}
      onClose={onClose}
      onNameChange={setName}
      onSave={handleCreateWorkspace}
    />
  );
};

export default CreateWorkspaceModalController;
