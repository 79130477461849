import React, {ReactElement, ReactNode} from 'react';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  main: {
    margin: '0 auto',
    maxWidth: '1920px',
    padding: '2rem',
  },
});

type MainProps = {
  children: ReactNode;
};

const Main = ({children}: MainProps): ReactElement => {
  const classes = useStyles();

  return <main className={classes.main}>{children}</main>;
};

export default Main;
