/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SaveWorkspaceInput = {
    workspaceId: string;
    name: string;
    zoomIndex: number;
    x: number;
    y: number;
    backgroundColor: number;
    workspaceTags?: WorkspaceTagsInput | null | undefined;
    workspaceFilters?: WorkspaceFiltersInput | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type WorkspaceTagsInput = {
    tags: Array<WorkspaceTagInput>;
};
export type WorkspaceTagInput = {
    text: string;
    tagId: number;
    color: string;
    x: number;
    y: number;
    lockHighlight: boolean;
};
export type WorkspaceFiltersInput = {
    folderIds?: Array<string> | null | undefined;
    tagIds?: Array<number> | null | undefined;
    typeIds?: Array<number> | null | undefined;
};
export type timelineActionBarSaveWorkspaceMutationVariables = {
    input: SaveWorkspaceInput;
};
export type timelineActionBarSaveWorkspaceMutationResponse = {
    readonly saveWorkspace: {
        readonly workspace: {
            readonly id: string;
        };
    } | null;
};
export type timelineActionBarSaveWorkspaceMutation = {
    readonly response: timelineActionBarSaveWorkspaceMutationResponse;
    readonly variables: timelineActionBarSaveWorkspaceMutationVariables;
};



/*
mutation timelineActionBarSaveWorkspaceMutation(
  $input: SaveWorkspaceInput!
) {
  saveWorkspace(input: $input) {
    workspace {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SaveWorkspacePayload",
    "kind": "LinkedField",
    "name": "saveWorkspace",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "timelineActionBarSaveWorkspaceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "timelineActionBarSaveWorkspaceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eec45551f219adcf729e9aa3686e7393",
    "id": null,
    "metadata": {},
    "name": "timelineActionBarSaveWorkspaceMutation",
    "operationKind": "mutation",
    "text": "mutation timelineActionBarSaveWorkspaceMutation(\n  $input: SaveWorkspaceInput!\n) {\n  saveWorkspace(input: $input) {\n    workspace {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b603b4a1ff4e57610e2cfbc52d2e91e1';
export default node;
