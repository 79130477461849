import React from 'react';

import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
}));

const FooterNav: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <PatentAndCopyright />
    </footer>
  );
};

export default FooterNav;

export const PatentAndCopyright: React.FC = () => {
  return (
    <Grid alignItems={'flex-end'} container justifyContent={'center'} spacing={4} wrap={'nowrap'}>
      <Grid item>&copy; {new Date().getFullYear()} EVDense</Grid>
      <Grid item>U.S. Patent 9,466,050 B2</Grid>
    </Grid>
  );
};
