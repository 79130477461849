import React, {ReactElement} from 'react';

import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
  },
}));

type ChipGroupProps = {
  children: ReactElement;
};

const ChipGroup = ({children}: ChipGroupProps): ReactElement => {
  const classes = useStyles();

  return <Box className={classes.chipContainer}>{children}</Box>;
};

export default ChipGroup;
