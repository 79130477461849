import {Dispatch, SetStateAction, useCallback, useState} from 'react';

import saveAs from 'file-saver';
import JSZip from 'jszip';
import {useDispatch} from 'react-redux';

import {fetchWithSession} from '../../utils/tokens';
import {downloadTimestampString, incrementFileName} from '../../utils/utils';

import {setApiFeedback} from '../../state/common/actions';
import {UserAccount} from '../../state/common/state';
import {TDownloadEvidence} from '../../state/evidence/state';
import {TWorkspace} from '../../state/timeline/state';

import {DownloadOrigin} from '.';

type FileCounts = Record<string, number>;

export type FileDownload = Omit<TDownloadEvidence, 'storedOn' | 'tags' | 'mimeType'> & {
  mimeType: string;
  type: 'File' | 'JournalEntry';
};

function useDownloadFiles(): [
  (
    files: FileDownload[],
    user: UserAccount,
    origin: DownloadOrigin['origin'],
    selectedWorkspace: TWorkspace | undefined,
  ) => void,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  number,
] {
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false);
  const [numItemsProcessed, setNumItemsProcessed] = useState(0);

  const downloadFn = useCallback(
    async (
      files: FileDownload[],
      user: UserAccount,
      origin: DownloadOrigin['origin'],
      selectedWorkspace,
    ) => {
      setNumItemsProcessed(0);
      type Item = typeof files[number];
      const prepareJournalEntry = (item: Item) => {
        const {subject, body} = item;
        const htmlString = `<!DOCTYPE html><html><body>${body}</body></html>`;
        return new File([htmlString], `${subject}.html`, {
          type: 'text/html;charset=utf-8',
        });
      };

      const prepareFile = async (item: Item) => {
        const {fileName, url} = item;
        if (url) {
          const res = await fetchWithSession(url);
          const {ok, status, statusText} = res;

          if (ok) {
            const blob = await res.blob();
            return new File([blob], item.fileName, {
              type: `${item.mimeType};charset=utf-8`,
            });
          } else {
            dispatch(
              setApiFeedback({
                message: `${status}: Failed to download ${fileName}. Reason: ${statusText}`,
              }),
            );
            return;
          }
        }
      };

      const prepareBlob = async (item: Item): Promise<ArrayBuffer | undefined> => {
        const {fileName, url} = item;
        if (url) {
          const res = await fetchWithSession(url);
          const {ok, status, statusText} = res;

          if (ok) {
            const blob = await res.blob();
            const blobData = await blob.arrayBuffer();

            return blobData;
          } else {
            dispatch(
              setApiFeedback({
                message: `${status}: Failed to download ${fileName}. Reason: ${statusText}`,
              }),
            );
            return;
          }
        }
      };

      if (!files || files.length === 0) {
        setIsDownloading(false);
        dispatch(setApiFeedback({message: `Error: No files selected`}));
      } else {
        switch (files.length) {
          case 1:
            {
              const [item] = files;
              if (item.type == 'JournalEntry') {
                const journalEntry = prepareJournalEntry(item);
                saveAs(journalEntry);
                setNumItemsProcessed(1);
                setIsDownloading(false);
              } else {
                const file = await prepareFile(item);

                if (file !== undefined) {
                  saveAs(file, item.fileName);
                  setNumItemsProcessed(1);
                  setIsDownloading(false);
                }
              }
            }
            break;
          default: {
            // multiple
            const zip = new JSZip();

            // zip name format: {firstname}-{lastname}_{download-datetime}__{workspacename}_{evidence-occurance-date-start}-{evidence-occurance-date-end}
            const {firstName, lastName} = user;
            const downloadDateTime = downloadTimestampString(new Date()).replace(':', '');
            let zipName = `${firstName}-${lastName}_${downloadDateTime}`;

            if (origin === 'timeline' && selectedWorkspace !== undefined) {
              // get earliest eventDate
              const fileWithEarliestEventDate = files.reduce(
                (acc, curr) => (curr.eventDate < acc.eventDate ? curr : acc),
                files[0] || undefined,
              );

              const earliestEventDate = downloadTimestampString(
                new Date(fileWithEarliestEventDate.eventDate),
              ).replace(':', '');

              // get latest eventDate
              const fileWithLatestEventDate = files.reduce(
                (acc, curr) => (curr.eventDate > acc.eventDate ? curr : acc),
                files[0] || undefined,
              );

              const latestEventDate = downloadTimestampString(
                new Date(fileWithLatestEventDate.eventDate),
              ).replace(':', '');

              zipName = `${zipName}__${selectedWorkspace.name}_${earliestEventDate}-${latestEventDate}`;
            }

            const dupFileNames = {} as FileCounts;

            let relativePath = '';

            const zipFile = async (item: Item, relativePath: string) => {
              // because zipjs uses filenames as keys, we have to check for duplicates
              // we'll store count of duplicate instances in object dupFileNames so that we can
              // add a suffix like ` (2)` for the 2nd duplicate
              if (relativePath in zip.files) {
                dupFileNames[relativePath] = dupFileNames[relativePath] + 1 || 1;
                relativePath = incrementFileName(relativePath, dupFileNames[relativePath]);
              }

              if (item?.type === 'JournalEntry') {
                const journalEntry = prepareJournalEntry(item);
                zip.file(relativePath, journalEntry);
              } else {
                const file = await prepareBlob(item);

                if (file !== undefined) {
                  zip.file(relativePath, file, {binary: true});
                } else {
                  console.log(`File ${relativePath} is undefined`);
                }
              }
            };

            // there's some duplicate code below, but I'm not sure it makes sense to refactor at the moment...
            if (origin === 'evidence') {
              for (const item of files) {
                relativePath = item.fileName;
                await zipFile(item, relativePath);
                setNumItemsProcessed(numItemsProcessed + 1);
              }
            } else {
              for (const item of files) {
                for (const folder of item.folders) {
                  relativePath = `${folder.name}/${item.fileName}`;
                  if (selectedWorkspace.filters.folders.includes(folder.id)) {
                    await zipFile(item, relativePath);
                  }
                }
                // not inside inner loop on purpose!!! because it's still just a single file being processed even though we're placing it in multiple folders for download
                setNumItemsProcessed(numItemsProcessed + 1);
              }
            }

            await zip.generateAsync({type: 'blob'}).then(function (content) {
              // see FileSaver.js
              saveAs(content, zipName);
              setIsDownloading(false);
            });
            break;
          }
        }
      }
    },
    [],
  );
  return [downloadFn, isDownloading, setIsDownloading, numItemsProcessed];
}

export default useDownloadFiles;
