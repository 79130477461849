/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RemoveEvidenceFromFolderInput = {
    userFolderId: string;
    evidenceIds: Array<string>;
    clientMutationId?: string | null | undefined;
};
export type removeEvidenceFromFolderMutationVariables = {
    input: RemoveEvidenceFromFolderInput;
};
export type removeEvidenceFromFolderMutationResponse = {
    readonly removeEvidenceFromFolder: {
        readonly userFolder: {
            readonly evidenceCount: number;
            readonly " $fragmentRefs": FragmentRefs<"evidenceNav_tagsNav" | "evidenceMain_tagFilter" | "evidenceList_folderEvidenceFull">;
        };
        readonly evidenceNodes: ReadonlyArray<{
            readonly node: {
                readonly folderCount: number;
                readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_folderGroup">;
            };
        }>;
    } | null;
};
export type removeEvidenceFromFolderMutation = {
    readonly response: removeEvidenceFromFolderMutationResponse;
    readonly variables: removeEvidenceFromFolderMutationVariables;
};



/*
mutation removeEvidenceFromFolderMutation(
  $input: RemoveEvidenceFromFolderInput!
) {
  removeEvidenceFromFolder(input: $input) {
    userFolder {
      evidenceCount
      ...evidenceNav_tagsNav
      ...evidenceMain_tagFilter
      ...evidenceList_folderEvidenceFull
      id
    }
    evidenceNodes {
      node {
        __typename
        folderCount
        ...evidenceDetail_folderGroup
        id
      }
    }
  }
}

fragment evidenceDetail_folderGroup on Evidence {
  __isEvidence: __typename
  id
  folders {
    id
    name
  }
}

fragment evidenceList_folderEvidenceFull on Folder {
  __isFolder: __typename
  evidences {
    edges {
      node {
        __typename
        id
        fileName: name
        storedOn: created
        eventDate
        size
        folderCount
        tagCount
        commentCount
        tags: tagsWithIds {
          id
          text
        }
      }
    }
  }
}

fragment evidenceMain_tagFilter on Folder {
  __isFolder: __typename
  tags {
    id
    tagId
    text
  }
}

fragment evidenceNav_tagsNav on Folder {
  __isFolder: __typename
  id
  tags {
    id
    tagId
    text
    evidenceCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "evidenceCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "folderCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeEvidenceFromFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveEvidenceFromFolderPayload",
        "kind": "LinkedField",
        "name": "removeEvidenceFromFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFolder",
            "kind": "LinkedField",
            "name": "userFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceNav_tagsNav"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceMain_tagFilter"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceList_folderEvidenceFull"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceDetail_folderGroup"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeEvidenceFromFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveEvidenceFromFolderPayload",
        "kind": "LinkedField",
        "name": "removeEvidenceFromFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFolder",
            "kind": "LinkedField",
            "name": "userFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FolderTag",
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tagId",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EvidenceConnection",
                    "kind": "LinkedField",
                    "name": "evidences",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EvidenceEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": "fileName",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": "storedOn",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "created",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "eventDate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "size",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tagCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "commentCount",
                                "storageKey": null
                              },
                              {
                                "alias": "tags",
                                "args": null,
                                "concreteType": "TagWithId",
                                "kind": "LinkedField",
                                "name": "tagsWithIds",
                                "plural": true,
                                "selections": [
                                  (v4/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Folder",
                "abstractKey": "__isFolder"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEvidence"
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFolder",
                    "kind": "LinkedField",
                    "name": "folders",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b262cc0b8764c5bcda909041801d6f3",
    "id": null,
    "metadata": {},
    "name": "removeEvidenceFromFolderMutation",
    "operationKind": "mutation",
    "text": "mutation removeEvidenceFromFolderMutation(\n  $input: RemoveEvidenceFromFolderInput!\n) {\n  removeEvidenceFromFolder(input: $input) {\n    userFolder {\n      evidenceCount\n      ...evidenceNav_tagsNav\n      ...evidenceMain_tagFilter\n      ...evidenceList_folderEvidenceFull\n      id\n    }\n    evidenceNodes {\n      node {\n        __typename\n        folderCount\n        ...evidenceDetail_folderGroup\n        id\n      }\n    }\n  }\n}\n\nfragment evidenceDetail_folderGroup on Evidence {\n  __isEvidence: __typename\n  id\n  folders {\n    id\n    name\n  }\n}\n\nfragment evidenceList_folderEvidenceFull on Folder {\n  __isFolder: __typename\n  evidences {\n    edges {\n      node {\n        __typename\n        id\n        fileName: name\n        storedOn: created\n        eventDate\n        size\n        folderCount\n        tagCount\n        commentCount\n        tags: tagsWithIds {\n          id\n          text\n        }\n      }\n    }\n  }\n}\n\nfragment evidenceMain_tagFilter on Folder {\n  __isFolder: __typename\n  tags {\n    id\n    tagId\n    text\n  }\n}\n\nfragment evidenceNav_tagsNav on Folder {\n  __isFolder: __typename\n  id\n  tags {\n    id\n    tagId\n    text\n    evidenceCount\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd05c90e273a4cc5c558fff59cf44ed54';
export default node;
