/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceMain_folderName = {
    readonly type: string;
    readonly name: string;
    readonly " $refType": "evidenceMain_folderName";
};
export type evidenceMain_folderName$data = evidenceMain_folderName;
export type evidenceMain_folderName$key = {
    readonly " $data"?: evidenceMain_folderName$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceMain_folderName">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "evidenceMain_folderName",
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Folder",
  "abstractKey": "__isFolder"
};
(node as any).hash = '464640bf86cca8e84e895f998c074785';
export default node;
