const redMask = 0xff0000,
  greenMask = 0xff00,
  blueMask = 0xff;

export type RGBValues = {
  red: number;
  green: number;
  blue: number;
};

export function colorValueToRGB(color: number): RGBValues {
  const red = (color & redMask) >> 16,
    green = (color & greenMask) >> 8,
    blue = color & blueMask;

  return {
    red,
    green,
    blue,
  };
}

export function rgbToColorValue({red, green, blue}: RGBValues): number {
  return (red << 16) + (green << 8) + blue;
}

export function colorValueToCss(colorValue: string): string {
  if (colorValue?.startsWith('#')) {
    return colorValue;
  } else {
    const parsed = Number.parseInt(colorValue, 10);

    if (isNaN(parsed)) {
      return '#555';
    } else {
      const {red, green, blue} = colorValueToRGB(parsed);

      return `rgb(${red},${green},${blue})`;
    }
  }
}

const minViewportWidth = 977;

export const getZoomedHeight = (zoom: number): number => 3 + 5 * (zoom / 10);
export const zoomedWidthPercentage = (zoom: number): number => zoom * 3 + 5;
export const getZoomedWidth = (zoom: number): string => `${zoomedWidthPercentage(zoom)}%`;

export const getMonthWidth = (zoomLevel: number): number => {
  const widthMultiplier = zoomedWidthPercentage(zoomLevel) / 100,
    viewportWidthPixels =
      document.getElementById('timeline-canvas')?.clientWidth || minViewportWidth;

  return viewportWidthPixels * widthMultiplier;
};

export const getBoundLeft = (calendarWidth: number, zoomLevel: number): number =>
  calendarWidth * -1 + getMonthWidth(zoomLevel) * 1.5;

export const getBoundRight = (zoomLevel: number): number =>
  (document.getElementById('timeline-canvas')?.clientWidth ?? minViewportWidth) -
  getMonthWidth(zoomLevel) * 1.5;
