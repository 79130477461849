import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  RenameFolderInput,
  renameFolderMutation,
} from '../../graphql/__generated__/renameFolderMutation.graphql';

const mutation = graphql`
  mutation renameFolderMutation($input: RenameFolderInput!) {
    renameFolder(input: $input) {
      account {
        userFolders {
          ...evidenceMain_folderName
        }
      }
    }
  }
`;

function useRenameFolder(): [
  (id: string, name: string, callback: () => void) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<renameFolderMutation>(mutation);
  return [
    useCallback(
      (id, name, callback) => {
        const input: RenameFolderInput = {
          id,
          name,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted() {
            callback();
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useRenameFolder;
