import React from 'react';
import type {ReactElement} from 'react';

import {connect} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {LOGIN} from '../../config/routes';

import {setAuthenticatedUser} from '../../state/common/actions';
import {getIsAuthenticated} from '../../state/common/selectors';
import {UserAccount} from '../../state/common/state';
import {TRootState} from '../../state/store';
import {getOfflineMode} from '../../state/timeline/selectors';

import LoginPage from '../../pages/login/login-page';
import ApiFeedback from '../feedback/api-feedback';
import ErrorBoundary from '../feedback/error-boundary';
import PrivateRoute from './private-route';
import RouterView from './router.view';

type Props = {
  isAuthenticated: boolean;
  offlineMode: boolean;
  setAuthenticatedUser(value: UserAccount | null): void;
};

const RootRouterComponent = ({
  isAuthenticated,
  offlineMode,
  setAuthenticatedUser,
}: Props): ReactElement => {
  return (
    <ErrorBoundary>
      <ApiFeedback />
      <BrowserRouter>
        <Switch>
          <Route path={LOGIN}>
            <LoginPage />
          </Route>
          <PrivateRoute isAuthenticated={isAuthenticated && !offlineMode} redirectTo={LOGIN}>
            <RouterView setAuthenticatedUser={setAuthenticatedUser} />
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: TRootState) => ({
  isAuthenticated: getIsAuthenticated(state),
  offlineMode: getOfflineMode(state),
});

const mapDispatchToProps = {
  setAuthenticatedUser,
};

const RootRouter = connect(mapStateToProps, mapDispatchToProps)(RootRouterComponent);

export default RootRouter;
