/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AddCommentInput = {
    evidenceIds: Array<string>;
    body: string;
    clientMutationId?: string | null | undefined;
};
export type addCommentToEvidenceMutationVariables = {
    input: AddCommentInput;
};
export type addCommentToEvidenceMutationResponse = {
    readonly addComment: {
        readonly evidenceNodes: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly commentCount: number;
                readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_comments">;
            };
        }>;
    } | null;
};
export type addCommentToEvidenceMutation = {
    readonly response: addCommentToEvidenceMutationResponse;
    readonly variables: addCommentToEvidenceMutationVariables;
};



/*
mutation addCommentToEvidenceMutation(
  $input: AddCommentInput!
) {
  addComment(input: $input) {
    evidenceNodes {
      node {
        __typename
        id
        ...evidenceDetail_comments
        commentCount
      }
    }
  }
}

fragment evidenceDetail_comments on Evidence {
  __isEvidence: __typename
  comments {
    id
    created
    body
    authorName
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commentCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addCommentToEvidenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddCommentPayload",
        "kind": "LinkedField",
        "name": "addComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceDetail_comments"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addCommentToEvidenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddCommentPayload",
        "kind": "LinkedField",
        "name": "addComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEvidence"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comments",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "authorName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3f1aca1dafa68395ce6f8c4453d7787",
    "id": null,
    "metadata": {},
    "name": "addCommentToEvidenceMutation",
    "operationKind": "mutation",
    "text": "mutation addCommentToEvidenceMutation(\n  $input: AddCommentInput!\n) {\n  addComment(input: $input) {\n    evidenceNodes {\n      node {\n        __typename\n        id\n        ...evidenceDetail_comments\n        commentCount\n      }\n    }\n  }\n}\n\nfragment evidenceDetail_comments on Evidence {\n  __isEvidence: __typename\n  comments {\n    id\n    created\n    body\n    authorName\n  }\n}\n"
  }
};
})();
(node as any).hash = '89f098e372f4b9788ae49ce9eec12bdb';
export default node;
