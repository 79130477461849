import {useCallback} from 'react';

import {graphql, useMutation} from 'react-relay';
import {Disposable} from 'relay-runtime';

import {
  DeleteCommentInput,
  deleteCommentMutation,
} from '../../graphql/__generated__/deleteCommentMutation.graphql';

const mutation = graphql`
  mutation deleteCommentMutation($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      evidenceNodes {
        node {
          commentCount
          ...evidenceDetail_comments
        }
      }
    }
  }
`;

function useDeleteComment(): [
  (folderId: string, commentIds: string[], callback: () => void) => Disposable,
  boolean,
] {
  const [commit, isInFlight] = useMutation<deleteCommentMutation>(mutation);
  return [
    useCallback(
      (folderId, commentIds, callback) => {
        const input: DeleteCommentInput = {
          folderId,
          commentIds,
        };
        return commit({
          variables: {
            input,
          },
          onCompleted() {
            callback();
          },
          onError: (e) => console.error(e),
        });
      },
      [commit],
    ),
    isInFlight,
  ];
}

export default useDeleteComment;
