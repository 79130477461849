export const idToMimeType = new Map([
  [0, 'application/octet-stream'],
  [1, 'application/msword'],
  [2, 'application/vnd.ms-excel'],
  [3, 'application/vnd.ms-powerpoint'],
  [4, 'application/vnd.oasis.opendocument.text'],
  [5, 'application/pdf'],
  [6, 'application/sketchup'],
  [7, 'application/msoutlook'],
  [8, 'application/zip'],
  [9, 'application/rtf'],
  [10, 'application/vnd.oasis.opendocumnt.spreadsheet'],
  [11, 'application/vnd.oasis.opendocument.presentation'],
  [100, 'image/png'],
  [101, 'image/jpeg'],
  [103, 'image/tiff'],
  [105, 'image/bmp'],
  [200, 'text/plain'],
  [201, 'text/journalentry'],
  [202, 'text/html'],
  [203, 'text/xml'],
  [204, 'message/rfc822'],
  [300, 'audio/mpeg'],
  [301, 'audio/x-wav'],
  [302, 'audio/x-ms-wma'],
  [303, 'audio/x-caf'],
  [304, 'audio/caf'],
  [305, 'audio/3gpp'],
  [307, 'audio/x-m4a'],
  [400, 'video/avi'],
  [401, 'video/mpeg'],
  [402, 'video/x-msvideo'],
  [403, 'video/quicktime'],
  [404, 'videio/x-ms-wmv'],
  [405, 'video/3gpp'],
  [500, 'application/evdense-portable-timeline'],
] as const);

export const mimeTypeToId = new Map([
  ['application/octet-stream', 0],
  ['application/msword', 1],
  ['application/vnd.ms-word', 1],
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 1],
  ['application/vnd.ms-excel', 2],
  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 2],
  ['application/vnd.ms-powerpoint', 3],
  ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 3],
  ['application/vnd.oasis.opendocument.text', 4],
  ['application/pdf', 5],
  ['application/sketchup', 6],
  ['application/x-koan', 6],
  ['application/koan', 6],
  ['application/msoutlook', 7],
  ['application/vnd.ms-outlook', 7],
  ['application/zip', 8],
  ['application/rtf', 9],
  ['application/vnd.oasis.opendocument.spreadsheet', 10],
  ['application/vnd.oasis.opendocument.presentation', 11],
  ['image/png', 100],
  ['image/jpeg', 101],
  ['image/jpg', 101],
  ['image/pjpeg', 101],
  ['image/tiff', 103],
  ['image/gif', 104],
  ['image/bmp', 105],
  ['image/x-bmp', 105],
  ['text/plain', 200],
  ['text/journalentry', 201],
  ['text/html', 202],
  ['text/xml', 203],
  ['application/xml', 203],
  ['message/rfc822', 204],
  ['multipart/related', 204],
  ['text/html.email', 204],
  ['audio/mpeg', 300],
  ['audio/mp3', 300],
  ['audio/mp4', 300],
  ['audio/x-wav', 301],
  ['audio/wav', 301],
  ['audio/x-ms-wma', 302],
  ['audio/ms-wma', 302],
  ['audio/x-caf', 303],
  ['audio/caf', 304],
  ['audio/3gpp', 305],
  ['audio/x-m4a', 307],
  ['audio/mp4a-latm', 307],
  ['video/avi', 400],
  ['video/msvideo', 400],
  ['video/mpeg', 401],
  ['video/mp4', 401],
  ['video/vnd.objectvideo', 401],
  ['video/x-msvideo', 402],
  ['video/quicktime', 403],
  ['video/x-ms-wmv', 404],
  ['video/x-ms-wvx', 404],
  ['video/ms-wmv', 404],
  ['video/3gpp', 405],
  ['application/evdense-portable-timeline', 500],
] as const);

export type MimeType =
  | 'application/octet-stream'
  | 'application/msword'
  | 'application/vnd.ms-word'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.ms-powerpoint'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  | 'application/vnd.oasis.opendocument.text'
  | 'application/pdf'
  | 'application/sketchup'
  | 'application/x-koan'
  | 'application/koan'
  | 'application/msoutlook'
  | 'application/vnd.ms-outlook'
  | 'application/zip'
  | 'application/rtf'
  | 'application/vnd.oasis.opendocument.spreadsheet'
  | 'application/vnd.oasis.opendocument.presentation'
  | 'image/png'
  | 'image/jpeg'
  | 'image/jpg'
  | 'image/pjpeg'
  | 'image/tiff'
  | 'image/gif'
  | 'image/bmp'
  | 'image/x-bmp'
  | 'text/plain'
  | 'text/journalentry'
  | 'text/html'
  | 'text/xml'
  | 'application/xml'
  | 'message/rfc822'
  | 'multipart/related'
  | 'text/html.email'
  | 'audio/mpeg'
  | 'audio/mp3'
  | 'audio/mp4'
  | 'audio/x-wav'
  | 'audio/wav'
  | 'audio/x-ms-wma'
  | 'audio/ms-wma'
  | 'audio/x-caf'
  | 'audio/caf'
  | 'audio/3gpp'
  | 'audio/x-m4a'
  | 'audio/mp4a-latm'
  | 'video/avi'
  | 'video/msvideo'
  | 'video/mpeg'
  | 'video/mp4'
  | 'video/vnd.objectvideo'
  | 'video/x-msvideo'
  | 'video/quicktime'
  | 'video/x-ms-wmv'
  | 'video/x-ms-wvx'
  | 'video/ms-wmv'
  | 'video/3gpp'
  | 'application/evdense-portable-timeline';

export default MimeType;
