import React, {ReactElement, useState} from 'react';

import {Box, Button, Divider, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CloudUpload, NoteAdd} from '@material-ui/icons';

import theme from '../../../config/theme';

import {evidenceNav_foldersNav$key} from '../../../graphql/__generated__/evidenceNav_foldersNav.graphql';
import {evidenceNav_tagsNav$key} from '../../../graphql/__generated__/evidenceNav_tagsNav.graphql';

import EvidenceNavFolders from './evidence-nav.folders';
import EvidenceListLogEntryModal from './evidence-nav.log-entry.modal';
import EvidenceNavTags from './evidence-nav.tags';
import EvidenceListUploadFilesModal from './evidence-nav.upload-files.modal';

const DRAWER_WIDTH = 240;

const useStyles = makeStyles({
  drawer: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
    position: 'relative',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginRight: theme.spacing(4),
  },
  drawerContainer: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: DRAWER_WIDTH,
  },
  listItemIconRoot: {minWidth: 32},
  listItemText: {wordBreak: 'break-all'},
  divider: {
    marginBlock: theme.spacing(2),
  },
});

type Props = {
  account: evidenceNav_foldersNav$key;
  currentFolderId: string;
  currentTagFilterIds: number[];
  folder: evidenceNav_tagsNav$key;
  setCurrentFolderId(id: string): void;
  setCurrentTagFilterIds(ids: number[]): void;
};

const EvidenceNavView = ({
  account,
  currentFolderId,
  currentTagFilterIds,
  folder,
  setCurrentFolderId,
  setCurrentTagFilterIds,
}: Props): ReactElement => {
  const classes = useStyles();
  const [openLogEntryModal, setOpenLogEntryModal] = useState<boolean>(false);
  const [openUploadFilesModal, setOpenUploadFilesModal] = useState<boolean>(false);

  const handleOpenLogEntryModal = () => {
    setOpenLogEntryModal(true);
  };

  const handleOpenUploadFilesModal = () => {
    setOpenUploadFilesModal(true);
  };

  const handleCloseUploadFilesModal = () => {
    setOpenUploadFilesModal(false);
  };

  const handleCloseLogEntryModal = () => {
    setOpenLogEntryModal(false);
  };

  return (
    <aside className={classes.drawer}>
      <Box className={classes.drawerContainer}>
        <EvidenceNavFolders
          account={account}
          currentFolderId={currentFolderId}
          setCurrentFolderId={setCurrentFolderId}
        />
        <br />
        <Grid container direction={'column'} spacing={1}>
          <Grid item>
            <Button
              color={'secondary'}
              fullWidth
              onClick={handleOpenUploadFilesModal}
              startIcon={<CloudUpload />}
              variant={'contained'}
            >
              Upload Files
            </Button>
          </Grid>
          <Grid item>
            <Button
              color={'primary'}
              fullWidth
              onClick={handleOpenLogEntryModal}
              startIcon={<NoteAdd />}
              variant={'contained'}
            >
              Log Entry
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        {/* TODO sort tags to currently filtered are at top */}
        <EvidenceNavTags
          currentFolderId={currentFolderId}
          currentTagFilterIds={currentTagFilterIds}
          folder={folder}
          setCurrentTagFilterIds={setCurrentTagFilterIds}
        />
      </Box>
      <EvidenceListLogEntryModal onClose={handleCloseLogEntryModal} open={openLogEntryModal} />
      <EvidenceListUploadFilesModal
        onClose={handleCloseUploadFilesModal}
        open={openUploadFilesModal}
      />
    </aside>
  );
};

export default EvidenceNavView;
