import React from 'react';

import {makeStyles} from '@material-ui/core/styles';

import {TTimelineEvidence, TWorkspaceSortKey} from '../../../state/timeline/state';

import TimelineCalendar from '../timeline-calendar';
import TimelineFilmstripIndicator from './timeline-filmstrip-indicator';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
  },
  filmstripContainer: {
    'display': 'flex',
    'alignItems': 'center',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'position': 'relative',
    'width': 'calc(100% - 4rem)',
    '& > div': {
      'width': '100%',
      '& > div': {
        gridTemplateRows: '1fr 1fr',
        paddingTop: '0.5rem',
      },
      '& .hours, & .monthDays': {
        display: 'none',
      },
    },
  },
}));

type Props = {
  months: {month: Date; evidences: TTimelineEvidence[]}[];
  sortKey: TWorkspaceSortKey;
  selectedWorkspaceId: string | undefined;
  workspacePosition: {x: number; y: number} | undefined;
};

const TimelineFilmstripView: React.FC<Props> = ({
  months,
  sortKey,
  selectedWorkspaceId,
  workspacePosition,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!(months == void 0 || months.length == 0) && (
        <div className={classes.filmstripContainer}>
          <TimelineCalendar months={months} sortKey={sortKey} />
          <TimelineFilmstripIndicator
            selectedWorkspaceId={selectedWorkspaceId}
            workspacePosition={workspacePosition}
          />
        </div>
      )}
    </div>
  );
};

export default TimelineFilmstripView;
