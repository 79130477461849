import React, {ReactElement} from 'react';

import {Redirect, Route} from 'react-router-dom';

type Props = {
  children: ReactElement;
  isAuthenticated: boolean;
  redirectTo: string;
  path?: string;
};

const PrivateRoute = ({
  children,
  isAuthenticated,
  redirectTo,
  path,
  ...rest
}: Props): ReactElement => {
  return (
    <Route
      {...rest}
      path={path}
      render={({location}) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectTo,
              state: {from: location},
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
