import React from 'react';

import {Typography} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';

import {TTimelineEvidence, TWorkspaceSortKey, TWorkspaceTag} from '../../../state/timeline/state';

import TimelineCalendar from '../timeline-calendar';

const useStyles = makeStyles<Theme, {timelineStart: number; timelineEnd: number}>({
  containerEmpty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    position: 'relative',
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  emptyMsg: {
    color: 'var(--workspace-contrast-text-color)',
  },
  container: {
    '--timeline-start': ({timelineStart}) => `calc(${timelineStart}/100)`,
    '--timeline-end': ({timelineEnd}) => `calc(${timelineEnd}/100)`,
    '--timeline-scale': 'calc(1 / (var(--timeline-end) - var(--timeline-start)))',

    'display': 'flex',
    'justifyContent': 'center',
    'overflow': 'visible',
    'width': '100%',
    'zIndex': 1,
    'transform-origin': 'calc(var(--timeline-start) * 100%) 50%',
    'transform': `
      translate(calc(var(--timeline-start) * -100%), 0)
      scale(var(--timeline-scale))
    `,
  },
  calendarRoot: {
    position: 'relative',
    flex: 1,
    minWidth: 'min-content',
    scrollbarWidth: 'none',
  },
});

type Props = {
  selectedWorkspaceId: string | undefined;
  months: {month: Date; evidences: TTimelineEvidence[]}[];
  sortKey: TWorkspaceSortKey;
  workspacePosition: {x: number; y: number} | undefined;
  openedEvidenceNode: TTimelineEvidence | undefined;
  openedWorkspaceTag: TWorkspaceTag | undefined;
};

const TimelineCanvasCalendarView: React.FC<Props> = ({
  months,
  sortKey,
  workspacePosition,
  openedEvidenceNode,
  openedWorkspaceTag,
}) => {
  const classes = useStyles({
    timelineStart: workspacePosition?.x || 0,
    timelineEnd: workspacePosition?.y || 100,
  });

  if (months == undefined || months.length == 0) {
    return (
      <div className={classes.containerEmpty}>
        <Typography className={classes.emptyMsg} variant={'h2'}>
          Please select at least one each of folders, tags, and types
          <br />
          in order to see matching timeline evidences.
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <TimelineCalendar
        classes={{
          root: classes.calendarRoot,
        }}
        connectionTarget
        months={months}
        sortKey={sortKey}
        trackVisibility={!openedEvidenceNode && !openedWorkspaceTag}
      />
    </div>
  );
};

export default TimelineCanvasCalendarView;
