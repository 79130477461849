import * as t from 'io-ts';
import * as tt from 'io-ts-types';

import {fromEnum} from '../../common/data/types';

export const TagT = t.type({
  id: t.number,
  text: t.string,
  evidenceCount: t.union([t.number, t.undefined]),
});

export type TTag = t.OutputOf<typeof TagT>;

export const CommentT = t.type({
  created: tt.date,
  id: t.string,
  authorName: t.string,
  body: t.string,
  evidenceId: t.string,
});

export type TComment = t.OutputOf<typeof CommentT>;

export const ContentTypeT = t.type({
  type: t.string,
  name: t.string,
  id: t.number,
});

export const EvidenceReqT = t.type({
  id: t.string,
  fileName: t.string,
  storedOn: tt.date,
  eventDate: tt.date,
  contentType: ContentTypeT,
  size: t.number,
  tagIds: t.array(t.number),
  folderIds: t.array(t.string),
  comments: t.array(CommentT),
});

export const EvidenceOptT = t.partial({
  subject: t.string,
  body: t.string,
  url: t.string,
});

export const EvidenceT = t.intersection([EvidenceReqT, EvidenceOptT]);

export type TEvidence = t.OutputOf<typeof EvidenceT>;

export enum FolderTypeEnum {
  UNSORTEDFOLDER = 'UnsortedFolder',
  ALLFILESFOLDER = 'AllFilesFolder',
  USERFOLDER = 'UserFolder',
}

export const FolderTypeCodec = fromEnum('FolderTypeEnum', FolderTypeEnum);

export const FolderT = t.type({
  id: t.string,
  name: t.string,
  evidenceCount: t.number,
  type: FolderTypeCodec,
});

export type TFolder = t.OutputOf<typeof FolderT>;
