import produce from 'immer';
import {inject, injectable} from 'inversify';
import {Store} from 'redux';

import {TRootState, rootReducer} from '../../state/store';
import {
  addWorkspace,
  removeWorkspace,
  setFolderFilters,
  setSelectedWorkspaceId,
  setTagFilters,
  setTypeFilters,
  toggleFolderFilter,
  toggleTagFilter,
  toggleTypeFilter,
} from '../../state/timeline/actions';
import {
  getAllFolders,
  getAllTags,
  getAllTypes,
  getFolderFilters,
  getSelectedWorkspace,
  getSelectedWorkspaceId,
  getTagFilters,
  getTypeFilters,
  getWorkspaceById,
} from '../../state/timeline/selectors';
import {TWorkspace} from '../../state/timeline/state';

import {FilterToggle} from '../../pages/timeline/filter-selection-drawer/filter-selection-drawer.controller';
import keys from '../common/container/keys';
import {IEvidenceService} from '../evidences/evidence-service';
import {DownloadEvidenceOption} from './data/types';
import {makeOfflineDocument} from './offline-timeline';

export interface ITimelineService {
  selectWorkspace(workspaceId: string): Promise<void>;

  updateWorkspace(workspaceId: string): Promise<void>;

  createWorkspace(workspace: TWorkspace): Promise<void>;

  deleteWorkspace(workspaceId: string): Promise<void>;

  toggleFilter(filter: FilterToggle): Promise<void>;

  toggleAllFilters(type: 'folders' | 'tags' | 'types'): Promise<void>;

  downloadTimeline(downloadEntites?: DownloadEvidenceOption): Promise<void>;
}

@injectable()
export default class TimelineService implements ITimelineService {
  @inject(keys.evidences.IEvidenceService)
  private entityService!: IEvidenceService;

  @inject(keys.store)
  private store!: Store;

  public async selectWorkspace(workspaceId: string): Promise<void> {
    this.store.dispatch(setSelectedWorkspaceId(workspaceId));
  }

  public async updateWorkspace(workspaceId: string): Promise<void> {
    const state = this.store.getState(),
      workspace = getWorkspaceById(workspaceId)(state);

    if (!workspace) {
      throw new Error(`No workspace with the id ${workspaceId} found`);
    }

    this.updateFilters();
  }

  private updateFilters(): void {
    const state = this.store.getState(),
      workspace = getSelectedWorkspace(state);

    if (!workspace) {
      return;
    }

    const {folders, tags, types} = workspace.filters;

    this.store.dispatch(
      setFolderFilters({
        workspaceId: workspace.id,
        folders,
      }),
    );
    this.store.dispatch(
      setTagFilters({
        workspaceId: workspace.id,
        tags,
      }),
    );
    this.store.dispatch(
      setTypeFilters({
        workspaceId: workspace.id,
        types,
      }),
    );
  }

  public async createWorkspace(workspace: TWorkspace): Promise<void> {
    this.store.dispatch(addWorkspace(workspace));
  }

  public async deleteWorkspace(workspaceId: string): Promise<void> {
    this.store.dispatch(removeWorkspace(workspaceId));
  }

  public async toggleFilter(filter: FilterToggle): Promise<void> {
    const state = this.store.getState(),
      workspaceId = getSelectedWorkspaceId(state);

    if (!workspaceId) {
      return;
    }

    switch (filter.type) {
      case 'folder':
        this.store.dispatch(
          toggleFolderFilter({
            workspaceId,
            folderId: filter.id,
          }),
        );
        break;
      case 'tag':
        this.store.dispatch(
          toggleTagFilter({
            workspaceId,
            tagId: filter.id,
          }),
        );
        break;
      case 'type':
        this.store.dispatch(
          toggleTypeFilter({
            workspaceId,
            typeId: filter.id,
          }),
        );
    }
  }

  public async toggleAllFilters(type: 'folders' | 'tags' | 'types'): Promise<void> {
    const state = this.store.getState(),
      selectedWorkspaceId = getSelectedWorkspaceId(state);

    if (!selectedWorkspaceId) {
      return;
    }

    if (type === 'folders') {
      const allFolders = getAllFolders(state),
        folderFilters = getFolderFilters(state);

      const allSelected = !allFolders.some((folder) => !folderFilters.includes(folder.id));

      if (allSelected) {
        this.store.dispatch(
          setFolderFilters({
            workspaceId: selectedWorkspaceId,
            folders: [],
          }),
        );
      } else {
        this.store.dispatch(
          setFolderFilters({
            workspaceId: selectedWorkspaceId,
            folders: allFolders.map((f) => f.id),
          }),
        );
      }
    } else if (type === 'tags') {
      const allTags = getAllTags(state),
        tagFilters = getTagFilters(state);

      const allSelected = !allTags.some((tag) => !tagFilters.includes(tag.id));

      if (allSelected) {
        this.store.dispatch(
          setTagFilters({
            workspaceId: selectedWorkspaceId,
            tags: [],
          }),
        );
      } else {
        this.store.dispatch(
          setTagFilters({
            workspaceId: selectedWorkspaceId,
            tags: allTags.map((t) => t.id),
          }),
        );
      }
    } else {
      const allTypes = getAllTypes(state),
        typeFilters = getTypeFilters(state);

      const allDefinedTypes = allTypes.filter((t) => t.id !== undefined);

      const allSelected = !allDefinedTypes.some((type) => !typeFilters.includes(type.id));

      if (allSelected) {
        this.store.dispatch(
          setTypeFilters({
            workspaceId: selectedWorkspaceId,
            types: [],
          }),
        );
      } else {
        this.store.dispatch(
          setTypeFilters({
            workspaceId: selectedWorkspaceId,
            types: allTypes.map((t) => t.id),
          }),
        );
      }
    }
  }

  public async downloadTimeline(downloadEntites?: DownloadEvidenceOption): Promise<void> {
    const state = await this.getState(downloadEntites);

    const renderedTemplate = state ? makeOfflineDocument(state) : '';
    const templateBlob = new Blob([renderedTemplate], {type: 'text/html;charset=utf-8'});
    const downloadUrl = URL.createObjectURL(templateBlob);

    const downloadElement = document.createElement('a');
    downloadElement.setAttribute('download', 'portable_timeline.html');
    downloadElement.setAttribute('href', downloadUrl);
    downloadElement.click();
  }

  private async getState(
    downloadEntities: DownloadEvidenceOption = 'cached',
  ): Promise<TRootState | undefined> {
    if (downloadEntities === 'all') {
      await Promise.all([
        this.entityService.cacheFileBlobs(),
        this.entityService.cacheThumbnailBlobs(),
      ]);
    }

    let state: ReturnType<typeof rootReducer> = this.store.getState();

    if (downloadEntities === 'omit') {
      state = produce(state, (draft: ReturnType<typeof rootReducer>) => {
        draft.evidences.evidenceFiles = {};
        draft.evidences.evidenceThumbnails = {};
      });
    }

    state = produce(state, (draft: ReturnType<typeof rootReducer>) => {
      draft.timeline.offlineMode = true;
    });

    return state;
  }
}
