/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteWorkspaceInput = {
    workspaceId: string;
    clientMutationId?: string | null | undefined;
};
export type deleteWorkspaceModalDeleteWorkspaceMutationVariables = {
    input: DeleteWorkspaceInput;
};
export type deleteWorkspaceModalDeleteWorkspaceMutationResponse = {
    readonly deleteWorkspace: {
        readonly account: {
            readonly name: string;
        };
    } | null;
};
export type deleteWorkspaceModalDeleteWorkspaceMutation = {
    readonly response: deleteWorkspaceModalDeleteWorkspaceMutationResponse;
    readonly variables: deleteWorkspaceModalDeleteWorkspaceMutationVariables;
};



/*
mutation deleteWorkspaceModalDeleteWorkspaceMutation(
  $input: DeleteWorkspaceInput!
) {
  deleteWorkspace(input: $input) {
    account {
      name
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteWorkspaceModalDeleteWorkspaceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteWorkspacePayload",
        "kind": "LinkedField",
        "name": "deleteWorkspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteWorkspaceModalDeleteWorkspaceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteWorkspacePayload",
        "kind": "LinkedField",
        "name": "deleteWorkspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eedbddc5e1a874419e262acfaae3dc96",
    "id": null,
    "metadata": {},
    "name": "deleteWorkspaceModalDeleteWorkspaceMutation",
    "operationKind": "mutation",
    "text": "mutation deleteWorkspaceModalDeleteWorkspaceMutation(\n  $input: DeleteWorkspaceInput!\n) {\n  deleteWorkspace(input: $input) {\n    account {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5cd46fe4a8399a1af4d225a1684a739a';
export default node;
