import React, {Fragment, useCallback, useState} from 'react';

import {EditorState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {useDispatch} from 'react-redux';

import LoadingScreen from '../../../../components/feedback/loading-screen';

import useCreateJournalEntry from '../../../../hooks/mutations/create-journal-entry.hook';

import {setApiFeedback} from '../../../../state/common/actions';

import EvidenceNavLogEntryModalView from './evidence-nav.log-entry.modal.view';

type Props = {
  open: boolean;
  onClose(): void;
};

const EvidenceNavLogEntryModal: React.FC<Props> = ({open, onClose}) => {
  const dispatch = useDispatch();

  const [subjectValue, setSubjectValue] = useState<string>('');
  const [subjectError, setSubjectError] = useState<boolean>(false);
  const [subjectHelperText, setSubjectHelperText] = useState<string | null>(null);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const [createJournalEntry, loading] = useCreateJournalEntry();

  const onSubjectValueChange = (subjectValue: string) => {
    setSubjectValue(subjectValue);
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const handleSubmit = () => {
    // set values
    const subject = subjectValue;
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const body = draftToHtml(rawContentState);

    // validate form
    const MIN_SUBJECT_LENGTH = 3;

    if (subject.length >= MIN_SUBJECT_LENGTH) {
      handleCreateJournalEntry({subject, body});
      handleClose();
    } else {
      setSubjectError(true);
      setSubjectHelperText(
        `Journal Entry subject must be at least ${MIN_SUBJECT_LENGTH} characters`,
      );
    }
  };

  const handleCreateJournalEntry = useCallback(
    ({subject, body}) => {
      const callback = () => {
        dispatch(
          setApiFeedback({
            severity: 'success',
            message: 'New journal entry successflly created',
          }),
        );
      };
      createJournalEntry(new Date(), subject, body, callback);
    },
    [editorState],
  );

  const handleClose = () => {
    setSubjectValue('');
    setSubjectError(false);
    setEditorState(EditorState.createEmpty());
    onClose();
  };

  return (
    <Fragment>
      {loading && <LoadingScreen />}
      <EvidenceNavLogEntryModalView
        editorState={editorState}
        handleSubmit={handleSubmit}
        onClose={handleClose}
        onEditorStateChange={onEditorStateChange}
        onSubjectValueChange={onSubjectValueChange}
        open={open}
        subjectError={subjectError}
        subjectHelperText={subjectHelperText}
      />
    </Fragment>
  );
};

export default EvidenceNavLogEntryModal;
