/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AddEvidenceToFolderInput = {
    sourceFolderId: string;
    targetFolderId: string;
    evidenceIds: Array<string>;
    clientMutationId?: string | null | undefined;
};
export type addEvidenceToFolderMutationVariables = {
    input: AddEvidenceToFolderInput;
};
export type addEvidenceToFolderMutationResponse = {
    readonly addEvidenceToFolder: {
        readonly sourceFolder: {
            readonly evidenceCount: number;
            readonly " $fragmentRefs": FragmentRefs<"evidenceNav_tagsNav" | "evidenceMain_tagFilter">;
        };
        readonly targetFolder: {
            readonly evidenceCount: number;
        };
        readonly evidenceNodes: ReadonlyArray<{
            readonly node: {
                readonly folderCount: number;
                readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_folderGroup">;
            };
        }>;
    } | null;
};
export type addEvidenceToFolderMutation = {
    readonly response: addEvidenceToFolderMutationResponse;
    readonly variables: addEvidenceToFolderMutationVariables;
};



/*
mutation addEvidenceToFolderMutation(
  $input: AddEvidenceToFolderInput!
) {
  addEvidenceToFolder(input: $input) {
    sourceFolder {
      __typename
      ...evidenceNav_tagsNav
      ...evidenceMain_tagFilter
      evidenceCount
      id
    }
    targetFolder {
      evidenceCount
      id
    }
    evidenceNodes {
      node {
        __typename
        folderCount
        ...evidenceDetail_folderGroup
        id
      }
    }
  }
}

fragment evidenceDetail_folderGroup on Evidence {
  __isEvidence: __typename
  id
  folders {
    id
    name
  }
}

fragment evidenceMain_tagFilter on Folder {
  __isFolder: __typename
  tags {
    id
    tagId
    text
  }
}

fragment evidenceNav_tagsNav on Folder {
  __isFolder: __typename
  id
  tags {
    id
    tagId
    text
    evidenceCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "evidenceCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "folderCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addEvidenceToFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddEvidenceToFolderPayload",
        "kind": "LinkedField",
        "name": "addEvidenceToFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "sourceFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceNav_tagsNav"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceMain_tagFilter"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFolder",
            "kind": "LinkedField",
            "name": "targetFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceDetail_folderGroup"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addEvidenceToFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddEvidenceToFolderPayload",
        "kind": "LinkedField",
        "name": "addEvidenceToFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "sourceFolder",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isFolder"
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FolderTag",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tagId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFolder",
            "kind": "LinkedField",
            "name": "targetFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EvidenceNode",
            "kind": "LinkedField",
            "name": "evidenceNodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEvidence"
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFolder",
                    "kind": "LinkedField",
                    "name": "folders",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "881e62834a2c93f1b32ceafc1c795616",
    "id": null,
    "metadata": {},
    "name": "addEvidenceToFolderMutation",
    "operationKind": "mutation",
    "text": "mutation addEvidenceToFolderMutation(\n  $input: AddEvidenceToFolderInput!\n) {\n  addEvidenceToFolder(input: $input) {\n    sourceFolder {\n      __typename\n      ...evidenceNav_tagsNav\n      ...evidenceMain_tagFilter\n      evidenceCount\n      id\n    }\n    targetFolder {\n      evidenceCount\n      id\n    }\n    evidenceNodes {\n      node {\n        __typename\n        folderCount\n        ...evidenceDetail_folderGroup\n        id\n      }\n    }\n  }\n}\n\nfragment evidenceDetail_folderGroup on Evidence {\n  __isEvidence: __typename\n  id\n  folders {\n    id\n    name\n  }\n}\n\nfragment evidenceMain_tagFilter on Folder {\n  __isFolder: __typename\n  tags {\n    id\n    tagId\n    text\n  }\n}\n\nfragment evidenceNav_tagsNav on Folder {\n  __isFolder: __typename\n  id\n  tags {\n    id\n    tagId\n    text\n    evidenceCount\n  }\n}\n"
  }
};
})();
(node as any).hash = '05d5de9acbeb52ebc01b15206d35e909';
export default node;
