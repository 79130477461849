import React, {ReactElement, useState} from 'react';

import {UsernameCaseMapped} from '@tynor/precis';
import {plainToClass} from 'class-transformer';
import {ValidationError, validateSync} from 'class-validator';
import {Base64} from 'js-base64';
import {useDispatch} from 'react-redux';
import {graphql, useMutation} from 'react-relay';
import {useHistory} from 'react-router-dom';

import {loginFormControllerRespondPasswordMutation} from '../../../graphql/__generated__/loginFormControllerRespondPasswordMutation.graphql';
import type {loginFormControllerStartAuthMutation} from '../../../graphql/__generated__/loginFormControllerStartAuthMutation.graphql';

import {clientAuth} from '../../../utils/auth';
import {isBase64} from '../../../utils/utils';
import LoginFormData from '../../../utils/validator-classes/login-form.data';

import {setApiFeedback, setIsAuthenticated} from '../../../state/common/actions';

import {LocationState} from '../login-page';
import LoginFormView from './login-form.view';

export type ChangeData =
  | {
      property: 'email';
      email: string;
    }
  | {
      property: 'password';
      password: string;
    };

type Props = {
  referrer: LocationState['from'];
};

const loginFormController = ({referrer}: Props): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidated, setIsValidated] = useState(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const [commitStartAuth] = useMutation<loginFormControllerStartAuthMutation>(graphql`
    mutation loginFormControllerStartAuthMutation($input: StartAuthInput!) {
      startAuth(input: $input) {
        token
        challenge {
          nonce
          salt
          iterations
        }
      }
    }
  `);

  const [commitRespondPassword] = useMutation<loginFormControllerRespondPasswordMutation>(graphql`
    mutation loginFormControllerRespondPasswordMutation(
      $input: RespondPasswordAuthChallengeInput!
    ) {
      respondPasswordAuthChallenge(input: $input) {
        result {
          ... on AuthOK {
            session {
              user {
                ...headerNav_userRoles
              }
            }
          }
          ... on AuthNext {
            options {
              ... on SelectAccountAuthChallenge {
                accounts {
                  account {
                    id
                    name
                  }
                  type
                }
              }
            }
          }
        }
      }
    }
  `);

  function onChange(data: ChangeData) {
    const {property, ...changeData} = data;

    const formData = plainToClass(
      LoginFormData,
      Object.assign(
        {},
        {
          email,
          password,
        },
        changeData,
      ),
    );

    if (errors.length !== 0) {
      const formErrors = validateSync(formData);
      setErrors(formErrors);
    }

    if (property === 'email') {
      setEmail(formData.email);
    } else {
      setPassword(formData.password);
    }
  }

  async function onSend() {
    const formData = plainToClass(LoginFormData, {
      email,
      password,
    });

    const formErrors = validateSync(formData);
    if (formErrors.length !== 0) {
      setErrors(formErrors);
      setIsValidated(true);
      return;
    }

    // While the server will ensure the email is normalized for the
    // purposes of user lookup, it is required to do this on the client
    // as well.
    // The `AuthMessage` is constructed in part with the username,
    // and it *must* match the `AuthMessage` constructed by the server.
    //
    // It has the additional benefit of catching errors that would be
    // returned by the server before starting the auth sequence.
    let username: string;
    try {
      username = UsernameCaseMapped.enforce(email);
    } catch (e) {
      // TODO: Error handling
      // This case is hit if the email is malformed in terms of what the
      // server will accept.
      // It catches things such as invalid Unicode codepoints.
      // The error will be one of the errors from here:
      // https://github.com/tynor/precis-js/blob/v0.4.0/src/error.ts
      // They will include the index into the email where the error
      // occurred, if relevant.
      return;
    }

    const clientNonce = await clientAuth.nonce();
    commitStartAuth({
      variables: {
        input: {
          nonce: clientNonce,
          username,
        },
      },
      onCompleted(data) {
        const startAuth = data.startAuth;
        if (startAuth === null) {
          // TODO: Error handling
          return;
        }
        const {
          token,
          challenge: {nonce, salt: encodedSalt, iterations},
        } = startAuth;
        if (!nonce.startsWith(clientNonce)) {
          // TODO: Error handling
          return;
        }
        if (!isBase64(encodedSalt)) {
          // TODO: Error handling
          return;
        }
        const salt = Base64.toUint8Array(encodedSalt);
        clientAuth
          .saltedPassword({password, salt, iterations})
          .then((password) =>
            clientAuth.createProof({
              password,
              authMessage: clientAuth.authMessage({
                username,
                nonce,
                salt,
                iterations,
              }),
            }),
          )
          .then((proof) => {
            commitRespondPassword({
              variables: {
                input: {
                  token,
                  proof: Base64.fromUint8Array(proof),
                },
              },
              onCompleted(data) {
                const result = data.respondPasswordAuthChallenge?.result;
                if (!result) {
                  // TODO: Error handling
                  return;
                }
                const {options} = result;
                if (options) {
                  // TODO: Additional challenges are required
                  // It would probably make sense to have some state
                  // for which challenge was active or something.
                  return;
                }
                dispatch(setIsAuthenticated(true));
                // Redirects to original URL or homepage
                history.push(referrer);
              },
              /* eslint-disable @typescript-eslint/no-explicit-any */
              onError(e: any) {
                // TODO: Error handling
                console.error(e);
                dispatch(setApiFeedback({message: e[0].message}));
              },
            });
          })
          .catch((e) => {
            // TODO: Error handling
            dispatch(setApiFeedback({error: e}));
            console.error(e);
          });
      },
      onError(e) {
        // TODO: Error handling
        dispatch(setApiFeedback({error: e}));
        console.error(e);
      },
    });
  }

  return (
    <LoginFormView
      email={email}
      errors={!isValidated ? [] : errors}
      onChange={onChange}
      onSend={onSend}
      password={password}
    />
  );
};

export default loginFormController;
