import {IsEmail, MinLength} from 'class-validator';

export default class LoginFormData {
  @IsEmail()
  @MinLength(1)
  public email = '';

  @MinLength(1)
  public password = '';
}
