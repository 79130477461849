/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type tagsQueryVariables = {};
export type tagsQueryResponse = {
    readonly currentSession: {
        readonly account?: {
            readonly activeTagsWithIds: ReadonlyArray<{
                readonly id: string;
                readonly text: string;
            }>;
            readonly allFilesFolder: {
                readonly type: string;
                readonly id: string;
                readonly evidences: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly storedOn: Date;
                            readonly fileName: string;
                            readonly eventDate: Date;
                            readonly size: number;
                            readonly folders: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string;
                            }>;
                            readonly tags: ReadonlyArray<{
                                readonly id: string;
                                readonly text: string;
                            }>;
                            readonly comments: ReadonlyArray<{
                                readonly id: string;
                                readonly created: Date;
                                readonly body: string;
                                readonly authorName: string;
                            }>;
                            readonly mimeType?: string | undefined;
                            readonly url?: string | undefined;
                            readonly subject?: string | undefined;
                            readonly body?: string | undefined;
                        };
                    } | null> | null;
                };
            };
        } | null | undefined;
        readonly message?: string | undefined;
    } | null;
};
export type tagsQuery = {
    readonly response: tagsQueryResponse;
    readonly variables: tagsQueryVariables;
};



/*
query tagsQuery {
  currentSession {
    __typename
    ... on Session {
      account {
        activeTagsWithIds {
          id
          text
        }
        allFilesFolder {
          type: __typename
          id
          evidences {
            edges {
              node {
                __typename
                id
                storedOn: created
                fileName: name
                eventDate
                size
                folders {
                  id
                  name
                }
                tags: tagsWithIds {
                  id
                  text
                }
                comments {
                  id
                  created
                  body
                  authorName
                }
                ... on File {
                  mimeType
                  url
                }
                ... on JournalEntry {
                  subject
                  body
                }
              }
            }
          }
        }
        id
      }
    }
    ... on SessionError {
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "TagWithId",
  "kind": "LinkedField",
  "name": "activeTagsWithIds",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": "storedOn",
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v5 = {
  "alias": "fileName",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserFolder",
  "kind": "LinkedField",
  "name": "folders",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": "tags",
  "args": null,
  "concreteType": "TagWithId",
  "kind": "LinkedField",
  "name": "tagsWithIds",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Comment",
  "kind": "LinkedField",
  "name": "comments",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mimeType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "File",
  "abstractKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    (v10/*: any*/)
  ],
  "type": "JournalEntry",
  "abstractKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "SessionError",
  "abstractKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "tagsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllFilesFolder",
                    "kind": "LinkedField",
                    "name": "allFilesFolder",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EvidenceConnection",
                        "kind": "LinkedField",
                        "name": "evidences",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EvidenceEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "tagsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllFilesFolder",
                    "kind": "LinkedField",
                    "name": "allFilesFolder",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EvidenceConnection",
                        "kind": "LinkedField",
                        "name": "evidences",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EvidenceEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/),
                                  (v0/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81527d0bb4344965f736e0a3d9bd6661",
    "id": null,
    "metadata": {},
    "name": "tagsQuery",
    "operationKind": "query",
    "text": "query tagsQuery {\n  currentSession {\n    __typename\n    ... on Session {\n      account {\n        activeTagsWithIds {\n          id\n          text\n        }\n        allFilesFolder {\n          type: __typename\n          id\n          evidences {\n            edges {\n              node {\n                __typename\n                id\n                storedOn: created\n                fileName: name\n                eventDate\n                size\n                folders {\n                  id\n                  name\n                }\n                tags: tagsWithIds {\n                  id\n                  text\n                }\n                comments {\n                  id\n                  created\n                  body\n                  authorName\n                }\n                ... on File {\n                  mimeType\n                  url\n                }\n                ... on JournalEntry {\n                  subject\n                  body\n                }\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n    ... on SessionError {\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f1a3b0227cae84728b359a5215bbb623';
export default node;
