"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.codepoint = void 0;
const codepoint = (c) => {
    if (c.length === 0) {
        throw new Error(`Zero length string '${c}' passed to codepoint`);
    }
    return c.codePointAt(0);
};
exports.codepoint = codepoint;
