import React, {Fragment, ReactElement} from 'react';

import {TableCell, TableRow, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {graphql, useFragment} from 'react-relay';

import {evidenceDetail_activeTags$key} from '../../graphql/__generated__/evidenceDetail_activeTags.graphql';
import {evidenceDetail_metaData$key} from '../../graphql/__generated__/evidenceDetail_metaData.graphql';

import {humanFileSize, readableDateString, readableDateTimeString} from '../../utils/utils';

import {JOURNAL_ENTRY_MIMETYPE} from '../../services/evidences/data/evidence/content-type';
import {Referrer} from './evidence-detail.controller';

const useStyles = makeStyles((theme) => ({
  fileName: {wordBreak: 'break-all'},
  freeSoloCreateOption: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  account: evidenceDetail_activeTags$key;
  evidence: evidenceDetail_metaData$key;
  referrer: Referrer['referrer'];
  currentFolderId: string;
};

const EvidenceDetailMetaData = ({evidence}: Props): ReactElement => {
  const classes = useStyles();

  const data = useFragment(
    graphql`
      fragment evidenceDetail_metaData on Evidence @relay(plural: true) {
        id
        storedOn: created
        fileName: name
        eventDate
        size
        ... on File {
          mimeType
        }
      }
    `,
    evidence as evidenceDetail_metaData$key,
  );

  console.warn(data);

  if (!data) {
    return <div />;
  }

  const {fileName, storedOn, eventDate, size, mimeType} = data[0];

  return (
    <Fragment>
      <TableRow>
        <TableCell component={'th'} scope={'row'} width={'33%'}>
          <Typography noWrap variant={'inherit'}>
            Name:
          </Typography>
        </TableCell>
        <TableCell className={classes.fileName}>
          <b>{fileName}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component={'th'} scope={'row'}>
          Stored:
        </TableCell>
        <TableCell>
          <b>{readableDateString(storedOn)}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component={'th'} scope={'row'}>
          Occurred:
        </TableCell>
        <TableCell>
          <b>{readableDateTimeString(eventDate)}</b>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component={'th'} scope={'row'} width={'33%'}>
          Size:
        </TableCell>
        <TableCell>
          <b>{humanFileSize(size, true)}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component={'th'} scope={'row'} width={'33%'}>
          <Typography noWrap variant={'inherit'}>
            Type:
          </Typography>
        </TableCell>
        <TableCell>
          <b>{mimeType ?? JOURNAL_ENTRY_MIMETYPE}</b>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default EvidenceDetailMetaData;
