import React from 'react';

import {enableAllPlugins} from 'immer';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';

import App from './app';
import * as serviceWorker from './serviceWorker';

enableAllPlugins();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(module as any)?.hot?.accept();

// TODO: use error boundaries - https://reactjs.org/docs/error-boundaries.html
window.addEventListener('unhandledrejection', (e) => {
  console.error('unhandled rejection: [%s] - [%o]', e.reason, e);
  e.cancelBubble = true;
  e.preventDefault();
  e.stopPropagation();
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
