import evidenceKeys from '../../evidences/container/keys';
import timelineKeys from '../../timeline/container/keys';

const keys = {
  NODE_ENV: Symbol.for('@common/NODE_ENV'),
  BASE_URL: Symbol.for('@common/BASE_URL'),
  store: Symbol.for('@common/store'),
  IRequestService: Symbol.for('@common/IRequestService'),
  ISessionFacade: Symbol.for('@common/ISessionFacade'),
  SessionFacade: Symbol.for('@common/SessionFacade'),
  IAuthService: Symbol.for('@common/IAuthService'),
  AuthService: Symbol.for('@common/AuthService'),
  evidences: evidenceKeys,
  timeline: timelineKeys,
};

export default keys;
