import React, {CSSProperties, useState} from 'react';

import {Checkbox, ListItemIcon, Menu, MenuItem, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ColorLens, Info} from '@material-ui/icons';

import {TWorkspaceTag} from '../../../state/timeline/state';

const useStyles = makeStyles((theme) => ({checkboxRoot: {color: theme.palette.text.primary}}));

type Props = {
  anchorEl: Element | null;
  workspaceId: string | undefined;
  workspaceTag: TWorkspaceTag;
  style?: CSSProperties;
  handleClose(): void;
  handleOpenDetail(): void;
  handleOpenColorPicker(): void;
  setLockHighlight(args: {workspaceId: string; tagId: number; highlight: boolean}): void;
};

const TagNodeContextMenuView: React.FC<Props> = ({
  anchorEl,
  workspaceId,
  workspaceTag,
  handleClose,
  handleOpenDetail,
  handleOpenColorPicker,
  setLockHighlight,
  style,
}) => {
  const [checked, setChecked] = useState(workspaceTag.lockHighlight),
    classes = useStyles();

  if (!workspaceId) {
    return null;
  }

  const labelId = `node-list-lock-highlight-${workspaceTag.tagId}`;

  return (
    <Menu anchorEl={anchorEl} onClose={handleClose} open={Boolean(anchorEl)} style={style}>
      <MenuItem
        button
        dense
        onClick={() => {
          setLockHighlight({
            workspaceId,
            tagId: workspaceTag.tagId,
            highlight: !checked,
          });
          setChecked(!checked);
        }}
      >
        <ListItemIcon>
          <Checkbox
            checked={checked}
            classes={{root: classes.checkboxRoot}}
            color={'default'}
            disableRipple
            edge={'start'}
            inputProps={{'aria-labelledby': labelId}}
            tabIndex={-1}
          />
        </ListItemIcon>
        <Typography id={labelId} variant={'inherit'}>
          Lock Highlight
        </Typography>
      </MenuItem>
      <MenuItem button dense onClick={handleOpenDetail}>
        <ListItemIcon>
          <Info />
        </ListItemIcon>
        <Typography variant={'inherit'}>Show Details</Typography>
      </MenuItem>
      <MenuItem button dense onClick={handleOpenColorPicker}>
        <ListItemIcon>
          <ColorLens />
        </ListItemIcon>
        <Typography variant={'inherit'}>Pick color</Typography>
      </MenuItem>
    </Menu>
  );
};

export default TagNodeContextMenuView;
