import React, {ReactElement} from 'react';

import {Button, Grid, Typography} from '@material-ui/core';
import {deepOrange} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/core/styles';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import {graphql, useLazyLoadQuery} from 'react-relay';
import {NavLink, Route, useParams} from 'react-router-dom';

import {USER_BASE, USER_EVENT_LOG, USER_PATH} from '../../../config/routes';

import {userQuery} from '../../../graphql/__generated__/userQuery.graphql';

import EventLog from './event-log';
import UserDetails from './user-details';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabHeading: {
    flexGrow: 1,
    marginBottom: theme.spacing(6),
  },
  navLink: {
    '&.active': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      display: 'block',
    },
    '&.active .MuiButtonBase-root': {
      fontWeight: 'bold',
    },
    '&.active .MuiButton-startIcon': {
      color: deepOrange[500],
    },
  },
}));

type UserParams = {
  id: string;
};

const User = (): ReactElement => {
  const classes = useStyles();

  const {id} = useParams<UserParams>();

  const data = useLazyLoadQuery<userQuery>(
    graphql`
      query userQuery($id: ID!) {
        user: node(id: $id) {
          ... on User {
            firstName
            lastName
            ...userDetails_userRoles
            ...userDetails_userDetails
          }
        }
      }
    `,
    {id},
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userData = data.user!;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item>
          <Typography variant={'h2'}>
            User:&nbsp;
            <em>
              <b>
                {userData.firstName} {userData.lastName}
              </b>
            </em>
          </Typography>
        </Grid>
        <Grid item style={{marginLeft: 'auto'}}>
          <NavLink className={classes.navLink} exact to={USER_BASE + `/${id}`}>
            <Button startIcon={<PersonPinIcon />}>User Details</Button>
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink className={classes.navLink} to={USER_BASE + `/${id}/log`}>
            <Button startIcon={<EventNoteIcon />}>User Event Log</Button>
          </NavLink>
        </Grid>
      </Grid>

      <br />
      <Route exact path={USER_PATH}>
        <UserDetails user={userData} />
      </Route>
      <Route path={USER_EVENT_LOG}>
        <EventLog userId={id} />
      </Route>
    </div>
  );
};

export default User;
