/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetailUnsortedFolderQueryVariables = {};
export type evidenceDetailUnsortedFolderQueryResponse = {
    readonly currentSession: {
        readonly account?: {
            readonly unsortedFolder: {
                readonly id: string;
            };
            readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_activeTags">;
        } | null | undefined;
        readonly message?: string | undefined;
    } | null;
};
export type evidenceDetailUnsortedFolderQuery = {
    readonly response: evidenceDetailUnsortedFolderQueryResponse;
    readonly variables: evidenceDetailUnsortedFolderQueryVariables;
};



/*
query evidenceDetailUnsortedFolderQuery {
  currentSession {
    __typename
    ... on Session {
      account {
        unsortedFolder {
          id
        }
        ...evidenceDetail_activeTags
        id
      }
    }
    ... on SessionError {
      message
    }
  }
}

fragment evidenceDetail_activeTags on Account {
  activeTagsWithIds {
    id
    text
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "UnsortedFolder",
  "kind": "LinkedField",
  "name": "unsortedFolder",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "SessionError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "evidenceDetailUnsortedFolderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "evidenceDetail_activeTags"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "evidenceDetailUnsortedFolderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagWithId",
                    "kind": "LinkedField",
                    "name": "activeTagsWithIds",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68cb569f6e00f46d416626ddec5bec71",
    "id": null,
    "metadata": {},
    "name": "evidenceDetailUnsortedFolderQuery",
    "operationKind": "query",
    "text": "query evidenceDetailUnsortedFolderQuery {\n  currentSession {\n    __typename\n    ... on Session {\n      account {\n        unsortedFolder {\n          id\n        }\n        ...evidenceDetail_activeTags\n        id\n      }\n    }\n    ... on SessionError {\n      message\n    }\n  }\n}\n\nfragment evidenceDetail_activeTags on Account {\n  activeTagsWithIds {\n    id\n    text\n  }\n}\n"
  }
};
})();
(node as any).hash = '93e5a9d3f01703f0a20ddd83e38648de';
export default node;
