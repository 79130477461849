import React, {ReactElement, ReactNode} from 'react';

import {Dialog, DialogContent, makeStyles} from '@material-ui/core';

import DialogHeader from './dialog-header';

const useStyles = makeStyles({
  dialogContentRoot: {
    paddingBottom: '1rem',
  },
});

type Props = {
  ariaTitle?: string;
  ariaDescription?: string;
  title: ReactNode | string;
  children: ReactNode;
  open: boolean;
  hasDataGridChild?: boolean;
  size?: 'md' | 'lg' | 'xl';
  onClose(): void;
};

const GenericDialog = ({
  ariaTitle,
  ariaDescription,
  title,
  children,
  open,
  hasDataGridChild,
  size,
  onClose,
}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <Dialog
      aria-describedby={ariaDescription}
      aria-labelledby={ariaTitle}
      disableEnforceFocus={hasDataGridChild}
      fullWidth
      maxWidth={size ?? 'xl'}
      onClose={onClose}
      open={open}
    >
      {title && (
        <DialogHeader handleClose={onClose} id={ariaTitle}>
          {title}
        </DialogHeader>
      )}
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
        id={ariaDescription}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default GenericDialog;
