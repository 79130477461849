import React, {ChangeEvent, Fragment, useCallback, useState} from 'react';

import {Button, TextareaAutosize} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {AddComment} from '@material-ui/icons';
import {useDispatch} from 'react-redux';

import useAddCommentToEvidence from '../../hooks/mutations/add-comment-to-evidence.hook';

import {setApiFeedback} from '../../state/common/actions';

import LoadingScreen from '../feedback/loading-screen';

const useStyles = makeStyles((theme) => ({
  commentWrapper: {
    marginTop: theme.spacing(2),
  },
  commentTextarea: {
    width: '100%',
  },
  submit: {
    position: 'relative',
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  evidenceIds: string[];
};

const EvidenceDetailAddComment: React.FC<Props> = ({evidenceIds}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [addCommentToEvidence, loading] = useAddCommentToEvidence();
  const [commentText, setCommentText] = useState<string>();
  const [showTextarea, setShowTextarea] = useState<boolean>(false);

  const handleAddCommentToEvidence = useCallback(
    (evidenceIds, commentText) => {
      let message = '';
      const messageBase = 'Comment added successfully';
      if (evidenceIds.length === 1) {
        message = messageBase;
      } else {
        message = messageBase.concat(` to ${evidenceIds.length} evidences`);
      }
      const callback = () => {
        dispatch(
          setApiFeedback({
            severity: 'success',
            message,
          }),
        );
      };
      addCommentToEvidence(evidenceIds, commentText, callback);
      setCommentText('');
    },
    [addCommentToEvidence],
  );

  const handleShow = () => {
    setShowTextarea(true);
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(event.target.value);
  };

  const handleSubmit = () => {
    if (commentText !== null) {
      handleAddCommentToEvidence(evidenceIds, commentText);
    }
  };

  return (
    <div className={classes.commentWrapper}>
      {!showTextarea && (
        <Button onClick={handleShow} startIcon={<AddComment />} variant={'contained'}>
          Add comment
        </Button>
      )}
      {showTextarea && (
        <Fragment>
          <TextareaAutosize
            className={classes.commentTextarea}
            maxRows={10}
            minRows={4}
            onChange={handleChange}
            placeholder={'Enter new comment...'}
            value={commentText}
          />
          <div className={classes.submit}>
            <Button color={'secondary'} onClick={handleSubmit} variant={'contained'}>
              Submit
            </Button>
            {loading && <LoadingScreen inline />}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default EvidenceDetailAddComment;
