import * as t from 'io-ts';
import * as tt from 'io-ts-types';

import {
  EvidenceReqT,
  EvidenceT,
  FolderT,
  FolderTypeEnum,
  TagT,
} from '../../services/evidences/data/types';
import {EvidenceMimeType} from '../timeline/state';

const EvidenceFileT = t.type({
  type: t.string,
  data: t.string,
});

export const EvidencesState = t.type({
  evidenceFiles: t.record(t.string, EvidenceFileT),
  evidenceThumbnails: t.record(t.string, t.string),
  isLoading: t.boolean, // maybe keep
  evidences: t.array(EvidenceT),
  tags: t.array(TagT),
  currentFolderId: t.union([t.string, t.undefined]),
  currentTagFilterIds: t.array(t.number),
  selectedEvidenceIds: t.array(t.string),
});

export const DownloadEvidenceFolder = t.type({
  id: t.string,
  name: t.string,
});

export const DownloadEvidenceReq = t.type({
  id: t.string,
  fileName: t.string,
  storedOn: tt.date,
  eventDate: tt.date,
  mimeType: EvidenceMimeType,
  size: t.number,
  tags: t.array(t.number),
  folders: t.array(DownloadEvidenceFolder),
});

export const DownloadEvidenceOpt = t.partial({
  subject: t.string,
  body: t.string,
  url: t.string,
});

export const DownloadEvidence = t.intersection([DownloadEvidenceReq, DownloadEvidenceOpt]);

export type TDownloadEvidence = t.OutputOf<typeof DownloadEvidence>;

export const DEFAULT_EVIDENCE_STATE: t.TypeOf<typeof EvidenceReqT> = {
  id: '',
  fileName: '',
  storedOn: new Date(),
  eventDate: new Date(),
  contentType: {type: '', name: '', id: 0},
  size: 0,
  tagIds: [],
  folderIds: [],
  comments: [],
};

export const DEFAULT_EVIDENCES_STATE: t.TypeOf<typeof EvidencesState> = {
  evidenceFiles: {},
  evidenceThumbnails: {},
  isLoading: false,
  evidences: [],
  tags: [],
  currentFolderId: undefined,
  currentTagFilterIds: [],
  selectedEvidenceIds: [],
};

export const DEFAULT_FOLDER_STATE: t.OutputOf<typeof FolderT> = {
  id: '',
  name: '',
  evidenceCount: 0,
  type: FolderTypeEnum['USERFOLDER'],
};
