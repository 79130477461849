/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type toolsTagExportQueryVariables = {};
export type toolsTagExportQueryResponse = {
    readonly currentSession: {
        readonly account?: {
            readonly activeTags: ReadonlyArray<{
                readonly text: string;
            }>;
        } | null | undefined;
    } | null;
};
export type toolsTagExportQuery = {
    readonly response: toolsTagExportQueryResponse;
    readonly variables: toolsTagExportQueryVariables;
};



/*
query toolsTagExportQuery {
  currentSession {
    __typename
    ... on Session {
      account {
        activeTags {
          text
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "activeTags",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "toolsTagExportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "toolsTagExportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Session",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37ea975644aa770f33ca661a0888c32b",
    "id": null,
    "metadata": {},
    "name": "toolsTagExportQuery",
    "operationKind": "query",
    "text": "query toolsTagExportQuery {\n  currentSession {\n    __typename\n    ... on Session {\n      account {\n        activeTags {\n          text\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '395e3c15c056199fe25d7f63085d94db';
export default node;
