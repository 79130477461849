/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceDetail_tagGroup = ReadonlyArray<{
    readonly id: string;
    readonly fileName: string;
    readonly tags: ReadonlyArray<{
        readonly id: string;
        readonly text: string;
    }>;
    readonly " $refType": "evidenceDetail_tagGroup";
}>;
export type evidenceDetail_tagGroup$data = evidenceDetail_tagGroup;
export type evidenceDetail_tagGroup$key = ReadonlyArray<{
    readonly " $data"?: evidenceDetail_tagGroup$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"evidenceDetail_tagGroup">;
}>;



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "evidenceDetail_tagGroup",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "fileName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "tags",
      "args": null,
      "concreteType": "TagWithId",
      "kind": "LinkedField",
      "name": "tagsWithIds",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Evidence",
  "abstractKey": "__isEvidence"
};
})();
(node as any).hash = 'd891c9410655175f7fdf8582c0b8c13b';
export default node;
