import {isString} from 'class-validator';
import {inject, injectable} from 'inversify';

import {setEvidenceFileData, setEvidenceThumbnail} from '../../../state/evidence/actions';
import {getEvidenceFiles, getEvidenceThumbnails} from '../../../state/evidence/selectors';
import {RootStore} from '../../../state/store';
import {getOfflineMode} from '../../../state/timeline/selectors';

import keys from '../../common/container/keys';
import {IEvidence} from '../data/evidence/evidence';
import MimeType from '../data/evidence/mime-type';
import {EvidenceFile} from '../types';

export interface IEvidenceRepository {
  /**
   * Retrieve an evidence's file info
   * @param evidence the ID and file name to be retrieved
   * @return The file data for this evidence, or undefined if the resource does not exist
   */
  getEvidenceFile(evidence: Pick<IEvidence, 'id' | 'fileName'>): Promise<EvidenceFile | undefined>;

  /**
   * Retrieve an evidence's thumbnail, if available
   * @param evidence - The ID to be retrieved
   * @return The data URI for the thumbnail image, or undefined if the resource doesn't exist or a thumbnail isn't
   *   available
   */
  getEvidenceThumbnail(evidence: Pick<IEvidence, 'id'>): Promise<string | undefined>;
}

@injectable()
export default class EvidenceRepository implements IEvidenceRepository {
  @inject(keys.store)
  private store!: RootStore;

  public async getEvidenceFile(
    evidence: Pick<IEvidence, 'id' | 'fileName'>,
  ): Promise<EvidenceFile | undefined> {
    // if in store, return that
    const existing = getEvidenceFiles(this.store.getState())[evidence.id.toString()];

    if (existing) {
      return existing as EvidenceFile;
    }

    if (getOfflineMode(this.store.getState())) {
      return undefined;
    }

    let response: {blob: Blob; type: MimeType} | undefined;

    try {
      if (typeof response === 'undefined') {
        return;
      }
    } catch (e) {
      console.error(e);
      return;
    }

    const {blob, type} = response;

    let data;

    if (type.startsWith('text/')) {
      data = await blob.text();
    } else {
      data = await EvidenceRepository.encodeBlob(blob);
    }

    const evidenceFile = {
      data,
      type,
    };

    this.store.dispatch(
      setEvidenceFileData({
        id: evidence.id.toString(),
        file: evidenceFile,
      }),
    );

    return evidenceFile;
  }

  public async getEvidenceThumbnail({id}: Pick<IEvidence, 'id'>): Promise<string | undefined> {
    const existing = getEvidenceThumbnails(this.store.getState())[id.toString()];

    if (existing) {
      return existing;
    }

    if (getOfflineMode(this.store.getState())) {
      return undefined;
    }

    let blob: Blob | undefined;

    try {
      if (typeof blob === 'undefined') {
        return;
      }
    } catch (e) {
      console.error(e);
      return;
    }

    const encodedBlob = await EvidenceRepository.encodeBlob(blob);

    this.store.dispatch(
      setEvidenceThumbnail({
        id: id.toString(),
        thumbnail: encodedBlob,
      }),
    );

    return encodedBlob;
  }

  /**
   * Encodes the blob as a data URI
   * @param blob{Blob} - The blob data to be encoded
   * @return {string} - The data URI encoded blob data
   * @private
   */
  private static encodeBlob(blob: Blob): Promise<string> {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Failed to parse input blob'));
      };

      fileReader.onload = () => {
        const result = fileReader.result;

        if (!isString(result)) {
          reject(new DOMException('FileReader returned an unexpected non-string result'));
        } else {
          resolve(result);
        }
      };

      fileReader.readAsDataURL(blob);
    });
  }
}
