/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type evidenceBaseFolderQueryVariables = {
    id: string;
    skip: boolean;
};
export type evidenceBaseFolderQueryResponse = {
    readonly folder?: {
        readonly " $fragmentRefs": FragmentRefs<"evidenceBase_dragOverlay" | "evidenceList_folderEvidenceFull" | "evidenceMain_folderName" | "evidenceNav_tagsNav" | "evidenceMain_tagFilter">;
    } | null | undefined;
};
export type evidenceBaseFolderQuery = {
    readonly response: evidenceBaseFolderQueryResponse;
    readonly variables: evidenceBaseFolderQueryVariables;
};



/*
query evidenceBaseFolderQuery(
  $id: ID!
  $skip: Boolean!
) {
  folder: node(id: $id) @skip(if: $skip) {
    __typename
    ...evidenceBase_dragOverlay
    ...evidenceList_folderEvidenceFull
    ...evidenceMain_folderName
    ...evidenceNav_tagsNav
    ...evidenceMain_tagFilter
    id
  }
}

fragment evidenceBase_dragOverlay on Folder {
  __isFolder: __typename
  evidences {
    edges {
      node {
        __typename
        id
        fileName: name
      }
    }
  }
}

fragment evidenceList_folderEvidenceFull on Folder {
  __isFolder: __typename
  evidences {
    edges {
      node {
        __typename
        id
        fileName: name
        storedOn: created
        eventDate
        size
        folderCount
        tagCount
        commentCount
        tags: tagsWithIds {
          id
          text
        }
      }
    }
  }
}

fragment evidenceMain_folderName on Folder {
  __isFolder: __typename
  type: __typename
  name
}

fragment evidenceMain_tagFilter on Folder {
  __isFolder: __typename
  tags {
    id
    tagId
    text
  }
}

fragment evidenceNav_tagsNav on Folder {
  __isFolder: __typename
  id
  tags {
    id
    tagId
    text
    evidenceCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "evidenceBaseFolderQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "folder",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceBase_dragOverlay"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceList_folderEvidenceFull"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceMain_folderName"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceNav_tagsNav"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "evidenceMain_tagFilter"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "evidenceBaseFolderQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "folder",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EvidenceConnection",
                    "kind": "LinkedField",
                    "name": "evidences",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EvidenceEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": "fileName",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": "storedOn",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "created",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "eventDate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "size",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "folderCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tagCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "commentCount",
                                "storageKey": null
                              },
                              {
                                "alias": "tags",
                                "args": null,
                                "concreteType": "TagWithId",
                                "kind": "LinkedField",
                                "name": "tagsWithIds",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "type",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FolderTag",
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tagId",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "evidenceCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Folder",
                "abstractKey": "__isFolder"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "babb0ae7fd4dd61c2f196002ea41c511",
    "id": null,
    "metadata": {},
    "name": "evidenceBaseFolderQuery",
    "operationKind": "query",
    "text": "query evidenceBaseFolderQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  folder: node(id: $id) @skip(if: $skip) {\n    __typename\n    ...evidenceBase_dragOverlay\n    ...evidenceList_folderEvidenceFull\n    ...evidenceMain_folderName\n    ...evidenceNav_tagsNav\n    ...evidenceMain_tagFilter\n    id\n  }\n}\n\nfragment evidenceBase_dragOverlay on Folder {\n  __isFolder: __typename\n  evidences {\n    edges {\n      node {\n        __typename\n        id\n        fileName: name\n      }\n    }\n  }\n}\n\nfragment evidenceList_folderEvidenceFull on Folder {\n  __isFolder: __typename\n  evidences {\n    edges {\n      node {\n        __typename\n        id\n        fileName: name\n        storedOn: created\n        eventDate\n        size\n        folderCount\n        tagCount\n        commentCount\n        tags: tagsWithIds {\n          id\n          text\n        }\n      }\n    }\n  }\n}\n\nfragment evidenceMain_folderName on Folder {\n  __isFolder: __typename\n  type: __typename\n  name\n}\n\nfragment evidenceMain_tagFilter on Folder {\n  __isFolder: __typename\n  tags {\n    id\n    tagId\n    text\n  }\n}\n\nfragment evidenceNav_tagsNav on Folder {\n  __isFolder: __typename\n  id\n  tags {\n    id\n    tagId\n    text\n    evidenceCount\n  }\n}\n"
  }
};
})();
(node as any).hash = '2fe8f484dda997ff1acda392c6d68911';
export default node;
