import React from 'react';

import {ArrayElement} from '../../../utils/utils';

import {TWorkspaceTag} from '../../../state/timeline/state';

import {WorkspaceTagColors} from './color-picker.constants';
import TagNodeColorPickerView from './tag-node-color-picker.view';

type Props = {
  selectedWorkspaceId: string | undefined;
  selectedTag: TWorkspaceTag | undefined;
  handleClose(): void;
  setWorkspaceTagColor(args: {workspaceId: string; tagId: number; color: string}): void;
};

const TagNodeColorPickerController: React.FC<Props> = ({
  selectedWorkspaceId,
  selectedTag,
  handleClose,
  setWorkspaceTagColor,
}) => {
  function saveTagColor(color: ArrayElement<WorkspaceTagColors>) {
    selectedTag &&
      selectedWorkspaceId &&
      setWorkspaceTagColor({
        workspaceId: selectedWorkspaceId,
        tagId: selectedTag.tagId,
        color,
      });
    handleClose();
  }

  return (
    <TagNodeColorPickerView handleClose={handleClose} handleSave={saveTagColor} tag={selectedTag} />
  );
};

export default TagNodeColorPickerController;
