import React, {ReactElement, Suspense} from 'react';

import {graphql, useLazyLoadQuery} from 'react-relay';

import {evidenceDetailUnsortedFolderQuery} from '../../graphql/__generated__/evidenceDetailUnsortedFolderQuery.graphql';

import LoadingScreen from '../feedback/loading-screen';
import EvidenceDetailView from './evidence-detail.view';

type Props = {
  referrer: Referrer['referrer'];
  orientation?: 'horizontal' | 'vertical';
  selectedEvidenceIds: string[];
  taggedEvidence?: string[];
  currentFolderId: string | undefined;
  setSelectedEvidenceIds(ids: string[]): void;
};

export type Referrer = {
  referrer: 'workspace-tag-detail' | 'evidence-node-detail' | 'evidence-list' | undefined;
};

const EvidenceDetailController = ({
  referrer,
  orientation,
  selectedEvidenceIds,
  taggedEvidence,
  currentFolderId,
  setSelectedEvidenceIds,
}: Props): ReactElement => {
  // turn unsortedFolder id into a fragment!
  const data = useLazyLoadQuery<evidenceDetailUnsortedFolderQuery>(
    graphql`
      query evidenceDetailUnsortedFolderQuery {
        currentSession {
          ... on Session {
            account {
              unsortedFolder {
                id
              }
              ...evidenceDetail_activeTags
            }
          }
          ... on SessionError {
            message
          }
        }
      }
    `,
    {},
  );

  const account = data.currentSession?.account ?? undefined;
  if (account === undefined) {
    throw new Error('no account');
  }
  const unsortedFolderId = account.unsortedFolder.id ?? undefined;

  if (referrer !== 'evidence-list') {
    currentFolderId = unsortedFolderId;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    currentFolderId = currentFolderId!;
  }

  return (
    <Suspense fallback={<LoadingScreen local />}>
      <EvidenceDetailView
        account={account}
        currentFolderId={currentFolderId}
        orientation={orientation}
        referrer={referrer}
        selectedEvidenceIds={selectedEvidenceIds}
        setSelectedEvidenceIds={setSelectedEvidenceIds}
        taggedEvidence={taggedEvidence}
      />
    </Suspense>
  );
};

export default EvidenceDetailController;
