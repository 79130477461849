import MsgReader, {FieldsData} from '@kenjiuno/msgreader';
import mime from 'mime-types';
import PostalMime from 'postal-mime/dist/postal-mime';

import {fetchWithSession} from '../../../utils/tokens';
import {readableDateTimeString} from '../../../utils/utils';
import {capitalize} from '../../../utils/utils';

import {JOURNAL_ENTRY_MIMETYPE} from '../../../services/evidences/data/evidence/content-type';
import MimeType from '../../../services/evidences/data/evidence/mime-type';

const EMPTY_BODY_TEXT = '<em>Empty Body Text</em>';

type EmailAddress = {
  name?: string;
  email: string;
};

type InlineAttachment = {
  contentId: string;
  content: Blob;
  filename: string;
  mimeType: MimeType;
};

type Attachments = {
  content: Blob;
  filename: string;
  mimeType: MimeType;
}[];

type Recipients = {
  to: EmailAddress[] | undefined; // ???can it really be undefined
  cc?: EmailAddress[] | undefined;
  bcc?: EmailAddress[] | undefined;
};

type BlobPartsObj = {
  heading: string;
  subject?: string;
  date: string;
  body: string;
  sender?: EmailAddress;
  recipients?: Recipients;
  attachments?: Attachments;
};

type EMLProps = {
  subject?: string;
  date: string;
  html: string;
  from: EMLEmailAddress;
  to: EMLEmailAddress[];
  cc?: EMLEmailAddress[] | undefined;
  bcc?: EMLEmailAddress[] | undefined;
  attachments?: InlineAttachment[];
};

type EMLEmailAddress = {
  name?: string;
  address: string;
};

type EMLRecipients = {
  to: EMLEmailAddress[] | undefined; // ???can it really be undefined
  cc?: EMLEmailAddress[] | undefined;
  bcc?: EMLEmailAddress[] | undefined;
};

type PartWrapper = 'h1' | 'div' | 'p';

type Props = {
  eventDate: Date;
  mimeType: string;
  url?: string;
  subject?: string;
  body?: string;
};

export const parseBlob = async ({
  mimeType,
  url,
  subject,
  body,
  eventDate,
}: Props): Promise<Blob | undefined> => {
  switch (mimeType) {
    // text/journalentry
    case JOURNAL_ENTRY_MIMETYPE: {
      if (body !== undefined) {
        const heading = 'JOURNAL ENTRY';
        const date = readableDateTimeString(eventDate);
        return createHTMLBlob({heading, subject, date, body});
      }
      break;
    }

    // message/rfc822 for .eml and other email files
    case 'message/rfc822': {
      if (url !== undefined) {
        return await createEMLBlob(url);
      }
      break;
    }
    // case 'application/vnd.ms-outlook':
    case 'application/vnd.ms-outlook': {
      if (url !== undefined) {
        return await createMSGBlob(url);
      }
      break;
    }
    // case 'image':
    // case 'audio':
    // case 'video':
    // case 'text/plain':
    default: {
      if (url !== undefined) {
        return await fetchFileBlob(url);
      }
      break;
    }
  }
};

const fetchFileBlob = async (url: string) => {
  const res = await fetchWithSession(url);
  if (res.ok) {
    const blob = await res.blob();
    return blob;
  }
};

const createHTMLBlob = (blobPartsObj: BlobPartsObj) => {
  const blobPartsArray = parseBlobPartsArray(blobPartsObj);
  return new Blob(blobPartsArray, {
    type: 'text/html;charset=utf-8',
  });
};

const createEMLBlob = async (url: string) => {
  const res = await fetchWithSession(url);
  if (res.ok) {
    const msgBuffer = await res.arrayBuffer();

    const parser = new PostalMime();
    const email = await parser.parse(msgBuffer);

    const {subject, date, html, from, to, cc, bcc, attachments} = email as EMLProps;

    // no error check since PostalMime has no error check
    const body =
      html && attachments !== undefined ? attachInlineImages(html, attachments) : EMPTY_BODY_TEXT;
    const sender = {
      name: from.name,
      email: from.address ?? 'Unknown sender email',
    };
    const recipients = {to, cc, bcc};

    return createHTMLBlob({
      heading: 'E-MAIL [eml]',
      date: readableDateTimeString(date),
      sender,
      recipients: parseEmlRecipients(recipients),
      subject,
      body,
      attachments,
    });
  }
};

const parseEmlRecipients = (recipients: EMLRecipients): Recipients => {
  let to = [] as EmailAddress[];
  let cc = [] as EmailAddress[];
  let bcc = [] as EmailAddress[];
  if (recipients !== undefined) {
    for (const [key, value] of Object.entries(recipients)) {
      if (value !== undefined) {
        switch (key) {
          case 'to':
            to = value.map(({name, address}) => ({name, email: address}));
            break;

          case 'cc':
            cc = value.map(({name, address}) => ({name, email: address}));
            break;

          case 'bcc':
            bcc = value.map(({name, address}) => ({name, email: address}));
            break;

          default:
            break;
        }
      }
    }
  }
  return {
    to,
    cc,
    bcc,
  };
};

const createMSGBlob = async (url: string) => {
  const res = await fetchWithSession(url);
  if (res.ok) {
    const msgBuffer = await res.arrayBuffer();
    const msg = new MsgReader(msgBuffer);
    const {
      attachments,
      body,
      messageDeliveryTime,
      recipients,
      senderEmail,
      senderName,
      subject,
      error,
    } = msg.getFileData();

    if (!error) {
      const date = readableDateTimeString(messageDeliveryTime as string);

      const sender = {
        name: senderName,
        email: senderEmail ?? 'Unknown sender email',
      };

      const attachmentsMap = attachments?.map((i) => {
        const {content, fileName} = msg.getAttachment(i);
        const blobContent = new Blob([content]);
        const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        const mimeType = mime.lookup(extension) as MimeType;

        return {
          content: blobContent,
          filename: fileName,
          mimeType,
        };
      });
      return createHTMLBlob({
        heading: 'E-MAIL [msg]',
        date,
        sender,
        recipients: parseMsgRecipients(recipients),
        subject,
        body: body ?? EMPTY_BODY_TEXT,
        attachments: attachmentsMap,
      });
    }
  }
};

const parseMsgRecipients = (recipients: FieldsData[] | undefined): Recipients => {
  const to = [] as EmailAddress[];
  const cc = [] as EmailAddress[];
  const bcc = [] as EmailAddress[];
  if (recipients !== undefined) {
    recipients.forEach((r) => {
      const {name, email, recipType} = r;
      if (name !== undefined && email !== undefined) {
        switch (recipType) {
          case 'to':
            to.push({name, email});
            break;

          case 'cc':
            cc.push({name, email});
            break;

          case 'bcc':
            bcc.push({name, email});
            break;

          default:
            break;
        }
      }
    });
  }
  return {
    to,
    cc,
    bcc,
  };
};

const parseBlobPartsArray = (blobPartsObj: BlobPartsObj): BlobPart[] => {
  const blobParts = [] as BlobPart[];

  Object.entries(blobPartsObj).forEach((entry) => {
    const [key, value] = entry;
    switch (key) {
      case 'body': {
        const body = resetStyles(value as string);
        blobParts.push(
          formatBlobPart({
            value: body,
            wrapper: 'p',
            label: undefined,
            breakAfter: true,
            breakBefore: true,
          }),
        );
        break;
      }
      case 'heading': {
        if (typeof value === 'string') {
          blobParts.push(formatBlobPart({value, wrapper: 'h1'}));
        }
        break;
      }
      case 'sender': {
        const {name, email} = value as EmailAddress;
        const sender = [{name, email}];
        blobParts.push(
          formatBlobPart({value: parseEmailsArray(sender), wrapper: 'div', label: 'From'}),
        );
        break;
      }
      case 'recipients': {
        const {to, cc, bcc} = value as Recipients;

        let toValue = 'undisclosed recipients';
        if (to && to.length > 0) {
          toValue = parseEmailsArray(to);
        }
        blobParts.push(formatBlobPart({value: toValue, wrapper: 'div', label: 'To'}));
        if (cc && cc.length > 0) {
          blobParts.push(
            formatBlobPart({value: parseEmailsArray(cc), wrapper: 'div', label: 'CC'}),
          );
        }

        if (bcc && bcc.length > 0) {
          blobParts.push(
            formatBlobPart({value: parseEmailsArray(bcc), wrapper: 'div', label: 'BCC'}),
          );
        }
        break;
      }
      case 'attachments': {
        const attachmentsArray = value as Attachments;
        if (attachmentsArray.length > 0) {
          const attachmentLinks = [] as string[];
          attachmentsArray.map((a) => {
            const href = URL.createObjectURL(new Blob([a.content], {type: a.mimeType}));
            const textContent = a.filename || `attachment (${a.mimeType})`;
            const attachmentLink = `<li><a href='${href}' target='_blank'>${textContent}</a></li>`;
            attachmentLinks.push(attachmentLink);
          });
          blobParts.push(
            formatBlobPart({
              value: `<ul>${attachmentLinks.join('')}</ul>`,
              wrapper: 'div',
              label: 'Attachments',
            }),
          );
        }

        break;
      }
      default:
        if (typeof key === 'string' && typeof value === 'string') {
          blobParts.push(formatBlobPart({value, wrapper: 'div', label: capitalize(key)}));
        }
        break;
    }
  });

  return blobParts;
};

const parseEmailsArray = (emailArray: EmailAddress[]): string => {
  return emailArray
    .map(({name, email}) => {
      return `${name} &lt;${email}&gt;`;
    })
    .join(', ')
    .trim();
};

const formatBlobPart = ({
  value,
  wrapper,
  label,
  breakAfter,
  breakBefore,
}: {
  value: string;
  wrapper?: PartWrapper;
  label?: string;
  breakAfter?: boolean;
  breakBefore?: boolean;
}): string => {
  let part = value;
  if (label) {
    part = `<b>${label}:</b> ${value}`;
  }
  if (wrapper) {
    part = `<${wrapper}>${part}</${wrapper}>`;
  }
  if (breakBefore) {
    part = `<br/>${part}`;
  }
  if (breakAfter) {
    part = `${part}<br/>`;
  }
  return part;
};

const attachInlineImages = (html: string, attachmentsArray: InlineAttachment[]): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  doc.querySelectorAll('img').forEach((img) => {
    if (/^cid:/.test(img.src)) {
      // replace with inline attachment
      const cid = img.src.substring(4).trim();
      const attachment = attachmentsArray.find(
        (attachment) => attachment.contentId && attachment.contentId === `<${cid}>`,
      );
      if (attachment) {
        img.src = URL.createObjectURL(new Blob([attachment.content], {type: attachment.mimeType}));
      }
    }
  });
  return doc.documentElement.innerHTML;
};

const resetStyles = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const styleTags = doc.querySelectorAll('style');
  Array.from(styleTags).map((st: HTMLStyleElement) => st.remove());
  const newStyleTag = document.createElement('style');
  newStyleTag.textContent = `
      body {
        margin: 1rem auto;
        max-width: 60rem;
        padding: 0 1rem;
        line-height: 1.4;
      }
    `;
  doc.documentElement.prepend(newStyleTag);
  return doc.documentElement.innerHTML;
};

export default {};
