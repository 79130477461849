import produce from 'immer';
import {ActionType, createReducer} from 'typesafe-actions';

import {DEFAULT_TAG_COLOR} from '../../pages/timeline/tag-node-color-picker/color-picker.constants';
import {BACKGROUND_COLOR_OPTION_ID} from '../../pages/timeline/timeline.constants';
import * as actions from './actions';
import {
  addWorkspace,
  removeWorkspace,
  setEvidenceVisibilityStatus,
  setEvidences,
  setFolderFilters,
  setFolders,
  setIsLoading,
  setLockHighlight,
  setOpenedWorkspaceTag,
  setSelectedWorkspaceId,
  setShowEvidenceCounts,
  setShowLines,
  setTagFilters,
  setTags,
  setTypeFilters,
  setTypes,
  setWorkspaceBackground,
  setWorkspaceModified,
  setWorkspaceName,
  setWorkspacePosition,
  setWorkspaceSortKey,
  setWorkspaceTagColor,
  setWorkspaceTagPosition,
  setWorkspaceTags,
  setWorkspaceZoomIndex,
  setWorkspaces,
  toggleFolderFilter,
  toggleTagFilter,
  toggleTypeFilter,
  unsetSelectedWorkspaceId,
  updateWorkspace,
} from './actions';
import {
  DEFAULT_TIMELINE_STATE, // TimelineState,
  DEFAULT_WORKSPACE_STATE,
  TCaseFolder,
  TCaseTag,
  TEvidenceMimeType,
  TTimelineEvidence,
  TTimelineState,
  TWorkspace,
} from './state';

type TimelineAction = ActionType<typeof actions>;

const timelineReducer = createReducer<TTimelineState, TimelineAction>(DEFAULT_TIMELINE_STATE)
  .handleAction(
    setSelectedWorkspaceId,
    produce((state: TTimelineState, action) => {
      if (state.entities.workspaces.byId[action.payload]) {
        state.selectedWorkspaceId = action.payload;
        state.entities.workspaces.byId[action.payload].showLines = false;
        state.entities.workspaces.byId[action.payload].showEvidenceCounts = false;

        const workspace = state.entities.workspaces.byId[action.payload];
        state.filters.folders = workspace.filters?.folders;
        state.filters.tags = workspace.filters?.tags;
        state.filters.types = workspace.filters?.types;
      }
    }),
  )
  .handleAction(
    unsetSelectedWorkspaceId,
    produce((state: TTimelineState) => {
      state.selectedWorkspaceId = undefined;
      state.filters.folders = [];
      state.filters.tags = [];
      state.filters.types = [];
      state.visibleEvidences = {};
    }),
  )
  .handleAction(
    setWorkspaces,
    produce((state: TTimelineState, action) => {
      action.payload.forEach((workspace: TWorkspace) => {
        state.entities.workspaces.byId[workspace.id] = workspace;
      });
      state.entities.workspaces.allIds = action.payload.map((w: TWorkspace) => w.id);
    }),
  )
  .handleAction(
    removeWorkspace,
    produce((state: TTimelineState, action) => {
      delete state.entities.workspaces.byId[action.payload];
      state.entities.workspaces.allIds = state.entities.workspaces.allIds.filter(
        (id) => id !== action.payload,
      );
    }),
  )
  .handleAction(
    addWorkspace,
    produce((state: TTimelineState, action) => {
      state.entities.workspaces.byId[action.payload.id] = {
        ...DEFAULT_WORKSPACE_STATE,
        ...action.payload,
      };
      state.entities.workspaces.allIds.push(action.payload.id);
    }),
  )
  .handleAction(
    updateWorkspace,
    produce((state: TTimelineState, action) => {
      if (typeof action.payload.id === 'undefined') {
        return;
      }

      state.entities.workspaces.byId[action.payload.id] = {
        ...state.entities.workspaces.byId[action.payload.id],
        ...action.payload,
      };
    }),
  )

  .handleAction(
    setWorkspaceSortKey,
    produce((state: TTimelineState, action) => {
      state.workspaceSortKey = action.payload;
    }),
  )
  .handleAction(
    setOpenedWorkspaceTag,
    produce((state: TTimelineState, action) => {
      state.openedWorkspaceTag = action.payload;
    }),
  )
  .handleAction(
    actions.setOpenedEvidenceNode,
    produce((state: TTimelineState, action) => {
      state.openedEvidenceNode = action.payload;
    }),
  )
  .handleAction(
    setWorkspaceTags,
    produce((state: TTimelineState, action) => {
      const {workspaceId, tags} = action.payload;

      if (typeof state.entities.workspaces.byId[workspaceId] === 'undefined') {
        return;
      }

      state.entities.workspaces.byId[workspaceId].tags = tags;
    }),
  )
  .handleAction(
    setFolderFilters,
    produce((state: TTimelineState, action) => {
      const {workspaceId, folders} = action.payload;

      if (state.entities.workspaces.byId[workspaceId]) {
        // Array must be copied to avoid read-only properties of array
        state.entities.workspaces.byId[workspaceId].filters.folders = [...folders];
        // Array must be copied to avoid read-only properties of array
        state.filters.folders = [...folders];
      }
    }),
  )
  .handleAction(
    setTagFilters,
    produce((state, action) => {
      const {workspaceId, tags} = action.payload;

      if (state.entities.workspaces.byId[workspaceId]) {
        // Array must be copied to avoid read-only properties of array
        state.entities.workspaces.byId[workspaceId].filters.tags = [...tags];
        // Array must be copied to avoid read-only properties of array
        state.filters.tags = [...tags];
      }
    }),
  )
  .handleAction(
    setTypeFilters,
    produce((state: TTimelineState, action) => {
      const {workspaceId, types} = action.payload;

      if (state.entities.workspaces.byId[workspaceId]) {
        // Array must be copied to avoid read-only properties of array
        state.entities.workspaces.byId[workspaceId].filters.types = [...types];
        // Array must be copied to avoid read-only properties of array
        state.filters.types = [...types];
      }
    }),
  )
  .handleAction(
    toggleFolderFilter,
    produce((state: TTimelineState, action) => {
      const {workspaceId, folderId} = action.payload;
      const workspace = state.entities.workspaces.byId[workspaceId];
      if (workspace) {
        const folderIdIndex = state.entities.workspaces.byId[workspaceId].filters.folders.findIndex(
          (id) => id === folderId,
        );
        if (folderIdIndex === -1) {
          const folderIds = state.entities.workspaces.byId[workspaceId].filters.folders;
          state.entities.workspaces.byId[workspaceId].filters.folders = [...folderIds, folderId];
          state.filters.folders = [...folderIds, folderId];
        } else {
          const folderIds = state.entities.workspaces.byId[workspaceId].filters.folders.filter(
            (f) => f !== folderId,
          );
          state.entities.workspaces.byId[workspaceId].filters.folders = [...folderIds];
          state.filters.folders = [...folderIds];
        }
      }

      return state;
    }),
  )
  .handleAction(
    toggleTagFilter,
    produce((state: TTimelineState, action) => {
      const {workspaceId, tagId} = action.payload;
      const workspace = state.entities.workspaces.byId[workspaceId];
      if (workspace) {
        const tagIdIndex = state.entities.workspaces.byId[workspaceId].filters.tags.findIndex(
          (id) => id === tagId,
        );
        if (tagIdIndex === -1) {
          const tagIds = state.entities.workspaces.byId[workspaceId].filters.tags;
          state.entities.workspaces.byId[workspaceId].filters.tags = [...tagIds, tagId];
          state.filters.tags = [...tagIds, tagId];
        } else {
          const tagIds = state.entities.workspaces.byId[workspaceId].filters.tags.filter(
            (t) => t !== tagId,
          );
          state.entities.workspaces.byId[workspaceId].filters.tags = tagIds;
          state.filters.tags = tagIds;
        }
      }

      return state;
    }),
  )
  .handleAction(
    toggleTypeFilter,
    produce((state: TTimelineState, action) => {
      const {workspaceId, typeId} = action.payload;
      const workspace = state.entities.workspaces.byId[workspaceId];
      if (workspace) {
        const typeIdIndex = state.entities.workspaces.byId[workspaceId].filters.types.findIndex(
          (id) => id === typeId,
        );
        if (typeIdIndex === -1) {
          const typeIds = [...state.entities.workspaces.byId[workspaceId].filters.types, typeId];
          state.entities.workspaces.byId[workspaceId].filters.types = typeIds;
          state.filters.types = typeIds;
        } else {
          const typeIds = state.entities.workspaces.byId[workspaceId].filters.types.filter(
            (t) => t !== typeId,
          );
          state.entities.workspaces.byId[workspaceId].filters.types = typeIds;
          state.filters.types = typeIds;
        }
      }

      return state;
    }),
  )
  .handleAction(
    setFolders,
    produce((state: TTimelineState, action) => {
      action.payload.forEach((folder: TCaseFolder) => {
        state.entities.folders.byId[folder.id] = folder;
      });
      state.entities.folders.allIds = action.payload.map((f: TCaseFolder) => f.id);
    }),
  )
  .handleAction(
    setTags,
    produce((state: TTimelineState, action) => {
      action.payload.forEach((tag: TCaseTag) => {
        state.entities.tags.byId[tag.id] = tag;
      });
      state.entities.tags.allIds = action.payload.map((t: TCaseTag) => t.id);
    }),
  )
  .handleAction(
    setTypes,
    produce((state: TTimelineState, action) => {
      action.payload.forEach((type: TEvidenceMimeType) => {
        state.entities.types.byId[type.id] = type;
      });
      state.entities.types.allIds = action.payload.map((t: TEvidenceMimeType) => t.id);
    }),
  )
  .handleAction(
    setWorkspaceBackground,
    produce((state: TTimelineState, action) => {
      const {workspaceId, value} = action.payload;

      if (state.entities.workspaces.byId[workspaceId]) {
        // const backgroundOption = new WorkspaceBackgroundOption(shadeToColorIndex(shade));

        const newOptions = state.entities.workspaces.byId[workspaceId].options?.map((option) => {
          if (option.optionId !== BACKGROUND_COLOR_OPTION_ID) {
            // This isn't the item we care about - keep it as-is
            return option;
          }

          // Otherwise, this is the one we want - return an updated value
          return {
            ...option,
            value,
          };
        });

        state.entities.workspaces.byId[workspaceId] = {
          ...state.entities.workspaces.byId[workspaceId],
          options: newOptions,
        };
      }
    }),
  )
  .handleAction(
    setWorkspaceModified,
    produce((state: TTimelineState, action) => {
      const {workspaceId, modified} = action.payload;

      state.entities.workspaces.byId[workspaceId].modified = modified;
    }),
  )
  .handleAction(
    setWorkspaceName,
    produce((state: TTimelineState, action) => {
      const {workspaceId, name} = action.payload;

      state.entities.workspaces.byId[workspaceId].name = name;
    }),
  )
  .handleAction(
    setLockHighlight,
    produce((state: TTimelineState, action) => {
      const {workspaceId, tagId, highlight} = action.payload;

      if (state.entities.workspaces.byId[workspaceId]) {
        const tagIndex = state.entities.workspaces.byId[workspaceId].tags.findIndex(
          (tag) => tag.tagId === tagId,
        );

        if (tagIndex !== -1) {
          state.entities.workspaces.byId[workspaceId].tags[tagIndex].lockHighlight = highlight;
        }
      }
    }),
  )
  .handleAction(
    setEvidenceVisibilityStatus,
    produce((state: TTimelineState, action) => {
      const {evidenceId, visible} = action.payload;
      if (visible) {
        state.visibleEvidenceIds.push(evidenceId);
      } else {
        state.visibleEvidenceIds = state.visibleEvidenceIds.filter((id) => id !== evidenceId);
      }
    }),
  )
  .handleAction(
    setWorkspaceTagPosition,
    produce((state: TTimelineState, action: ReturnType<typeof setWorkspaceTagPosition>) => {
      const {workspaceId, tagId, coordinates} = action.payload;
      const tagIndex = state.entities.workspaces.byId[workspaceId]?.tags.findIndex(
        (tag) => tag.tagId === tagId,
      );

      if (tagIndex !== -1) {
        state.entities.workspaces.byId[workspaceId].tags[tagIndex].coordinates = coordinates;
      } else {
        const tag = state.entities.tags.byId[tagId];
        if (tag) {
          // const workspaceTag = new WorkspaceTag(tag, undefined, x, y);
          const workspaceTag = {
            tagId: tag.id,
            text: tag.text,
            coordinates,
            color: DEFAULT_TAG_COLOR,
            lockHighlight: false,
          };

          state.entities.workspaces.byId[workspaceId].tags.push(workspaceTag);
        }
      }
    }),
  )
  .handleAction(
    setWorkspacePosition,
    produce((state: TTimelineState, action: ReturnType<typeof setWorkspacePosition>) => {
      const {workspaceId, x, y} = action.payload;

      const workspace = state.entities.workspaces.byId[workspaceId];

      if (workspace) {
        if (typeof x !== 'undefined') {
          state.entities.workspaces.byId[workspaceId].coordinates.x = Math.round(x);
        }
        if (typeof y !== 'undefined') {
          state.entities.workspaces.byId[workspaceId].coordinates.y = Math.round(y);
        }
      }
    }),
  )
  .handleAction(
    setEvidences,
    produce((state: TTimelineState, action) => {
      action.payload.forEach((evidence: TTimelineEvidence) => {
        state.entities.evidences.byId[evidence.id] = evidence;
      });
      state.entities.evidences.allIds = action.payload.map((e: TTimelineEvidence) => e.id);
    }),
  )
  .handleAction(
    setWorkspaceTagColor,
    produce((state: TTimelineState, action: ReturnType<typeof setWorkspaceTagColor>) => {
      const {workspaceId, tagId, color} = action.payload;

      if (state.entities.workspaces.byId[workspaceId]) {
        const tagIndex = state.entities.workspaces.byId[workspaceId].tags.findIndex(
          (tag) => tag.tagId === tagId,
        );

        if (tagIndex !== -1) {
          state.entities.workspaces.byId[workspaceId].tags[tagIndex].color = color;
        } else {
          const tag = state.entities.tags.byId[tagId];
          if (tag) {
            // const workspaceTag = new WorkspaceTag(tag, undefined, x, y);
            const workspaceTag = {
              tagId: tag.id,
              text: tag.text,
              coordinates: {x: 0, y: 0},
              color: color,
              lockHighlight: false,
            };
            state.entities.workspaces.byId[workspaceId].tags.push(workspaceTag);
          }
        }
      }
    }),
  )
  .handleAction(
    setIsLoading,
    produce((state: TTimelineState, action: ReturnType<typeof setIsLoading>) => {
      state.isLoading = action.payload;
    }),
  )
  .handleAction(
    setShowLines,
    produce((state: TTimelineState, action: ReturnType<typeof setShowLines>) => {
      const {workspaceId, showLines} = action.payload;
      if (state.entities.workspaces.byId[workspaceId]) {
        state.entities.workspaces.byId[workspaceId].showLines = showLines;
      }
    }),
  )
  .handleAction(
    setShowEvidenceCounts,
    produce((state: TTimelineState, action: ReturnType<typeof setShowEvidenceCounts>) => {
      const {workspaceId, showEvidenceCounts} = action.payload;
      if (state.entities.workspaces.byId[workspaceId]) {
        state.entities.workspaces.byId[workspaceId].showEvidenceCounts = showEvidenceCounts;
      }
    }),
  )
  .handleAction(
    setWorkspaceZoomIndex,
    produce((state: TTimelineState, action) => {
      const {workspaceId, zoomIndex} = action.payload;
      if (state.entities.workspaces.byId[workspaceId]) {
        state.entities.workspaces.byId[workspaceId].zoomIndex = zoomIndex;
      }
    }),
  );

export default timelineReducer;
