import {connect} from 'react-redux';

import {TRootState} from '../../../../state/store';
import {addWorkspace} from '../../../../state/timeline/actions';
import {getWorkspaces} from '../../../../state/timeline/selectors';

import CloneWorkspaceController from './clone-workspace.controller';

const mapStateToProps = (state: TRootState) => ({
  workspaces: getWorkspaces(state),
});

const mapDispatchToProps = {addWorkspace};

const CloneWorkspace = connect(mapStateToProps, mapDispatchToProps)(CloneWorkspaceController);

export default CloneWorkspace;
