import {connect} from 'react-redux';

import {TRootState} from '../../../../state/store';
import {setWorkspaceBackground} from '../../../../state/timeline/actions';
import {getSelectedWorkspace} from '../../../../state/timeline/selectors';

import WorkspaceOptionsModalView from './workspace-options-modal.view';

const mapStateToProps = (state: TRootState) => ({
  selectedWorkspace: getSelectedWorkspace(state),
});

const dispatchToProps = {setWorkspaceBackground};

const WorkspaceOptionsModal = connect(mapStateToProps, dispatchToProps)(WorkspaceOptionsModalView);

export default WorkspaceOptionsModal;
