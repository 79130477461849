import React, {ReactElement} from 'react';

import LoadingScreen from '../../../components/feedback/loading-screen';

import {evidenceNav_foldersNav$key} from '../../../graphql/__generated__/evidenceNav_foldersNav.graphql';
import {evidenceNav_tagsNav$key} from '../../../graphql/__generated__/evidenceNav_tagsNav.graphql';

import EvidenceNavView from './evidence-nav.view';

type Props = {
  account: evidenceNav_foldersNav$key;
  currentFolderId: string | undefined;
  currentTagFilterIds: number[];
  folder: evidenceNav_tagsNav$key;
  setCurrentFolderId(id: string): void;
  setCurrentTagFilterIds(ids: number[]): void;
};

function EvidenceNavController({
  account,
  currentFolderId,
  currentTagFilterIds,
  folder,
  setCurrentFolderId,
  setCurrentTagFilterIds,
}: Props): ReactElement {
  if (currentFolderId === undefined) {
    return <LoadingScreen />;
  }

  return (
    <EvidenceNavView
      account={account as evidenceNav_foldersNav$key}
      currentFolderId={currentFolderId}
      currentTagFilterIds={currentTagFilterIds}
      folder={folder as evidenceNav_tagsNav$key}
      setCurrentFolderId={setCurrentFolderId}
      setCurrentTagFilterIds={setCurrentTagFilterIds}
    />
  );
}

export default EvidenceNavController;
