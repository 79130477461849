import React, {Component, ErrorInfo, ReactNode} from 'react';

import {WithStyles, createStyles, withStyles} from '@material-ui/core/styles';

const styles = createStyles({
  modal: {
    position: 'fixed',
    zIndex: 1,
    paddingTop: '100px',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#2e3f42',
  },
  modalContent: {
    backgroundColor: '#fefefe',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #888',
    width: '80%',
    height: '40%',
    textAlign: 'center',
  },
});

interface Props extends WithStyles<typeof styles> {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: '',
  };

  public static getDerivedStateFromError(e: Error): State {
    return {hasError: true, error: e.toString()};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const {classes, children} = this.props;
    const {hasError, error} = this.state;

    if (hasError) {
      return (
        <div className={classes.modal}>
          <div className={classes.modalContent}>
            <h2>App Crashed</h2>
            <p>Something went wrong...</p>
            {error}
          </div>
        </div>
      );
    }

    return children;
  }
}

export default withStyles(styles)(ErrorBoundary);
